import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppHasAccess } from '@/components/app-has-access';
import { AppType, ReceiptType } from '@/core.types';
import { useBooleanState } from '@/hooks/use-boolean-state';
import { hasAppAccess } from '@/services/app-service';

import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import {
  getDateFromUnixTimestamp,
  getUnixTimestampFromDate,
} from '@/services/helper-service';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { AppIcon, DefaultInput } from '../components';
import { AppDatePicker } from '../components/app-date-picker';
import { AppNumberInput } from '../components/app-number-input/app-number-input';
import { CreateReceiptModalTree } from './create-receipt-modal-tree';
import { UpdateReceiptDTO } from './use-create-receipt-logic';

export const useReminders = true;

type CreateReceiptInputSectionProps = {
  formState: UpdateReceiptDTO;
  receiptType: ReceiptType;
  setField(field: keyof UpdateReceiptDTO, value: any): void;
};

const NAME_MAX_LENGTH = 70;

export const INPUT_SECTION_PREFIX = 'step-three-input-section';

export const CreateReceiptInputSection = ({
  setField,
  formState,
  receiptType,
}: CreateReceiptInputSectionProps) => {
  const { formatMessage: t } = useIntl();
  const { activeSliceSelector } = useContext(CategoryTreeContext);
  const activeSlice = activeSliceSelector();
  const [treeModalOpened, openTreeModal, closeTreeModal] =
    useBooleanState(false);
  const onChangeAmount = ({ currentTarget: { value } }: any) => {
    setField('amount', value);
  };

  const onDateChange = (date) => {
    setField('receiptDate', getUnixTimestampFromDate(date.value));
  };

  const onRemindDateChange = (date) => {
    setField('remindedAt', getUnixTimestampFromDate(date.value));
  };

  const onChangeName = (event) => {
    const {
      currentTarget: { value },
    } = event;
    if (value.length <= NAME_MAX_LENGTH) {
      setField('name', value);
    } else {
      event.preventDefault();
    }
  };

  return (
    <div
      className='w-[298px] justify-self-center flex flex-col justify-center gap-[15px]'
      id={INPUT_SECTION_PREFIX}
    >
      <DefaultInput
        {...{
          value: formState.name,
          placeholder: t({
            id: hasAppAccess(AppType.ireceipt)
              ? 'receipt.name'
              : 'document.name',
          }),
          onChange: onChangeName,
          wrapperClassName: '!mt-auto !mb-0 !ml-0 !mr-0',
        }}
      />
      <AppHasAccess appType={AppType.ireceipt}>
        <AppNumberInput
          {...{
            value: formState.amount,
            placeholder: t({ id: 'amount' }),
            onChange: onChangeAmount,
          }}
        />
      </AppHasAccess>
      {receiptType === ReceiptType.invoice && useReminders && (
        <AppDatePicker
          value={getDateFromUnixTimestamp(
            formState.remindedAt || formState.receiptDate,
          )}
          onChange={onRemindDateChange}
          placeholder={t({ id: 'menu.profile.date' })}
          iconName='clock'
          iconSize={{ width: 18, height: 18 }}
          timeSelection
        />
      )}
      <AppDatePicker
        value={getDateFromUnixTimestamp(formState.receiptDate)}
        onChange={onDateChange}
        placeholder={t({ id: 'menu.profile.date' })}
        iconName='calendar'
      />

      <button
        onClick={openTreeModal}
        className='w-full h-[40px] rounded-[40px] border border-[var(--secondary)] flex justify-between items-center text-[var(--textMain60)] bg-[var(--mainBackground)] p-[10px_15px] text-[14px] leading-[22px]'
      >
        {activeSlice?.name || 'Home'}
        <AppIcon
          name='folder-open'
          iconSize={{ width: 20, height: 20 }}
          color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
        />
      </button>

      <CreateReceiptModalTree {...{ treeModalOpened, closeTreeModal }} />
    </div>
  );
};
