import { AppButton } from '@/components/app-button';
import { AppIcon } from './app-icon';

type AppForwardButtonProps = {
  onNextClick(): void;
  label: string;
};

export const AppForwardButton = ({
  onNextClick,
  label,
}: AppForwardButtonProps) => {
  return (
    <AppButton
      className='flex items-center cursor-pointer'
      onPress={onNextClick}
    >
      <div className='text-[16px] text-[var(--icons)] select-none'>
        {' '}
        {label}{' '}
      </div>
      <AppIcon className='ml-[10px]' name='arrow-right' />
    </AppButton>
  );
};
