import { useIntl } from 'react-intl';
import { ReceiptType } from '../core.types';

export const useGetReceiptName = ({
  name,
  type,
}: {
  type?: ReceiptType;
  name?: string;
}) => {
  const { formatMessage: t } = useIntl();
  if (name) {
    return name;
  }

  switch (type) {
    case ReceiptType.receipt:
      return t({ id: 'unfilledReceipt' });
    case ReceiptType.invoice:
      return t({ id: 'unfilledInvoice' });
    case ReceiptType.document:
      return t({ id: 'unfilledDocument' });
  }
};
