import { AppLoader } from '@/components/app-loader';
import { useIntl } from 'react-intl';
import { useQuerySharedAccounts } from './use-query-shared-accounts';
import { AccountItem } from '../../account-item';

export const Account = () => {
  const { data, activeId, loading, switchAccount, changeAccountInprogress } =
    useQuerySharedAccounts();
  const { formatMessage: t } = useIntl();

  const onAccountItemClick = (accountId: number) => {
    if (activeId !== accountId) {
      switchAccount(accountId);
    }
  };

  const finalLoading =
    !data || loading || !data?.availableAccount || changeAccountInprogress;

  const titleClass =
    'font-normal font-medium text-sm leading-6 text-[var(--textMain)] opacity-60 mb-2.5';

  return (
    <div className='font-roboto p-6 pt-6 pb-5 tablet:max-w-[667px] tablet:mx-auto laptop:w-[45%] laptop:p-0 laptop:mx-auto'>
      <div>
        <div className={titleClass}>
          {t({ id: 'menu.settings.account.personal' })}
        </div>

        {data && (
          <AccountItem
            userIcon={data.personalAccount.ownerPictureUrl}
            ownerFullName={data.personalAccount.ownerFullName}
            userCurrency={data.personalAccount.ownerCurrency}
            onClick={() => onAccountItemClick(data.personalAccount.id)}
            isActive={activeId === data.personalAccount.id}
          />
        )}
      </div>

      <div className='bg-[var(--textMain10)] h-[2px] my-4' />

      <div>
        {data?.availableAccount?.length > 0 && (
          <div className={titleClass}>
            {t({ id: 'menu.settings.account.premium' })}
          </div>
        )}
        <ul className='pl-0 mb-0'>
          {data?.availableAccount.map(
            ({ id, ownerPictureUrl, ownerFullName, ownerCurrency }) => {
              return (
                <li key={id}>
                  <AccountItem
                    userIcon={ownerPictureUrl}
                    ownerFullName={ownerFullName}
                    userCurrency={ownerCurrency}
                    onClick={() => onAccountItemClick(id)}
                    isActive={activeId === id}
                  />
                </li>
              );
            },
          )}
        </ul>
      </div>
      {finalLoading && <AppLoader />}
    </div>
  );
};
