import { CategoryId, ReceiptId } from '@/core.types';
import { useContext } from 'react';
import { generatePdfFromReceiptList } from '@/services/pdf-service';
import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';

type DTO = {
  selectedCategoryIdsList: CategoryId[];
  selectedReceiptIdsList: ReceiptId[];
};

export const useGetPDFBlobFromSelectedList = () => {
  const { getReceiptsFromSelectedLists } = useContext(CategoryTreeContext);
  return ({ selectedCategoryIdsList, selectedReceiptIdsList }: DTO) => {
    const selectedReceipts = getReceiptsFromSelectedLists({
      selectedCategoryIdsList,
      selectedReceiptIdsList,
    });
    return generatePdfFromReceiptList(selectedReceipts);
  };
};

export const useGetReceiptsFromSelectedTitle = () => {
  const { getReceiptsFromSelectedTitles } = useContext(CategoryTreeContext);
  return ({ selectedCategoryIdsList, selectedReceiptIdsList }: DTO) => {
    const maxTitles = 3;
    let titles = getReceiptsFromSelectedTitles({
      selectedCategoryIdsList,
      selectedReceiptIdsList,
    });
    const totalTitles = titles.length;
    titles = titles.slice(0, maxTitles);
    let title = titles.join(', ');
    if (totalTitles > maxTitles) {
      // TODO: Translate it
      title += ' et al.';
    }
    return title;
  };
};
