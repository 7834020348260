import { delay } from '@/services/helper-service';

export class OnlineDispatcher {
  private pingTimeout = 1500; // Timeout for each ping in ms
  private pingInterval = 5000; // Interval between pings in ms
  private initialized = false;
  private url: string;
  private isOnline: boolean = window.navigator.onLine;
  private onlineEvent: Event = new Event('serverOnline');
  private offlineEvent: Event = new Event('serverOffline');
  private isMonitoring = false;

  constructor() {
    this.url = `${import.meta.env.VITE_WEBSITE_URL}healthcheck`;
    this.startMonitoring();
  }

  /**
   * Starts the monitoring loop to periodically check server status.
   */
  private async startMonitoring() {
    if (this.isMonitoring) return; // Prevent multiple instances
    this.isMonitoring = true;

    while (this.isMonitoring) {
      try {
        const online = window.navigator.onLine
          ? await this.isAlive('GET', this.url)
          : false;

        if (online !== this.isOnline) {
          this.isOnline = online;
          this.dispatchEvent();
        }

        if (!this.initialized) {
          this.initialized = true;
        }

        await delay(this.pingInterval);
      } catch (error) {
        console.error('Error during online monitoring:', error);
        await delay(this.pingInterval);
      }
    }
  }

  /**
   * Waits until the dispatcher has completed its first initialization.
   */
  public async waitForReady(): Promise<void> {
    if (this.initialized) return;

    return new Promise((resolve) => {
      const checkInitialized = () => {
        if (this.initialized) {
          window.removeEventListener('serverOnline', checkInitialized);
          window.removeEventListener('serverOffline', checkInitialized);
          resolve();
        }
      };
      window.addEventListener('serverOnline', checkInitialized);
      window.addEventListener('serverOffline', checkInitialized);
    });
  }

  /**
   * Dispatches the appropriate event based on the server's online status.
   */
  private dispatchEvent() {
    console.log(`Server is ${this.isOnline ? 'online' : 'offline'}`);
    if (this.isOnline) {
      window.dispatchEvent(this.onlineEvent);
    } else {
      window.dispatchEvent(this.offlineEvent);
    }
  }

  /**
   * Checks if the server is alive by sending a request.
   * @param method HTTP method to use.
   * @param url URL to send the request to.
   * @returns Promise that resolves to true if the server is alive, else false.
   */
  private async isAlive(method: string, url: string): Promise<boolean> {
    try {
      const controller = new AbortController();
      const timeout = setTimeout(() => controller.abort(), this.pingTimeout);

      const response = await fetch(url, {
        method,
        signal: controller.signal,
      });

      clearTimeout(timeout);
      return response.ok;
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        if (error.name === 'AbortError') {
          console.warn('Ping request timed out');
        } else {
          console.error('Ping request failed:', error);
        }
      }
      return false;
    }
  }

  /**
   * Stops the monitoring loop.
   */
  public stopMonitoring() {
    this.isMonitoring = false;
  }
}
