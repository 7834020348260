// @ts-nocheck
import CropArea from './CropArea';
import Handle from './Handle';
import Point from './Point';
import Polygon from './Polygon';

import { sortPoints } from '../CropImages/open-cv-helpers-service';
import HandleLine from './HandleLine';
import helpers, { rotatePoint90Deg } from './helpers';
import { updateContainerBackground } from './id-crop-helpers';

export default class IdCrop {
  constructor(config) {
    this.config = {
      pointsList: config.pointsList ? config.pointsList : null,
      imgSrc: config.imgSrc,
      decreasePercent: null,
      size: config.size ? config.size : null,
      allowUpload: false,
      closeButtonSelector:
        typeof config.closeButtonSelector !== 'undefined'
          ? config.closeButtonSelector
          : false,
      containers: {
        displayArea: document.querySelector(config.displaySelector),
        refContainer: config.refContainer,
        previewArea: '',
      },
      crop: {
        overlayColor: 'rgba(0, 0, 0, 0)',
        fillColor: false,
        showImage: true,
        stroke: true,
        strokeColor: false,
        strokeDashed: false,
        strokeWeight: 0,
      },
    };

    this.handles = [];
    this.handlesLine = [];
    this.currentRotateDegree = 0;
  }

  updateHandleLines() {
    // !Note this method is used in Polygon.js
    for (let i = 0; i < this.handlesLine.length; i++) {
      this.handlesLine[i].updateByHandles();
    }
  }

  resizePoints(points, percent) {
    return [
      ...points.map(({ x, y }) => ({
        x: (x / 100) * (100 - percent),
        y: (y / 100) * (100 - percent),
      })),
    ];
  }

  resizePointsUp(points, percent) {
    // !Note this method is used in Poly.js
    return [
      ...points.map(({ x, y }) => ({
        x: (x / (100 - percent)) * 100,
        y: (y / (100 - percent)) * 100,
      })),
    ];
  }

  updateConfig(updateImg) {
    const { containers, size } = this.config;
    let { decreasePercent, pointsList: points } = this.config;
    const refContainerNode = containers.refContainer.current;
    if (!refContainerNode) {
      return null;
    }

    const refContainerStyle = window.getComputedStyle(refContainerNode),
      padding =
        parseFloat(refContainerStyle.paddingLeft) +
        parseFloat(refContainerStyle.paddingRight) +
        parseFloat(refContainerStyle.paddingBottom) +
        parseFloat(refContainerStyle.paddingTop);

    const realContainerWidth = refContainerNode.offsetWidth - padding;
    const wrapWidth = realContainerWidth;
    const wrapHeight = refContainerNode.offsetHeight - padding;
    const { imgWidth, imgHeight } = size;
    let height = imgHeight,
      width = imgWidth;

    if (wrapHeight < imgHeight) {
      height = wrapHeight;
      // calculate the percentage reduction
      decreasePercent = (1 - wrapHeight / imgHeight) * 100;
      width = imgWidth - (imgWidth / 100) * decreasePercent;
    }

    if (wrapWidth < width) {
      width = wrapWidth;
      // calculate the percentage reduction
      decreasePercent = (1 - wrapWidth / imgWidth) * 100;
      height = imgHeight - (imgHeight / 100) * decreasePercent;
    }

    decreasePercent = 100 - width / (imgWidth / 100);

    if (!updateImg) {
      points = this.resizePoints(points, decreasePercent);
    }

    this.config = {
      ...this.config,
      decreasePercent,
      pointsList: [...points],
      size: {
        ...size,
        width,
        height,
      },
    };
    containers.displayArea.classList.add('idwall-display');
  }

  async init(updateImg) {
    this.updateConfig(updateImg);
    const base64 = await helpers.dataURIFromSrc(this.config.imgSrc);
    await this.startCroppingArea(base64, this.config.pointsList);
  }

  getHandleLineCenters(targetPoints) {
    try {
      const centers = [];
      let lastPoint = {};
      let lastHandle = {};
      let firstHandle = {};

      if (!targetPoints) {
        return centers;
      }

      targetPoints.forEach((targetPoint, index) => {
        const handle = this.createHandles({ ...targetPoint }, index);
        if (index > 0) {
          const xx = (targetPoint.x + lastPoint.x) / 2;
          const yy = (targetPoint.y + lastPoint.y) / 2;
          centers.push({
            x: xx,
            y: yy,
            handle1: lastHandle,
            handle2: handle,
          });
        } else {
          firstHandle = handle;
        }
        lastPoint = targetPoint;
        lastHandle = handle;
      });

      const xx = (targetPoints[0].x + lastPoint.x) / 2;
      const yy = (targetPoints[0].y + lastPoint.y) / 2;
      centers.push({
        x: xx,
        y: yy,
        handle1: lastHandle,
        handle2: firstHandle,
      });
      return centers;
    } catch (error) {
      console.log('getHandleLineCenters - error', error);
    }
  }

  async startCroppingArea(base64, targetPoints) {
    try {
      const displayArea = this.config.containers.displayArea;
      this.cropArea = new CropArea(
        displayArea,
        base64,
        this.config.size,
        this.handles,
        this.handlesLine,
      );
      // await updateContainerBackground(base64, displayArea);
      // displayArea.style.backgroundImage = "url('" + base64 + "')";

      const image = await updateContainerBackground(
        base64,
        this.config.containers.displayArea,
      );
      this.image = await this.cropArea.create(image);

      const centers = this.getHandleLineCenters(targetPoints);
      for (let i = 0; i < centers.length; i++) {
        this.createHandlesLine({ ...centers[i] }, i);
      }
      this.startCroppingPolygon();
      window.addEventListener(
        helpers.isMobile() ? 'touchend' : 'mouseup',
        this.eventChange,
      );
    } catch (error) {
      console.log('startCroppingArea - error', error);
    }
  }

  createHandles(handleConfig = null, inx) {
    if (this.cropArea.isDrawing) {
      const { x, y } = handleConfig;

      const point = new Point(x, y);
      const handle = new Handle(
        this.config.containers.displayArea,
        this.image.left,
        this.image.top,
        point,
        inx,
      );

      handle.init();
      this.handles.push(handle);
      return handle;
    }
  }

  createHandlesLine(handleLineConfig = null, inx) {
    const { x, y } = handleLineConfig;

    const point = new Point(x, y);
    const handleLine = new HandleLine(
      this.config.containers.displayArea,
      handleLineConfig.handle1,
      handleLineConfig.handle2,
      point,
      inx,
    );

    handleLine.init();
    this.handlesLine.push(handleLine);
  }

  onResizeHandle(event) {
    this.cropPolygon.startResizing(event);
  }

  onResizeLine(event) {
    this.cropPolygon.startResizingLines(event);
  }

  getMouseDownEvent() {
    return helpers.isMobile() ? 'touchstart' : 'mousedown';
  }

  getMouseUpEvent() {
    return helpers.isMobile() ? 'touchend' : 'mouseup';
  }

  startCroppingPolygon() {
    this.cropArea.isDrawing = false;

    this.cropPolygon = new Polygon(
      this.config.containers.displayArea,
      this.cropArea.canvas,
      this.handles,
      this.handlesLine,
    );

    this.cropPolygon.drawWithOverlay();

    for (const handle of this.handles) {
      handle.node.addEventListener(
        this.getMouseDownEvent(),
        this.onResizeHandle.bind(this),
      );
    }

    for (const handleLine of this.handlesLine) {
      handleLine.node.addEventListener(
        this.getMouseDownEvent(),
        this.onResizeLine.bind(this),
      );
    }
  }

  eventChange() {
    document[helpers.isMobile() ? 'ontouchmove' : 'onmousemove'] =
      function () {};
  }

  resetParams() {
    this.cropArea = undefined;
    this.cropPolygon = undefined;
    this.handles = [];
    this.handlesLine = [];
    this.image = '';
  }

  async applyNewImg(base64) {
    this.resetParams();
    this.config.imgSrc = base64;
    await this.init(true);
  }

  // Note this function need to be async
  async applyNewImageSrc(base64) {
    this.cropArea.updateImage(base64);
  }

  async rotatePointList(rotateDegree, base64, size) {
    if (this.currentRotateDegree === rotateDegree || !this.handles.length) {
      return;
    }
    this.config.size = { ...this.config.size, ...size };
    const { height: oldHeight, width: oldWidth } = this.config.size;
    let points = this.handles.map(({ point }) => point);
    this.resetParams();
    this.updateConfig(base64);
    const { width, height } = this.config.size;
    this.currentRotateDegree = rotateDegree;
    const heightScale = height / oldWidth;
    const widthScale = width / oldHeight;

    points = points
      .map((point) => ({
        x: Math.round(point.x * widthScale),
        y: Math.round(point.y * heightScale),
      }))
      .map((point) =>
        rotatePoint90Deg({
          x: point.x,
          y: point.y,
          height: width,
        }),
      );

    points = sortPoints(points);
    this.config.pointsList = Object.values(points);
    await this.startCroppingArea(base64, this.config.pointsList);
  }
}
