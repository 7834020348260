import { toast } from 'react-toastify';
import type { ServerErrorResponse } from '../core.types';
import { ServerErrorType } from '../core.types';
import { useLogout } from './use-logout';

export const useErrorMiddleware = () => {
  console.warn('IDOCUMENT: useErrorMiddleware is deprecated');
  const logout = useLogout();

  const errorMiddleware = async (errorResponse: ServerErrorResponse) => {
    const errors = errorResponse.graphQLErrors || errorResponse.errors;

    if (!errors) {
      return;
    }

    for (const error of errors) {
      switch (error.type) {
        case ServerErrorType.TYPE_CODE_TOKEN_ISSUE:
        case ServerErrorType.TYPE_CODE_WRONG_ACCOUNT_ID_HEADER:
          await logout();
          break;
        case ServerErrorType.TYPE_CODE_UNEXPECTED_BY_FE:
        case ServerErrorType.TYPE_CODE_OFFLINE_ERROR:
        case ServerErrorType.TYPE_CODE_ONLINE_ERROR:
          const toastCaller =
            error.type === ServerErrorType.TYPE_CODE_UNEXPECTED_BY_FE
              ? toast.info
              : toast.error;

          toastCaller(error.message, {
            autoClose: 3000,
            position: 'bottom-center',
          });
          break;
      }
    }
  };

  return errorMiddleware;
};
