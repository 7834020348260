import { Maybe, ServerErrorResponse } from '@/core.types';
import {
  useLoginByEmailMutation,
  useRemoveUserMutation,
} from '@/graphql/codegen/graphql';
import { useBooleanState } from '@/hooks/use-boolean-state';
import { useErrorMiddleware } from '@/hooks/use-server-logic';
import { getRequestHTTPHeaders } from '@/ir-apollo-client';
import { fileDownloadQueueTable } from '@/services';
import { setAccountIdToken } from '@/services/auth-service';
import {
  lastCategoryUpdateAtom,
  lastReceiptUpdateAtom,
} from '@/store/category-store';

import { useRootContext } from '@/context/root-context';
import { getUserSharedAccount } from '@/context/use-current-user';
import request from 'graphql-request';
import { useRecoilCallback } from 'recoil';
import { userQuery } from './api-user-queries';
import { AvailableAccountShare, UserPayload } from './api-user.types';
import { useCallback } from 'react';

export const useGetUser = () => {
  const [userLoading, setUserLoading, unsetUserLoading] =
    useBooleanState(false);
  const errorMiddleware = useErrorMiddleware();
  const resetRecoilLastUpdatedBasedOnSharedAccounts =
    useResetRecoilLastUpdatedBasedOnSharedAccounts();
  const { setCurrentUser } = useRootContext();

  const fetchUser = useCallback(async () => {
    if (!window.onlineDispatcher.isOnline) {
      return;
    }

    try {
      setUserLoading();
      const data = await request<UserPayload>(
        import.meta.env.VITE_SINGLE_QL_LINK,
        userQuery,
        undefined,
        getRequestHTTPHeaders().headers,
      );

      const newCurrentSharedAccount = getUserSharedAccount(data);

      resetRecoilLastUpdatedBasedOnSharedAccounts(newCurrentSharedAccount);
      unsetUserLoading();
      setCurrentUser(data);
      return data;
    } catch (errorResponse) {
      await errorMiddleware(
        (errorResponse as any)?.response as ServerErrorResponse,
      );
    }
  }, []);

  return {
    fetchUser,
    userLoading,
  };
};

export const useLoginByEmail = () => {
  const [loginByEmail, { data, error, loading }] = useLoginByEmailMutation();
  return { loginByEmail, loginByEmailError: error, data, errors: {}, loading };
};

export const useSwitchUser = () => {
  const { fetchUser } = useGetUser();
  const { setRootID, setActiveSliceId } = useRootContext();

  return async (account_id: number) => {
    setAccountIdToken(account_id);
    window.localStorage.removeItem('root-id');
    await Promise.all([fileDownloadQueueTable.clear(), fetchUser()]);
    setRootID(null);
    setActiveSliceId(null);
  };
};

export const useRemoveUser = () => {
  const [removeUser] = useRemoveUserMutation();
  return { removeUser };
};

export const useResetRecoilLastUpdatedBasedOnSharedAccounts = () => {
  const { currentUser, currentUserSharedAccount, setCurrentUser } =
    useRootContext();

  return useRecoilCallback(
    ({ set }) =>
      async (newCurrentSharedAccount: Maybe<AvailableAccountShare>) => {
        if (currentUser && newCurrentSharedAccount) {
          setCurrentUser({
            ...currentUser,
            user: {
              ...currentUser.user,
              account: {
                ...currentUser.user.account,
                availableAccountShares:
                  currentUser?.user.account.availableAccountShares.map(
                    (sharedAccount) => {
                      if (
                        sharedAccount.accountFrom.id ===
                          newCurrentSharedAccount.accountFrom.id &&
                        sharedAccount.accountTo.id ===
                          newCurrentSharedAccount.accountTo.id
                      ) {
                        return newCurrentSharedAccount;
                      }
                      return sharedAccount;
                    },
                  ),
              },
            },
          });
        }

        if (
          currentUserSharedAccount?.canViewGroup === true &&
          newCurrentSharedAccount?.canViewGroup === false
        ) {
          set(lastReceiptUpdateAtom, null);
          set(lastCategoryUpdateAtom, null);
        }
      },
  );
};
