import { LocalCategory, LocalReceipt } from '@/core.types';
import { CategoryFolderTile } from './category-grid-tile/category-folder-tile';
import { CategoryReceiptTile } from './category-grid-tile/category-receipt-tile';

export const CategoryGrid = ({
  foldersList,
  emptyReceiptList,
  nonEmptyReceiptList,
}: any) => {
  const renderCategory = (category: LocalCategory & { amount: number }) => {
    return <CategoryFolderTile {...category} key={category.id} />;
  };
  const renderReceipt = (receipt: LocalReceipt) => {
    return <CategoryReceiptTile {...receipt} key={receipt.id} />;
  };

  const renderGridSection = (
    items: LocalReceipt[] | LocalCategory[],
    renderer,
  ) =>
    !!items.length && (
      <div className='w-full grid grid-rows-auto gap-[15px] grid-cols-[repeat(auto-fill,minmax(100px,1fr))] mb-[20px] content-auto last:pb-[40px] tablet:gap-[30px] tablet:grid-cols-[repeat(auto-fill,minmax(137px,1fr))]'>
        {items.map(renderer)}
      </div>
    );

  return (
    <div className='content-auto overscroll-none select-none'>
      {renderGridSection(emptyReceiptList, renderReceipt)}
      {!!emptyReceiptList.length && (
        <div className='w-full h-[5px] bg-[rgba(37,47,111,0.1)] my-[16px] tablet:my-[20px]' />
      )}
      {renderGridSection(foldersList, renderCategory)}
      {renderGridSection(nonEmptyReceiptList, renderReceipt)}
    </div>
  );
};
