import { useDetectMobileResolution } from '@/hooks';
import {
  imageQueryQueueItemsCountAtom,
  queryQueueItemsCountAtom,
} from '@/store';
import { editModeEnabledATOM } from '@/store/category-store';
import c from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';

export const CategorySyncCounter = () => {
  const queryQueueItemsCount = useRecoilValue(queryQueueItemsCountAtom);
  const imageQueryQueueItemsCount = useRecoilValue(
    imageQueryQueueItemsCountAtom,
  );
  const isMobile = useDetectMobileResolution();
  const isSmallMobile = useDetectMobileResolution(340);
  const editModeEnabled = useRecoilValue(editModeEnabledATOM);

  return (
    <div
      className={c('flex items-center', {
        hidden: isSmallMobile && editModeEnabled,
      })}
    >
      {!!imageQueryQueueItemsCount && (
        <ProgressCounter {...{ value: imageQueryQueueItemsCount, isMobile }} />
      )}
      {!!queryQueueItemsCount && (
        <ProgressCounter
          {...{ value: queryQueueItemsCount, isMobile, isUpload: true }}
        />
      )}
    </div>
  );
};

const calculateProgress = (value) => {
  if (value <= 20) {
    return 100 - (value / 20) * 100;
  } else {
    return 5;
  }
};

export const ProgressCounter = ({
  value = 0,
  isUpload = false,
  isMobile = false,
}) => {
  const [progress, setProgress] = useState(0);
  const prevValueRef = useRef(0);
  const isSmallMobile = useDetectMobileResolution(400);

  const targetProgress = calculateProgress(value);

  useEffect(() => {
    let animationFrame;
    const duration = 250;
    const start = performance.now();
    const initialProgress = prevValueRef.current;
    const delta = targetProgress - initialProgress;

    const animate = (currentTime) => {
      const elapsed = currentTime - start;
      const progressFraction = Math.min(elapsed / duration, 1);
      const easedProgress = easeQuadInOut(progressFraction);
      setProgress(initialProgress + delta * easedProgress);
      if (elapsed < duration) {
        animationFrame = requestAnimationFrame(animate);
      } else {
        prevValueRef.current = targetProgress;
      }
    };

    animationFrame = requestAnimationFrame(animate);

    return () => cancelAnimationFrame(animationFrame);
  }, [targetProgress]);

  const easeQuadInOut = (t) => {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
  };

  const size = isSmallMobile ? 25 : 30;
  const counterText = isMobile ? 'text-[10px]' : 'text-[12px]';
  const strokeWidth = 3;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  const pathColor = isUpload ? '#8a97e7' : '#76c488';

  return (
    <div
      style={{
        width: size,
        height: size,
      }}
      className={c('relative mx-[10px]')}
    >
      <svg width={size} height={size} className='transform rotate-[-90deg]'>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke='#e6e6e6'
          strokeWidth={strokeWidth}
          fill='none'
        />
        {/* Progress Circle */}
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          stroke={pathColor}
          strokeWidth={strokeWidth}
          fill='none'
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap='round'
          style={{
            transition: 'stroke-dashoffset 0.25s ease-in-out',
          }}
        />
      </svg>
      <div
        className={c('absolute inset-0 flex items-center justify-center', {
          'text-sm': isMobile,
          'text-lg': !isMobile,
        })}
      >
        <span
          className={c(
            'font-regular text-[10px] text-[var(--icons)]',
            counterText,
          )}
        >
          {value}
        </span>
      </div>
    </div>
  );
};
