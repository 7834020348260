import { useDeviceInfoMutation } from '@/graphql/codegen/graphql';

export const useSaveDeviceInfo = () => {
  const [saveDeviceInfo, { loading }] = useDeviceInfoMutation();

  return {
    saveDeviceInfo,
    loading,
  };
};
