import { PAGE_ROUTES } from '@/app.constants';
import { AppLoader } from '@/components/app-loader';
import { useMailConfirmMutation } from '@/graphql/codegen/graphql';
import { setAccountIdToken } from '@/services/auth-service';
import { useIsAuthorizedAtomState } from '@/store/root-store';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';

export const MailConfirmPage = () => {
  const [confirmEmail, { loading, error }] = useMailConfirmMutation();
  const history = useHistory();
  const { pathname } = useLocation();
  const { setIsAuthorised } = useIsAuthorizedAtomState();

  useEffect(() => {
    const mailConfirmRequest = async () => {
      const mailToken = pathname.split('/').pop();
      if (!mailToken) return;
      const { data } = await confirmEmail({
        variables: {
          token: mailToken,
        },
      });

      if (!data) return;

      const token = data.mailConfirm.token;
      const id = data.mailConfirm?.account?.id;

      if (token && id) {
        await setIsAuthorised(token);
        setAccountIdToken(id);
        toast.info('Mail is successfully confirmed', {
          autoClose: 3000,
          position: 'bottom-center',
        });
        history.push(PAGE_ROUTES.login);
      }
    };

    mailConfirmRequest();
  }, [confirmEmail, history, pathname, setIsAuthorised]);

  useEffect(() => {
    if (error && !loading) {
      history.push(PAGE_ROUTES.login);
    }
  }, [error, history, loading]);

  return <div>{loading && <AppLoader />}</div>;
};
