import { ACCEPT_FILE_TYPE, PAGE_ROUTES } from '@/app.constants';
import { AppIcon } from '@/components/app-icon';
import {
  AppType,
  Maybe,
  ReceiptType,
  RECOGNITION_TYPES,
  ScannedReceipt,
  VisionKitGalleryImageFile,
  VisionKitPDFResult,
  VisionKitScanResult,
} from '@/core.types';
import { useCreateReceiptList, useDetectMobileResolution } from '@/hooks';
import { useSelectReceiptFile } from '@/hooks/use-select-receipt-file';

import { useRootContext } from '@/context/root-context';
import { hasAppAccess } from '@/services/app-service';
import {
  addClickHandler,
  getBlobFromUrl,
  getTempPath,
  parseBoolEnv,
} from '@/services/helper-service';
import { generatePdfFromReceiptList } from '@/services/pdf-service';
import { getVisionKit } from '@/services/vision-kit-service';
import {
  dragEnabledATOM,
  newReceiptFileAtom,
  recognizedImagePointsAtom,
} from '@/store';
import noop from 'lodash/noop';
import { SyntheticEvent, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useRecoilValue, useSetRecoilState } from 'recoil';

const PLUS_ICON_DESKTOP_SIZE = {
  width: 54,
  height: 54,
};

const PLUS_ICON_MOBILE_SIZE = {
  width: 32,
  height: 32,
};

const AddReceiptMessageMap = {
  [ReceiptType.invoice]: 'new.invoice',
  [ReceiptType.receipt]: 'new.receipt',
  [ReceiptType.document]: 'new.document',
};

export const AddReceiptButton = () => {
  const createReceiptList = useCreateReceiptList();
  const isMobile = useDetectMobileResolution();
  const fileInputRef = useRef<Maybe<HTMLInputElement>>(null);
  const onChangeInput = useSelectReceiptFile(fileInputRef);
  const setNewReceiptFile = useSetRecoilState(newReceiptFileAtom);
  const history = useHistory();
  const { setRecognizedImageTexts } = useRootContext();
  const setRecognizedImagePoints = useSetRecoilState(recognizedImagePointsAtom);
  const dragEnabled = useRecoilValue(dragEnabledATOM);
  const { formatMessage: t } = useIntl();
  const { activeSliceId, receiptType, recognitionConfig } = useRootContext();

  const onScanSuccess = async (scannedResult: VisionKitScanResult) => {
    // @ts-ignore
    const scannedResultItem = scannedResult[0];

    // !If from camera
    if (!!(scannedResultItem as ScannedReceipt)) {
      // @ts-ignore
      if (hasAppAccess(AppType.idocument) && scannedResult.length > 1) {
        // @ts-ignore
        const combinedPDF = await generatePdfFromReceiptList(scannedResult);
        setNewReceiptFile(combinedPDF as File);
        history.push(`${PAGE_ROUTES.category}${PAGE_ROUTES.createReceipt}`);
        return;
      }

      createReceiptList(scannedResult as ScannedReceipt[]);
      return;
    }

    // !Detect from gallery or files
    const pdfFileLink = (scannedResult as VisionKitPDFResult).pdfTempUrl;
    const imageFileLink = (scannedResult as VisionKitGalleryImageFile)
      .imagePath;

    if (!pdfFileLink && !imageFileLink) {
      return;
    }

    const fileUrl = getTempPath(pdfFileLink || imageFileLink);
    let points = (scannedResult as VisionKitGalleryImageFile).points;
    if (points) {
      points = [
        points['topLeft'],
        points['bottomLeft'],
        points['topRight'],
        points['bottomRight'],
      ];
    }

    if (imageFileLink) {
      setRecognizedImageTexts(
        (scannedResult as VisionKitGalleryImageFile).text,
      );
      setRecognizedImagePoints(points);
    }

    const file = await getBlobFromUrl(fileUrl);
    setNewReceiptFile(file as File);

    history.push(
      `${PAGE_ROUTES.category}${
        pdfFileLink ? PAGE_ROUTES.createReceipt : PAGE_ROUTES.cropStep
      }`,
    );
  };

  const startVisionKit = () => {
    getVisionKit().scan(onScanSuccess, noop, {
      languages: recognitionConfig.languages,
      isFastTextRecognition:
        recognitionConfig.recognitionType === RECOGNITION_TYPES.fast,
    });
  };

  const onImageButtonClick = (event: SyntheticEvent<HTMLDivElement>) => {
    if (!activeSliceId) {
      toast.error('Error during sync, try to do pull to refresh when online', {
        autoClose: 3000,
        position: 'bottom-center',
      });
      event.preventDefault();
      return;
    }

    if (
      window.cordova ||
      parseBoolEnv(import.meta.env.VITE_EMULATE_VISIONKIT)
    ) {
      startVisionKit();
      event.preventDefault();
    }
  };

  return (
    <div
      className='app-footer__add-receipt-block'
      {...addClickHandler(onImageButtonClick)}
    >
      <label
        className='cursor-pointer w-[54px] h-[54px] bg-[var(--buttonPlus)] shadow-[0px_4px_4px_rgba(0,0,0,0.25)] rounded-full absolute top-[-27px] left-1/2 transform -translate-x-1/2 flex items-center justify-center text-[40px] laptop:w-[90px] laptop:h-[90px] laptop:top-[-45px]'
        htmlFor='add-receipt-main-button'
      >
        <input
          id='add-receipt-main-button'
          type='file'
          disabled={dragEnabled}
          ref={fileInputRef}
          onChange={onChangeInput}
          accept={ACCEPT_FILE_TYPE}
          className='hidden'
        />
        <AppIcon
          name='plus-icon'
          size={isMobile ? PLUS_ICON_MOBILE_SIZE : PLUS_ICON_DESKTOP_SIZE}
        />
      </label>
      <div className='text-[10px] text-[var(--actionText)] select-none w-full mt-[37px] text-center laptop:mt-[55px] laptop:text-[14px]'>
        {t({ id: AddReceiptMessageMap[receiptType] })}
      </div>
    </div>
  );
};
