import { AppConfirmModal } from '@/components/app-confirm-modal';
import { AppLoader } from '@/components/app-loader';
import { Maybe } from '@/core.types';
import { useBooleanState } from '@/hooks';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { AddButton } from '../add-button';
import { AddPhoneModal } from './add-phone-modal';
import { PhoneItem } from './phone-item';
import { usePhoneLogic } from './use-phone-logic';

export const Phone = () => {
  const { formatMessage: t } = useIntl();
  const [selectedPhoneId, setSelectedPhoneId] = useState<Maybe<number>>(null);
  const [deleteModalOpened, openDeleteModal, closeDeleteModal] =
    useBooleanState(false);
  const [addPhoneModalOpened, openAddPhoneModal, closeAddPhoneModal] =
    useBooleanState(false);
  const { loading, userPhones, deletePhone, addPhone } = usePhoneLogic();

  const handleDelete = async () => {
    if (selectedPhoneId) {
      await deletePhone(selectedPhoneId);
      closeDeleteModal();
    }
  };

  return (
    <>
      <div className='font-roboto p-[25px_15px_20px] laptop:p-0 laptop:w-[45%] laptop:m-0'>
        {loading && <AppLoader />}

        <div className='font-medium text-[14px] leading-[24px] text-[var(--textMain)] opacity-60'>
          {t({ id: 'menu.profile.phone.your' })}
        </div>

        {userPhones.length ? (
          userPhones.map((phone) => (
            <PhoneItem
              key={phone.id}
              {...phone}
              {...{
                setSelectedPhoneId,
                openDeleteModal,
              }}
            />
          ))
        ) : (
          <div className='flex justify-between items-center font-medium text-[16px] leading-[22px] text-[var(--emphasized)] w-fit mt-[20px] mx-auto'>
            {t({ id: 'menu.profile.phone.add' })}
          </div>
        )}
        <div className='bg-[var(--textMain10)] h-[2px] mt-[23px]' />
        <AddButton
          label={t({ id: 'menu.profile.phone.add' })}
          onClick={openAddPhoneModal}
        />
      </div>
      <AppConfirmModal
        key='confirm-modal'
        open={deleteModalOpened}
        onConfirmClick={handleDelete}
        title={t({ id: 'alert' })}
        onClose={closeDeleteModal}
      />
      <AddPhoneModal
        open={addPhoneModalOpened}
        {...{ addPhone, closeAddPhoneModal }}
      />
    </>
  );
};
