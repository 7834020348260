import { createObjectURL } from '@/services/helper-service';

export const updateContainerBackground = (
  base64: string,
  container: HTMLDivElement,
) => {
  return new Promise((resolve) => {
    fetch(base64)
      .then((res) => res.blob())
      .then((blob) => {
        const objectURL = createObjectURL(blob) as string;
        const image = new Image();
        image.src = objectURL;
        image.onload = () => {
          if (container) {
            container.style.backgroundImage = 'url(' + objectURL + ')';
            resolve(image);
          }
        };
      })
      .catch(console.log);
  });
};

export const createFileFromSrc = async (src: string) => {
  return new Promise((resolve) => {
    fetch(src)
      .then((res) => res.blob())
      .then((blob) => {
        resolve(blob);
      })
      .catch(console.log);
  });
};
