import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppLoader } from '@/components/app-loader';
import { useInviteFriendMutation } from '@/graphql/codegen/graphql';
import { useIntl } from 'react-intl';
// import { toast } from 'react-toastify';
import { SettingsIcon } from '../settings-icon';
import c from 'classnames';

export const Invite = ({ mobile }: any) => {
  // const { push } = useHistory();
  // const [email, setEmail] = useState('');
  const colorTextMain20 = `var(${APP_CSS_THEME_VARIABLES.textMain20})`;
  const { formatMessage: t } = useIntl();
  const [_mutation, { loading }] = useInviteFriendMutation();

  // const invite = async (email: string) => {
  //   try {
  //     await _mutation({
  //       variables: {
  //         email,
  //       },
  //     });

  //     toast.info(`success`, {
  //       autoClose: 3000,
  //       position: 'bottom-center',
  //     });
  //   } catch (error) {
  //     if (error instanceof Error) {
  //       toast.error(`${error.message}`, {
  //         autoClose: 3000,
  //         position: 'bottom-center',
  //       });
  //     }
  //   }
  // };

  // const click = () => {
  //   invite(email).then(() => {
  //     setEmail('');
  //     mobile && push('/settings');
  //   });
  // };

  // const onChange = (email: string) => {
  //   setEmail(email);
  // };

  const InviteItemClassName =
    'cursor-pointer w-fit flex items-center font-normal font-medium text-[16px] leading-[22px] tracking-[-0.3px] text-[var(--textMain)] opacity-90 not:first:mt-[20px]';

  return (
    <div className='font-roboto p-[25px_15px_20px] laptop:w-[45%] laptop:p-0 laptop:m-0 tablet:max-w-[667px] tablet:m-auto'>
      {loading ? <AppLoader /> : null}

      <div className='w-[210px] m-auto font-normal text-[18px] leading-[24px] text-[var(--textMain)] opacity-90 text-center'>
        {t({ id: 'menu.profile.invite' })}
        <strong>{t({ id: 'menu.profile.5premium' })}</strong>
        {t({ id: 'menu.profile.friends' })}
        {t({ id: 'menu.profile.have.yours' })}
        <strong className='text-[var(--emphasized)]'>
          {t({ id: 'menu.profile.free' })}
        </strong>
      </div>

      <ul className='list-none !pl-0 !mb-0 mt-[30px]'>
        <li className={c('invite__item-mail', InviteItemClassName)}>
          <SettingsIcon name='mail' color={colorTextMain20} />
          <span className='ml-[15px]'>
            {t({ id: 'menu.profile.invite.mail' })}
          </span>
        </li>

        <li className={c('invite__item-sms', InviteItemClassName)}>
          <SettingsIcon name='sms' color={colorTextMain20} />
          <span className='ml-[15px]'>
            {t({ id: 'menu.profile.invite.sms' })}
          </span>
        </li>

        <li className={c('invite__item-share', InviteItemClassName)}>
          <SettingsIcon name='share' color={colorTextMain20} />
          <span className='ml-[15px]'>
            {t({ id: 'menu.profile.invite.share' })}
          </span>
        </li>
      </ul>

      <div className='bg-[#e9e9e9] h-[2px] mt-[23px]' />

      <div className='cursor-pointer m-[23px_auto_0] flex flex-col items-center w-fit laptop:m-[60px_auto_0]'>
        <div className='border border-[rgba(173,181,229,0.2)] rounded-full p-[15px]'>
          <div className='bg-[#ffffff] shadow-[0_0_10px_rgba(0,0,0,0.15)] rounded-full p-[40px]'>
            <SettingsIcon name='gift' sizeType='custom' />
          </div>
        </div>
        <div className='mt-[12px] font-bold text-[16px] leading-[19px] text-center text-[var(--emphasized)]'>
          {t({ id: 'menu.profile.invite.gift' })}
        </div>
      </div>
    </div>
  );
};
