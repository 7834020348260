type InterpolateDTO = {
  inputRange: number[];
  outputRange: number[];
};

export const interpolate = ({
  inputRange: [minX, maxX],
  outputRange: [minY, maxY],
}: InterpolateDTO) => {
  return (value: number) =>
    +Number(minY + ((maxY - minY) * (value - minX)) / (maxX - minX)).toFixed(2);
};
