import { useCategoryDndLogic } from '@/category/use-category-dnd-logic';
import { AppTappable } from '@/components/app-tappable';
import {
  editModeEnabledATOM,
  selectedCategoryIDsAtom,
} from '@/store/category-store';
import c from 'classnames';
import { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { AppIcon } from '@/components';
import { AppPseudoRadio } from '@/components/app-pseudo-radio';
import type { LocalCategory } from '@/core.types';
import { useFolderItemLogic } from '../../use-folder-item-logic';
import { CategoryGridTileDescription } from './category-grid-tile-description';
import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';

const FOLDER_ICON_SIZE = { width: '100%', height: '100%' };

export const CategoryFolderTile = (
  localCategory: LocalCategory & { amount: number },
) => {
  const editModeEnabled = useRecoilValue(editModeEnabledATOM);
  const {
    onItemClick,
    currency,
    isItemActive,
    onItemDescriptionCLick,
    itemName,
  } = useFolderItemLogic(localCategory);
  const { isEmptyActiveSliceSelector } = useContext(CategoryTreeContext);
  const { createdBy } = localCategory;
  const isEmpty = isEmptyActiveSliceSelector(localCategory);

  const { isOver, isDraggable, drop, drag } = useCategoryDndLogic(
    localCategory.id,
  );
  const selectedCategoryIdsList = useRecoilValue(selectedCategoryIDsAtom);

  return (
    <div className='drop-container' ref={drag}>
      <div
        ref={drop}
        className={c('drop', {
          'opacity-[0.45]':
            !isDraggable &&
            isOver &&
            !selectedCategoryIdsList.includes(localCategory.id),
        })}
      >
        <div className='relative'>
          {editModeEnabled && (
            <AppPseudoRadio
              {...{
                isItemActive,
                className:
                  'absolute min-w-[16px] shadow-none top-[8px] left-[8px] z-[2] before:w-[6px] before:h-[6px]',
                // onClick: onItemClick,
              }}
            />
          )}
          <AppTappable onTap={onItemClick}>
            <AppIcon
              name={c({
                'green-folder-empty': isEmpty,
                'green-folder-non-empty': !isEmpty,
              })}
              id={localCategory.id}
              size={FOLDER_ICON_SIZE}
              className='cursor-pointer'
            />
          </AppTappable>
          <CategoryGridTileDescription
            {...{
              name: itemName,
              // ! Note folder name always not red
              isEmpty: false,
              amount: localCategory.amount,
              currency,
              onClick: onItemDescriptionCLick,
              createdBy,
              date: null,
            }}
          />
        </div>
      </div>
    </div>
  );
};
