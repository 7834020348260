import { useRootContext } from '@/context/root-context';
import { useGetUser } from '@/gql/api-user';
import {
  useAddAccountMutation,
  useRemoveAccountMutation,
} from '@/graphql/codegen/graphql';

export type UpdateAccountPayload = {
  email: string;
  canViewGroup: boolean;
  canEditGroup: boolean;
  canDeleteGroup: boolean;
};

export const useQuerySharedAccounts = () => {
  const { fetchUser } = useGetUser();
  const [_mutationAddAccount, { loading: processingAddEmail }] =
    useAddAccountMutation();
  const [_mutationRemoveAccount, { loading: processingRemoveAccount }] =
    useRemoveAccountMutation();
  const { currentUserSharedAccounts, currentUserPersonalAccount } =
    useRootContext();

  const addAccount = async (email: string) => {
    await _mutationAddAccount({
      variables: {
        email,
        canViewGroup: false,
        canEditGroup: false,
        canDeleteGroup: false,
      },
    });
    fetchUser();
  };

  const updateAccount = async (payload: UpdateAccountPayload) => {
    await _mutationAddAccount({
      variables: payload,
    });
    fetchUser();
  };

  const removeAccount = async (id: number) => {
    await _mutationRemoveAccount({
      variables: {
        sharing_id: id,
      },
    });
    fetchUser();
  };

  return {
    personalAccount: currentUserPersonalAccount,
    sharedAccounts: currentUserSharedAccounts,
    loading: processingAddEmail || processingRemoveAccount,
    addAccount,
    updateAccount,
    removeAccount,
  };
};
