import { ALL_PROFILES } from '@/app.constants';
import { AppIcon } from '@/components';
import { AppButton } from '@/components/app-button';
import { PeopleGroupIcon } from '@/components/app-icon/new-icons';
import { useRootContext } from '@/context/root-context';
import { useDetectMobileResolution } from '@/hooks';
import { madeByModalOpenedATOM } from '@/store/category-store';
import c from 'classnames';
import { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useClickAway } from 'react-use';
import { useRecoilState } from 'recoil';
import { MadeByModalAccountItem } from './made-by-modal-account-item';

export const MadeByFilterModal = () => {
  const isMobile = useDetectMobileResolution();
  const { formatMessage: t } = useIntl();
  const { currentUserPersonalAccount, currentUserSharedAccounts } =
    useRootContext();
  const accountList = !currentUserPersonalAccount
    ? []
    : [
        {
          ownerFullName: t({ id: 'all.profiles' }),
          id: ALL_PROFILES.id,
          icon: <PeopleGroupIcon />,
        },
        currentUserPersonalAccount as any,
        ...currentUserSharedAccounts,
      ];
  const [madeByModalOpened, setMadeByModalOpenStatus] = useRecoilState(
    madeByModalOpenedATOM,
  );

  const closeMadeByModal = () => {
    setMadeByModalOpenStatus(false);
  };

  const onClickAway = () => {
    if (madeByModalOpened) {
      closeMadeByModal();
    }
  };

  const iconSize = isMobile
    ? { width: 23, height: 23 }
    : { width: 30, height: 30 };

  const ref = useRef(null);
  useClickAway(ref, onClickAway);

  if (!madeByModalOpened) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={c(
        'bg-[var(--secondary)] rounded-t-[14px] fixed bottom-[-415px] left-0 right-0 transition-[0.5s] w-[100vw] box-border z-[1]',
        {
          'bottom-[calc(75px+var(--sab))] laptop:bottom-[95px]':
            madeByModalOpened,
        },
      )}
    >
      <div className='h-[55px] flex items-center justify-center w-[calc(100%_-_32px)] m-auto'>
        <AppButton
          onPress={closeMadeByModal}
          className='cursor-pointer mr-auto pl-[5px] w-[25%] h-full flex items-center hover:filter drop-shadow-[0_0_2px_var(--emphasized)]'
        >
          <AppIcon size={iconSize} name='close' />
        </AppButton>
        <div className='absolute font-roboto font-medium text-[16px] text-center tracking-[-0.4px] capitalize text-[#fff]'>
          {t({ id: 'sort.madeBy' })}
        </div>
      </div>

      <div className='pt-0 pl-[16px] pr-[16px] pb-[30px] overflow-y-scroll w-[calc(100%-32px)] max-w-[680px] max-h-[40vh] m-auto relative'>
        {accountList.map((item, index) => (
          <MadeByModalAccountItem {...item} key={index} />
        ))}
      </div>
    </div>
  );
};
