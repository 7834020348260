import Joi from 'joi';
import { MessageDescriptor } from 'react-intl';
import has from 'lodash/has';
import set from 'lodash/set';

export const createValidationResolver = (
  validationSchema: Record<any, any>,
  messages: Record<string, MessageDescriptor | string>,
) => {
  return async (data: Record<string, string>) => {
    const modifiedSchema = Object.keys(validationSchema).reduce(
      (modifiedSchema, key) => {
        if (has(data, key)) {
          set(modifiedSchema, key, validationSchema[key]);
        }
        return modifiedSchema;
      },
      {} as any,
    );

    const { error, value: values } = Joi.object(modifiedSchema)
      .unknown()
      .validate(data);

    return {
      values: error ? {} : values,
      errors: error
        ? error.details.reduce((previous: any, currentError: any) => {
            return {
              ...previous,
              [currentError.path.join('.')]:
                messages[currentError.path.join('.')],
            };
          }, {})
        : {},
    };
  };
};
