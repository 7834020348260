import c from 'classnames';
import { ChangeEvent, CSSProperties } from 'react';
import './app-slider.css';

type AppSliderProps = {
  min: number;
  max: number;
  step?: number;
  value: number;
  onRangeChange: (value: number) => void;
  caption?: string;
};

export const AppSlider = ({
  min,
  max,
  step = 1,
  value,
  onRangeChange,
  caption,
}: AppSliderProps) => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    onRangeChange(newValue);
  };

  const valuePercentage = ((value - min) / (max - min)) * 100;

  return (
    <div className='w-full'>
      <div
        className='relative w-full'
        style={
          {
            '--value-percentage': `${valuePercentage}%`,
          } as CSSProperties
        }
      >
        <input
          type='range'
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleChange}
          className={c(
            'w-full h-[6px] m-0 p-0 appearance-none bg-gradient-to-r from-[var(--secondary)] via-[var(--fillSecondary40)] to-[var(--fillSecondary40)] rounded-[3px] outline-none cursor-pointer focus:outline-none',
            'app-slider',
          )}
        />
        <div
          className='absolute top-[-18px] transform -translate-x-1/2 font-roboto font-normal text-[12px] leading-[1.2] text-[var(--icons)]'
          style={{ left: `${valuePercentage}%` }}
        >
          {value}
        </div>
      </div>
      {caption && (
        <span className='text-[var(--icons)] mb-[15px] mt-[-5px] font-[var(--font-family)] text-[12px] leading-[1.2] block'>
          {caption}
        </span>
      )}
    </div>
  );
};
