import { Maybe } from '@/core.types';
import { subscriptionPlanListAtom } from '@/store/root-store';
import {
  Purchases,
  PurchasesOffering,
  PurchasesPackage,
} from '@revenuecat/purchases-capacitor';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

export const useRegisterPurchaseList = (userId: Maybe<number>) => {
  const setSubscriptionPlanList = useSetRecoilState(subscriptionPlanListAtom);

  useEffect(() => {
    if (!userId) {
      return;
    }

    (async function () {
      try {
        // @ts-expect-error note
        await Purchases.setLogLevel('DEBUG');
        await Purchases.configure({
          apiKey: import.meta.env.VITE_REVENUECAT_API_KEY,
          appUserID: String(userId),
        });

        const offers = await Purchases.getOfferings();
        setTimeout(() => {
          console.log('offers', offers);
          console.log('offers', offers.all);
        }, 3000);

        const newSubscriptionPlanList = Object.values(offers?.all).reduce(
          (acc, offer: PurchasesOffering) => {
            offer?.availablePackages?.forEach(
              async (pack: PurchasesPackage) => {
                // alert('pack.identifier --- ' + pack.identifier);
                acc[pack.product.identifier] = pack;
              },
            );
            return acc;
          },
          {},
        );

        setTimeout(() => {
          console.log('newSubscriptionPlanList', newSubscriptionPlanList);
        }, 3000);

        // console.log('newSubscriptionPlanList', newSubscriptionPlanList);

        setSubscriptionPlanList((subscriptionPlanList) => ({
          ...subscriptionPlanList,
          ...newSubscriptionPlanList,
        }));
      } catch (e) {
        console.log(JSON.stringify(e));
      }
    })();
  }, [setSubscriptionPlanList, userId]);
};
