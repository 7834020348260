import { IonLabel } from '@ionic/react';
import c from 'classnames';

type AppIonLabelProps = {
  label: string;
  className?: string;
};

export const AppIonLabel = ({ label, className }: AppIonLabelProps) => {
  return (
    <IonLabel
      className={c(
        'block mx-auto w-fit text-[var(--textMain)] font-bold text-[24px] leading-[28px]',
        className,
      )}
    >
      {label}
    </IonLabel>
  );
};
