import { useRootContext } from '@/context/root-context';
import { SortField, SortType } from '@/core.types';
import c from 'classnames';
import { FaSortDown, FaSortUp } from 'react-icons/fa';

type CategorySortHeaderCellProps = {
  label: string;
  sortField: SortField;
};

export const CategorySortHeaderCell = ({
  label,
  sortField,
}: CategorySortHeaderCellProps) => {
  const { sortFilter, setSortFilter } = useRootContext();
  const onClick = () => {
    const sortType =
      sortField === sortFilter.sortField && sortFilter.sortType === SortType.asc
        ? SortType.desc
        : SortType.asc;

    setSortFilter({ sortType, sortField });
  };

  const isSortCellActive = sortFilter.sortField === sortField;

  return (
    <div
      className={c(
        'h-[36px] flex-grow bg-[var(--secondary)] text-center shadow-[0_3px_3px_rgba(0,0,0,0.35)] text-[#dadef3] w-[25%] cursor-pointer text-[12px] select-none flex items-center justify-center tablet:h-[36px] tablet:text-[16px] first:rounded-tl-[27px] first:rounded-bl-[27px] last:rounded-tr-[27px] last:rounded-br-[27px] not-last:border-r-[1px] not-last:border-[#fff]',
        {
          'text-white': isSortCellActive,
        },
      )}
      onClick={onClick}
    >
      {label}
      <div className='flex flex-col items-center justify-center ml-[6px]'>
        <FaSortUp
          style={{
            color:
              isSortCellActive && sortFilter.sortType === SortType.asc
                ? 'white'
                : '#bfc3e4',
          }}
        />
        <FaSortDown
          style={{
            marginTop: '-8px',
            color:
              isSortCellActive && sortFilter.sortType === SortType.desc
                ? 'white'
                : '#bfc3e4',
          }}
        />
      </div>
    </div>
  );
};
