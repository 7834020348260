import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppButton } from '@/components/app-button';
import { AppIcon } from '@/components/app-icon';
import c from 'classnames';
import { SyntheticEvent } from 'react';
import { DEFAULT_CLEAR_ICON_SIZE } from './default-input';

type InputClearableButtonProps = {
  clearable: boolean;
  value: string | number;
  onChange(event: SyntheticEvent<HTMLInputElement>): void;
  isSecondIcon?: boolean;
};

export const InputClearableButton = ({
  clearable,
  value,
  onChange,
  isSecondIcon = false,
}: InputClearableButtonProps) => {
  const onClearPress = () => {
    onChange({
      currentTarget: { value: '' },
    } as SyntheticEvent<HTMLInputElement>);
  };

  if (!clearable || !value) {
    return null;
  }

  return (
    <AppButton onPress={onClearPress} className='w-[30px]'>
      <AppIcon
        className={c(
          'default-input__icon',
          'w-[30px] h-[30px] flex items-center justify-center',
          {
            'default-input__clear-icon-left-side': isSecondIcon,
          },
        )}
        color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
        name='close'
        size={DEFAULT_CLEAR_ICON_SIZE}
      />
    </AppButton>
  );
};
