import { AppButton } from '@/components/app-button';
import { useIntl } from 'react-intl';

type AppConfirmModalProps = {
  open: boolean;
  onClose(event: any, reason: string): void;
  title: string;
  onConfirmClick(): void;
  confirmLabel?: string;
  cancelLabel?: string;
};

export const AppConfirmModal = ({
  open,
  onClose,
  title,
  confirmLabel,
  cancelLabel,
  onConfirmClick,
}: AppConfirmModalProps) => {
  const { formatMessage: t } = useIntl();

  if (!open) {
    return null;
  }

  return (
    <div className='fixed inset-0 z-10 flex items-center justify-center bg-[var(--textMain10)]'>
      <div className='w-[320px] bg-[var(--mainBackground)] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-[0_0_20px_rgba(0,0,0,0.85)] p-[15px] rounded-[15px]'>
        <div className='text-[var(--textMain)] mb-[23px] text-center text-[17px] leading-[22px] font-medium'>
          {title}
        </div>
        <div className='flex items-center justify-between gap-[10px]'>
          <AppButton
            className='rounded-[10px] h-[35px] w-[48%] font-medium text-[14px] leading-[16px] text-white text-center bg-[var(--emphasized)]'
            onPress={onClose}
          >
            {cancelLabel || t({ id: 'cancel' })}
          </AppButton>
          <AppButton
            className='rounded-[10px] h-[35px] w-[48%] font-medium text-[14px] leading-[16px] text-white text-center bg-[var(--secondary)]'
            onPress={onConfirmClick}
          >
            {confirmLabel || t({ id: 'confirm' })}
          </AppButton>
        </div>
      </div>
    </div>
  );
};
