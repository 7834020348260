import { useRootContext } from '@/context/root-context';
import { unlockBackSwipe } from '@/services/helper-service';
import { categoryToEditAtom } from '@/store';
import {
  createFolderModalOpenedATOM,
  dragEnabledATOM,
  editModeEnabledATOM,
  madeByModalOpenedATOM,
  selectedCategoryIDsAtom,
} from '@/store/category-store';
import {
  useSelectedIDsAtomSetters,
  useSetBooleanAtomState,
} from '@/store/category-store/category-store.callbacks';
import { useHistory } from 'react-router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CategoryId, LocalCategory } from '../core.types';

export const useFolderItemLogic = (item: LocalCategory) => {
  const { setSearchFilter } = useRootContext();
  const { name, id } = item;
  const editModeEnabled = useRecoilValue(editModeEnabledATOM);
  const [openCreateFolderModal] = useSetBooleanAtomState(
    createFolderModalOpenedATOM,
  );
  const selectedCategoryIdsList = useRecoilValue(selectedCategoryIDsAtom);
  const [addSelectedCategoryId, removeSelectedCategoryId] =
    useSelectedIDsAtomSetters(selectedCategoryIDsAtom);
  const { categoryListView, currentUserCurrency } = useRootContext();
  const { setActiveSliceId } = useRootContext();

  const dragEnabled = useRecoilValue(dragEnabledATOM);
  const setCategoryToEdit = useSetRecoilState(categoryToEditAtom);
  const history = useHistory();
  const isItemSelected = !!selectedCategoryIdsList.find(
    (selectedId: CategoryId) => selectedId === item.id,
  );
  const madeByModalOpened = useRecoilValue(madeByModalOpenedATOM);

  const onItemClick = async (e) => {
    e.stopPropagation();
    if (dragEnabled || madeByModalOpened) return;
    if (!editModeEnabled) {
      setSearchFilter('');
      await unlockBackSwipe();
      history.push(`/category/${id}`);
      setActiveSliceId(id);
      return;
    }

    return isItemSelected
      ? removeSelectedCategoryId(item.id)
      : addSelectedCategoryId(item.id);
  };

  const onItemDescriptionCLick = async () => {
    await setCategoryToEdit(item);
    openCreateFolderModal();
  };

  return {
    onItemDescriptionCLick,
    isItemActive: isItemSelected,
    onItemClick,
    itemName: name,
    categoryListView,
    currency: currentUserCurrency,
  };
};
