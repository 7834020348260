import { AppLoader } from '@/components';
import { useBooleanState } from '@/hooks/use-boolean-state';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AddEmailModal } from './add-email-modal';
import { useQuerySharedAccounts } from './use-query-shared-accounts';
import { useIntl } from 'react-intl';
import { AddButton } from '../add-button';
import { ProfileInfo } from '@/components/profile-info';
import { AccountItem } from '../../account-item';

export const Manage = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [addAccountModalOpened, openAccountModal, closeAccountModal] =
    useBooleanState(false);
  const { personalAccount, sharedAccounts, loading, addAccount } =
    useQuerySharedAccounts();
  const { formatMessage: t } = useIntl();

  const onManageShareAccountItemClick = (accountId) => {
    history.push(`${match.path}/${accountId}`);
  };

  const wrapperClassName =
    'font-roboto p-[24px_15px_20px] tablet:max-w-[667px] tablet:mx-auto laptop:w-[45%] laptop:p-0 laptop:m-0';

  const separationLine = <div className='bg-[#e9e9e9] h-[2px] my-[15px]' />;

  const AccountInfoTitleClass =
    'font-normal font-medium text-[14px] leading-[24px] text-[var(--textMain)] opacity-60 mb-[10px]';

  if (!personalAccount || loading) {
    return (
      <div className={wrapperClassName}>
        <AppLoader />
      </div>
    );
  }

  return (
    <div className={wrapperClassName}>
      <div className='manage-account__info'>
        <div className={AccountInfoTitleClass}>
          {t({ id: 'menu.settings.account.personal' })}
        </div>
        <ProfileInfo
          userIcon={personalAccount.ownerPictureUrl}
          ownerFullName={personalAccount.ownerFullName}
          userCurrency={personalAccount.ownerCurrency}
        />
      </div>

      {separationLine}

      <div className='manage-account__info'>
        <div className={AccountInfoTitleClass}>
          {t({
            id: `menu.settings.account.${
              personalAccount.accountType || 'personal'
            }`,
          })}
        </div>

        <ul className='list-none pl-0 mb-0'>
          {sharedAccounts.map(
            ({ ownerPictureUrl, ownerFullName, ownerCurrency, id }) => {
              return (
                <li key={ownerFullName}>
                  <AccountItem
                    userIcon={ownerPictureUrl}
                    ownerFullName={ownerFullName}
                    userCurrency={ownerCurrency}
                    onClick={() => onManageShareAccountItemClick(id)}
                    type='gear'
                  />
                </li>
              );
            },
          )}
        </ul>
      </div>
      {separationLine}
      <AddButton
        withInfo
        label={t({ id: 'menu.settings.account.add' })}
        onClick={openAccountModal}
      />
      <AddEmailModal
        open={addAccountModalOpened}
        {...{
          closeAccountModal,
          addAccount,
        }}
      />
    </div>
  );
};
