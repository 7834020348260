import { AppButton } from '@/components/app-button';
import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppIcon } from '@/components';

type HeaderControlItemProps = {
  onClick(): void;
  icon: string;
  isMobile: boolean;
};

export const HEADER_ICON_DESKTOP_SIZE = {
  width: 30,
  height: 30,
};

export const HEADER_ICON_MOBILE_SIZE = {
  width: 23,
  height: 23,
};

export const HeaderControlItem = ({
  onClick,
  icon,
  isMobile,
}: HeaderControlItemProps) => {
  return (
    <AppButton
      className='h-full cursor-pointer px-[10px] box-border flex items-center hover:drop-shadow-[0_0_2px_var(--secondary)]'
      onPress={onClick}
    >
      <AppIcon
        color={`var(${APP_CSS_THEME_VARIABLES.icons})`}
        name={icon}
        size={isMobile ? HEADER_ICON_MOBILE_SIZE : HEADER_ICON_DESKTOP_SIZE}
      />
    </AppButton>
  );
};
