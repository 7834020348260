export const AddFileIcon = ({ size = { width: 26, height: 23 } }: any) => {
  const { width, height } = size;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 26 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M22.2263 0.000183062C22.977 0.000183062 23.6829 0.292716 24.214 0.823597C24.745 1.35466 25.0376 2.06073 25.0376 2.81184V15.3143H23.163V2.81184C23.163 2.56159 23.0654 2.32617 22.8884 2.14915C22.7114 1.97213 22.4762 1.87474 22.2259 1.87474H2.81184C2.56159 1.87474 2.32617 1.97231 2.14915 2.14933C1.97213 2.32635 1.87456 2.56159 1.87456 2.81184V18.5498C1.87456 19.0665 2.29505 19.487 2.81184 19.487H13.4153V21.3616H2.81184C1.2613 21.3616 0 20.1001 0 18.5498V2.81184C0 1.2613 1.2613 0 2.81184 0H11.0842L22.2263 0.000183062Z'
        fill='white'
      />
      <path
        d='M25.0376 17.1889H21.101V13.2523H19.2264V17.1889H15.2899V19.0634H19.2264V23H21.101V19.0634H25.0376V17.1889Z'
        fill='white'
      />
      <path
        d='M9.86327 10.0334L7.30261 14.2063H16.6917L13.2775 7.9469L10.7168 12.1198L9.86327 10.0334Z'
        fill='white'
      />
    </svg>
  );
};
