export const ReceiptIcon = ({
  size = { width: 37, height: 41 },
  color = 'white',
}) => {
  const { width, height } = size;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 37 41'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_d)'>
        <path
          d='M31.818 1L29.1665 2.12902L26.2499 1L23.3331 2.12902L20.4165 1L17.4998 2.12902L14.5831 1L11.6665 2.12902L8.74978 1L5.83317 2.12902L3.18164 1V36L5.83317 34.871L8.74978 36L11.6665 34.871L14.5831 36L17.4998 34.871L20.4165 36L23.3331 34.871L26.2499 36L29.1665 34.871L31.818 36V1Z'
          fill='#EDEBED'
        />
        <path
          d='M5.56799 22.4516V2.01616L3.18164 1V36L5.83317 34.871L8.74978 36L11.6665 34.871L14.5831 36L17.4998 34.871L20.4165 36L23.3331 34.871L26.2499 36L29.1666 34.871L31.818 36V33.742H21.4771C12.6907 33.742 5.56799 28.6871 5.56799 22.4516Z'
          fill='#DBD9DC'
        />
        <path
          d='M27.5754 7.36361H24.9239C24.3376 7.36361 23.8633 7.00779 23.8633 6.56816C23.8633 6.12843 24.3377 5.77271 24.9239 5.77271H27.5754C28.1617 5.77271 28.636 6.12853 28.636 6.56816C28.636 7.00779 28.1616 7.36361 27.5754 7.36361Z'
          fill='#6F6571'
        />
        <path
          d='M27.5754 10.5455H24.9239C24.3376 10.5455 23.8633 10.1897 23.8633 9.75004C23.8633 9.31032 24.3377 8.95459 24.9239 8.95459H27.5754C28.1617 8.95459 28.636 9.31042 28.636 9.75004C28.636 10.1897 28.1616 10.5455 27.5754 10.5455Z'
          fill='#6F6571'
        />
        <path
          d='M27.5754 16.909H24.9239C24.3376 16.909 23.8633 16.5532 23.8633 16.1136C23.8633 15.6738 24.3377 15.3181 24.9239 15.3181H27.5754C28.1617 15.3181 28.636 15.6739 28.636 16.1136C28.636 16.5532 28.1616 16.909 27.5754 16.909Z'
          fill='#6F6571'
        />
        <path
          d='M27.5754 21.6817H24.9239C24.3376 21.6817 23.8633 21.3259 23.8633 20.8863C23.8633 20.4466 24.3377 20.0908 24.9239 20.0908H27.5754C28.1617 20.0908 28.636 20.4466 28.636 20.8863C28.636 21.3259 28.1616 21.6817 27.5754 21.6817Z'
          fill='#6F6571'
        />
        <path
          d='M18.1115 7.36361H7.34229C6.8011 7.36361 6.36328 7.00779 6.36328 6.56816C6.36328 6.12843 6.80122 5.77271 7.34229 5.77271H18.1115C18.6527 5.77271 19.0906 6.12853 19.0906 6.56816C19.0906 7.00779 18.6526 7.36361 18.1115 7.36361Z'
          fill='#AEA8AF'
        />
        <path
          d='M19.8146 10.5455H7.23017C6.75096 10.5455 6.36328 10.1897 6.36328 9.75004C6.36328 9.31032 6.75106 8.95459 7.23017 8.95459H19.8146C20.2938 8.95459 20.6815 9.31042 20.6815 9.75004C20.6815 10.1897 20.2938 10.5455 19.8146 10.5455Z'
          fill='#9C969E'
        />
        <path
          d='M19.8392 16.909H7.20552C6.73993 16.909 6.36328 16.5532 6.36328 16.1136C6.36328 15.6738 6.74003 15.3181 7.20552 15.3181H19.8392C20.3048 15.3181 20.6815 15.6739 20.6815 16.1136C20.6815 16.5532 20.3048 16.909 19.8392 16.909Z'
          fill='#9C969E'
        />
        <path
          d='M14.9542 21.6817H7.31781C6.79015 21.6817 6.36328 21.3259 6.36328 20.8863C6.36328 20.4466 6.79027 20.0908 7.31781 20.0908H14.9542C15.4819 20.0908 15.9087 20.4466 15.9087 20.8863C15.9087 21.3259 15.4817 21.6817 14.9542 21.6817Z'
          fill='#AEA8AF'
        />
        <path
          d='M27.3432 26.4544H9.2467C8.53216 26.4544 7.9541 26.0986 7.9541 25.659C7.9541 25.2194 8.53231 24.8635 9.2467 24.8635H27.3433C28.0579 24.8635 28.6359 25.2194 28.6359 25.659C28.6359 26.0986 28.0577 26.4544 27.3432 26.4544Z'
          fill='#6F6571'
        />
        <path
          d='M21.3185 29.6363H13.6821C13.1544 29.6363 12.7275 29.2805 12.7275 28.8409C12.7275 28.4012 13.1545 28.0454 13.6821 28.0454H21.3185C21.8461 28.0454 22.273 28.4012 22.273 28.8409C22.273 29.2805 21.8461 29.6363 21.3185 29.6363Z'
          fill='#9C969E'
        />
      </g>
      <defs>
        <filter
          id='filter0_d'
          x='-1'
          y='0'
          width='41'
          height='41'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          />
          <feOffset dx='2' dy='2' />
          <feGaussianBlur stdDeviation='1.5' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
