import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { SettingsWrap } from '@/components';
import { AppBackButton } from '@/components/app-back-button';
import { useGetUser } from '@/gql/api-user';
import { isApplicationOnlineAtom } from '@/store';
import c from 'classnames';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { AppTheme } from './components/app-theme';
import { useProfileEdit } from './components/profile-section/use-profile-edit';
import { SettingsIcon } from './settings-icon';
import { useSettingsMenu } from './use-settings-menu';

import { AppTappable } from '@/components/app-tappable';
import { PrivateRoute } from '@/components/private-route';
import { ProfileInfo } from '@/components/profile-info';
import { AppType } from '@/core.types';
import { hasAppAccess } from '@/services/app-service';
import { unlockBackSwipe } from '@/services/helper-service';
import { Account } from './components/Account';
import { Plan } from './components/app-plan';
import { Manage } from './components/Manage';
import { ManageAccount } from './components/manage-account';
import { Password } from './components/password';
import { Phone } from './components/phone-section/phone';
import { Profile } from './components/profile-section';
import { Recognition } from './components/recognition';
import { SettingsEmail } from './components/settings-email';
import { SettingsLanguage } from './components/settings-language';
import { MenuItemClass, SettingsMenu } from './components/settings-menu';
import { LogoHeader } from './logo-header';
import { LogoutButton } from './logout-button';
import { Terms } from './terms';
import { Invite } from './components/invite-section';

const MARGIN_WIDTH = 5;
export const SAVE_BUTTON_CLASSNAME =
  'w-full h-[40px] rounded-[10px] mt-[20px] bg-[var(--secondary)] text-white';

export const Settings = () => {
  const isDesktop = window.matchMedia('(min-width: 1022px)').matches;
  const isMobile = !isDesktop;
  const isApplicationOnline = useRecoilValue(isApplicationOnlineAtom);
  const recognitionShown = !hasAppAccess(AppType.idocument) || isMobile;
  const menuRoute = useSettingsMenu({
    isApplicationOnline,
    recognitionShown,
  });
  const editProfileInfoRef = useRef<HTMLDivElement | null>(null);
  const match = useRouteMatch();
  const history = useHistory();
  const { formatMessage: t } = useIntl();
  const { fetchUser } = useGetUser();
  const { data, userIcon: profileUserIcon } = useProfileEdit();

  const pathArray = history.location.pathname.split('/');
  const isSettingsTab = pathArray.length === 2;
  const [editProfileInfoWidth, setEditProfileInfoWidth] = useState(0);
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;

  useEffect(() => {
    if (isDesktop) return;
    const main = document.querySelector('.main-section');
    if (main) main.scrollTop = 0;
  }, [history.location.pathname, isDesktop]);

  const showMenu = () => {
    const arrPath = history.location.pathname
      .split('/')
      .filter((el) => el !== '');
    return arrPath.length !== 1;
  };
  useEffect(() => {
    fetchUser();
    window.newPoly.reset();
  }, [fetchUser]);

  useLayoutEffect(() => {
    editProfileInfoRef.current &&
      isSettingsTab &&
      setEditProfileInfoWidth(
        editProfileInfoRef.current?.clientWidth + MARGIN_WIDTH,
      );
  }, [history.location.pathname, isSettingsTab]);

  const accountSettingsIconPropsMap = {
    family: {
      name: 'family-account',
      color: colorEmphasized,
    },
    personal: {
      name: 'personal-account',
      color: colorEmphasized,
    },
    business: {
      name: 'business-account',
      color: colorEmphasized,
    },
  };

  const getRoute = () => (
    <Switch>
      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/profile`}
          component={Profile}
          mobile
        />
      )}
      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/manage/:id`}
          component={ManageAccount}
          mobile
        />
      )}
      {isApplicationOnline && (
        <PrivateRoute path={`${match.path}/manage`} component={Manage} mobile />
      )}

      {isApplicationOnline && (
        <PrivateRoute path={`${match.path}/invite`} component={Invite} mobile />
      )}

      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/plan`}
          component={Plan}
          mobile={isMobile}
        />
      )}

      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/password`}
          component={Password}
          mobile={isMobile}
        />
      )}

      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/email`}
          component={SettingsEmail}
          mobile={isMobile}
        />
      )}

      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/phone`}
          component={Phone}
          mobile={isMobile}
        />
      )}

      <PrivateRoute
        path={`${match.path}/language`}
        component={SettingsLanguage}
        mobile={isMobile}
      />

      <PrivateRoute
        path={`${match.path}/theme`}
        component={AppTheme}
        mobile={isMobile}
      />

      {recognitionShown && (
        <PrivateRoute
          path={`${match.path}/recognition`}
          component={Recognition}
          mobile={isMobile}
        />
      )}

      <PrivateRoute
        path={`${match.path}/terms`}
        component={Terms}
        mobile={isMobile}
      />

      {isApplicationOnline && (
        <PrivateRoute
          path={`${match.path}/account`}
          component={Account}
          mobile={isMobile}
        />
      )}
      {!isMobile && isSettingsTab && <Redirect to={`${match.path}/profile`} />}
    </Switch>
  );

  const withMenu = () => {
    const isProfileActive = pathArray.includes('profile') && isDesktop;
    const isManageActive = pathArray.includes('manage') && isDesktop;
    const accountType = data?.user.account.accountType;

    return (
      <div className='p-[15px_15px_0] m-auto tablet:max-w-[667px] laptop:max-w-[1023px] laptop:flex laptop:justify-between laptop:p-[40px_15px]'>
        <ul className='list-none p-0 w-full laptop:overflow-hidden laptop:flex laptop:flex-col laptop:h-fit laptop:w-[45%] laptop:mb-0 laptop:box-border laptop:border-[2px] laptop:border-[var(--textMain10)] laptop:shadow-[0_4px_4px_var(--box-shadow)] laptop:rounded-[14px]'>
          <AppTappable
            onTap={async () => {
              await unlockBackSwipe();
              history.push('/settings/profile');
            }}
            component='li'
            className={c('h-fit', MenuItemClass, 'laptop:p-[20px_35px]', {
              'laptop:bg-[var(--textMain10)]': isProfileActive,
            })}
          >
            {data?.user && (
              <ProfileInfo
                containerStyles={
                  editProfileInfoWidth
                    ? {
                        width: `calc(100% - ${editProfileInfoWidth}px)`,
                      }
                    : {}
                }
                userIcon={profileUserIcon}
                ownerFullName={data.user.account.ownerFullName}
                userCurrency={data.user.currency}
              />
            )}
            {isApplicationOnline && (
              <div ref={editProfileInfoRef} className='flex items-center'>
                <SettingsIcon
                  name='edit'
                  color={colorEmphasized}
                  sizeType='small'
                />
                <span className='whitespace-nowrap font-normal text-[12px] leading-[14px] text-center text-[var(--emphasized)] ml-[5px] w-min'>
                  {t({ id: 'menu.profile' })}
                </span>
              </div>
            )}
          </AppTappable>

          <li
            className={c(
              'h-[2px] w-full bg-[var(--textMain10)] laptop:m-auto laptop:w-[calc(100%-70px)]',
              { 'mt-[10px]': !isDesktop },
              { 'laptop:w-full': isProfileActive || isManageActive },
            )}
          />
          {isApplicationOnline && (
            <AppTappable
              component='li'
              onTap={async () => {
                await unlockBackSwipe();
                if (accountType === 'family') {
                  history.push('/settings/manage');
                }
              }}
              className={c('menu-item--account-type h-[40px]', MenuItemClass, {
                'laptop:bg-[var(--textMain10)]': isManageActive,
              })}
            >
              <div className='flex items-center'>
                <SettingsIcon
                  {...accountSettingsIconPropsMap[accountType ?? 'personal']}
                />
                <div className='w-[10px]'></div>
                <span
                  className='font-bold text-[15px] leading-[18px] tracking-[0.09em] uppercase text-[var(--emphasized)]'>
                  {t({
                    id: `menu.settings.account.${
                      accountType?.toLowerCase() === 'family'
                        ? 'premium'
                        : accountType ?? 'personal'
                    }`,
                  })}
                </span>
              </div>
              {accountType === 'family' && (
                <div className='flex items-center'>
                  <SettingsIcon
                    name='gear'
                    color={colorEmphasized}
                    sizeType='small'
                  />
                  <span className='font-normal text-[12px] leading-[14px] text-center text-[var(--emphasized)] ml-[5px]'>
                    {t({ id: 'menu.profile.manage' })}
                  </span>
                </div>
              )}
            </AppTappable>
          )}

          <li
            className={c(
              'h-[2px] w-full bg-[var(--textMain10)] laptop:m-auto laptop:w-[calc(100%-70px)]',
              { 'laptop:m-[0_auto_15px]': isDesktop },
              { 'laptop:w-full': isManageActive },
            )}
          />

          <SettingsMenu
            isDesktop={isDesktop}
            location={history.location}
            menuRoute={menuRoute}
            path={match.path}
          />

          {isApplicationOnline && <LogoutButton />}
        </ul>
        {getRoute()}
      </div>
    );
  };

  const onBackClick = () => history.goBack();
  const withoutMenu = () => <>{getRoute()}</>;
  const isShowMenu = isMobile && showMenu();

  return (
    <SettingsWrap>
      <header
        className={c('grid grid-cols-[30%_40%_30%] items-center', {
          'grid grid-cols-[100%]': isSettingsTab,
        })}
      >
        {!isSettingsTab && !isDesktop && (
          <AppBackButton
            onBackClick={onBackClick}
            label={t({ id: 'back' })}
            className='p-[10px] h-full'
          />
        )}
        <LogoHeader
          customName={t({ id: `menu${history.location.pathname}` })}
          className='m-auto text-[var(--logo)] h-[44px] text-[18px] font-medium flex items-center whitespace-nowrap tablet:h-[50px]'
        />
      </header>
      <main
        className={c(
          'main-section',
          'h-full overflow-y-auto rounded-[15px]',
          'bg-[var(--mainBackground)]',
          {
            '!overflow-auto': isShowMenu,
            'main-section--desktop': isDesktop,
          },
        )}
      >
        {isShowMenu ? withoutMenu() : withMenu()}
      </main>
    </SettingsWrap>
  );
};
