import { AppSlider } from '@/components/app-slider';
import { interpolate } from '@/services';
import c from 'classnames';
import debounce from 'lodash/debounce';
import { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useClickAway } from 'react-use';
import { useCropStepContext } from './crop-step-context';
import {
  CONTRAST_RANGE_MAP,
  INTENSITY_SLIDER_RANGE_MAP,
  SLIDER_RANGE_TYPE,
  SLIDER_STEP,
} from './crop-step.constants';

const applyFilters = debounce((params) => {
  window.newPoly.applyFilters(params);
}, 200);

export const CropStepImageIntensityControls = () => {
  const { formatMessage: t } = useIntl();
  const { hideIntensity, intensityEnabled } = useCropStepContext();
  const [brightness, setBrightness] = useState(
    window.newPoly.filters.brightness,
  );
  const [contrast, setContrast] = useState(window.newPoly.filters.contrast);
  const [contrastPercentageValue, setContrastPercentageValue] = useState(0);

  const onBrightnessChange = (brightness) => {
    setBrightness(brightness);
    applyFilters({ brightness, contrast });
  };

  const onChangeContrast = (percentageValue) => {
    const rangeType =
      percentageValue < 0
        ? SLIDER_RANGE_TYPE.negative
        : SLIDER_RANGE_TYPE.positive;

    const interpolateValue = interpolate({
      inputRange: INTENSITY_SLIDER_RANGE_MAP[rangeType],
      outputRange: CONTRAST_RANGE_MAP[rangeType],
    });
    const interpolatedValue = interpolateValue(percentageValue);
    setContrast(interpolatedValue);
    setContrastPercentageValue(percentageValue);
    applyFilters({ brightness, contrast: interpolatedValue });
  };

  const onClickAway = (event) => {
    const styleButton = document.getElementById('intensity-button');
    if (
      intensityEnabled &&
      styleButton &&
      !styleButton.contains(event.target)
    ) {
      hideIntensity();
    }
  };

  const ref = useRef(null);
  useClickAway(ref, onClickAway);

  return (
    <div
      ref={ref}
      className={c(
        'bg-[var(--primary)] h-[120px] absolute w-full left-1/2 -translate-x-1/2 flex items-center justify-center z-10 transition duration-300 top-0',
        {
          'top-[-110px]': intensityEnabled,
        },
      )}
    >
      <div className='w-full pt-[19px] px-[15px] pb-0 max-w-[540px]'>
        <AppSlider
          {...{
            min: INTENSITY_SLIDER_RANGE_MAP.all[0],
            max: INTENSITY_SLIDER_RANGE_MAP.all[1],
            step: SLIDER_STEP,
            value: brightness,
            caption: t({ id: 'style.style' }),
            onRangeChange: onBrightnessChange,
          }}
        />
        <AppSlider
          {...{
            min: INTENSITY_SLIDER_RANGE_MAP.all[0],
            max: INTENSITY_SLIDER_RANGE_MAP.all[1],
            step: SLIDER_STEP,
            value: contrastPercentageValue,
            onRangeChange: onChangeContrast,
            caption: t({ id: 'style.contrast' }),
          }}
        />
      </div>
    </div>
  );
};
