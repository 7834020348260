import { Redirect, Route } from 'react-router-dom';

export const PrivateRoute = ({
  component: Component,
  hasAccess = true,
  ...rest
}: {
  component: any;
  hasAccess?: boolean;
  [x: string]: any;
}) => (
  <Route
    {...rest}
    render={(props) =>
      hasAccess === true ? (
        <Component {...{ ...props, ...rest }} />
      ) : (
        <Redirect to='/' />
      )
    }
  />
);
