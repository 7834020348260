import { APP_COLOR_THEME_TYPE } from '@/color-theme.constants';
import { AppPseudoRadio } from '@/components/app-pseudo-radio';
import { addClickHandler } from '@/services/helper-service';

export type ThemePaletteProps = {
  label: string;
  themeType: APP_COLOR_THEME_TYPE;
  onThemeChange(themeType: APP_COLOR_THEME_TYPE): void;
  selectedAppColorType: APP_COLOR_THEME_TYPE | undefined;
};

const IRECEIPT_COLOR_TILES = ['#252F6F', '#141B50', '#A3ACE2'];
const LIGHT_COLOR_TILES = ['#E2E2E2', '#EAEAEA', '#59A4AE'];
const DARK_COLOR_TILES = ['#181818', '#101010', '#3079E8'];
const IDOCUMENT_COLOR_TILES = ['#043D36', '#0A4D45', '#59A4AE'];

export const COLOR_TILES_MAP = {
  [APP_COLOR_THEME_TYPE.DARK]: DARK_COLOR_TILES,
  [APP_COLOR_THEME_TYPE.LIGHT]: LIGHT_COLOR_TILES,
  [APP_COLOR_THEME_TYPE.IRECEIPT]: IRECEIPT_COLOR_TILES,
  [APP_COLOR_THEME_TYPE.IDOCUMENT]: IDOCUMENT_COLOR_TILES,
};

export const ThemePalette = ({
  label,
  themeType,
  onThemeChange,
  selectedAppColorType = APP_COLOR_THEME_TYPE.IDOCUMENT,
}: ThemePaletteProps) => {
  const colorTiles = COLOR_TILES_MAP[themeType];

  const onThemePaletteClick = () => {
    onThemeChange(themeType);
  };

  return (
    <div
      className='cursor-pointer mt-[40px] w-full'
      {...addClickHandler(onThemePaletteClick)}
    >
      <div className='flex items-center mb-[20px] font-roboto text-[18px] font-bold text-[var(--textMain)]'>
        <AppPseudoRadio isItemActive={selectedAppColorType === themeType} />
        <div className='w-[10px]'></div>
        <span className='ion-float-lg-left'>{label}</span>
      </div>
      <div className='flex'>
        {colorTiles.map((color) => (
          <div
            className='w-[33.3%] h-[40px]'
            style={{ backgroundColor: color }}
            key={color}
          />
        ))}
      </div>
    </div>
  );
};
