import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { useCreateOrUpdateReceiptMutation } from '@/graphql/codegen/graphql';
import { useLogout } from '@/hooks/use-logout';
import { delay, resolveFilePath } from '@/services/helper-service';
import { useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useSetRecoilState } from 'recoil';
import { createFileFromSrc } from '../components/idcrop/id-crop-helpers';
import {
  REQUEST_TYPE,
  RequestCreateCategoryItem,
  RequestCreateReceiptItem,
  RequestDeleteCategoryItem,
  RequestDeleteReceiptItem,
  RequestUpdateCategoryItem,
  RequestUpdateReceiptItem,
  ServerError,
  ServerErrorResponse,
  ServerErrorType,
} from '../core.types';
import {
  useCreateOrUpdateCategoryRequest,
  useDeleteCategoryList,
} from '../gql/api-category';
import {
  uploadFile,
  useDeleteReceipts,
  useSetReadDocumentPermission,
} from '../gql/api-receipt';
import {
  checkIfTableIsEmpty,
  localReceiptFilesTable,
  replaceTempIdInLocalFilesTable,
  requestTable,
  waitUntilAppWillBeOnline,
  waitUntilDBWillBeNotEmpty,
} from '../services/indexed-db-service';
import { queryQueueItemsCountAtom } from '../store';
import {
  deleteRelatedRequests,
  updateCategoryRequestTempIdsOnRealIds,
  updateReceiptRequestTempIdsOnRealIds,
} from './use-query-queue';

type RequestErrorMiddlewareDTO = {
  errorResponse: ServerErrorResponse;
  rollbackStrategy(): Promise<void>;
};

export const useRequestSync = () => {
  const setQueryQueueItemsCount = useSetRecoilState(queryQueueItemsCountAtom);
  const [createReceipt] = useCreateOrUpdateReceiptMutation();
  const [updateReceipt] = useCreateOrUpdateReceiptMutation();
  const { deleteReceipts } = useDeleteReceipts();

  const { createOrUpdateCategoryRequest } = useCreateOrUpdateCategoryRequest();
  const { deleteCategories } = useDeleteCategoryList();
  const {
    updateCategoryList,
    updateReceiptList,
    updateReceiptTempIdOnRealId,
    updateCategoryTempIdOnRealId,
    deleteCategoryList,
    deleteReceiptList,
  } = useContext(CategoryTreeContext);
  const setReadDocumentPermission = useSetReadDocumentPermission();

  const logout = useLogout();

  const postProcessAllRequestQueueErrors = async (errors: ServerError[]) => {
    for (const error of errors) {
      if (
        [
          ServerErrorType.TYPE_CODE_TOKEN_ISSUE,
          ServerErrorType.TYPE_CODE_WRONG_ACCOUNT_ID_HEADER,
        ].includes(error.type)
      ) {
        await logout();
        break;
      }
    }
  };

  const requestErrorMiddleware = async ({
    errorResponse,
    rollbackStrategy,
  }: RequestErrorMiddlewareDTO) => {
    const errors: ServerError[] = errorResponse.graphQLErrors as ServerError[];

    for (const error of errors) {
      const toastCaller =
        error.type === ServerErrorType.TYPE_CODE_UNEXPECTED_BY_FE
          ? toast.info
          : toast.error;

      toastCaller(error.message, {
        autoClose: 3000,
        position: 'bottom-center',
      });

      if (
        [
          ServerErrorType.TYPE_CODE_TOKEN_ISSUE,
          ServerErrorType.TYPE_CODE_WRONG_ACCOUNT_ID_HEADER,
          ServerErrorType.TYPE_CODE_OFFLINE_ERROR,
        ].includes(error.type)
      ) {
        await rollbackStrategy();
      }
    }

    // ! Need delay to prevent server from Ddos
    await delay(3000);
    return errors;
  };

  const removeRequestItem = async (queryKey: string) => {
    await requestTable.where('id').equals(queryKey).delete();
    setQueryQueueItemsCount(await requestTable.count());
  };

  const createReceiptStrategy = async (
    requestKey: string,
    { payload, headers, rollbackPayload }: RequestCreateReceiptItem,
  ) => {
    try {
      const {
        imagePath,
        thumbPath,
        updatedAt: mockUpdatedAt,
        id: tempId,
        ...receiptData
      } = payload;

      const mainFile = (await createFileFromSrc(
        resolveFilePath(imagePath),
      )) as File;

      const imageThumbFile = (await createFileFromSrc(
        resolveFilePath(thumbPath),
      )) as File;

      const { data } = await createReceipt({
        variables: {
          ...receiptData,
          fileExtension: mainFile?.type.split('/').pop(),
        },
        context: {
          headers,
        },
      });

      const { id, updatedAt, uploadFileLink, uploadFileThumbLink } =
        data?.createOrUpdateReceipt || {};

      if (
        !data?.createOrUpdateReceipt ||
        !uploadFileLink ||
        !uploadFileThumbLink ||
        !id
      ) {
        return;
      }

      await Promise.all([
        uploadFile({ url: uploadFileLink, file: mainFile }),
        uploadFile({
          url: uploadFileThumbLink,
          file: imageThumbFile,
        }),
      ]);

      await setReadDocumentPermission({
        variables: { id, fileUploaded: true },
      });
      // Update receipt ids in request queue table
      await updateReceiptRequestTempIdsOnRealIds({
        tempId: tempId as string,
        id,
      });
      // Update receipt ids in local files table
      await replaceTempIdInLocalFilesTable({
        table: localReceiptFilesTable,
        tempId: String(tempId),
        id: id,
      });
      // Update receipt ids in storage + local receipt and categories tables
      updateReceiptTempIdOnRealId({
        tempId: tempId as string,
        id,
        updatedAt,
      });
      await removeRequestItem(requestKey);
    } catch (errorResponse) {
      return requestErrorMiddleware({
        errorResponse: errorResponse as ServerErrorResponse,
        rollbackStrategy: async () => {
          await deleteRelatedRequests(payload.id);
          await removeRequestItem(requestKey);
          deleteReceiptList(rollbackPayload);
        },
      });
    }
  };
  const updateReceiptStrategy = async (
    requestKey: string,
    { payload, headers, rollbackPayload }: RequestUpdateReceiptItem,
  ) => {
    try {
      await updateReceipt({
        variables: {
          ...payload,
          category_id: payload.category_id,
        },
        context: {
          headers,
        },
      });
      await removeRequestItem(requestKey);
    } catch (errorResponse) {
      return requestErrorMiddleware({
        errorResponse: errorResponse as ServerErrorResponse,
        rollbackStrategy: async () => {
          await removeRequestItem(requestKey);
          updateReceiptList([rollbackPayload]);
        },
      });
    }
  };

  const createCategoryStrategy = async (
    requestKey: string,
    { payload, headers, rollbackPayload }: RequestCreateCategoryItem,
  ) => {
    try {
      const { id: tempId, ...restCategory } = payload;
      const {
        data: {
          // @ts-ignore
          createOrUpdateCategory: { id, updatedAt },
        },
      } = await createOrUpdateCategoryRequest({
        variables: {
          ...restCategory,
          id: null,
        },
        context: {
          headers,
        },
      });

      await updateCategoryRequestTempIdsOnRealIds({
        tempId: tempId as string,
        id,
      });

      await updateCategoryTempIdOnRealId({
        tempId: tempId as string,
        id,
        updatedAt,
      });
      await removeRequestItem(requestKey);
    } catch (errorResponse) {
      return requestErrorMiddleware({
        errorResponse: errorResponse as ServerErrorResponse,
        rollbackStrategy: async () => {
          await removeRequestItem(requestKey);
          deleteCategoryList([rollbackPayload]);
        },
      });
    }
  };

  const deleteReceiptStrategy = async (
    requestKey: string,
    { payload, headers, rollbackPayload }: RequestDeleteReceiptItem,
  ) => {
    try {
      await deleteReceipts({
        variables: { ids: payload },
        context: { headers },
      });
      // todo later use only on ios
      // todo later remove this items by id from images store, and delete them from vision kit
      await removeRequestItem(requestKey);
    } catch (errorResponse) {
      return requestErrorMiddleware({
        errorResponse: errorResponse as ServerErrorResponse,
        rollbackStrategy: async () => {
          await removeRequestItem(requestKey);
          updateReceiptList(rollbackPayload);
        },
      });
    }
  };

  const deleteCategoryStrategy = async (
    requestKey: string,
    { payload, headers, rollbackPayload }: RequestDeleteCategoryItem,
  ) => {
    try {
      await deleteCategories({
        variables: { ids: payload },
        context: { headers },
      });
      await removeRequestItem(requestKey);
    } catch (errorResponse) {
      return requestErrorMiddleware({
        errorResponse: errorResponse as ServerErrorResponse,
        rollbackStrategy: async () => {
          await removeRequestItem(requestKey);
          updateCategoryList(rollbackPayload);
        },
      });
    }
  };

  const updateCategoryStrategy = async (
    requestKey: string,
    { payload, headers, rollbackPayload }: RequestUpdateCategoryItem,
  ) => {
    try {
      await createOrUpdateCategoryRequest({
        variables: payload,
        context: {
          headers,
        },
      });
      await removeRequestItem(requestKey);
    } catch (errorResponse) {
      return requestErrorMiddleware({
        errorResponse: errorResponse as ServerErrorResponse,
        rollbackStrategy: async () => {
          await removeRequestItem(requestKey);
          updateCategoryList([rollbackPayload]);
        },
      });
    }
  };

  const REQUEST_TYPE_STRATEGY_MAP = {
    [REQUEST_TYPE.createReceipt]: createReceiptStrategy,
    [REQUEST_TYPE.updateReceipt]: updateReceiptStrategy,
    [REQUEST_TYPE.deleteReceipt]: deleteReceiptStrategy,
    [REQUEST_TYPE.createCategory]: createCategoryStrategy,
    [REQUEST_TYPE.updateCategory]: updateCategoryStrategy,
    [REQUEST_TYPE.deleteCategory]: deleteCategoryStrategy,
  };

  useEffect(() => {
    const handleCategoryTreeRequestList = async () => {
      let completedRequestErrors: ServerError[] = [];
      while (true) {
        try {
          await Promise.all([
            waitUntilAppWillBeOnline(),
            waitUntilDBWillBeNotEmpty(requestTable),
          ]);

          const requestKeysList = await requestTable.toCollection().keys();
          // TODO remove counter
          setQueryQueueItemsCount(requestKeysList.length);

          for await (const requestKey of requestKeysList) {
            const request = await requestTable.get(requestKey);

            if (!request) {
              continue;
            }
            // @ts-ignore
            const errors = await REQUEST_TYPE_STRATEGY_MAP[request.requestType](
              request.id,
              // @ts-ignore
              request,
            );
            if (errors) {
              completedRequestErrors.push(...errors);
            }
          }

          if (await checkIfTableIsEmpty(requestTable)) {
            postProcessAllRequestQueueErrors(completedRequestErrors);
            completedRequestErrors = [];
          }
        } catch (error) {
          console.log('handleCategoryTreeRequestList - error', error);
        }
      }
    };
    handleCategoryTreeRequestList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
