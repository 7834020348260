import { AppButton } from '@/components/app-button';
import { useIntl } from 'react-intl';
import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { SettingsIcon } from '../../settings-icon';
import c from 'classnames';

export const SettingsEmailsList = ({
  userEmailsList,
  onDeleteEmailClick,
}: any) => {
  const { formatMessage: t } = useIntl();
  const colorTextMain20 = `var(${APP_CSS_THEME_VARIABLES.textMain20})`;

  return (
    <ul className='email__mail-list'>
      {userEmailsList?.length ? (
        userEmailsList.map((email, index) => (
          <li
            className={c('mt-5 flex justify-between items-center', {
              'mt-[20px]': index == 0,
            })}
            key={email.email}
          >
            <span className='flex items-center'>
              <SettingsIcon name='mail' color={colorTextMain20} />
              <span className='ml-[15px] font-medium text-[16px] leading-[22px] text-[var(--textMain)] opacity-90'>
                {email.email}
              </span>
            </span>
            <AppButton
              onPress={onDeleteEmailClick(email.id)}
              className='p-[10px_10px_10px_20px] cursor-pointer mr-[-10px]'
            >
              <SettingsIcon
                name='close'
                sizeType='small'
                color={colorTextMain20}
              />
            </AppButton>
          </li>
        ))
      ) : (
        <div
          className={c(
            'mt-5 flex justify-between items-center',
            'font-medium text-[16px] leading-[22px] text-[var(--secondary)] w-fit mx-auto',
          )}
        >
          {t({ id: 'menu.profile.add.mail' })}
        </div>
      )}
    </ul>
  );
};
