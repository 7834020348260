import { ReactElement } from 'react';

type AppModalLayoutProps = {
  open: boolean;
  children: ReactElement;
};

export const AppModalLayout = ({ open, children }: AppModalLayoutProps) => {
  if (!open) {
    return null;
  }

  return <div className='fixed inset-0 z-10'>{children}</div>;
};
