import { MadeByFilterModal } from './category-made-by-modal';

import { AppType } from '@/core.types';
import { hasAppAccess } from '@/services/app-service';
import { CategoryHeaderLeftContent } from './category-header-left-content';
import { HeaderRightControls } from './header-right-controls';

export const CategoryPageHeader = () => {
  return (
    <header className='pl-[5px] pr-[5px] grid grid-cols-[minmax(0,1fr)_auto] justify-between items-stretch h-[50px] box-border z-[1] shadow-[0px_4px_6.14px_rgba(0,0,0,0.2)] bg-[var(--primary)] laptop:justify-center laptop:h-[55px] laptop:grid-cols-[33.3%_33.3%_33.3%]'>
      <CategoryHeaderLeftContent />
      <div className='hidden laptop:block laptop:font-medium laptop:text-[26px] laptop:leading-[30px] laptop:text-center laptop:text-[var(--logo)] laptop:user-select-none laptop:py-[10px]'>
        {hasAppAccess(AppType.ireceipt) ? 'iReceipt' : 'iDocument'}
      </div>
      <HeaderRightControls />
      <MadeByFilterModal />
    </header>
  );
};
