import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppButton } from '@/components/app-button';
import c from 'classnames';
import { AppIcon } from './app-icon';

type AppBackButtonProps = {
  onBackClick(): void;
  label?: string;
  labelClassName?: string;
  className?: string;
};

export const AppBackButton = ({
  onBackClick,
  label,
  className = '',
  labelClassName = '',
}: AppBackButtonProps) => (
  <AppButton
    className={`flex items-center cursor-pointer ${className}`}
    onPress={onBackClick}
  >
    <AppIcon
      className='mr-[10px]'
      name='arrow-left'
      color={`var(${APP_CSS_THEME_VARIABLES.actionText})`}
    />
    <div
      className={c(
        'text-[16px] text-[var(--actionText)] select-none',
        labelClassName,
      )}
    >
      {' '}
      {label}{' '}
    </div>{' '}
  </AppButton>
);
