import { InputClearableButton } from '@/components/default-input/input-clearable-button';
import c from 'classnames';
import { SyntheticEvent } from 'react';
import './app-number-input.css';

const MAX_NUMBER_VALUE = 999999999.99;
const CURRENCY_PATTERN = /^\d*([.,]\d{0,2})?$/m;

type DefaultInputProps = {
  value: string | number;
  placeholder: string;
  onChange(event: SyntheticEvent<HTMLInputElement> | string): void;
  maxNumberValue?: typeof MAX_NUMBER_VALUE;
  clearable?: boolean;
  inputClassName?: string;
};

const removeZeroOnStartValue = (value: string) => {
  const digitsArray = value.split('');
  let stopFilteringZeros = false;

  return digitsArray
    .filter((digit) => {
      if (stopFilteringZeros) {
        return true;
      }

      if (digit !== '0') {
        stopFilteringZeros = true;
        return true;
      }

      return false;
    })
    .join('');
};

export const AppNumberInput = ({
  value,
  placeholder,
  onChange,
  inputClassName = '',
  clearable = true,
  maxNumberValue = MAX_NUMBER_VALUE,
}: DefaultInputProps) => {
  const handleChange = ({
    currentTarget,
  }: SyntheticEvent<HTMLInputElement>) => {
    const amountValue = currentTarget.value;
    if (!CURRENCY_PATTERN.test(currentTarget.value)) {
      return;
    }

    const parsedValue = parseFloat(amountValue);

    const finalValue =
      parsedValue < 0
        ? 0
        : parsedValue > maxNumberValue
        ? maxNumberValue
        : removeZeroOnStartValue(amountValue).replace(',', '.');

    // @ts-ignore
    onChange({
      currentTarget: { value: finalValue },
    } as SyntheticEvent<HTMLInputElement>);
  };

  return (
    <div className='w-full m-auto relative box-border flex justify-between border-none rounded-[37px] bg-[var(--mainBackground)]'>
      <input
        type='text'
        inputMode='decimal'
        onChange={handleChange}
        placeholder={placeholder}
        value={String(value)}
        className={c(
          'h-[40px] text-left block w-full box-border font-normal text-[14px] leading-[22px] p-[10px_15px] border border-[var(--secondary)] caret-[var(--textMain60)] text-[var(--textMain60)] rounded-[37px] placeholder:text-[14px] placeholder:leading-[22px] placeholder:opacity-60 disabled:opacity-70',
          inputClassName,
          'bg-transparent',
          'app-number-input',
        )}
      />
      <div className='absolute top-1/2 right-[15px] transform -translate-y-1/2 flex items-center gap-[5px] text-[var(--textMain60)]'>
        <InputClearableButton
          {...{
            clearable,
            value,
            onChange,
          }}
        />
      </div>
    </div>
  );
};
