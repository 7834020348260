import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { useCallback, useContext, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { useSetLocalReceiptImagesToReceiptsStore } from '../category-tree-hooks/use-receipts-sync-logic';
import type {
  FileRequest,
  LocalFileReceipt,
  Maybe,
  VisionKitDownloadedFile,
} from '../core.types';
import {
  fileDownloadQueueTable,
  waitUntilAppWillBeOnline,
  waitUntilDBWillBeNotEmpty,
} from '../services/indexed-db-service';
import { getVisionKit } from '../services/vision-kit-service';
import { imageQueryQueueItemsCountAtom } from '../store';
import { delay, resolveFilePath } from '@/services/helper-service';

export const useImageRequestSync = () => {
  // TODO remove counter
  const setImageQueryQueueItemsCount = useSetRecoilState(
    imageQueryQueueItemsCountAtom,
  );
  const setLocalReceiptImageToReceiptsStore =
    useSetLocalReceiptImagesToReceiptsStore();
  const { getReceiptById } = useContext(CategoryTreeContext);

  const handleImageQueryQueue = useCallback(async () => {
    const visionKit = getVisionKit();
    while (true) {
      try {
        await Promise.all([
          waitUntilAppWillBeOnline(),
          waitUntilDBWillBeNotEmpty(fileDownloadQueueTable),
          delay(1000),
        ]);
        const imageQueueItems: FileRequest[] =
          await fileDownloadQueueTable.toArray();

        setImageQueryQueueItemsCount(imageQueueItems.length);

        for await (const imageQuery of imageQueueItems) {
          const { id, url } = imageQuery;

          // !Logic of switch account fixes
          const existedRequestResult = await fileDownloadQueueTable
            .where('id')
            .equals(id)
            .toArray();
          if (!existedRequestResult[0] || !window.onlineDispatcher.isOnline) {
            break;
          }

          // !Check if we have receipt in memory
          const receiptToEdit = getReceiptById(id);
          if (!receiptToEdit) {
            await delay(1000);
            continue;
          }
          const localFile = await new Promise<Maybe<LocalFileReceipt>>(
            (resolve) => {
              const finalPath = resolveFilePath(url);
              try {
                visionKit.download(
                  ([receipt]: VisionKitDownloadedFile[]) => {
                    resolve({
                      id,
                      ...receipt,
                    } as LocalFileReceipt);
                  },
                  (error) => {
                    console.log('vision kit error download', error);
                    resolve(null);
                  },
                  [finalPath],
                );
              } catch (error) {
                resolve(null);
              }
            },
          );
          if (localFile !== null) {
            await fileDownloadQueueTable.where('id').equals(id).delete();
            setImageQueryQueueItemsCount(await fileDownloadQueueTable.count());
            setLocalReceiptImageToReceiptsStore(localFile);
          }
        }
      } catch (error) {
        console.log('Error during server sync IMAGE request list', error);
      }
    }
  }, [
    getReceiptById,
    setImageQueryQueueItemsCount,
    setLocalReceiptImageToReceiptsStore,
  ]);

  useEffect(() => {
    if (window.cordova) {
      handleImageQueryQueue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
