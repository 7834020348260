import './app-loader.css';

export const AppLoader = () => {
  return (
    <div className='loader-window'>
      <div className='app-loader'>
        <div className='bar-container'>
          {[...Array(12)].map((x, index) => (
            <div className='bar-container-item' key={index} />
          ))}
        </div>
        <div className='app-loader-signature'>Loading...</div>
      </div>
    </div>
  );
};
