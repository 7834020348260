// @ts-nocheck
import flow from 'lodash/flow';
import IdCrop from '@/components/idcrop/IdCrop';
import { detectCornerPoints } from './edge-detection-service';
import { ImageFilters, INITIAL_FILTERS, FILTER_TYPE } from './image-filters';
import {
  cropAndTransform,
  rescalePoint,
  resizeByScale,
  resizeByWidth,
  sortPoints,
} from './open-cv-helpers-service';
import { delay, generateHash } from '@/services/helper-service';

const previewResizeWidth = 800;
const finalCroppingWidth = 5000;
const imageMiniMultiplier = 0.1;
let instIdCrop = null;
let actualMatrix = null;
let sourceMatrix = null;
let sourceScale = null;
let connerPoints;

const INITIAL_MINI_IMAGES = {
  [FILTER_TYPE.original]: null,
  [FILTER_TYPE.bw]: null,
  [FILTER_TYPE.gray]: null,
};

export class Poly {
  constructor() {
    this.currentImage = null;
    this.imagePreviewUrl = null;
    this.initialImage = null;
    this.filtersHash = '';
    this.filters = INITIAL_FILTERS;
    this.initialImageMatrix = null;
    this.matrixCache = {};
    this.miniImages = INITIAL_MINI_IMAGES;
  }

  loadImg(src, callback, saveImage = true) {
    let image = new Image();
    image.src = src;
    image.onload = () => {
      if (saveImage) {
        this.imagePreviewUrl = this.resizeGetB64image(image);
        this.initialImage = image;
        image = null;
        // async functions for getting mini images
        this.getMiniImages();
      }

      callback(image);
    };
  }

  resizeGetB64image(imgElement, size = previewResizeWidth) {
    //Читаем загруженную картинку в матрицу
    let matrix = window.cv.imread(imgElement);
    if (matrix.cols > matrix.rows) {
      window.cv.rotate(matrix, matrix, 0);
    }
    sourceMatrix = matrix;
    sourceScale = size / sourceMatrix.cols;
    // ужимаем до 800 по ширине
    matrix = resizeByWidth(matrix, previewResizeWidth);
    actualMatrix = matrix;
    return this.getB64image(matrix);
  }

  async getMiniImages() {
    await Promise.resolve();
    const imageMiniMatrix = new window.cv.Mat();
    const newSIze = new window.cv.Size(
      Math.round(sourceMatrix.cols * imageMiniMultiplier),
      Math.round(sourceMatrix.rows * imageMiniMultiplier),
    );
    window.cv.resize(
      sourceMatrix,
      imageMiniMatrix,
      newSIze,
      0,
      0,
      window.cv.INTER_AREA,
    );
    this.miniImages = {
      [FILTER_TYPE.original]: this.getB64image(imageMiniMatrix),
      [FILTER_TYPE.bw]: this.getB64image(
        ImageFilters.FILTER_TYPE_METHOD_MAP[FILTER_TYPE.bw](imageMiniMatrix),
      ),
      [FILTER_TYPE.gray]: this.getB64image(
        ImageFilters.FILTER_TYPE_METHOD_MAP[FILTER_TYPE.gray](imageMiniMatrix),
      ),
    };
  }

  getB64image(mat, filtersHash) {
    const tempCanvas = document.createElement('canvas');
    window.cv.imshow(tempCanvas, mat);
    const base64 = tempCanvas.toDataURL();

    if (filtersHash) {
      this.matrixCache[filtersHash] = base64;
    }

    return base64;
  }

  async initCrop(refContainer, cropPoints, imageSize) {
    const matrix = await this.getMatrixFromFilters({}, actualMatrix);
    const tempCanvas = document.createElement('canvas');
    window.cv.imshow(tempCanvas, matrix);
    const b64image = tempCanvas.toDataURL();
    const cols = matrix.cols;
    const rows = matrix.rows;
    if (cropPoints) {
      cropPoints = [...cropPoints];
      const scale = cols / imageSize.width;
      cropPoints = cropPoints.map((point) => {
        return { x: point.x * scale, y: point.y * scale };
      });
    }

    connerPoints =
      connerPoints || cropPoints || detectCornerPoints(matrix).intersections;

    this.init(
      b64image,
      connerPoints,
      {
        imgWidth: cols,
        imgHeight: rows,
      },
      refContainer,
    );
  }

  init(imgSrc, pointsList, size, refContainer) {
    let points = [...pointsList];

    if (pointsList.length === 4) {
      const corners = sortPoints(points);
      points = [
        corners.leftTop,
        corners.rightTop,
        corners.rightBottom,
        corners.leftBottom,
      ];
    }

    points = points.map((point) => {
      return {
        x: point.x < 0 ? 0 : Math.abs(point.x),
        y: point.y < 0 ? 0 : Math.abs(point.y),
      };
    });

    instIdCrop = new IdCrop({
      displaySelector: '#displayId',
      refContainer: refContainer,
      pointsList: [...points],
      size,
      imgSrc,
    });

    instIdCrop.init();
    window.instIdCrop = instIdCrop;
  }

  getSourceMatPoints() {
    let modifiedPoints = instIdCrop.handles.map(({ point: { x, y } }) => ({
      x,
      y,
    }));
    modifiedPoints = window.instIdCrop.resizePointsUp(
      modifiedPoints,
      window.instIdCrop.config.decreasePercent,
    );

    connerPoints = [...modifiedPoints];

    return modifiedPoints.map((point) => rescalePoint(point, sourceScale));
  }

  async crop(callback) {
    const sourceMatPoints = this.getSourceMatPoints();
    const croppedWidth = this.findCroppedWidth(sourceMatPoints);
    let scaleToSource = finalCroppingWidth / croppedWidth;
    scaleToSource = scaleToSource < 1 ? scaleToSource : 1;
    let finalMatrix = resizeByScale(sourceMatrix, scaleToSource);
    finalMatrix = await this.getMatrixFromFilters(this.filters, finalMatrix);

    const finalPoints = sourceMatPoints.map((point) =>
      rescalePoint(point, 1 / scaleToSource),
    );
    finalMatrix = cropAndTransform(finalPoints, finalMatrix);

    const imageSrc = this.getB64image(finalMatrix);
    this.currentImage = imageSrc;
    this.loadImg(imageSrc, callback, false);
  }

  findCroppedWidth(points) {
    const corners = sortPoints(points);
    const topWidth = corners.rightTop.x - corners.leftTop.x;
    const bottomWidth = corners.rightBottom.x - corners.leftBottom.x;
    return topWidth > bottomWidth ? topWidth : bottomWidth;
  }

  reset() {
    instIdCrop && instIdCrop.resetParams();
    connerPoints = undefined;
    instIdCrop = null;
    try {
      !!actualMatrix && actualMatrix.delete();
      !!sourceMatrix && sourceMatrix.delete();
    } catch (error) {}

    sourceScale = null;
    this.filters = INITIAL_FILTERS;
    this.currentImage = null;
    this.imagePreviewUrl = null;
    this.initialImage = null;
    this.filtersHash = '';
    this.removeConnerPoints();
    this.initialImageMatrix = null;
    this.matrixCache = {};
    this.miniImages = INITIAL_MINI_IMAGES;
  }

  removeConnerPoints() {
    connerPoints = undefined;
  }

  getMatFromB64(base64) {
    return new Promise((resolve) => {
      if (this.initialImageMatrix) {
        resolve(this.initialImageMatrix);
        return;
      }
      const img = new Image();
      img.src = base64;
      img.onload = () => {
        try {
          this.initialImageMatrix = window.cv.imread(img);
          resolve(this.initialImageMatrix);
        } catch (error) {
          console.log('getMatFromB64', error);
        }
      };
    });
  }

  addBorderLines(matrix, lines) {
    lines.forEach((line) => {
      window.cv.line(matrix, line.startPoint, line.endPoint, [255, 0, 0, 255]);
    });
  }

  async getMatrixFromFilters(filters, customMatrix) {
    const matrix =
      customMatrix || (await this.getMatFromB64(this.imagePreviewUrl));
    this.filters = { ...this.filters, ...filters };
    const { contrast, brightness, filterType, rotateDegree } = this.filters;

    const filtersCascadeOperation = flow(
      ImageFilters.rotate(rotateDegree),
      ImageFilters.FILTER_TYPE_METHOD_MAP[filterType],
      ImageFilters.changeFilterIntensity({ contrast, brightness }),
    );
    return filtersCascadeOperation(matrix);
  }

  async applyFilters(newFilters) {
    await delay(100);
    const newMatrix = await this.getMatrixFromFilters(newFilters);
    const filtersHash = generateHash(this.filters);
    const base64 = this.getB64image(newMatrix, filtersHash);
    await delay(100);

    if (newFilters.rotateDegree !== undefined) {
      await instIdCrop.rotatePointList(newFilters.rotateDegree, base64, {
        imgWidth: newMatrix.cols,
        imgHeight: newMatrix.rows,
      });
    } else {
      await instIdCrop.applyNewImageSrc(base64);
    }

    await newMatrix.delete();
  }
}
