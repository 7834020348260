import { AppType } from '@/core.types';

type AppHasAccessProps = {
  appType: AppType;
  children: any;
};

export const AppHasAccess = ({ appType, children }: AppHasAccessProps) => {
  if (appType !== import.meta.env.VITE_PROJECT_TYPE) {
    return null;
  }

  return children;
};
