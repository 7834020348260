import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { LocalReceipt, Maybe } from '@/core.types';
import {
  useDeleteReceiptsMutation,
  useSetReadDocumentMutation,
} from '@/graphql/codegen/graphql';
import pick from 'lodash/pick';
import { useContext } from 'react';

export const useDeleteReceipts = () => {
  const [deleteReceipts] = useDeleteReceiptsMutation();

  return {
    deleteReceipts,
  };
};

type UploadFileDTO = {
  url: string;
  file: Maybe<Blob | File>;
};

export const uploadFile = async ({ url, file }: UploadFileDTO) => {
  try {
    await fetch(url, {
      method: 'PUT',
      body: file,
    });
  } catch (error) {
    console.error('IReceipt - file Upload to GC error:', error);
    throw error;
  }
};

export const useSetReadDocumentPermission = (postUpdate = false) => {
  const { createNewReceiptList } = useContext(CategoryTreeContext);
  const [mutation] = useSetReadDocumentMutation({
    // @ts-ignore
    update: (_, { data: { createOrUpdateReceipt: receiptResponse } }) => {
      if (!postUpdate) {
        return;
      }

      createNewReceiptList([
        {
          ...pick(receiptResponse, [
            'id',
            'imagePath',
            'thumbPath',
            'amount',
            'name',
            'receiptDate',
            'createdBy',
            'type',
            'deleted',
            'updatedAt',
          ]),
          parentId: receiptResponse.category.id,
        } as LocalReceipt,
      ]);
    },
  });

  return mutation;
};
