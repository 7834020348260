type PlusIconProps = {
  size: {
    width: number;
    height: number;
  };
};

export const PlusIcon = ({
  size = { width: 54, height: 54 },
}: PlusIconProps) => {
  const { width, height } = size;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 54 54'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M51.583 24.9166H29.083V2.41658C29.083 1.26596 28.1503 0.333252 26.9997 0.333252C25.849 0.333252 24.9163 1.26596 24.9163 2.41658V24.9166H2.41634C1.26572 24.9166 0.333008 25.8493 0.333008 26.9999C0.333008 28.1505 1.26572 29.0833 2.41634 29.0833H24.9163V51.5832C24.9163 52.7339 25.849 53.6666 26.9997 53.6666C28.1503 53.6666 29.083 52.7339 29.083 51.5832V29.0833H51.583C52.7336 29.0833 53.6663 28.1505 53.6663 26.9999C53.6663 25.8493 52.7336 24.9166 51.583 24.9166Z'
        fill='white'
      />
    </svg>
  );
};
