import {
  useCreatePdfMutation,
  useNotifyPdfSavedMutation,
} from '@/graphql/codegen/graphql';

export const useGetServerPdfLink = () => {
  const [createPdfMethod] = useCreatePdfMutation();
  const [notifyPdfSavedMethod] = useNotifyPdfSavedMutation();

  const getServerPDFLink = async (name) => {
    return createPdfMethod({ variables: { name } });
  };
  const notifyPdfSaved = async (id) => {
    return notifyPdfSavedMethod({ variables: { id } });
  };

  return {
    getServerPDFLink,
    notifyPdfSaved,
  };
};
