import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppIcon } from '@/components/app-icon';
import { AppModal } from '@/components/app-modal';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import c from 'classnames';

type AddPhoneModalProps = {
  open: boolean;
  closeAddPhoneModal(): void;
  addPhone(phone: string): Promise<void>;
};

export const AddPhoneModal = ({
  open,
  closeAddPhoneModal,
  addPhone,
}: AddPhoneModalProps) => {
  const { formatMessage: t } = useIntl();
  const [phone, setPhone] = useState('');
  const [validFormatPhone, setValidFormatPhone] = useState(false);
  const normalisePhone = (phone: string) => phone.replace(/ /g, '');

  const onChangePhone = (value: string, data: any) => {
    setPhone(value);
    if (!data.format) {
      setValidFormatPhone(value.length > 8);
    } else {
      setValidFormatPhone(data.format.length === value.length);
    }
  };

  const onCloseClick = () => {
    setPhone('');
    closeAddPhoneModal();
  };

  const onSaveClick = async () => {
    if (validFormatPhone) {
      await addPhone(normalisePhone(phone));
      onCloseClick();
    }
  };

  return (
    <AppModal
      {...{
        open,
        onSaveClick,
        onClose: onCloseClick,
      }}
      content={
        <div className='relative w-full'>
          <PhoneInput
            country='no'
            value={phone}
            onChange={onChangePhone}
            inputClass={c(
              'relative h-[40px] text-left block w-full box-border font-normal text-[14px] leading-[22px] p-[10px_15px] border border-[var(--secondary)] text-[var(--textMain60)] caret-[var(--textMain60)] bg-[var(--mainBackground)] rounded-[37px] placeholder:text-[14px] placeholder:leading-[22px] placeholder:opacity-60 disabled:opacity-70',
              'bg-transparent',
            )}
          />
          <AppIcon
            name='phone'
            className='absolute top-1/2 right-[12px] transform translate-y-[-50%]'
            color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
          />
        </div>
      }
      title={t({ id: 'menu.profile.phone.new' })}
    />
  );
};
