import { AppButton, AppButtonProps } from '@/components/app-button';
import { ButtonHTMLAttributes } from 'react';
import c from 'classnames';

export const AppFormSubmitButton = ({
  children,
  ...props
}: AppButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <AppButton
      {...props}
      type='submit'
      className={c(
        'bg-[var(--secondary)] h-[40px] rounded-[10px] text-white w-full text-center',
        props.className,
      )}
    >
      {children}
    </AppButton>
  );
};
