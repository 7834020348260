import { useCallback, useState } from 'react';

type USEFormStateDTO<T> = {
  defaultValues: T;
};

export const useFormState = <T>({
  defaultValues = {} as T,
}: USEFormStateDTO<T>) => {
  const [formState, setFormState] = useState(defaultValues);

  const setField = useCallback((name: keyof T, value) => {
    setFormState((state) => ({ ...state, [name]: value }));
  }, []);

  const getField = useCallback(
    (name: keyof T) => {
      return formState[name];
    },
    [formState],
  );

  return {
    formState,
    setField,
    getField,
  };
};
