import { CategoryId, ReceiptId } from '@/core.types';
import noop from 'lodash/noop';
import { useIntl } from 'react-intl';
import { AppLoader } from '../app-loader';
import { AppModal } from '../app-modal';
import { useShareModalLogic } from './use-share-modal-logic';
import { AppButton } from '@/components/app-button';
import { AppIcon } from '../app-icon/app-icon';

export const ShareModalButton = ({
  onClick,
  icon,
  label,
}: {
  onClick(): void;
  icon: string;
  label: string;
}) => {
  return (
    <AppButton
      className='h-[42px] rounded-[10px] flex justify-center items-center w-[calc(50%-5px)] text-white text-[14px] bg-[var(--emphasized)] px-[40px] py-[10px]'
      onPress={onClick}
    >
      <AppIcon
        name={icon}
        size={{ width: 20, height: 20 }}
        className='pr-[10px]'
      />
      {label}
    </AppButton>
  );
};

export type ShareModalProps = {
  selectedCategoryIdsList?: CategoryId[];
  selectedReceiptIdsList?: ReceiptId[];
  closeShareModal(): void;
  shareModalOpened: boolean;
  onShareEnd?(): void;
};

export const ShareModal = ({
  shareModalOpened,
  closeShareModal,
  selectedCategoryIdsList = [],
  selectedReceiptIdsList = [],
  onShareEnd = noop,
}: ShareModalProps) => {
  const { formatMessage: t } = useIntl();
  const { buttons, loading } = useShareModalLogic({
    selectedCategoryIdsList,
    selectedReceiptIdsList,
    closeShareModal,
    onShareEnd,
  });

  return (
    <AppModal
      open={shareModalOpened}
      onClose={closeShareModal}
      content={
        <div className='flex justify-center gap-[10px] items-center'>
          {loading && <AppLoader />}
          {buttons.map((button) => (
            <ShareModalButton {...button} key={button.label} />
          ))}
        </div>
      }
      title={t({ id: 'modal.share' })}
    />
  );
};
