import { AppConfirmModal } from '@/components/app-confirm-modal';
import { ShareModal } from '@/components/share-modal';
import { useRootContext } from '@/context/root-context';
import { CategoryView } from '@/core.types';
import {
  useBooleanState,
  useDeleteCategoryList,
  useDeleteReceiptList,
  useDetectMobileResolution,
  usePrint,
} from '@/hooks';
import {
  createFolderModalOpenedATOM,
  editModeEnabledATOM,
  selectedCategoryIDsAtom,
  selectedReceiptIDsAtom,
} from '@/store/category-store';
import {
  useSelectedIDsAtomSetters,
  useSetBooleanAtomState,
} from '@/store/category-store/category-store.callbacks';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { CategorySyncCounter } from '../category-sync-counter';
import { CreateCategoryModal } from '../create-category-modal';
import { HeaderControlItem } from './header-control-item';

export const HeaderRightControls = () => {
  const { formatMessage: t } = useIntl();
  const { activeSliceId } = useRootContext();
  const editModeEnabled = useRecoilValue(editModeEnabledATOM);
  const isMobile = useDetectMobileResolution();
  const [enableEditMode, unsetEditMode] =
    useSetBooleanAtomState(editModeEnabledATOM);

  const createFolderModalOpened = useRecoilValue(createFolderModalOpenedATOM);
  const [openCreateFolderModal, closeCreateMolderModal] =
    useSetBooleanAtomState(createFolderModalOpenedATOM);

  const selectedCategoryIdsList = useRecoilValue(selectedCategoryIDsAtom);
  const selectedReceiptIdsList = useRecoilValue(selectedReceiptIDsAtom);
  const [_0, _1, clearSelectedCategories] = useSelectedIDsAtomSetters(
    selectedCategoryIDsAtom,
  );
  const [_2, _3, clearSelectedReceipts] = useSelectedIDsAtomSetters(
    selectedReceiptIDsAtom,
  );

  const { categoryListView, setCategoryListView } = useRootContext();
  const [shareModalOpened, openShareModal, closeShareModal] =
    useBooleanState(false);

  const [deleteModalOpened, openDeleteModal, closeDeleteModal] =
    useBooleanState(false);
  const deleteCategoryList = useDeleteCategoryList();
  const deleteReceiptList = useDeleteReceiptList();

  const print = usePrint();

  const deleteElements = async () => {
    if (activeSliceId) {
      if (selectedCategoryIdsList.length) {
        await deleteCategoryList({
          ids: selectedCategoryIdsList,
          parentId: activeSliceId,
        });
        clearSelectedCategories();
      }
      if (selectedReceiptIdsList.length) {
        await deleteReceiptList({
          ids: selectedReceiptIdsList,
          parentId: activeSliceId,
        });
        clearSelectedReceipts();
      }

      unsetEditMode();
    }

    closeDeleteModal();
  };

  const tileControlClick = () => {
    setCategoryListView(CategoryView.tile);
  };

  const onListIconClick = () => {
    setCategoryListView(CategoryView.list);
  };

  const onCloseIconClick = () => {
    unsetEditMode();
    clearSelectedCategories();
    clearSelectedReceipts();
  };

  const onShareEnd = () => {
    clearSelectedCategories();
    clearSelectedReceipts();
    unsetEditMode();
  };

  const onPrintIconClick = () => {
    print({
      categoryIds: selectedCategoryIdsList,
      receiptIds: selectedReceiptIdsList,
    });
    clearSelectedCategories();
    clearSelectedReceipts();
    unsetEditMode();
  };

  const categoryListTypeControlMap = {
    tile: (
      <HeaderControlItem
        isMobile={isMobile}
        icon='list'
        onClick={onListIconClick}
      />
    ),
    list: (
      <HeaderControlItem
        isMobile={isMobile}
        icon='tile'
        onClick={tileControlClick}
      />
    ),
  };

  const iconConfig = editModeEnabled
    ? [
        {
          icon: 'share',
          onClick: openShareModal,
        },
        {
          icon: 'print',
          onClick: onPrintIconClick,
        },
        {
          icon: 'trash',
          onClick: openDeleteModal,
        },
        {
          icon: 'close',
          onClick: onCloseIconClick,
        },
      ]
    : [
        {
          icon: 'create-folder',
          onClick: openCreateFolderModal,
        },
        {
          icon: 'select-mode',
          onClick: enableEditMode,
        },
      ];

  const renderIcon = ({ component: Component, icon, onClick }: any, index) => {
    return Component ? (
      <Component isMobile={isMobile} key={index} />
    ) : (
      <HeaderControlItem {...{ icon, onClick, isMobile }} key={index} />
    );
  };

  return (
    <>
      <div className='flex items-center justify-end'>
        {!editModeEnabled && <CategorySyncCounter />}
        {iconConfig.map(renderIcon)}
        {!editModeEnabled && categoryListTypeControlMap[categoryListView]}
      </div>
      {createFolderModalOpened && (
        <CreateCategoryModal
          open={createFolderModalOpened}
          onClose={closeCreateMolderModal}
        />
      )}
      <ShareModal
        {...{
          onShareEnd,
          shareModalOpened,
          closeShareModal,
          selectedCategoryIdsList,
          selectedReceiptIdsList,
        }}
      />
      <AppConfirmModal
        open={deleteModalOpened}
        onConfirmClick={deleteElements}
        title={t({ id: 'alert' })}
        onClose={closeDeleteModal}
      />
    </>
  );
};
