import { AppForm, AppFormTextField } from '@/components/app-form';
import { AppFormSubmitButton } from '@/components/app-form/app-form-submit-button';
import { AppLoader } from '@/components/app-loader';
import {
  AuthPageImage,
  AuthPageTitle,
  AuthPageWrapper,
} from '@/components/auth-base';
import { useForgotPasswordMutation } from '@/graphql/codegen/graphql';
import {
  FORM_VALIDATION_RESOLVER,
  setFormServerErrors,
} from '@/scenes/common-auth-logic';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

type ForgotPasswordValues = {
  email: string;
};

export const ForgotPasswordPage = () => {
  const { formatMessage: t } = useIntl();
  const [triggerForgotPasswordMutation, { data, loading }] =
    useForgotPasswordMutation();

  const onSubmit = async (
    { email }: ForgotPasswordValues,
    _: any,
    methods: any,
  ) => {
    const { setError } = methods;
    try {
      await triggerForgotPasswordMutation({
        variables: {
          email,
        },
      });
    } catch (errorResponse: any) {
      setFormServerErrors(errorResponse, setError);
    }
  };

  const isReset = !!data?.resetPasswordRequest;

  return (
    <AuthPageWrapper>
      <Link to='/auth'>
        <AuthPageImage />
      </Link>

      <AuthPageTitle>{t({ id: 'forgot.h1' })}</AuthPageTitle>
      <AuthPageTitle>{t({ id: 'forgot.p' })}</AuthPageTitle>

      {isReset ? (
        <AuthPageTitle>{t({ id: 'forgot.success' })}</AuthPageTitle>
      ) : (
        <AppForm
          onSubmit={onSubmit}
          className='w-full max-w-[300px]'
          formConfig={{ resolver: FORM_VALIDATION_RESOLVER }}
        >
          <AppFormTextField
            type='email'
            name='email'
            placeholder={t({ id: 'forgot.email' })}
            icon={{
              name: 'mail',
            }}
          />
          <AppFormSubmitButton>{t({ id: 'btn.submit' })}</AppFormSubmitButton>
        </AppForm>
      )}
      {loading && <AppLoader />}
    </AuthPageWrapper>
  );
};
