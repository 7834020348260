import { AppButton } from '@/components/app-button';
import { AppIcon } from '@/components/app-icon';
import { useIntl } from 'react-intl';

type CropStepImageActionControlProps = {
  type: string;
  onClick(): void;
  disabled: boolean;
  id: string;
};

export const CropStepImageActionControl = ({
  type,
  onClick,
  disabled,
  id,
}: CropStepImageActionControlProps) => {
  const { formatMessage: t } = useIntl();
  const label = t({ id: 'style.' + type });

  return (
    <AppButton
      className='flex flex-col items-center cursor-pointer select-none'
      onPress={onClick}
      disabled={disabled}
      id={id}
    >
      <AppIcon name={type} className='mb-[6px]' />
      <span className='text-[10px] leading-[11px] text-[var(--icons)] select-none'>
        {label}
      </span>
    </AppButton>
  );
};
