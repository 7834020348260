import { DefaultInput } from '@/components';
import { AppConfirmModal } from '@/components/app-confirm-modal';
import { AppLoader } from '@/components/app-loader';
import { AppModal } from '@/components/app-modal';
import { useBooleanState } from '@/hooks';
import { isValidEmail } from '@/services/helper-service';
import cx from 'classnames';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { SettingsEmailsList } from './settings-emails-list';
import { useChangeEmail } from './use-change-email';

import { AddButton } from '../add-button';

export const SettingsEmail = () => {
  const { formatMessage: t } = useIntl();
  const { loading, dataEmails, deleteEmail, addEmail } = useChangeEmail();
  const [email, setEmail] = useState('');
  const [emailIdToDelete, setEmailIdToDelete] = useState(null);
  const [deleteEmailModalOpened, openDeleteEmailModal, closeDeleteEmailModal] =
    useBooleanState(false);
  const [addEmailModalOpened, openAddEmailModal, closeAddEmailModal] =
    useBooleanState(false);

  const onDeleteEmailClick = (id) => () => {
    setEmailIdToDelete(id);
    openDeleteEmailModal();
  };

  const deleteUserEmail = () => {
    if (!emailIdToDelete) return;
    deleteEmail(emailIdToDelete).then(() => {
      setEmailIdToDelete(null);
      closeDeleteEmailModal();
    });
  };

  const addNewEmail = () =>
    addEmail(email).then(() => {
      setEmail('');
      closeAddEmailModal();
    });

  const onEmailChange = ({ currentTarget: { value } }) => {
    setEmail(value);
  };

  const userEmailsList = dataEmails?.user.emails;
  const saveAddEmailModalDisabled = !isValidEmail(email) || loading;

  return (
    <>
      <div className='font-roboto p-[25px_15px_20px] tablet:max-w-[667px] tablet:mx-auto laptop:w-[45%] laptop:p-0 laptop:m-0'>
        {loading && <AppLoader />}

        <span className='font-medium text-[14px] leading-[24px] text-[var(--textMain)] opacity-60'>
          {t({ id: 'menu.profile.your.mail' })}
        </span>

        <SettingsEmailsList {...{ userEmailsList, onDeleteEmailClick }} />
        <div className='bg-[var(--textMain10)] h-[2px] mt-[23px]' />
        <AddButton
          label={t({ id: 'menu.profile.add.mail' })}
          className={cx({
            'mt-[22px]': !userEmailsList?.length,
          })}
          onClick={openAddEmailModal}
        />
      </div>

      <AppConfirmModal
        open={deleteEmailModalOpened}
        onClose={closeDeleteEmailModal}
        onConfirmClick={deleteUserEmail}
        title={t({ id: 'alert' })}
      />

      <AppModal
        open={addEmailModalOpened}
        onClose={closeAddEmailModal}
        content={
          <DefaultInput
            value={email}
            onChange={onEmailChange}
            iconName='mail'
            placeholder='example@gmail.com'
          />
        }
        title={t({ id: 'menu.profile.new.mail' })}
        onSaveClick={addNewEmail}
        saveDisabled={saveAddEmailModalDisabled}
      />
    </>
  );
};
