import { PAGE_ROUTES } from '@/app.constants';
import { AppFilePreview } from '@/components/app-file-preview';
import { useGetSaveAreaPaddings } from '@/hooks/use-get-safe-area-paddings';
import { usePreloadImage } from '@/hooks/use-preload-image';
import { useLocation } from 'react-router';
import { AppLoader } from '../components/app-loader';
import { CreateReceiptInputSection } from './create-receipt-input-section';
import './create-receipt.css';
import { UpdateReceiptFooter } from './update-receipt-footer';
import { useCreateReceiptLogic } from './use-create-receipt-logic';
import { usePageLayoutSizeStyle } from './use-page-layout-style';
import { useUpdateReceiptLogic } from './use-update-receipt-logic';
import { useRootContext } from '@/context/root-context';

export const CreateReceipt = () => {
  const { receiptType } = useRootContext();
  const { pathname } = useLocation();
  const pageLogic = pathname.includes(PAGE_ROUTES.createReceipt)
    ? useCreateReceiptLogic
    : useUpdateReceiptLogic;
  const {
    activeSliceId,
    formState,
    setField,
    // @ts-ignore
    onBackClick,
    onSaveCloseClick,
    imagePath,
    loading,
    isPDFFile,
  } = pageLogic();

  const { safeAreaTop, safeAreaBottom } = useGetSaveAreaPaddings();
  const { keyboardShown, imageContainerHeight, gridTemplateRows, rowGap } =
    usePageLayoutSizeStyle({
      safeAreaTop,
      safeAreaBottom,
      receiptType,
    });

  const preloadImage = usePreloadImage(imagePath, isPDFFile);

  if (!activeSliceId || !imagePath || !preloadImage) {
    return null;
  }

  return (
    <div
      className="bg-[var(--photoBackground)] h-[100vh] w-full p-[var(--sat)_0_var(--sab)_0] grid box-border relative after:content-[''] after:block after:absolute after:w-full after:left-0 after:right-0 after:bottom-0 after:h-[var(--sab)] after:bg-[var(--primary)]"
      style={{ gridTemplateRows, rowGap }}
    >
      {!keyboardShown && (
        <div className='pt-[10px] box-border'>
          <AppFilePreview
            {...{
              isPDFFile,
              imageContainerHeight,
              preloadImage,
              name: formState.name,
            }}
          />
        </div>
      )}
      <CreateReceiptInputSection
        {...{
          receiptType,
          formState,
          setField,
        }}
      />
      <UpdateReceiptFooter
        {...{
          onBackClick,
          onSaveCloseClick,
        }}
      />
      {loading && <AppLoader />}
    </div>
  );
};
