import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppButton } from '@/components/app-button';
import { AppIcon } from '@/components/app-icon';
import { SyntheticEvent } from 'react';
import { useWatch } from 'react-hook-form';

type InputClearableButtonProps = {
  onClick(event: SyntheticEvent<HTMLInputElement>): void;
  fieldName: string;
};

const CLEAR_ICON_SIZE = { width: 12, height: 12 };

export const AppFormFieldClearButton = ({
  onClick,
  fieldName,
}: InputClearableButtonProps) => {
  const fieldValue = useWatch({ name: fieldName });

  if (!fieldValue) {
    return null;
  }

  return (
    <AppButton onPress={onClick} className='w-[30px]'>
      <AppIcon
        className='w-[30px] h-[30px] flex items-center justify-center'
        color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
        name='close'
        size={CLEAR_ICON_SIZE}
      />
    </AppButton>
  );
};
