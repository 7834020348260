import c from 'classnames';

export type Option = {
  label: string;
  value: string | number;
};

type AppSelectProps = {
  options: Option[];
  value: Option | null;
  className?: string;
  placeholder: string;
  onChange(
    event: React.ChangeEvent<HTMLSelectElement>,
    value: Option | null,
    reason: string,
  ): void;
};

export const AppSelect = ({
  options,
  onChange,
  value,
  placeholder,
  className = '',
}: AppSelectProps) => {
  const renderOption = (option: Option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  );

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    const selectedOption =
      options.find((option) => option.value === selectedValue) || null;
    onChange(event, selectedOption, 'select-option');
  };

  return (
    <div className={c('relative inline-block w-full', className)}>
      <select
        id='demo-simple-select'
        value={value ? value.value : ''}
        onChange={handleChange}
        aria-label='Without label'
        className='w-full p-2.5 px-3.5 text-sm leading-[22px] text-[var(--textMain60)] bg-transparent border border-[var(--secondary)] rounded-[37px] appearance-none cursor-pointer focus:outline-none hover:border-[var(--secondary)]'
      >
        <option value='' disabled className='text-[var(--textMain60)]'>
          {placeholder}
        </option>
        {options.map(renderOption)}
      </select>
    </div>
  );
};
