import { getAppName } from '@/services/app-service';

export const LogoHeader = ({
  className = 'app-col header-col header-col__logo',
  activeCategory: { id, name } = { id: null, name: null },
  isEdit,
  customName = null,
}: any) => {
  return (
    <>
      {(/Home/gi.test(name) && !isEdit) || (!id && !isEdit) ? (
        <div className={className}>{customName || getAppName()}</div>
      ) : null}
    </>
  );
};
