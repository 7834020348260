export const CloseIcon = ({
  size = { width: 20, height: 20 },
  color = 'white',
  opacity = '1',
}) => {
  const { width, height } = size;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity={opacity}>
        <path
          d='M19.375 17.9102L11.4648 10L19.375 2.08984C19.7795 1.68533 19.7795 1.02952 19.375 0.625C18.9705 0.220483 18.3147 0.220483 17.9102 0.625L10 8.53516L2.08984 0.625C1.68533 0.220483 1.02952 0.220483 0.625 0.625C0.220483 1.02952 0.220484 1.68533 0.625 2.08984L8.53516 10L0.625 17.9102C0.220483 18.3147 0.220483 18.9705 0.625 19.375C1.02952 19.7795 1.68533 19.7795 2.08984 19.375L10 11.4648L17.9102 19.375C18.3147 19.7795 18.9705 19.7795 19.375 19.375C19.7795 18.9705 19.7795 18.3147 19.375 17.9102Z'
          fill={color}
        />
      </g>
    </svg>
  );
};
