import { AppLoader } from '@/components/app-loader';
import { CropStepContext } from './crop-step-context';
import { CropStepFooter } from './crop-step-footer';
import { CropStepImageActions } from './crop-step-image-actions';
import { CropStepImageIntensityControls } from './crop-step-image-intensity-controls';
import { CropStepStyleFilters } from './crop-step-style-filters';
import './crop-step.css';
import { useCropStepLogic } from './use-crop-step-logic';

export const CropStep = () => {
  const value = useCropStepLogic();
  const {
    progressEnabled,
    refContainer,
    isSmallResolution,
    previewImageLoaded,
    disableBackSwipe,
    displayRef,
  } = value;

  return (
    <CropStepContext.Provider value={value}>
      {(progressEnabled || !previewImageLoaded) && <AppLoader />}
      <div
        className='flex flex-col w-full h-[100vh] box-border p-[var(--sat)_0_var(--sab)_0] bg-[var(--primary)] print:bg-none'
        onTouchStart={disableBackSwipe}
      >
        <div className='p-0 flex-grow h-full flex flex-col' ref={refContainer}>
          <div
            id='displayId'
            className='id-crop__wrapper idwall-display'
            ref={displayRef}
          />
        </div>

        <div className='react-crop__footer'>
          <CropStepImageIntensityControls />
          <CropStepStyleFilters />
          <CropStepImageActions />
          {isSmallResolution && <CropStepFooter />}
        </div>
      </div>
    </CropStepContext.Provider>
  );
};
