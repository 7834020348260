import {
  clearTokens,
  isUserAuthorized,
  setUserToken,
} from '@/services/auth-service';
import { PurchasesPackage } from '@revenuecat/purchases-capacitor';
import { useCallback } from 'react';
import {
  atom,
  RecoilValue,
  Snapshot,
  useRecoilState,
  useSetRecoilState,
} from 'recoil';
import { Poly } from '../components/CropImages/Poly';
import { CategoryId, ScrollPositionMapType } from '../core.types';

export const scrollPositionsMapAtom = atom({
  key: 'scrollPositionsMapAtom',
  default: {} as ScrollPositionMapType,
});

export const useSetScrollPosition = () => {
  const setPosition = useSetRecoilState(scrollPositionsMapAtom);

  const setPositionHandler = (id: CategoryId, scrollTop: number) => {
    setPosition((scrollPosition: ScrollPositionMapType) => ({
      ...scrollPosition,
      [id]: scrollTop,
    }));
  };

  return setPositionHandler;
};

window.newPoly = new Poly();

export const queryQueueItemsCountAtom = atom({
  key: 'queryQueueItemsCount',
  default: 0,
});

export const imageQueryQueueItemsCountAtom = atom({
  key: 'imageQueryQueueItemsCount',
  default: 0,
});

export const isAuthorizedAtom = atom<boolean>({
  key: 'isAuthorizedAtom',
  default: isUserAuthorized(),
});

export const isApplicationOnlineAtom = atom<boolean>({
  key: 'isApplicationOnlineAtom',
  default: window.navigator.onLine,
});

export const useIsAuthorizedAtomState = () => {
  const [isAuthorized, setIsAuthorisedInternal] =
    useRecoilState(isAuthorizedAtom);

  const setIsAuthorised = useCallback(
    async (token: string) => {
      setUserToken(token);
      setIsAuthorisedInternal(true);
    },
    [setIsAuthorisedInternal],
  );

  const unsetAuthorised = useCallback(() => {
    clearTokens();
    setIsAuthorisedInternal(false);
  }, [setIsAuthorisedInternal]);

  return { isAuthorized, setIsAuthorised, unsetAuthorised };
};

export const getSnapshotValue = <T>(
  snapshot: Snapshot,
  value: RecoilValue<T>,
): T => snapshot.getLoadable(value).contents;

export const subscriptionPlanListAtom = atom<Record<string, PurchasesPackage>>({
  key: 'subscriptionPlanListAtom',
  default: {} as Record<string, PurchasesPackage>,
});
