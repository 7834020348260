// @ts-nocheck
import Point from './Point';
import BgImage from './BgImage';
import Polygon from './Polygon';
import helpers from './helpers';
import { updateContainerBackground } from './id-crop-helpers';

export default class CropArea {
  constructor(container, base64, size, handles, handlesLine) {
    this.container = container;
    this.base64 = base64;
    this.size = size;

    this.canvas = document.createElement('canvas');
    this.isDrawing = true;
    this.img = '';
    this.handles = handles;
    this.handlesLine = handlesLine;
  }

  clearCanvas() {
    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  async create(img) {
    this.clearCanvas();
    this.container.innerHTML = '';
    this.container.appendChild(this.canvas);
    const image = img || (await helpers.loadImage(this.base64));
    this.img = new BgImage(image, this.container);
    this.canvas.width = this.img.width;
    this.canvas.height = this.img.height;
    this.canvas.style.left = this.img.left + 'px';
    this.canvas.style.top = this.img.top + 'px';
    return this.img;
  }

  updateImage(base64) {
    updateContainerBackground(base64, this.container);
  }

  crop(img, preview, points, config) {
    const auxCanvas = document.createElement('canvas');

    auxCanvas.width = this.img.realWidth;
    auxCanvas.height = this.img.realHeight;

    const realPoints = [];
    for (const point of points) {
      realPoints.push(
        new Point(point.x * this.img.ratio, point.y * this.img.ratio),
      );
    }

    const renderer = new Polygon(
      preview,
      auxCanvas,
      this.handles,
      this.handlesLine,
    );
    renderer.drawWithOverlay();

    const base64 = auxCanvas.toDataURL();
    const crop = new CustomEvent('crop', { detail: base64 });
    document.dispatchEvent(crop);

    return base64;
  }
}
