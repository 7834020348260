import transparentImageUrl from './transparent-image.png';
import c from 'classnames';

export const AppImagePlaceholder = ({
  className = '',
  imageClassName = '',
}: {
  className?: string;
  imageClassName?: string;
}) => (
  <>
    <div
      className={c(
        'flex justify-center items-center absolute h-full w-full bg-[var(--mainBackground)] z-[1] overflow-hidden',
        className,
      )}
    >
      <div className='absolute top-0 left-[-45%] h-full w-[45%] bg-[linear-gradient(to left, rgba(251,251,251,0.05), rgba(251,251,251,0.3), rgba(251,251,251,0.6), rgba(251,251,251,0.3), rgba(251,251,251,0.05))] animate-loading z-[2]' />
    </div>
    <img
      className={c('w-full', imageClassName)}
      src={transparentImageUrl}
      alt='transparency'
    />
  </>
);
