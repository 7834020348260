import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { AppBackButton } from '@/components/app-back-button';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
// import { useRootContext } from '@/context/root-context';

export const CategoryHeaderBack = () => {
  const { activeSliceSelector } = useContext(CategoryTreeContext);
  const activeSlice = activeSliceSelector();
  const { goBack } = useHistory();

  return (
    <AppBackButton
      onBackClick={goBack}
      label={activeSlice?.name ?? 'No slice'}
      labelClassName='text-ellipsis whitespace-nowrap overflow-hidden'
      className='p-[10px] w-full pr-0 hover:drop-shadow-[0_0_2px_var(--emphasized)]'
    />
  );
};
