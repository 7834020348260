import Joi from 'joi';

export const VALIDATION_PATTERNS = {
  phoneRegexp: /^\+?[0-9]{0,15}$/,
  email:
    /^(([^<>()[\](?!+)\.,;:\s@"]+(\.[^<>()[\],;:\s@"]+)*)|(".+"))@(([^<>()[\](?!\+)\.,;:\s@\"]+\.)+[^<>()[\](?!\+)\.,;:\s@\"]{2,})$/,
};

export const VALIDATION_STRATEGIES = {
  required: Joi.any().required(),
  requiredString: Joi.string().required(),
  email: Joi.string().required().regex(VALIDATION_PATTERNS.email),
  phone: Joi.string()
    .required()
    .pattern(VALIDATION_PATTERNS.phoneRegexp, 'numbers'),
  confirmPassword: Joi.any()
    .valid(Joi.ref('password'))
    .required()
    .options({ messages: { 'any.only': '{{#label}} does not match' } }),
  requiredMaxLength: (length: number) => Joi.string().required().max(length),
  maxLength: (length: number) => Joi.string().max(length),
  minLength: (length: number) => Joi.string().min(length),
  otpCode: Joi.string().min(4),
  selectRequired: Joi.string().invalid(null).required(),
  dateRequired: Joi.date().required(),
};
