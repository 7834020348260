import { AppIcon, AppLoader } from '@/components';
import { AppLogo } from '@/components/app-logo';
import {
  useAppleLoginMutation,
  useFacebookLoginMutation,
  useGoogleLoginMutation,
} from '@/graphql/codegen/graphql';
import { useRequestDeviceNotifications } from '@/hooks/use-request-device-notifications';
import { getAppName } from '@/services/app-service';
import { setAccountIdToken } from '@/services/auth-service';
import { useIsAuthorizedAtomState } from '@/store';
import {
  SignInWithApple,
  SignInWithAppleOptions,
  SignInWithAppleResponse,
} from '@capacitor-community/apple-sign-in';
import { FacebookLogin as FacebookLoginMobile } from '@capacitor-community/facebook-login';
import {
  GoogleAuth,
  GoogleAuth as GoogleLoginMobile,
} from '@codetrix-studio/capacitor-google-auth';
import c from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import { PAGE_ROUTES } from '@/app.constants';
import { AppTappable } from '@/components/app-tappable';
import { AuthPageWrapper } from '@/components/auth-base';
import { useBooleanState } from '@/hooks';
import { isPlatform } from '@ionic/react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

const appType = import.meta.env.VITE_PROJECT_TYPE;
const appleSignInOptions: SignInWithAppleOptions = {
  clientId: 'io.' + appType,
  redirectURI: 'https://' + appType + '.io',
  scopes: 'email name',
  state: '12345',
  nonce: 'nonce',
};

export const useRegisterBySocial = () => {
  const [_mutationFacebook, { data: dataFacebook, loading: loadingFacebook }] =
    useFacebookLoginMutation();
  const [_mutationGoogle, { data: dataGoogle, loading: loadingGoogle }] =
    useGoogleLoginMutation();
  const [_mutationApple, { data: dataApple, loading: loadingApple }] =
    useAppleLoginMutation();

  const registerBySocial = async (unsetLoading, access_token, type) => {
    try {
      if (type === 'fb') {
        await _mutationFacebook({
          variables: {
            access_token,
          },
        });
      } else if (type === 'go') {
        await _mutationGoogle({
          variables: {
            access_token,
          },
        });
      } else if (type === 'ap') {
        await _mutationApple({
          variables: {
            access_token,
          },
        });
      }
    } catch (error) {
      console.warn(error);
    } finally {
      unsetLoading();
    }
  };

  let token: string | null = '';
  let accountId: number | null = null;

  if (dataFacebook) {
    token = dataFacebook.facebookLogin.token ?? '';
    accountId = dataFacebook.facebookLogin?.account?.id ?? null;
  } else if (dataGoogle) {
    token = dataGoogle.googleLogin.token ?? '';
    accountId = dataGoogle?.googleLogin.account?.id ?? null;
  } else if (dataApple) {
    token = dataApple.appleLogin.token ?? '';
    accountId = dataApple.appleLogin.account?.id ?? null;
  }

  return {
    registerBySocial,
    token,
    accountId,
    loading: loadingFacebook || loadingGoogle || loadingApple,
  };
};

export const AuthPage = () => {
  const history = useHistory();
  const { registerBySocial, token, loading, accountId } = useRegisterBySocial();
  const { setIsAuthorised } = useIsAuthorizedAtomState();
  const lang = localStorage.getItem('locale');
  const requestDeviceNotifications = useRequestDeviceNotifications();
  const { formatMessage: t } = useIntl();
  const [socialLoading, setLoading, unsetLoading] = useBooleanState(false);
  useEffect(() => {
    const r = async () => {
      if (!isPlatform('capacitor')) {
        const googleClientId =
          import.meta.env.VITE_PROJECT_TYPE == 'ireceipt'
            ? import.meta.env.VITE_IRECEIPT_GOOGLE_WEB_CLIENT_ID
            : import.meta.env.VITE_IDOCUMENT_GOOGLE_WEB_CLIENT_ID;

        const facebookAppId =
          import.meta.env.VITE_PROJECT_TYPE === 'ireceipt'
            ? import.meta.env.VITE_IRECEIPT_FACEBOOK_APP_ID
            : import.meta.env.VITE_IDOCUMENT_FACEBOOK_APP_ID;

        try {
          if (!window.location.hostname.includes('localhost')) {
            GoogleAuth.initialize({
              clientId: googleClientId + '.apps.googleusercontent.com',
              scopes: ['profile', 'email'],
              grantOfflineAccess: true,
            });
          }
        } catch (error) {
          console.log('GoogleAuth initialize error', error);
        }

        FacebookLoginMobile.initialize({ appId: facebookAppId });
      }
    };

    r();
  }, []);

  if (token && accountId) {
    // !Note- order is important here - todo refactor
    setAccountIdToken(accountId);
    setIsAuthorised(token).then(() => {
      requestDeviceNotifications();
      history.push(PAGE_ROUTES.category);
    });
  }

  const APPLELogin = () => {
    setLoading();
    SignInWithApple.authorize(appleSignInOptions)
      .then((result: SignInWithAppleResponse) => {
        registerBySocial(unsetLoading, JSON.stringify(result), 'ap');
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        unsetLoading();
      });
  };

  const FBLogin = () => {
    setLoading();
    FacebookLoginMobile.login({ permissions: ['public_profile', 'email'] })
      .then((result) => {
        // @ts-ignore
        registerBySocial(unsetLoading, result.accessToken.token, 'fb');
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        unsetLoading();
      });
  };

  const GlLogin = () => {
    setLoading();
    GoogleLoginMobile.signIn()
      .then((credential) => {
        registerBySocial(
          unsetLoading,
          credential.authentication.accessToken,
          'go',
        );
      })
      .catch((error) => {
        console.log(JSON.stringify(error));
        unsetLoading();
      });
  };

  const socialButtonClassName =
    'text-white w-[31px] h-[31px] bg-transparent rounded-full font-bold p-0 inline-flex justify-center items-center not:first:ml-[10px]';

  return (
    <AuthPageWrapper>
      <div
        className={c(
          'mx-auto tablet:max-w-[667px] tablet:px-[15px] laptop:max-w-[1023px]',
          {
            disabled: loading,
          },
        )}
      >
        <Link to='/auth'>
          <div className='flex items-center justify-center text-center'>
            <AppLogo className='w-[150px] h-[133px]' />
          </div>
        </Link>
        <h1 className='m-[10px_auto_0] w-fit font-alata text-[var(--authTextColor)]'>
          {getAppName()}
        </h1>

        <div
          className={c(
            'top-[45px] text-[var(--authTextColor)]',
            'text-center',
            {
              '[mr-28px]': lang === 'no',
            },
          )}
        >
          <p className='max-w-[255px] m-[5px_auto_0]'>{t({ id: 'auth.p' })}</p>
        </div>

        <div className='max-w-[255px] mt-[29px] mx-auto mb-0 flex justify-between gap-[10px] text-center'>
          <Link to='/login' className='no-underline'>
            <button
              className={c(
                'w-[120px] h-[40px] p-[6px] text-[13.5px] rounded-[40px] shadow-none bg-[#2072fe] text-white',
                'hover:bg-[#1f77da] focus:bg-[#1f77da] active:bg-[#1f77da]',
              )}
            >
              {t({ id: 'btn.login' })}
            </button>
          </Link>
          <Link to='/sign-up' className='no-underline'>
            <button
              className={c(
                'w-[120px] h-[40px] p-[6px] text-[13.5px] rounded-[40px] shadow-none bg-[#4bcab6] text-white',
                'hover:bg-[#49b6a2] focus:bg-[#49b6a2] active:bg-[#49b6a2]',
              )}
            >
              {t({ id: 'btn.sign' })}
            </button>
          </Link>
        </div>

        <div className='m-[30px_auto_0] text-[12px] text-[var(--authTextColor)] text-center'>
          <p className='mb-0'>{t({ id: 'auth.social' })}</p>
          <div className='mt-[15px] flex gap-2 justify-center'>
            {isPlatform('capacitor') && (
              <div className='w-[30px] h-[30px] bg-white flex items-center justify-center rounded-full'>
                <AppTappable
                  component='button'
                  onTap={APPLELogin}
                  className={socialButtonClassName}
                >
                  <AppIcon name='apple' />
                </AppTappable>
              </div>
            )}

            <AppTappable
              component='button'
              onTap={FBLogin}
              className={socialButtonClassName}
            >
              <AppIcon name='facebook' />
            </AppTappable>
            <div className='w-[30px] h-[30px] bg-white flex items-center justify-center rounded-full'>
              <AppTappable
                component='button'
                onTap={GlLogin}
                className={socialButtonClassName}
              >
                <AppIcon name='google' />
              </AppTappable>
            </div>
            {socialLoading && <AppLoader />}
          </div>
          <div className='fixed bottom-[17px] left-0 right-0 m-auto flex flex-col justify-center items-center gap-2'>
            <p className='!text-[14px]'>
              © New Conception AS
              <a
                href='https://ireceipt.io/privacy_policy.html'
                className='block text-[13px] leading-[15px] text-[var(--authTextColor)] underline'
              >
                Privacy policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </AuthPageWrapper>
  );
};
