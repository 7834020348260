import { AppDatePicker, AppSelect, DefaultInput } from '@/components';
import { AppButton } from '@/components/app-button';
import { AppConfirmModal } from '@/components/app-confirm-modal';
import { AppHasAccess } from '@/components/app-has-access';
import { AppLoader } from '@/components/app-loader';
import { AppType } from '@/core.types';
import { useRemoveUser } from '@/gql/api-user';
import { useBooleanState } from '@/hooks';
import { useGetCurrencyOptions } from '@/hooks/use-get-currency-options';
import { useLogout } from '@/hooks/use-logout';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useProfileEdit } from './use-profile-edit';
import { ProfileUploadPhoto } from './profile-upload-photo';
import { SAVE_BUTTON_CLASSNAME } from '../../settings-page';

export const Profile = ({ mobile }: { mobile: boolean }) => {
  const { push } = useHistory();
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    currency: null,
    gender: '',
    birthday: null,
    email: '',
    img: null,
    fileForSend: null,
    dirty: false,
  });

  const { loading, data, changeProfile } = useProfileEdit();
  const { formatMessage: t } = useIntl();
  const currencyOptions = useGetCurrencyOptions();
  const [removeConfirmOpened, openRemoveConfirm, closeRemoveConfirm] =
    useBooleanState(false);
  const { removeUser } = useRemoveUser();
  const logOut = useLogout();
  const [
    removeFirstValidationCompleted,
    completeFirstValidation,
    revertDeletion,
  ] = useBooleanState(false);

  useEffect(() => {
    if (data?.user) {
      const {
        firstName,
        lastName,
        currency,
        gender,
        birthday,
        facebookPictureUrl,
        googlePictureUrl,
        customPictureUrl,
        email,
      } = data.user;

      const img = customPictureUrl
        ? `${import.meta.env.VITE_WEBSITE_URL}/${customPictureUrl}`
        : facebookPictureUrl;

      setState({
        ...state,
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        currency: currencyOptions.find(({ value }) => value === currency)
          ?.value,
        gender: gender ?? '',
        birthday: birthday ? new Date(birthday * 1000) : birthday,
        email: email ?? '',
        img: img ?? googlePictureUrl ?? null,
      } as any);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSetNewProfile = () => {
    changeProfile({
      currency: state.currency ?? '',
      firstName: state.firstName,
      lastName: state.lastName,
      birthday: state.birthday
        ? parseInt((new Date(state.birthday).getTime() / 1000).toFixed(0))
        : state.birthday ?? undefined,
      gender: state.gender,
      customPicture: state.fileForSend ?? undefined,
    }).then(() => {
      mobile && push('/settings');
    });
  };

  const onRemoveConfirmClick = async () => {
    await removeUser();
    logOut();
  };

  const onFirstRemoveValidationClick = async () => {
    completeFirstValidation();
    closeRemoveConfirm();
  };

  const changeState = (type, value) => {
    setState({
      ...state,
      [type]: value,
      dirty: true,
    });
  };

  const changeDateHandler = (date) => {
    changeState('birthday', date.value);
  };
  return (
    <>
      <div className='p-[40px_15px] m-auto font-roboto tablet:max-w-[667px] laptop:w-[45%] laptop:m-0 laptop:p-0 laptop:h-[670px] laptop:max-w-unset laptop:flex laptop:flex-col'>
        <ProfileUploadPhoto {...{ state, setState }} />
        <div className='!w-[298px] m-auto'>
          <div>
            <DefaultInput
              value={state.firstName}
              placeholder={t({ id: 'menu.profile.first' })}
              onChange={({ currentTarget }: any) =>
                changeState('firstName', currentTarget.value)
              }
            />
          </div>

          <div className='mt-[20px]'>
            <DefaultInput
              inputClassName='h-[40px]'
              value={state.lastName}
              placeholder={t({ id: 'menu.profile.last' })}
              onChange={({ currentTarget }: any) =>
                changeState('lastName', currentTarget.value)
              }
            />
          </div>

          <div className='mt-[20px] h-[40px]'>
            <AppDatePicker
              value={state.birthday}
              onChange={changeDateHandler}
              placeholder={t({ id: 'menu.profile.date' })}
              iconName='calendar'
              maxDate={new Date()}
            />
          </div>

          <AppHasAccess appType={AppType.ireceipt}>
            <div className='mt-[25px]'>
              <AppSelect
                value={state.currency}
                options={currencyOptions}
                placeholder={t({ id: 'menu.profile.currency' })}
                onChange={(event) => {
                  changeState('currency', event.target.value);
                }}
              />
            </div>
          </AppHasAccess>

          <AppButton
            className={SAVE_BUTTON_CLASSNAME}
            onPress={onSetNewProfile}
            disabled={!state.dirty || loading || !state.currency}
          >
            {t({ id: 'save' })}
          </AppButton>
        </div>
        <div className='mt-[20px] text-center font-normal text-[12px] leading-[14px] text-[var(--textMain60)] opacity-60'>
          {t({ id: 'menu.profile.change.data' })}
        </div>
        {/* !TODO implement on future account deletion */}
        <div
          className='cursor-pointer mt-[60px] font-bold text-[16px] leading-[22px] text-center text-[var(--mistakeText)] laptop:mt-auto laptop:mb-[25px]'
          onClick={openRemoveConfirm}
        >
          {t({ id: 'menu.profile.delete.account' })}
        </div>
      </div>
      <AppConfirmModal
        open={removeConfirmOpened}
        onConfirmClick={onFirstRemoveValidationClick}
        // TODO -change title title for 1 modal
        title={t({ id: 'menu.profile.remove.confirm1' })}
        onClose={closeRemoveConfirm}
      />
      <AppConfirmModal
        open={removeFirstValidationCompleted}
        onConfirmClick={onRemoveConfirmClick}
        // TODO -change title title for 1 modal
        title={t({ id: 'menu.profile.remove.confirm2' })}
        onClose={revertDeletion}
      />
      {loading && <AppLoader />}
    </>
  );
};
