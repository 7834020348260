import { useRootContext } from '@/context/root-context';
import { AllHtmlEntities } from 'html-entities';
import symbols from '../currencies.json';
import { uppercaseFirstLettersInEachWordOfAString } from '../services';

const entities = new AllHtmlEntities();

export const useGetCurrencyOptions = () => {
  const { locale } = useRootContext();

  const currencyNames = new Intl.DisplayNames([locale], {
    type: 'currency',
  });

  return symbols.map((el) => {
    const currencySymbol = entities.decode(el.symbol);
    const currencyName = uppercaseFirstLettersInEachWordOfAString(
      // @ts-ignore
      currencyNames.of(el.abbreviation),
    );

    return {
      label: `${currencySymbol} - ${currencyName} - ${el.abbreviation}`,
      value: el.abbreviation,
    };
  });
};
