export const useGetSaveAreaPaddings = () => {
  const safeAreaTop = parseInt(
    getComputedStyle(document.documentElement).getPropertyValue('--sat'),
  );
  const safeAreaBottom =
    parseInt(
      getComputedStyle(document.documentElement).getPropertyValue('--sab-pure'),
    ) / 2;

  return { safeAreaTop, safeAreaBottom };
};
