const SETTINGS_ICON_DEFAULT_SIZES = {
  mobile: { width: 20, height: 20 },
  desktop: { width: 24, height: 24 },
};

const SETTINGS_ICON_SMALL_SIZES = {
  mobile: { width: 10, height: 10 },
  desktop: { width: 12, height: 12 },
};

export const SETTINGS_ICON_SIZE_TYPES = {
  small: 'small',
  default: 'default',
  custom: 'custom',
};

export const SETTINGS_ICON_RESOLUTION_TYPES = {
  mobile: 'mobile',
  desktop: 'desktop',
};

export const SETTINGS_ICON_SIZE_MAP = {
  small: SETTINGS_ICON_SMALL_SIZES,
  default: SETTINGS_ICON_DEFAULT_SIZES,
  custom: {
    gift: {
      mobile: { width: 60, height: 64 },
      desktop: { width: 75, height: 81 },
    },
    check: {
      mobile: { width: 14, height: 14 },
      desktop: { width: 16, height: 16 },
    },
    'coming-soon': {
      mobile: { width: 86, height: 86 },
      desktop: { width: 120, height: 120 },
    },
    notify: {
      mobile: { width: 32, height: 32 },
      desktop: { width: 56, height: 56 },
    },
    'arrow-right': {
      mobile: { width: 6, height: 10 },
      desktop: { width: 8, height: 12 },
    },
  },
};
