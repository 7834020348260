import { onError } from '@apollo/client/link/error';
import { toast } from 'react-toastify';
import { ServerErrorType } from './core.types';

// Since hooks can't be used outside components, we'll need to create a logout function
// that can be called from the error link. One common approach is to use a callback or
// a global store (like Redux) to handle logout actions.

let logoutFunction: () => void;

// Export a function to set the logout function
export const setLogoutFunction = (logout: () => void) => {
  logoutFunction = logout;
};

// Define the error link
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  // Handle GraphQL errors
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, type } = error as any;

      switch (type) {
        case ServerErrorType.TYPE_CODE_TOKEN_ISSUE:
        case ServerErrorType.TYPE_CODE_WRONG_ACCOUNT_ID_HEADER:
          // Trigger logout
          if (logoutFunction) {
            logoutFunction();
          }
          break;

        case ServerErrorType.TYPE_CODE_UNEXPECTED_BY_FE:
        case ServerErrorType.TYPE_CODE_OFFLINE_ERROR:
        case ServerErrorType.TYPE_CODE_ONLINE_ERROR:
          // Show toast notifications
          const toastCaller =
            type === ServerErrorType.TYPE_CODE_UNEXPECTED_BY_FE
              ? toast.info
              : toast.error;

          toastCaller(message, {
            autoClose: 3000,
            position: 'bottom-center',
          });
          break;

        default:
          // Handle other error types if necessary
          break;
      }
    });
  }

  // Optionally, handle network errors
  if (networkError) {
    toast.error(`Network error: ${networkError.message}`, {
      autoClose: 3000,
      position: 'bottom-center',
    });
  }
});
