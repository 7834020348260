import { useRootContext } from '@/context/root-context';
import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import iDocumentMessages from '../translations/idocument-override-messages';
import messages from '../translations/messages';

export const IntlWrapper = ({ children }: { children: ReactNode }) => {
  const { locale } = useRootContext();

  const projectType = import.meta.env.VITE_PROJECT_TYPE;
  const getMessages = (locale: string) => {
    if (projectType === 'idocument') {
      return { ...messages[locale], ...iDocumentMessages[locale] };
    }

    return messages[locale];
  };

  return (
    <IntlProvider
      locale={locale}
      messages={getMessages(locale)}
      defaultLocale='en'
    >
      {children}
    </IntlProvider>
  );
};
