import { AppButton } from '@/components/app-button';
import { SettingsIcon } from '../../settings-icon';

type PhoneItemProps = {
  id: number;
  phone: string;
  setSelectedPhoneId(id: number): void;
  deleteShown?: boolean | null;
  openDeleteModal(): void;
};

export const PhoneItem = ({
  id,
  phone,
  setSelectedPhoneId,
  openDeleteModal,
  deleteShown = true,
}: PhoneItemProps) => {
  const onDeleteItemClick = () => {
    setSelectedPhoneId(id);
    openDeleteModal();
  };
  const colorTextMain20 = `var(--textMain20)`;

  return (
    <div className='mt-[20px] flex justify-between items-center'>
      <span className='flex items-center'>
        <SettingsIcon name='phone' color={colorTextMain20} />
        <span className='ml-[15px] font-medium text-[16px] leading-[22px] text-[var(--textMain)] opacity-90'>
          {phone}
        </span>
      </span>
      {deleteShown && (
        <AppButton
          className='p-[10px_10px_10px_20px] cursor-pointer mr-[-10px]'
          onPress={onDeleteItemClick}
        >
          <SettingsIcon name='close' sizeType='small' color={colorTextMain20} />
        </AppButton>
      )}
    </div>
  );
};
