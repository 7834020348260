export type AppFormFieldErrorProps = {
  error?:
    | string
    | {
        message?: string;
      };
};

export const AppFormFieldError = ({ error }: AppFormFieldErrorProps) => {
  return error ? (
    <div className='mt-0 min-h-[14px] pt-[2px] pl-[10px] text-[12px] leading-[12px] text-[var(--mistakeStroke)]'>
      {typeof error === 'string' ? error : error.message}
    </div>
  ) : (
    <span className='block w-full min-h-[14px] pt-[2px]' />
  );
};
