import { AppBackButton } from '@/components/app-back-button';
import { AppButton } from '@/components/app-button';
import c from 'classnames';
import { useIntl } from 'react-intl';
import './create-receipt.css';

type CreateReceiptSaveButtonsProps = {
  onSaveCloseClick(): void;
  onBackClick?(): void;
};

export const UpdateReceiptFooter = ({
  onSaveCloseClick,
  onBackClick,
}: CreateReceiptSaveButtonsProps) => {
  const { formatMessage: t } = useIntl();

  return (
    <div
      className='bg-[var(--primary)] shadow-[0px_-4px_10px_rgba(0,0,0,0.2)] p-[0_15px] flex items-stretch justify-center'
      id={'update-receipt-footer'}
    >
      {onBackClick && (
        <AppBackButton
          onBackClick={onBackClick}
          label={t({ id: 'back' })}
          className='w-full max-w-[540px] text-[var(--actionText)]'
        />
      )}
      <AppButton
        className={c('w-full text-[var(--actionText)]', {
          'max-w-[540px] text-right': onBackClick,
        })}
        onPress={onSaveCloseClick}
      >
        <span className='font-roboto font-medium text-[16px] leading-[22px]'>
          {t({ id: 'save.close' })}
        </span>
      </AppButton>
    </div>
  );
};
