import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { parseBoolEnv } from '@/services/helper-service';

export const useSettingsMenu = ({
  isApplicationOnline,
  recognitionShown,
}: {
  isApplicationOnline: boolean;
  recognitionShown: boolean;
}) => {
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;
  const colorTextMain = `var(${APP_CSS_THEME_VARIABLES.textMain})`;

  const menuRoute = [
    {
      id: 'invite',
      to: '/invite',
      iconName: 'invite-friend',
      iconColor: colorTextMain,
      hidden: true,
    },
    {
      id: 'plan',
      to: '/plan',
      iconName: 'subscription',
      iconColor: colorTextMain,
      hidden:
        !window.cordova ||
        !isApplicationOnline ||
        parseBoolEnv(import.meta.env.VITE_SHOW_SUBSCRIPTION),
    },
    {
      id: 'password',
      to: '/password',
      iconName: 'lock',
      iconColor: colorTextMain,
      hidden: !isApplicationOnline,
    },
    {
      id: 'email',
      to: '/email',
      iconName: 'mail',
      iconColor: colorTextMain,
      hidden: !isApplicationOnline,
    },
    {
      id: 'phone',
      to: '/phone',
      iconName: 'phone',
      iconColor: colorTextMain,
      hidden: !isApplicationOnline,
    },
    {
      id: 'lang',
      to: '/language',
      iconName: 'language',
      iconColor: colorTextMain,
    },
    {
      id: 'theme',
      to: '/theme',
      iconName: 'theme',
      iconColor: colorTextMain,
    },
    {
      id: 'quality',
      to: '/quality',
      iconName: 'quality',
      iconColor: colorTextMain,
      hidden: true,
    },
    {
      id: 'recognition',
      to: '/recognition',
      iconName: 'quality',
      hidden: !recognitionShown,
      iconColor: colorTextMain,
    },
    {
      id: 'terms',
      to: '/terms',
      iconName: 'terms',
      iconColor: colorTextMain,
    },
    { type: 'line' },
    {
      id: 'account',
      to: '/account',
      iconName: 'change-account',
      iconColor: colorEmphasized,
      hidden: !isApplicationOnline,
    },
  ].filter(({ hidden }) => !hidden);

  return menuRoute;
};
