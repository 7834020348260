import { isReceiptEmpty } from '@/category-tree-hooks/category-service';
import { AppPseudoRadio } from '@/components/app-pseudo-radio';
import { AppTappable } from '@/components/app-tappable';
import type { LocalReceipt } from '@/core.types';
import { resolveFilePath } from '@/services/helper-service';
import { editModeEnabledATOM } from '@/store/category-store';
import { useRecoilValue } from 'recoil';
import { useReceiptDNDLogic } from '../../use-receipt-dnd-logic';
import { useReceiptItemLogic } from '../../use-receipt-item-logic';
import { CategoryGridTileDescription } from './category-grid-tile-description';
import { CategoryGridTileImage } from './category-grid-tile-image';

export const CategoryReceiptTile = (localReceipt: LocalReceipt) => {
  const editModeEnabled = useRecoilValue(editModeEnabledATOM);
  const {
    onItemClick,
    date,
    currency,
    isItemActive,
    onItemDescriptionCLick,
    itemName,
  } = useReceiptItemLogic(localReceipt);
  const { createdBy, thumbPath } = localReceipt;
  const isEmpty = isReceiptEmpty(localReceipt);
  const { drag } = useReceiptDNDLogic(localReceipt.id);

  return (
    <div className='relative' ref={drag}>
      {editModeEnabled && (
        <AppPseudoRadio
          {...{
            isItemActive,
            className:
              'absolute min-w-[16px] shadow-none top-[8px] left-[8px] z-[2] before:w-[6px] before:h-[6px]',
            // onClick: onItemClick,
          }}
        />
      )}
      <AppTappable onTap={onItemClick}>
        <CategoryGridTileImage imagePath={resolveFilePath(thumbPath)} />
      </AppTappable>
      <CategoryGridTileDescription
        {...{
          name: itemName as string,
          isEmpty,
          amount: localReceipt.amount,
          currency,
          onClick: onItemDescriptionCLick,
          createdBy,
          date,
        }}
      />
    </div>
  );
};
