import { useCreateReceiptFileFromMobileApp } from '@/category-tree-hooks/use-receipts-sync-logic';
import {
  Maybe,
  VisionKitBase64DTO,
  VisionKitBase64ResponseDTO,
} from '@/core.types';
import { useCreateOrUpdateReceiptMutation } from '@/graphql/codegen/graphql';
import { useFormState } from '@/hooks/use-form-state';

import { useRootContext } from '@/context/root-context';
import { startConvertPdfToImage } from '@/services/convert-pdf-to-image';
import {
  createObjectURL,
  getBase64FromBlob,
  getUnixTimestampFromDate,
  isFileUrlPdf,
  parseBoolEnv,
} from '@/services/helper-service';
import { generateThumbnail } from '@/services/image-service';
import { extractAmountAndName } from '@/services/receipt-text-service';
import { getVisionKit } from '@/services/vision-kit-service';
import { recognizedImagePointsAtom } from '@/store';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';
import { uploadFile, useSetReadDocumentPermission } from '../gql/api-receipt';
import { croppedImageFileAtom, newReceiptFileAtom } from '../store';

export type UpdateReceiptDTO = {
  name: string;
  amount: number;
  receiptDate: number;
  remindedAt: Maybe<number>;
};

export const useCreateReceiptLogic = () => {
  const history = useHistory();
  // !Note this hook method directly creates receipt only on WEB without any request QUEUE
  const [createReceipt] = useCreateOrUpdateReceiptMutation();
  const {
    activeSliceId,
    currentUserName,
    receiptType,
    currentAccountId,
    userId,
  } = useRootContext();
  const newReceiptFile = useRecoilValue(newReceiptFileAtom);
  const croppedImageFile = useRecoilValue(croppedImageFileAtom);
  const createReceiptFileFromMobileApp = useCreateReceiptFileFromMobileApp();
  const isPDFFile = isFileUrlPdf(newReceiptFile?.type);
  const fileUrl =
    newReceiptFile || croppedImageFile
      ? createObjectURL(isPDFFile ? newReceiptFile : croppedImageFile)
      : null;
  const visionKit = getVisionKit();
  const { recognizedImageTexts, setRecognizedImageTexts } = useRootContext();
  const setRecognizedImagePoints = useSetRecoilState(recognizedImagePointsAtom);
  const [loading, setLoading] = useState(false);

  // !Note - post update enable
  const setReadDocumentPermission = useSetReadDocumentPermission(true);

  const {
    formState: { name, receiptDate, amount, remindedAt },
    setField,
  } = useFormState<UpdateReceiptDTO>({
    defaultValues: {
      // @ts-ignore
      ...extractAmountAndName(recognizedImageTexts),
      remindedAt: getUnixTimestampFromDate(new Date()),
      receiptDate: getUnixTimestampFromDate(new Date()),
    },
  });

  const onSaveReceiptSuccess = useRecoilCallback(({ set }) => () => {
    window.newPoly.reset();
    set(newReceiptFileAtom, null);
    set(croppedImageFileAtom, null);
    history.go(isPDFFile ? -1 : -2);
  });

  const onSaveCloseClick = useRecoilCallback(({ snapshot }) => async () => {
    const file = isPDFFile ? newReceiptFile : croppedImageFile;

    try {
      setLoading(true);
      if (!activeSliceId) return;
      const {
        data: {
          // @ts-ignore
          createOrUpdateReceipt: { uploadFileLink, uploadFileThumbLink, id },
        },
      } = await createReceipt({
        variables: {
          // @ts-ignore
          category_id: activeSliceId,
          type: receiptType,
          name,
          receiptDate,
          // createdBy: currentUserName,
          // createdById: currentAccountId,
          amount: Number(amount),
          fileExtension: file?.type.split('/').pop(),
        },
      });

      const imageThumbFile = isPDFFile
        ? await startConvertPdfToImage(file as File)
        : await generateThumbnail(file as File);

      await Promise.all([
        uploadFile({ url: uploadFileLink, file: file }),
        uploadFile({
          url: uploadFileThumbLink,
          file: imageThumbFile,
        }),
      ]);

      await setReadDocumentPermission({
        variables: { id, fileUploaded: true },
      });
    } catch (error) {
      console.log('IReceipt WEB - error during receipt creation');
    } finally {
      setLoading(false);
    }

    onSaveReceiptSuccess();
  });

  const onSaveCordovaClick = async () => {
    const fileToSave = croppedImageFile || newReceiptFile;
    setRecognizedImageTexts(null);
    setRecognizedImagePoints(null);
    if (!fileToSave || !activeSliceId) {
      return;
    }

    const base64File = await getBase64FromBlob(fileToSave);
    const base64DTO: VisionKitBase64DTO = {
      base64: base64File.split(',').pop() as string,
      extension: croppedImageFile ? 'jpeg' : 'pdf',
    };

    visionKit.base64String(
      ({ thumbPath, filePath }: VisionKitBase64ResponseDTO) => {
        createReceiptFileFromMobileApp({
          id: uuidv4(),
          deleted: false,
          name,
          receiptDate,
          amount: Number(amount),
          createdBy: currentUserName,
          createdById: userId as number,
          type: receiptType,
          imagePath: filePath,
          thumbPath,
          parentId: activeSliceId,
          updatedAt: null,
          remindedAt,
          accountId: currentAccountId as number,
        });
        onSaveReceiptSuccess();
      },
      (error) => {
        alert(JSON.stringify(error));
        console.log('vision kit base64String error', error);
      },
      base64DTO,
    );
  };

  useEffect(() => {
    if ((!newReceiptFile && !croppedImageFile) || !activeSliceId) {
      history.goBack();
    }

    return () => {
      if (fileUrl) {
        window.URL.revokeObjectURL(fileUrl);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    imagePath: fileUrl,
    activeSliceId,
    onSaveCloseClick:
      window.cordova || parseBoolEnv(import.meta.env.VITE_EMULATE_VISIONKIT)
        ? onSaveCordovaClick
        : onSaveCloseClick,
    onBackClick: history.goBack,
    formState: { name, receiptDate, amount, remindedAt },
    isPDFFile,
    setField,
  };
};
