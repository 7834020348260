import c from 'classnames';
import { useBooleanState } from '@/hooks';
import { AppImagePlaceholder } from '../app-image-placeholder';
import { ReceiptCloudTile } from './receipt-cloud-tile';
import {
  detectLocalImagePath,
  detectDesktopApplication,
} from '@/services/helper-service';

type CategoryGridTileImageProps = {
  imagePath: string;
};

export const CategoryGridTileImage = ({
  imagePath,
}: CategoryGridTileImageProps) => {
  const [imageLoaded, setImageIsLoaded] = useBooleanState(false);

  return (
    <>
      {!imageLoaded && (
        <AppImagePlaceholder
          className='h-[70px] tablet:h-[90px]'
          imageClassName='h-[70px] relative tablet:h-[90px]'
        />
      )}
      {!detectLocalImagePath(imagePath) && !detectDesktopApplication() && (
        <ReceiptCloudTile />
      )}
      <img
        src={imagePath}
        draggable='false'
        className={c(
          'w-full h-[70px] object-cover object-[center_top] shadow-[0_2px_4px_var(--textMain40)] cursor-pointer tablet:h-[90px]',
          {
            'absolute top-0 left-0': !imageLoaded,
          },
        )}
        width='100%'
        height='90'
        onLoad={setImageIsLoaded}
        alt=''
      />
    </>
  );
};
