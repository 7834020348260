import { AppButton } from '@/components/app-button';
import { useRootContext } from '@/context/root-context';
import { useDetectMobileResolution } from '@/hooks';
import { useSetScrollPosition } from '@/store';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { AppIcon } from '../app-icon';
import { AddReceiptButton } from './add-receipt-button';

const ICON_MOBILE_SIZE = { width: 28, height: 25 };
const ICON_DESKTOP_SIZE = { width: 45, height: 40 };

export const AppFooter = () => {
  const isMobile = useDetectMobileResolution();
  const history = useHistory();
  const setPositionHandler = useSetScrollPosition();
  const { rootID, setActiveSliceId, currentUserIcon } = useRootContext();

  const onHomeClick = () => {
    if (rootID) {
      setActiveSliceId(rootID);
      setPositionHandler(rootID, 0);
    }
    history.push('/category');
  };
  const { formatMessage: t } = useIntl();

  const onProfileClick = () => {
    history.push('/settings');
  };

  const iconSize = isMobile ? ICON_MOBILE_SIZE : ICON_DESKTOP_SIZE;

  const itemClass =
    'w-full row-gap-[5px] box-content flex flex-col justify-center items-center cursor-pointer select-none text-[var(--actionText)] text-[11px] leading-[11.7px] tablet:w-auto tablet:first:pr-[15px] tablet:first:mr-auto tablet:last:pl-[15px] tablet:last:ml-auto laptop:text-[14px] laptop:leading-[16px] hover:filter hover:drop-shadow-[0_0_2px_var(--secondary)]';

  return (
    <footer className='grid grid-cols-[33.33%_33.33%_33.33%] justify-center bg-[var(--primary)] relative z-[1] select-none h-[75px] laptop:grid-cols-[85px_minmax(auto,1045px)_85px] laptop:h-[95px]'>
      <AppButton onPress={onHomeClick} className={itemClass}>
        <AppIcon size={iconSize} name='home' />
        {t({ id: 'home' })}
      </AppButton>

      <AddReceiptButton />

      <AppButton onPress={onProfileClick} className={itemClass}>
        {currentUserIcon ? (
          <img
            src={currentUserIcon}
            className='object-cover laptop:w-[40px] laptop:h-[40px] w-[25px] h-[25px] rounded-full'
            alt='profile-pic'
          />
        ) : (
          <AppIcon size={iconSize} name='no-user' />
        )}
        {t({ id: 'profile' })}
      </AppButton>
    </footer>
  );
};
