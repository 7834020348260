export const NotifyIcon = ({
  size = { width: 32, height: 32 },
  color = 'white',
}) => {
  const { width, height } = size;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M25.4551 14.4062C25.0929 14.4062 24.7989 14.1122 24.7989 13.75C24.7989 11.237 23.8208 8.87538 22.0444 7.09825C21.7881 6.8419 21.7881 6.4263 22.0444 6.16995C22.3008 5.9136 22.7164 5.9136 22.9729 6.16995C24.9968 8.19462 26.1114 10.8871 26.1114 13.75C26.1114 14.1122 25.8174 14.4062 25.4551 14.4062Z'
        fill={color}
      />
      <path
        d='M7.51758 14.4063C7.15533 14.4063 6.86133 14.1123 6.86133 13.75C6.86133 10.8871 7.97612 8.19467 10.0008 6.17081C10.2571 5.91446 10.6729 5.91446 10.9292 6.17081C11.1856 6.42715 11.1856 6.84292 10.9292 7.09927C9.15211 8.87543 8.17383 11.237 8.17383 13.75C8.17383 14.1123 7.87983 14.4063 7.51758 14.4063Z'
        fill={color}
      />
      <path
        d='M16.4865 26C14.677 26 13.2052 24.5282 13.2052 22.7188C13.2052 22.3565 13.4992 22.0625 13.8615 22.0625C14.2237 22.0625 14.5177 22.3565 14.5177 22.7188C14.5177 23.8047 15.4005 24.6875 16.4865 24.6875C17.5722 24.6875 18.4552 23.8047 18.4552 22.7188C18.4552 22.3565 18.7492 22.0625 19.1115 22.0625C19.4737 22.0625 19.7677 22.3565 19.7677 22.7188C19.7677 24.5282 18.2959 26 16.4865 26Z'
        fill={color}
      />
      <path
        d='M23.7051 23.375H9.26756C8.42306 23.375 7.73621 22.6881 7.73621 21.8438C7.73621 21.3957 7.93135 20.9714 8.27181 20.68C9.60258 19.5556 10.3612 17.9212 10.3612 16.1896V13.7499C10.3612 10.3726 13.1088 7.625 16.4863 7.625C19.8637 7.625 22.6113 10.3726 22.6113 13.7499V16.1896C22.6113 17.9212 23.3699 19.5556 24.692 20.6739C25.0411 20.9714 25.2363 21.3957 25.2363 21.8438C25.2363 22.6881 24.5494 23.375 23.7051 23.375ZM16.4863 8.9375C13.8323 8.9375 11.6737 11.0961 11.6737 13.7499V16.1896C11.6737 18.3078 10.7454 20.3081 9.12753 21.6757C9.09693 21.702 9.04871 21.7563 9.04871 21.8438C9.04871 21.9627 9.14852 22.0625 9.26756 22.0625H23.7051C23.8239 22.0625 23.9238 21.9627 23.9238 21.8438C23.9238 21.7563 23.8757 21.702 23.8467 21.6775C22.2271 20.3081 21.2988 18.3078 21.2988 16.1896V13.7499C21.2988 11.0961 19.1402 8.9375 16.4863 8.9375Z'
        fill={color}
      />
      <path
        d='M16.4865 8.9375C16.1242 8.9375 15.8302 8.6435 15.8302 8.28125V5.65625C15.8302 5.294 16.1242 5 16.4865 5C16.8487 5 17.1427 5.294 17.1427 5.65625V8.28125C17.1427 8.6435 16.8487 8.9375 16.4865 8.9375Z'
        fill={color}
      />
    </svg>
  );
};
