import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { SettingsIcon } from '../settings-icon';
import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppTappable } from '@/components/app-tappable';
import { unlockBackSwipe } from '@/services/helper-service';
import c from 'classnames';
import { useSettingsMenu } from '../use-settings-menu';

export const MenuItemClass =
  'flex h-[50px] cursor-pointer justify-between items-center font-roboto laptop:p-[0_35px]';

export const SettingsMenu = ({
  location: { pathname },
  menuRoute,
  path,
  isDesktop,
}: {
  location: { pathname: string };
  menuRoute: ReturnType<typeof useSettingsMenu>;
  path: string;
  isDesktop: boolean;
}) => {
  const { formatMessage: t } = useIntl();
  const history = useHistory();
  const colorTextMain = `var(${APP_CSS_THEME_VARIABLES.textMain})`;

  return (
    <>
      {menuRoute.map((item, inx: number) => {
        const isActive = pathname.includes(item?.to ?? 'no-route');
        const iconOpacity = isActive ? null : '0.2';

        const onLinkClick = async () => {
          await unlockBackSwipe();
          history.push(`${path}${item.to}`);
        };

        if (item.type) {
          return (
            <li
              className={cx(
                'h-[2px] w-full bg-[var(--textMain10)] laptop:m-auto laptop:w-[calc(100%-70px)]',
                {
                  'laptop:m-[15px_auto]': isDesktop,
                },
              )}
              key={inx}
            />
          );
        }

        if (!item.to) return null;

        return (
          <li
            key={inx}
            className={cx(
              `menu-item--${item.id}`,
              MenuItemClass,
              'laptop:h-[45px]',
              {
                'laptop:bg-[var(--textMain10)]': isActive,
              },
            )}
          >
            <AppTappable
              onTap={onLinkClick}
              // TODO maybe padding 0 important
              className={cx(
                'flex justify-between items-center w-full h-full no-underline',
                {
                  'active-link': pathname.includes(item.to),
                },
              )}
              // @ts-ignore
              disabled={!!item?.disabled}
            >
              <div className='flex items-center'>
                <SettingsIcon
                  color={item.iconColor}
                  name={item.iconName}
                  opacity={iconOpacity}
                />
                <span
                  className={c(
                    'font-bold text-[18px] leading-[22px] tracking-[-0.1px] text-[var(--emphasized)] menu-text',
                    'ml-[10px] font-medium text-[18px] leading-[21px] tracking-[-0.3px] text-[var(--textMain)] opacity-[0.9]',
                  )}
                >
                  {t({ id: `menu.${item.id}` })}
                </span>
              </div>
              {!['account', 'logout'].includes(item.id) && (
                <SettingsIcon
                  name='arrow-right'
                  color={colorTextMain}
                  sizeType='custom'
                  opacity={iconOpacity}
                />
              )}
            </AppTappable>
          </li>
        );
      })}
    </>
  );
};
