export const FileIcon = ({
  size = { width: 30, height: 30 },
  color = 'white',
}) => {
  const { width, height } = size;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.48489 12.2461H5.62507C5.39151 12.2461 5.16751 12.3391 5.00257 12.5045C4.83762 12.6699 4.7454 12.8942 4.74616 13.1278L4.75911 19.8043C4.75911 20.2897 5.15262 20.6832 5.63801 20.6832C6.12341 20.6832 6.51692 20.2897 6.51692 19.8043V17.7771C6.87628 17.7753 7.26018 17.7737 7.48489 17.7737C9.02919 17.7737 10.2856 16.5339 10.2856 15.0099C10.2856 13.4859 9.02919 12.2461 7.48489 12.2461ZM7.48489 16.0159C7.25819 16.0159 6.87288 16.0175 6.51212 16.0194C6.51024 15.6452 6.5086 15.2422 6.5086 15.0099C6.5086 14.8111 6.5076 14.395 6.50655 14.0039H7.48483C8.05014 14.0039 8.52774 14.4646 8.52774 15.0099C8.52774 15.5552 8.0502 16.0159 7.48489 16.0159Z'
        fill={color}
      />
      <path
        d='M14.9607 12.2461H13.125C12.8916 12.2461 12.6678 12.3389 12.5029 12.5041C12.338 12.6693 12.2456 12.8933 12.2461 13.1266C12.2461 13.1267 12.2592 19.5864 12.2592 19.6098C12.2601 19.8429 12.3535 20.0662 12.519 20.2304C12.6837 20.3939 12.9062 20.4855 13.1381 20.4855H13.1415C13.197 20.4853 14.5063 20.4803 15.0298 20.4712C17.0158 20.4365 18.4572 18.7099 18.4572 16.3658C18.4571 13.9017 17.0521 12.2461 14.9607 12.2461ZM14.9991 18.7137C14.7715 18.7177 14.3798 18.7209 14.0141 18.7232C14.0116 17.9598 14.0068 14.7973 14.0055 14.0039H14.9607C16.574 14.0039 16.6993 15.8118 16.6993 16.3659C16.6993 17.5197 16.1736 18.6932 14.9991 18.7137Z'
        fill={color}
      />
      <path
        d='M24.2498 13.9363C24.7352 13.9363 25.1287 13.5428 25.1287 13.0574C25.1287 12.572 24.7352 12.1785 24.2498 12.1785H21.5625C21.0771 12.1785 20.6836 12.572 20.6836 13.0574V19.6877C20.6836 20.1731 21.0771 20.5666 21.5625 20.5666C22.0479 20.5666 22.4414 20.1731 22.4414 19.6877V17.1887H24.0363C24.5217 17.1887 24.9152 16.7952 24.9152 16.3098C24.9152 15.8244 24.5217 15.4309 24.0363 15.4309H22.4414V13.9363H24.2498Z'
        fill={color}
      />
      <path
        d='M26.8359 8.49609H26.1914V8.24537C26.1914 7.11557 25.7633 6.04184 24.986 5.22188L21.3353 1.37104C20.5093 0.499746 19.3469 0 18.1462 0H6.44531C4.99143 0 3.80859 1.18283 3.80859 2.63672V8.49609H3.16406C1.71018 8.49609 0.527344 9.67893 0.527344 11.1328V21.6797C0.527344 23.1336 1.71018 24.3164 3.16406 24.3164H3.80859V27.3633C3.80859 28.8172 4.99143 30 6.44531 30H23.5547C25.0086 30 26.1914 28.8172 26.1914 27.3633V24.3164H26.8359C28.2898 24.3164 29.4727 23.1336 29.4727 21.6797V11.1328C29.4727 9.67893 28.2898 8.49609 26.8359 8.49609ZM5.56641 2.63672C5.56641 2.15209 5.96068 1.75781 6.44531 1.75781H18.1462C18.8666 1.75781 19.564 2.05764 20.0597 2.58047L23.7104 6.43131C24.1768 6.92326 24.4336 7.5675 24.4336 8.24537V8.49609H5.56641V2.63672ZM24.4336 27.3633C24.4336 27.8479 24.0393 28.2422 23.5547 28.2422H6.44531C5.96068 28.2422 5.56641 27.8479 5.56641 27.3633V24.3164H24.4336V27.3633ZM27.7148 21.6797C27.7148 22.1643 27.3206 22.5586 26.8359 22.5586H3.16406C2.67943 22.5586 2.28516 22.1643 2.28516 21.6797V11.1328C2.28516 10.6482 2.67943 10.2539 3.16406 10.2539H26.8359C27.3206 10.2539 27.7148 10.6482 27.7148 11.1328V21.6797Z'
        fill={color}
      />
    </svg>
  );
};
