import { addClickHandler } from '@/services/helper-service';
import noop from 'lodash/noop';
import type { HTMLAttributes } from 'react';

export type AppButtonProps = {
  onPress?(...args: any[]): void;
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit';
} & HTMLAttributes<HTMLButtonElement>;

export const AppButton = ({
  children,
  className = '',
  disabled,
  onPress,
  type = 'button',
}: AppButtonProps) => {
  return (
    <button
      {...addClickHandler(onPress ?? noop)}
      className={className}
      type={type}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
