import { useIntl } from 'react-intl';

export const Terms = () => {
  const { formatMessage: t } = useIntl();
  return (
    <div style={{ color: 'var(--textMain);', padding: '30px' }}>
      <a href='https://ireceipt.io/privacy_policy.html'>
        {t({ id: 'app.privacyPolicy' })}
      </a>
      <br />
      <br />
      <a href='https://www.apple.com/legal/internet-services/itunes/dev/stdeula/'>
        {t({ id: 'app.licenseUserAgreement' })}
      </a>
      <br />
      <br />
      <a href='https://ireceipt.io/gdpr.html'>{t({ id: 'app.gdpr' })}</a>
    </div>
  );
};
