// @ts-nocheck
import {
  resizeByScale,
  rescalePoints,
  sortPoints,
} from './open-cv-helpers-service';

const minReceiptsAreaPercent = 10;

const widthToResize = 300;
const align = 30;
const gaussianSize = 3;
const cannyThreshold1 = 50;
const cannyThreshold2 = 50;
const contourPolyApproxCoefficient = 0.05;
const reducePixels = 3;

export const findAngle = (line1, line2) => {
  //find vector components
  const dAx = line1.endPoint.x - line1.startPoint.x;
  const dAy = line1.endPoint.y - line1.startPoint.y;
  const dBx = line2.endPoint.x - line2.startPoint.x;
  const dBy = line2.endPoint.y - line2.startPoint.y;
  let angle = Math.atan2(dAx * dBy - dAy * dBx, dAx * dBx + dAy * dBy);
  if (angle < 0) {
    angle = angle * -1;
  }
  return angle * (180 / Math.PI);
};

export const detectCornerPoints = (matrix) => {
  // resize
  const width = matrix.cols;
  const height = matrix.rows;
  const scale = widthToResize / width;
  const matrixSmall = resizeByScale(matrix, scale);

  // mr. Gray
  window.cv.cvtColor(matrixSmall, matrixSmall, window.cv.COLOR_RGBA2GRAY, 0);

  // gaussian
  const ksize = new window.cv.Size(gaussianSize, gaussianSize);
  window.cv.GaussianBlur(
    matrixSmall,
    matrixSmall,
    ksize,
    0,
    0,
    window.cv.BORDER_DEFAULT,
  );

  // Morphology
  const kernel = window.cv.getStructuringElement(
    window.cv.MORPH_RECT,
    new window.cv.Size(9, 9),
  );
  window.cv.dilate(
    matrixSmall,
    matrixSmall,
    kernel,
    new window.cv.Point(-1, -1),
  );

  //Canny
  window.cv.Canny(
    matrixSmall,
    matrixSmall,
    cannyThreshold1,
    cannyThreshold2,
    3,
  );

  // Contour
  const orientedPoints = findPointsByContour(matrixSmall);
  const points = orientedPoints ? Object.values(orientedPoints) : undefined;
  const fullArea = matrixSmall.cols * matrixSmall.rows;
  matrixSmall.delete();
  matrix.delete();

  if (
    points !== undefined &&
    areaIsValid(fullArea, points) &&
    checkValidAngles(orientedPoints)
  ) {
    return { intersections: rescalePoints(points, scale), isValid: true };
  }
  return {
    intersections: getDefaultPoints(width, height),
    isValid: false,
  };
};

const checkValidAngles = (points) => {
  const lines = [
    { startPoint: points.leftTop, endPoint: points.rightTop },
    { startPoint: points.rightTop, endPoint: points.rightBottom },
    { startPoint: points.rightBottom, endPoint: points.leftBottom },
    { startPoint: points.leftBottom, endPoint: points.leftTop },
  ];
  let lastLine = null;
  for (let i = 0, l = lines.length; i < l; i++) {
    if (lastLine) {
      const angle = findAngle(lastLine, lines[i]);
      if (angle > 105 || angle < 75) {
        return false;
      }
    }
    lastLine = lines[i];
  }

  return true;
};

const reducePoints = (points) => {
  points.leftTop.x += reducePixels;
  points.leftTop.y += reducePixels;

  points.rightTop.x -= reducePixels;
  points.rightTop.y += reducePixels;

  points.leftBottom.x += reducePixels;
  points.leftBottom.y -= reducePixels;

  points.rightBottom.x -= reducePixels;
  points.rightBottom.y -= reducePixels;

  return points;
};

const areaIsValid = (fullArea, points) => {
  const area = calcPolygonArea(points);

  return (area / fullArea) * 100 > minReceiptsAreaPercent;
};

const calcPolygonArea = (vertices) => {
  let total = 0;

  for (let i = 0, l = vertices.length; i < l; i++) {
    const addX = vertices[i].x;
    const addY = vertices[i === vertices.length - 1 ? 0 : i + 1].y;
    const subX = vertices[i === vertices.length - 1 ? 0 : i + 1].x;
    const subY = vertices[i].y;

    total += addX * addY * 0.5;
    total -= subX * subY * 0.5;
  }

  return Math.abs(total);
};

const findPointsByContour = (mat) => {
  const contours = new window.cv.MatVector();
  const hierarchy = new window.cv.Mat();
  window.cv.findContours(
    mat,
    contours,
    hierarchy,
    window.cv.RETR_LIST,
    window.cv.CHAIN_APPROX_SIMPLE,
  );
  let sortableContours = [];
  for (let i = 0; i < contours.size(); i++) {
    const cnt = contours.get(i);
    const area = window.cv.contourArea(cnt, false);
    const perim = window.cv.arcLength(cnt, false);
    const area1 = window.cv.contourArea(cnt, true);
    const perim1 = window.cv.arcLength(cnt, true);
    const evaluation = area + area1 + perim + perim1;
    sortableContours.push({
      areaSize: area,
      perimiterSize: perim1,
      contour: cnt,
      evaluation: evaluation,
    });
  }
  sortableContours = sortableContours
    .sort((item1, item2) => {
      return item1.evaluation > item2.evaluation
        ? -1
        : item1.evaluation < item2.evaluation
        ? 1
        : 0;
    })
    .slice(0, 10);
  let bestApprox = undefined;
  for (const contour of sortableContours) {
    const approx = new window.cv.Mat();
    window.cv.approxPolyDP(
      contour.contour,
      approx,
      contourPolyApproxCoefficient * contour.perimiterSize,
      true,
    );
    if (approx.rows === 4) {
      bestApprox = approx;
      break;
    }
    approx.delete();
  }
  contours.delete();
  hierarchy.delete();
  if (bestApprox === undefined) {
    return undefined;
  }
  const cornerArray = [
    getPointByIndexes(bestApprox, 0, 1),
    getPointByIndexes(bestApprox, 2, 3),
    getPointByIndexes(bestApprox, 4, 5),
    getPointByIndexes(bestApprox, 6, 7),
  ];
  bestApprox.delete();

  return reducePoints(sortPoints(cornerArray));
};

const getPointByIndexes = (approx, i1, i2) => {
  return new window.cv.Point(approx.data32S[i1], approx.data32S[i2]);
};

const getDefaultPoints = (maxX, maxY) => {
  return [
    { x: align, y: align },
    { x: align, y: maxY - align },
    { x: maxX - align, y: align },
    { x: maxX - align, y: maxY - align },
  ];
};
