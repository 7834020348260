import { SETTINGS_LANGUAGE_MAP } from '@/app.constants';
import { AppPseudoRadio } from '@/components/app-pseudo-radio';
import { useRootContext } from '@/context/root-context';
import { useProfileLocaleEditMutation } from '@/graphql/codegen/graphql';
import { addClickHandler } from '@/services/helper-service';
import { useIntl } from 'react-intl';

export const SettingsLanguage = () => {
  const { formatMessage: t } = useIntl();
  const { locale, setLocale } = useRootContext();
  const [_mutation] = useProfileLocaleEditMutation();

  const changeLocale = async ({ locale, recognizeLocale }) => {
    try {
      await _mutation({
        variables: {
          locale,
          recognizeLocale,
        },
      });
    } catch (error) {
      console.warn(error);
    }
  };

  const onLanguageChange = async (locale: string) => {
    await changeLocale({ locale, recognizeLocale: locale });
    setLocale(locale);
  };

  const settingsLanguageOptions = SETTINGS_LANGUAGE_MAP.map((language) => ({
    label: t({ id: `menu.lang.${language}` }),
    isActive: language === locale,
    onClick: () => onLanguageChange(language),
  }));

  return (
    <div className='font-roboto p-5 pt-5 pb-5 flex flex-col tablet:max-w-[667px] tablet:mx-auto laptop:w-[45%] laptop:p-0 laptop:m-0'>
      <div className='font-bold text-[22px] leading-6 text-center text-[var(--textMain)]'>
        {t({ id: 'menu.lang.choose' })}
      </div>

      <ul className='mt-5 w-fit list-none'>
        {settingsLanguageOptions.map(({ label, onClick, isActive }) => (
          <li
            key={label}
            className='cursor-pointer flex items-center h-[50px]'
            {...addClickHandler(onClick)}
          >
            <AppPseudoRadio isItemActive={isActive} />
            <span className='font-medium text-[18px] leading-5 ml-2.5 text-[var(--textMain)]'>
              {label}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};
