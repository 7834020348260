import c from 'classnames';
import { useCallback, useRef } from 'react';
import { useClickAway } from 'react-use';
import { FILTER_TYPE } from '@/components/CropImages/image-filters';
import { useCropStepContext } from '../crop-step-context';
import { RECEIPT_STYLE } from '../crop-step.constants';
import { CropStepStyleFilter } from './crop-step-style-filter';

// !Note - do not wrap in memo!!! Need to get rid of window.newPoly, after you can do it
export const CropStepStyleFilters = () => {
  const { setStyleFilter, styleFilter, hideStyle, styleEnabled } =
    useCropStepContext();

  const onOriginalStyleClick = async () => {
    try {
      setStyleFilter(RECEIPT_STYLE.original);
      await window.newPoly.applyFilters({ filterType: FILTER_TYPE.original });
    } catch {
      console.log('error original');
    }
  };

  const onGrayStyleClick = async () => {
    try {
      setStyleFilter(RECEIPT_STYLE.gray);
      await window.newPoly.applyFilters({ filterType: FILTER_TYPE.gray });
    } catch {
      console.log('error gray');
    }
  };

  const onBWStyleClick = async () => {
    try {
      setStyleFilter(RECEIPT_STYLE.bw);
      await window.newPoly.applyFilters({ filterType: FILTER_TYPE.bw });
    } catch {
      console.log('error BW');
    }
  };

  const styleFilters = [
    {
      onClick: onOriginalStyleClick,
      type: RECEIPT_STYLE.original,
    },
    {
      onClick: onGrayStyleClick,
      type: RECEIPT_STYLE.gray,
    },
    {
      onClick: onBWStyleClick,
      type: RECEIPT_STYLE.bw,
    },
  ];

  const onClickAway = (event) => {
    const styleButton = document.getElementById('style-button');

    if (styleEnabled && styleButton && !styleButton.contains(event.target)) {
      hideStyle();
    }
  };

  const ref = useRef(null);
  useClickAway(ref, onClickAway);

  return (
    <div
      ref={ref}
      className={c(
        'bg-primary flex items-center justify-center absolute left-1/2 transform -translate-x-1/2 w-full z-10 transition duration-300 bottom-7 laptop:bottom-[-30px]',
        {
          'bottom-[140px] laptop:bottom-[70px]': styleEnabled,
        },
      )}
    >
      <div className='p-4 flex items-center justify-evenly w-full max-w-lg'>
        {styleFilters.map((item) => (
          <CropStepStyleFilter {...item} key={item.type} {...{ styleFilter }} />
        ))}
      </div>
    </div>
  );
};
