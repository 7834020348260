import { LocalCategory, LocalReceipt } from '@/core.types';
import isEqual from 'lodash/isEqual';
import { memo } from 'react';

import { CategoryItemTableRowFolder } from './category-table-row-folder';
import { CategoryItemTableRowReceipt } from './category-table-row-receipt';

export const CategoryItemTable = memo(
  ({
    foldersList,
    emptyReceiptList,
    nonEmptyReceiptList,
  }: {
    foldersList: LocalCategory[];
    emptyReceiptList: LocalReceipt[];
    nonEmptyReceiptList: LocalReceipt[];
  }) => {
    const renderReceiptItemRow = (item: LocalReceipt) => (
      <CategoryItemTableRowReceipt {...item} key={item.id} />
    );

    return (
      <>
        <div className='w-full select-none last:mb-[32px] tablet:last:mb-[64px]'>
          <div>{emptyReceiptList.map(renderReceiptItemRow)}</div>
        </div>
        {!!emptyReceiptList?.length && (
          <div className='w-full select-none last:mb-[32px] tablet:last:mb-[64px] h-[4px] bg-[#e9e9e9]' />
        )}
        <div className='w-full select-none last:mb-[32px] tablet:last:mb-[64px]'>
          <div>
            {foldersList.map((item) => (
              <CategoryItemTableRowFolder {...item} key={item.id} />
            ))}
            {nonEmptyReceiptList.map(renderReceiptItemRow)}
          </div>
        </div>
      </>
    );
  },
  isEqual,
);
