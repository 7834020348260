import { DefaultInput } from '@/components';
import { AppLoader } from '@/components/app-loader';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { AppButton } from '@/components/app-button';
import { useProfilePasswordEditMutation } from '@/graphql/codegen/graphql';
import { useIsAuthorizedAtomState } from '@/store';
import { SAVE_BUTTON_CLASSNAME } from '../settings-page';

const VALIDATION_MESSAGE = 'Passwords don’t match.';

export const Password = ({ mobile }: any) => {
  const { formatMessage: t } = useIntl();
  const { push } = useHistory();
  const [_mutation, { loading, data }] = useProfilePasswordEditMutation();
  const { setIsAuthorised } = useIsAuthorizedAtomState();

  const [newPass, setNewPass] = useState('');
  const [againNewPass, setAgainNewPass] = useState('');
  const [isValidationError, setIsValidationError] = useState(false);
  const [isDirtyForm, setIsDirtyForm] = useState(false);

  const reset = () => {
    setNewPass('');
    setAgainNewPass('');
    setIsValidationError(false);
    setIsDirtyForm(false);
  };

  useEffect(() => {
    return () => reset();
  }, []);

  useEffect(() => {
    if (isDirtyForm) {
      setIsValidationError(newPass !== againNewPass);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPass, againNewPass]);

  const changePassword = async (password: string) => {
    const response = await _mutation({
      variables: { password },
    });

    if (!response?.data?.profileEdit.token) {
      return;
    }

    await setIsAuthorised(response?.data?.profileEdit.token);
  };

  useEffect(() => {
    if (!loading && newPass && againNewPass && data) {
      reset();
    }
  }, [againNewPass, data, loading, newPass]);

  const changePasswordHandler = () => {
    if (newPass === againNewPass) {
      return changePassword(newPass).then(() => mobile && push('/settings'));
    } else {
      setIsDirtyForm(true);
      setIsValidationError(true);
    }
  };

  return (
    <div className='p-[20px_0] max-w-[343px] w-full m-[0_auto] laptop:p-0'>
      <DefaultInput
        type='password'
        value={newPass}
        placeholder={t({ id: 'menu.profile.password.new' })}
        onChange={({ currentTarget: { value } }) => setNewPass(value)}
      />

      <DefaultInput
        type='password'
        wrapperClassName='!mt-[10px]'
        value={againNewPass}
        placeholder={t({ id: 'menu.profile.password.again' })}
        onChange={({ currentTarget: { value } }) => setAgainNewPass(value)}
        hasError={isValidationError}
        errorMessage={VALIDATION_MESSAGE}
      />

      <AppButton
        className={SAVE_BUTTON_CLASSNAME}
        onPress={changePasswordHandler}
        disabled={isValidationError}
      >
        {t({ id: 'save' })}
      </AppButton>
      {loading && <AppLoader />}
    </div>
  );
};
