import { useGetIOSKeyboardHeight } from '@/hooks/use-get-ios-keyboard-height';

import {
  createObjectURL,
  getBlob,
  HTMLImageElementToCanvas,
} from '@/services/helper-service';
import c from 'classnames';
import { useRef } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { useIntl } from 'react-intl';

export const MAX_PROFILE_IMAGE_SIZE = 600;

export const ProfileUploadPhoto = ({ setState, state }: any) => {
  const fileUpload = useRef<HTMLInputElement | null>(null);
  const keyboardHeight = useGetIOSKeyboardHeight();
  const { formatMessage: t } = useIntl();

  if (keyboardHeight) {
    return null;
  }

  return (
    <div
      onClick={() => {
        fileUpload?.current?.click();
      }}
      className='cursor-pointer !w-fit  mx-auto mb-12'
    >
      <div
        className={c(
          'relative text-center w-[110px] h-[110px] m-auto rounded-full overflow-hidden cursor-pointer bg-[var(--emphasized)]',
          {
            'bg-[var(--emphasized)] shadow-[inset_2px_2px_4px_var(--emphasized30)] flex justify-center':
              !state.img,
          },
        )}
      >
        {state.img ? (
          <img
            className={c('object-cover w-full h-full', {
              'w-1/2 h-auto object-none': !state.img,
            })}
            src={state.img}
            alt='profile-pic'
          />
        ) : (
          <FaUserCircle
            className={c(
              'object-cover w-full h-full ',
              'w-1/2 h-auto object-none',
            )}
            size={30}
            color='white'
          />
        )}

        <input
          onChange={(e: any) => {
            const image = new Image();
            image.onload = () => {
              getBlob(
                HTMLImageElementToCanvas(image, MAX_PROFILE_IMAGE_SIZE),
              ).then((e: any) => {
                setState({
                  ...state,
                  fileForSend: e.file,
                  img: e.url,
                  dirty: true,
                });
              });
            };

            image.src = createObjectURL(e.target.files[0]) as string;
          }}
          ref={fileUpload}
          className='hidden'
          type='file'
          accept='image/*'
        />
      </div>

      <div className='mt-4 font-normal text-[16px] leading-[19px] text-center text-[var(--textMain)]'>
        {t({ id: 'menu.profile.photo' })}
      </div>
    </div>
  );
};
