import { CategoryId, ReceiptId } from '@/core.types';
import { useCallback } from 'react';
import { RecoilState, useSetRecoilState } from 'recoil';

export const useSetBooleanAtomState = (atom: RecoilState<any>) => {
  const setAtom = useSetRecoilState(atom);

  const setTrue = useCallback(() => {
    setAtom(true);
  }, [setAtom]);

  const setFalse = useCallback(() => {
    setAtom(false);
  }, [setAtom]);

  return [setTrue, setFalse];
};

export const useSelectedIDsAtomSetters = (atom: any) => {
  const setList = useSetRecoilState(atom);

  const clearList = useCallback(() => {
    setList([]);
  }, [setList]);

  const addSelectedIdToList = useCallback(
    (id: ReceiptId | CategoryId) => {
      setList((list: Array<ReceiptId | CategoryId>) => [...list, id]);
    },
    [setList],
  );

  const removeSelectedIdFromList = useCallback(
    (idToRemove: ReceiptId | CategoryId) => {
      setList((list: Array<ReceiptId | CategoryId>) =>
        list.filter((id) => id !== idToRemove),
      );
    },
    [setList],
  );

  return [addSelectedIdToList, removeSelectedIdFromList, clearList] as const;
};
