import { STORAGE_KEYS } from '@/app.constants';
import { Maybe } from '@/core.types';
import {
  AvailableAccountShare,
  UserPayload,
} from '@/gql/api-user/api-user.types';
import { resolveFilePath } from '@/services/helper-service';
import { useLocalStorage } from 'react-use';

export const getUserSharedAccount = (
  currentUserPayload?: UserPayload | null,
): Maybe<AvailableAccountShare> => {
  if (!currentUserPayload) {
    return null;
  }

  const availableAccountShares: AvailableAccountShare[] =
    currentUserPayload.user.account.availableAccountShares;
  const currentAccountId = currentUserPayload.user.currentAccount.id;
  const ownAccountId = currentUserPayload.user.account.id;

  return (
    availableAccountShares.find(
      ({ accountTo, accountFrom }) =>
        accountTo.id === ownAccountId && accountFrom.id === currentAccountId,
    ) || null
  );
};

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useLocalStorage<Maybe<UserPayload>>(
    STORAGE_KEYS.currentUser,
    null,
  );

  const account = currentUser?.user?.account;

  const finalOwnerPictureUrl =
    currentUser?.user?.currentAccount?.ownerPictureUrl.startsWith('http')
      ? currentUser?.user?.currentAccount?.ownerPictureUrl
      : import.meta.env.VITE_WEBSITE_URL +
        currentUser?.user?.currentAccount?.ownerPictureUrl;

  return {
    currentUserName: currentUser?.user?.account?.ownerFullName ?? '',
    currentUserCurrency: currentUser?.user?.currentAccount.ownerCurrency ?? '',
    currentUserIcon: window.onlineDispatcher.isOnline
      ? finalOwnerPictureUrl ?? ''
      : '',
    currentUserPhones: currentUser?.user.phones || [],
    currentUserPersonalAccount: {
      id: account?.id,
      ownerFullName: account?.ownerFullName,
      ownerCurrency: account?.ownerCurrency,
      ownerPictureUrl: resolveFilePath(account?.ownerPictureUrl) ?? '',
      accountType: account?.accountType,
    },
    currentUserSharedAccounts:
      account?.ownAccountShares?.map(
        ({ id, accountTo, canViewGroup, canEditGroup, canDeleteGroup }) => ({
          ...accountTo,
          id,
          ownerPictureUrl: resolveFilePath(accountTo.ownerPictureUrl),
          canViewGroup,
          canEditGroup,
          canDeleteGroup,
        }),
      ) ?? [],

    currentAccountId: currentUser?.user?.currentAccount?.id,
    userId: currentUser?.user?.id,
    currentUserSharedAccount: getUserSharedAccount(currentUser),
    currentUser,
    setCurrentUser,
  };
};
