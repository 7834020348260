// @ts-nocheck
import helpers from './helpers';
import Magnify from './Magnify';

const Mag = new Magnify('displayId', 3);
export default class Polygon {
  constructor(container, canvas, handles, handlesLine) {
    this.container = container;
    this.canvas = canvas;
    this.bounds = this.canvas.getBoundingClientRect();
    this.croppingArea = {
      overlayColor: 'rgba(0, 0, 0, 0.7)',
      stroke: true,
      strokeColor: '#A3ACE2',
      strokeDashed: true,
      strokeWeight: 2,
    };
    this.handles = handles;
    this.handlesLine = handlesLine;
  }

  drawWithOverlay() {
    const {
      overlayColor,
      stroke,
      strokeColor,
      strokeDashed,
      strokeWeight,
      fillColor = '',
      img = '',
    } = this.croppingArea;

    const context = this.canvas.getContext('2d');
    // Save context for clipping and clear canvas.
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    context.save();
    // Draw overlay
    context.fillStyle = overlayColor;
    context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    context.restore();
    // Draw quadrilateral.
    context.save();
    // if (strokeDashed) context.setLineDash([5, 3]);
    context.strokeStyle = strokeColor;
    context.lineWidth = strokeWeight;
    this.draw(context);
    // Create a "hole" on the overlay inside the quadrilateral.
    context.clip();
    if (!img && !fillColor) {
      context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    } else if (!img && fillColor) {
      context.fillStyle = fillColor;
      context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    } else {
      context.drawImage(img, 0, 0, this.canvas.width, this.canvas.height);
    }
    // Add stroke outside the cleared area.
    if (stroke) context.stroke();
    // Remove clipping mask.
    context.restore();
  }

  draw(context) {
    context.beginPath();
    context.moveTo(this.handles[0].point.x, this.handles[0].point.y);
    for (const handleInst of this.handles.slice(1)) {
      context.lineTo(handleInst.point.x, handleInst.point.y);
    }
    context.closePath();
  }

  startResizingLines(event) {
    if (!this.isDrawing && event.target.className === 'idwall-handle-line') {
      const direction = parseInt(event.target.getAttribute('data-inx'));
      document[helpers.isMobile() ? 'ontouchmove' : 'onmousemove'] = (
        event,
      ) => {
        const obj = helpers.isMobile() ? event.changedTouches[0] : event;
        this.eventCallbackLine({ x: obj.clientX, y: obj.clientY, direction });
      };
    }
  }

  startResizing(event) {
    if (
      !this.isDrawing &&
      ['idwall-handle', 'idwall-handle__circle'].includes(
        event.target.className,
      )
    ) {
      const isCircleTarget = event.target.className === 'idwall-handle__circle';
      const dataInxNode = isCircleTarget
        ? event.target.parentNode
        : event.target;

      const direction = parseInt(dataInxNode.getAttribute('data-inx'));

      Mag.init();

      window.addEventListener(
        helpers.isMobile() ? 'touchend' : 'mouseup',
        Mag.reset,
      );

      document[helpers.isMobile() ? 'ontouchmove' : 'onmousemove'] = (
        event,
      ) => {
        const obj = helpers.isMobile() ? event.changedTouches[0] : event;
        this.eventCallback({
          x: obj.clientX,
          y: obj.clientY,
          direction,
          event,
        });
      };
    }
  }

  eventCallback({ x, y, direction, event }) {
    if (direction !== 0) {
      Mag.moveMagnifier(event, this.handles[0].point, 0);
    } else {
      Mag.moveMagnifier(event, this.handles[1].point, 1);
    }

    const point = this.resolveBounds(x, y);
    this.resize(direction, point.x, point.y);
  }

  eventCallbackLine({ x, y, direction }) {
    const point = this.resolveBounds(x, y);
    x = point.x - this.bounds.left;
    y = point.y - this.bounds.top;
    const handleLine = this.handlesLine[direction];
    const handle1 = handleLine.handle1;
    const handle2 = handleLine.handle2;
    if (direction === 0 || direction === 2) {
      const offsetY = y - handleLine.point.y;
      this.resize(
        handle1.inx,
        handle1.point.x,
        handle1.point.y + offsetY,
        false,
      );
      this.resize(
        handle2.inx,
        handle2.point.x,
        handle2.point.y + offsetY,
        false,
      );
    } else {
      const offsetX = x - handleLine.point.x;
      this.resize(
        handle1.inx,
        handle1.point.x + offsetX,
        handle1.point.y,
        false,
      );
      this.resize(
        handle2.inx,
        handle2.point.x + offsetX,
        handle2.point.y,
        false,
      );
    }
  }

  resolveBounds(x, y) {
    const bounds = this.bounds;
    if (x < bounds.left) {
      x = bounds.left;
    }

    if (x > bounds.right) {
      x = bounds.right;
    }

    if (y < bounds.top) {
      y = bounds.top;
    }

    if (y > bounds.bottom) {
      y = bounds.bottom;
    }
    return {
      x: x,
      y: y,
    };
  }

  resize(direction, x, y, correctBounds = true) {
    x = correctBounds ? x - this.bounds.left : x;
    y = correctBounds ? y - this.bounds.top : y;
    this.handles[direction].updatePoint({ x: x, y: y });
    this.drawWithOverlay();
    window.instIdCrop.updateHandleLines();
  }
}
