import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { AppButton } from '@/components/app-button';
import { useRootContext } from '@/context/root-context';
import { CategoryId } from '@/core.types';
import { useContext } from 'react';

export const CreateReceiptModalTreeBreadcrumbsItem = ({
  id,
}: {
  id: CategoryId;
}) => {
  const { setActiveSliceId } = useRootContext();
  const { getCategoryById } = useContext(CategoryTreeContext);
  const category = getCategoryById(id);

  const onItemClick = () => {
    setActiveSliceId(id);
  };

  if (!category) {
    return null;
  }

  return (
    <AppButton
      className='text-[14px] leading-[22px] tracking-[-0.3px] text-[#cacacd] cursor-pointer max-w-[70px] whitespace-nowrap overflow-hidden !text-ellipsis last-of-type:text-[#a3ace2]'
      onPress={onItemClick}
    >
      {category.name}
      {' / '}
    </AppButton>
  );
};
