import { addClickHandler } from '@/services/helper-service';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { useIntl } from 'react-intl';

export const Promo = () => {
  const { formatMessage: t } = useIntl();
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div {...addClickHandler(stopPropagation)}>
      <span
        className='text-[var(--textMain)] text-[13px] underline mb-[5px] block'
        onClick={async () => {
          await Purchases.presentCodeRedemptionSheet();
        }}
      >
        {t({ id: 'menu.profile.enter-promo' })}
      </span>
    </div>
  );
};
