import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ProfilePhoto: { input: any; output: any; }
  ReceiptPhoto: { input: any; output: any; }
};

export type Account = {
  __typename?: 'Account';
  accountType?: Maybe<Scalars['String']['output']>;
  availableAccountShares?: Maybe<Array<Maybe<AccountShare>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  hasUserFilter?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  ownAccountShares?: Maybe<Array<Maybe<AccountShare>>>;
  ownerCurrency?: Maybe<Scalars['String']['output']>;
  ownerEmail?: Maybe<Scalars['String']['output']>;
  ownerFirstName?: Maybe<Scalars['String']['output']>;
  ownerFullName?: Maybe<Scalars['String']['output']>;
  ownerId?: Maybe<Scalars['Int']['output']>;
  ownerLastName?: Maybe<Scalars['String']['output']>;
  ownerLocale?: Maybe<Scalars['String']['output']>;
  ownerPictureUrl?: Maybe<Scalars['String']['output']>;
  receipts?: Maybe<Array<Maybe<Receipt>>>;
  rootCategory?: Maybe<Category>;
};


export type AccountCategoriesArgs = {
  lastUpdated?: InputMaybe<Scalars['Int']['input']>;
};


export type AccountReceiptsArgs = {
  lastUpdated?: InputMaybe<Scalars['Int']['input']>;
};

export type AccountShare = {
  __typename?: 'AccountShare';
  accountFrom: Account;
  accountTo: Account;
  canDeleteGroup?: Maybe<Scalars['Boolean']['output']>;
  canEditGroup?: Maybe<Scalars['Boolean']['output']>;
  canViewGroup?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

export type Category = {
  __typename?: 'Category';
  children?: Maybe<Array<Maybe<Category>>>;
  createdAt: Scalars['Int']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdById?: Maybe<Scalars['Int']['output']>;
  deleted: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Category>;
  parentId?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Int']['output'];
};

export type Device = {
  __typename?: 'Device';
  diskFree?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  os?: Maybe<Scalars['String']['output']>;
  osVersion?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  pushToken?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

export type Email = {
  __typename?: 'Email';
  email: Scalars['String']['output'];
  id?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addDevice: Device;
  addEmail?: Maybe<Scalars['Boolean']['output']>;
  addPhone?: Maybe<Scalars['Boolean']['output']>;
  appleLogin: User;
  createOrUpdateCategory: Category;
  createOrUpdateReceipt: Receipt;
  createPdfLink: Pdf;
  deleteCategories?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  deleteEmail?: Maybe<Scalars['Boolean']['output']>;
  deletePhone?: Maybe<Scalars['Boolean']['output']>;
  deleteReceipts?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  facebookLogin: User;
  googleLogin: User;
  inviteFriend?: Maybe<Scalars['Boolean']['output']>;
  loginByEmail: User;
  loginBySMS: User;
  mailConfirm: User;
  notifyPdfSaved?: Maybe<Scalars['Boolean']['output']>;
  profileEdit: User;
  registerByMail: User;
  removeAccountSharing?: Maybe<Scalars['Boolean']['output']>;
  removeUser?: Maybe<Scalars['Boolean']['output']>;
  resetPassword: User;
  resetPasswordRequest?: Maybe<Scalars['Boolean']['output']>;
  shareAccount?: Maybe<Scalars['Boolean']['output']>;
};


export type MutationAddDeviceArgs = {
  diskFree?: InputMaybe<Scalars['Float']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  os?: InputMaybe<Scalars['String']['input']>;
  osVersion?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  pushToken?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationAddPhoneArgs = {
  phone: Scalars['String']['input'];
};


export type MutationAppleLoginArgs = {
  access_token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateOrUpdateCategoryArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateOrUpdateReceiptArgs = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  category_id?: InputMaybe<Scalars['Int']['input']>;
  fileExtension?: InputMaybe<Scalars['String']['input']>;
  fileUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  receiptDate?: InputMaybe<Scalars['Int']['input']>;
  receiptPhoto?: InputMaybe<Scalars['ReceiptPhoto']['input']>;
  remindedAt?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePdfLinkArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteCategoriesArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type MutationDeleteEmailArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeletePhoneArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationDeleteReceiptsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};


export type MutationFacebookLoginArgs = {
  access_token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGoogleLoginArgs = {
  access_token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInviteFriendArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginByEmailArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationLoginBySmsArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  phone: Scalars['String']['input'];
};


export type MutationMailConfirmArgs = {
  token: Scalars['String']['input'];
};


export type MutationNotifyPdfSavedArgs = {
  id: Scalars['Int']['input'];
};


export type MutationProfileEditArgs = {
  birthday?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  customPicture?: InputMaybe<Scalars['ProfilePhoto']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  recognizeLocale?: InputMaybe<Scalars['String']['input']>;
  secondaryRecognizeLocale?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRegisterByMailArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationRemoveAccountSharingArgs = {
  sharing_id: Scalars['Int']['input'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationResetPasswordRequestArgs = {
  email: Scalars['String']['input'];
};


export type MutationShareAccountArgs = {
  canDeleteGroup?: InputMaybe<Scalars['Boolean']['input']>;
  canEditGroup?: InputMaybe<Scalars['Boolean']['input']>;
  canViewGroup?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
};

export type Pdf = {
  __typename?: 'Pdf';
  createdAt: Scalars['Int']['output'];
  deleted: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  path?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Int']['output'];
  uploadFileLink?: Maybe<Scalars['String']['output']>;
};

export type Phone = {
  __typename?: 'Phone';
  id?: Maybe<Scalars['Int']['output']>;
  phone: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  share?: Maybe<Share>;
  user?: Maybe<User>;
};


export type QueryShareArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};

export type Receipt = {
  __typename?: 'Receipt';
  amount?: Maybe<Scalars['Float']['output']>;
  category?: Maybe<Category>;
  createdAt: Scalars['Int']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdById?: Maybe<Scalars['Int']['output']>;
  deleted: Scalars['Boolean']['output'];
  id?: Maybe<Scalars['Int']['output']>;
  imagePath?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['Int']['output']>;
  predictedAmount?: Maybe<Scalars['Float']['output']>;
  predictedName?: Maybe<Scalars['String']['output']>;
  receiptDate?: Maybe<Scalars['Int']['output']>;
  remindedAt?: Maybe<Scalars['Int']['output']>;
  texts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  thumbPath?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Int']['output'];
  uploadFileLink?: Maybe<Scalars['String']['output']>;
  uploadFileThumbLink?: Maybe<Scalars['String']['output']>;
};

export type Share = {
  __typename?: 'Share';
  account?: Maybe<Account>;
};

export type User = {
  __typename?: 'User';
  account?: Maybe<Account>;
  birthday?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  currentAccount?: Maybe<Account>;
  customPictureUrl?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emails?: Maybe<Array<Maybe<Email>>>;
  facebookId?: Maybe<Scalars['String']['output']>;
  facebookName?: Maybe<Scalars['String']['output']>;
  facebookPictureUrl?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  googleId?: Maybe<Scalars['String']['output']>;
  googleName?: Maybe<Scalars['String']['output']>;
  googlePictureUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  inviteHash?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  phones?: Maybe<Array<Maybe<Phone>>>;
  pictureUrl?: Maybe<Scalars['String']['output']>;
  recognizeLocale?: Maybe<Scalars['String']['output']>;
  rootCategory?: Maybe<Category>;
  secondaryRecognizeLocale?: Maybe<Scalars['String']['output']>;
  secondsLeft?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

export type CategoryTreeQueryVariables = Exact<{
  dateFrom?: InputMaybe<Scalars['Int']['input']>;
  dateTo?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type CategoryTreeQuery = { __typename?: 'Query', user?: { __typename: 'User', id?: number | null, currentAccount?: { __typename: 'Account', id?: number | null, receipts?: Array<{ __typename?: 'Receipt', id?: number | null, imagePath?: string | null, thumbPath?: string | null, updatedAt: number, name?: string | null, type?: string | null, createdById?: number | null, parentId?: number | null, deleted: boolean } | null> | null, categories?: Array<{ __typename?: 'Category', id?: number | null, parentId?: number | null, name?: string | null, deleted: boolean } | null> | null } | null } | null };

export type ProfileEditMutationVariables = Exact<{
  customPicture?: InputMaybe<Scalars['ProfilePhoto']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  birthday?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
}>;


export type ProfileEditMutation = { __typename?: 'Mutation', profileEdit: { __typename?: 'User', id?: number | null, token?: string | null, email?: string | null, phone?: string | null, secondsLeft?: string | null, facebookId?: string | null, facebookPictureUrl?: string | null, facebookName?: string | null, googleId?: string | null, googlePictureUrl?: string | null, customPictureUrl?: string | null, googleName?: string | null, locale?: string | null, currency?: string | null, firstName?: string | null, lastName?: string | null, birthday?: number | null, gender?: string | null, currentAccount?: { __typename?: 'Account', id?: number | null, ownerCurrency?: string | null } | null } };

export type DeletePhoneMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeletePhoneMutation = { __typename?: 'Mutation', deletePhone?: boolean | null };

export type AddPhoneMutationVariables = Exact<{
  phone: Scalars['String']['input'];
}>;


export type AddPhoneMutation = { __typename?: 'Mutation', addPhone?: boolean | null };

export type NotifyPdfSavedMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;


export type NotifyPdfSavedMutation = { __typename?: 'Mutation', notifyPdfSaved?: boolean | null };

export type CreatePdfMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreatePdfMutation = { __typename?: 'Mutation', createPdfLink: { __typename?: 'Pdf', id?: number | null, path?: string | null, uploadFileLink?: string | null } };

export type AddEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type AddEmailMutation = { __typename?: 'Mutation', addEmail?: boolean | null };

export type DeleteEmailMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
}>;


export type DeleteEmailMutation = { __typename?: 'Mutation', deleteEmail?: boolean | null };

export type ProfilePasswordEditMutationVariables = Exact<{
  password?: InputMaybe<Scalars['String']['input']>;
}>;


export type ProfilePasswordEditMutation = { __typename?: 'Mutation', profileEdit: { __typename?: 'User', token?: string | null } };

export type MailConfirmMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type MailConfirmMutation = { __typename?: 'Mutation', mailConfirm: { __typename?: 'User', token?: string | null, account?: { __typename?: 'Account', id?: number | null } | null } };

export type AddAccountMutationVariables = Exact<{
  email: Scalars['String']['input'];
  canViewGroup?: InputMaybe<Scalars['Boolean']['input']>;
  canEditGroup?: InputMaybe<Scalars['Boolean']['input']>;
  canDeleteGroup?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type AddAccountMutation = { __typename?: 'Mutation', shareAccount?: boolean | null };

export type RemoveAccountMutationVariables = Exact<{
  sharing_id: Scalars['Int']['input'];
}>;


export type RemoveAccountMutation = { __typename?: 'Mutation', removeAccountSharing?: boolean | null };

export type ProfileLocaleEditMutationVariables = Exact<{
  locale?: InputMaybe<Scalars['String']['input']>;
  recognizeLocale?: InputMaybe<Scalars['String']['input']>;
}>;


export type ProfileLocaleEditMutation = { __typename?: 'Mutation', profileEdit: { __typename?: 'User', locale?: string | null, recognizeLocale?: string | null } };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'User', token?: string | null, account?: { __typename?: 'Account', id?: number | null } | null } };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', resetPasswordRequest?: boolean | null };

export type DeviceInfoMutationVariables = Exact<{
  uuid?: InputMaybe<Scalars['String']['input']>;
  os?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  osVersion?: InputMaybe<Scalars['String']['input']>;
  platform?: InputMaybe<Scalars['String']['input']>;
  diskFree?: InputMaybe<Scalars['Float']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pushToken?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
}>;


export type DeviceInfoMutation = { __typename?: 'Mutation', addDevice: { __typename?: 'Device', id?: number | null, uuid?: string | null, os?: string | null, model?: string | null, osVersion?: string | null, platform?: string | null, diskFree?: number | null, name?: string | null, pushToken?: string | null } };

export type RemoveUserMutationVariables = Exact<{ [key: string]: never; }>;


export type RemoveUserMutation = { __typename?: 'Mutation', removeUser?: boolean | null };

export type LoginByEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type LoginByEmailMutation = { __typename?: 'Mutation', loginByEmail: { __typename?: 'User', token?: string | null, account?: { __typename?: 'Account', id?: number | null } | null } };

export type CreateOrUpdateReceiptMutationVariables = Exact<{
  photo?: InputMaybe<Scalars['ReceiptPhoto']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  category_id: Scalars['Int']['input'];
  amount?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  receiptDate?: InputMaybe<Scalars['Int']['input']>;
  remindedAt?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  fileExtension?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateOrUpdateReceiptMutation = { __typename?: 'Mutation', createOrUpdateReceipt: { __typename?: 'Receipt', id?: number | null, imagePath?: string | null, thumbPath?: string | null, amount?: number | null, name?: string | null, receiptDate?: number | null, remindedAt?: number | null, updatedAt: number, createdBy?: string | null, type?: string | null, uploadFileLink?: string | null, uploadFileThumbLink?: string | null, deleted: boolean, category?: { __typename?: 'Category', id?: number | null } | null } };

export type SetReadDocumentMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']['input']>;
  fileUploaded?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type SetReadDocumentMutation = { __typename?: 'Mutation', createOrUpdateReceipt: { __typename?: 'Receipt', id?: number | null, imagePath?: string | null, thumbPath?: string | null, amount?: number | null, name?: string | null, receiptDate?: number | null, updatedAt: number, createdBy?: string | null, type?: string | null, category?: { __typename?: 'Category', id?: number | null } | null } };

export type DeleteReceiptsMutationVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
}>;


export type DeleteReceiptsMutation = { __typename?: 'Mutation', deleteReceipts?: Array<number | null> | null };

export type CreateOrUpdateCategoryMutationVariables = Exact<{
  name: Scalars['String']['input'];
  parent_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateOrUpdateCategoryMutation = { __typename?: 'Mutation', createOrUpdateCategory: { __typename?: 'Category', id?: number | null, name?: string | null, type?: string | null, createdBy?: string | null, updatedAt: number, parent?: { __typename?: 'Category', id?: number | null } | null } };

export type DeleteCategoriesMutationVariables = Exact<{
  ids?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>> | InputMaybe<Scalars['Int']['input']>>;
}>;


export type DeleteCategoriesMutation = { __typename?: 'Mutation', deleteCategories?: Array<number | null> | null };

export type InviteFriendMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type InviteFriendMutation = { __typename?: 'Mutation', inviteFriend?: boolean | null };

export type LoginBySmsMutationVariables = Exact<{
  phone: Scalars['String']['input'];
  code?: InputMaybe<Scalars['String']['input']>;
}>;


export type LoginBySmsMutation = { __typename?: 'Mutation', loginBySMS: { __typename?: 'User', token?: string | null, account?: { __typename?: 'Account', id?: number | null } | null } };

export type RegisterByMailMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
}>;


export type RegisterByMailMutation = { __typename?: 'Mutation', registerByMail: { __typename?: 'User', token?: string | null, account?: { __typename?: 'Account', id?: number | null } | null } };

export type GoogleLoginMutationVariables = Exact<{
  access_token: Scalars['String']['input'];
}>;


export type GoogleLoginMutation = { __typename?: 'Mutation', googleLogin: { __typename?: 'User', token?: string | null, account?: { __typename?: 'Account', id?: number | null } | null } };

export type FacebookLoginMutationVariables = Exact<{
  access_token: Scalars['String']['input'];
}>;


export type FacebookLoginMutation = { __typename?: 'Mutation', facebookLogin: { __typename?: 'User', token?: string | null, account?: { __typename?: 'Account', id?: number | null } | null } };

export type AppleLoginMutationVariables = Exact<{
  access_token: Scalars['String']['input'];
}>;


export type AppleLoginMutation = { __typename?: 'Mutation', appleLogin: { __typename?: 'User', token?: string | null, account?: { __typename?: 'Account', id?: number | null } | null } };


export const CategoryTreeDocument = gql`
    query categoryTree($dateFrom: Int, $dateTo: Int, $type: String) {
  user {
    id
    __typename
    currentAccount {
      id
      __typename
      receipts(lastUpdated: null) {
        id
        imagePath
        thumbPath
        updatedAt
        name
        type
        createdById
        parentId
        deleted
      }
      categories(lastUpdated: null) {
        id
        parentId
        name
        deleted
      }
    }
  }
}
    `;

/**
 * __useCategoryTreeQuery__
 *
 * To run a query within a React component, call `useCategoryTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryTreeQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCategoryTreeQuery(baseOptions?: Apollo.QueryHookOptions<CategoryTreeQuery, CategoryTreeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryTreeQuery, CategoryTreeQueryVariables>(CategoryTreeDocument, options);
      }
export function useCategoryTreeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryTreeQuery, CategoryTreeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryTreeQuery, CategoryTreeQueryVariables>(CategoryTreeDocument, options);
        }
export function useCategoryTreeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CategoryTreeQuery, CategoryTreeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CategoryTreeQuery, CategoryTreeQueryVariables>(CategoryTreeDocument, options);
        }
export type CategoryTreeQueryHookResult = ReturnType<typeof useCategoryTreeQuery>;
export type CategoryTreeLazyQueryHookResult = ReturnType<typeof useCategoryTreeLazyQuery>;
export type CategoryTreeSuspenseQueryHookResult = ReturnType<typeof useCategoryTreeSuspenseQuery>;
export type CategoryTreeQueryResult = Apollo.QueryResult<CategoryTreeQuery, CategoryTreeQueryVariables>;
export const ProfileEditDocument = gql`
    mutation profileEdit($customPicture: ProfilePhoto, $currency: String, $firstName: String, $lastName: String, $birthday: Int, $gender: String) {
  profileEdit(
    customPicture: $customPicture
    currency: $currency
    firstName: $firstName
    lastName: $lastName
    birthday: $birthday
    gender: $gender
  ) {
    id
    token
    email
    phone
    secondsLeft
    facebookId
    facebookPictureUrl
    facebookName
    googleId
    googlePictureUrl
    customPictureUrl
    googleName
    locale
    currency
    firstName
    lastName
    birthday
    gender
    currentAccount {
      id
      ownerCurrency
    }
  }
}
    `;
export type ProfileEditMutationFn = Apollo.MutationFunction<ProfileEditMutation, ProfileEditMutationVariables>;

/**
 * __useProfileEditMutation__
 *
 * To run a mutation, you first call `useProfileEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileEditMutation, { data, loading, error }] = useProfileEditMutation({
 *   variables: {
 *      customPicture: // value for 'customPicture'
 *      currency: // value for 'currency'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      birthday: // value for 'birthday'
 *      gender: // value for 'gender'
 *   },
 * });
 */
export function useProfileEditMutation(baseOptions?: Apollo.MutationHookOptions<ProfileEditMutation, ProfileEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfileEditMutation, ProfileEditMutationVariables>(ProfileEditDocument, options);
      }
export type ProfileEditMutationHookResult = ReturnType<typeof useProfileEditMutation>;
export type ProfileEditMutationResult = Apollo.MutationResult<ProfileEditMutation>;
export type ProfileEditMutationOptions = Apollo.BaseMutationOptions<ProfileEditMutation, ProfileEditMutationVariables>;
export const DeletePhoneDocument = gql`
    mutation DeletePhone($id: Int) {
  deletePhone(id: $id)
}
    `;
export type DeletePhoneMutationFn = Apollo.MutationFunction<DeletePhoneMutation, DeletePhoneMutationVariables>;

/**
 * __useDeletePhoneMutation__
 *
 * To run a mutation, you first call `useDeletePhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhoneMutation, { data, loading, error }] = useDeletePhoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePhoneMutation(baseOptions?: Apollo.MutationHookOptions<DeletePhoneMutation, DeletePhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePhoneMutation, DeletePhoneMutationVariables>(DeletePhoneDocument, options);
      }
export type DeletePhoneMutationHookResult = ReturnType<typeof useDeletePhoneMutation>;
export type DeletePhoneMutationResult = Apollo.MutationResult<DeletePhoneMutation>;
export type DeletePhoneMutationOptions = Apollo.BaseMutationOptions<DeletePhoneMutation, DeletePhoneMutationVariables>;
export const AddPhoneDocument = gql`
    mutation AddPhone($phone: String!) {
  addPhone(phone: $phone)
}
    `;
export type AddPhoneMutationFn = Apollo.MutationFunction<AddPhoneMutation, AddPhoneMutationVariables>;

/**
 * __useAddPhoneMutation__
 *
 * To run a mutation, you first call `useAddPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPhoneMutation, { data, loading, error }] = useAddPhoneMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useAddPhoneMutation(baseOptions?: Apollo.MutationHookOptions<AddPhoneMutation, AddPhoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPhoneMutation, AddPhoneMutationVariables>(AddPhoneDocument, options);
      }
export type AddPhoneMutationHookResult = ReturnType<typeof useAddPhoneMutation>;
export type AddPhoneMutationResult = Apollo.MutationResult<AddPhoneMutation>;
export type AddPhoneMutationOptions = Apollo.BaseMutationOptions<AddPhoneMutation, AddPhoneMutationVariables>;
export const NotifyPdfSavedDocument = gql`
    mutation NotifyPDFSaved($id: Int!) {
  notifyPdfSaved(id: $id)
}
    `;
export type NotifyPdfSavedMutationFn = Apollo.MutationFunction<NotifyPdfSavedMutation, NotifyPdfSavedMutationVariables>;

/**
 * __useNotifyPdfSavedMutation__
 *
 * To run a mutation, you first call `useNotifyPdfSavedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyPdfSavedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyPdfSavedMutation, { data, loading, error }] = useNotifyPdfSavedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotifyPdfSavedMutation(baseOptions?: Apollo.MutationHookOptions<NotifyPdfSavedMutation, NotifyPdfSavedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotifyPdfSavedMutation, NotifyPdfSavedMutationVariables>(NotifyPdfSavedDocument, options);
      }
export type NotifyPdfSavedMutationHookResult = ReturnType<typeof useNotifyPdfSavedMutation>;
export type NotifyPdfSavedMutationResult = Apollo.MutationResult<NotifyPdfSavedMutation>;
export type NotifyPdfSavedMutationOptions = Apollo.BaseMutationOptions<NotifyPdfSavedMutation, NotifyPdfSavedMutationVariables>;
export const CreatePdfDocument = gql`
    mutation CreatePDF($name: String) {
  createPdfLink(name: $name) {
    id
    path
    uploadFileLink
  }
}
    `;
export type CreatePdfMutationFn = Apollo.MutationFunction<CreatePdfMutation, CreatePdfMutationVariables>;

/**
 * __useCreatePdfMutation__
 *
 * To run a mutation, you first call `useCreatePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPdfMutation, { data, loading, error }] = useCreatePdfMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreatePdfMutation(baseOptions?: Apollo.MutationHookOptions<CreatePdfMutation, CreatePdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePdfMutation, CreatePdfMutationVariables>(CreatePdfDocument, options);
      }
export type CreatePdfMutationHookResult = ReturnType<typeof useCreatePdfMutation>;
export type CreatePdfMutationResult = Apollo.MutationResult<CreatePdfMutation>;
export type CreatePdfMutationOptions = Apollo.BaseMutationOptions<CreatePdfMutation, CreatePdfMutationVariables>;
export const AddEmailDocument = gql`
    mutation AddEmail($email: String!) {
  addEmail(email: $email)
}
    `;
export type AddEmailMutationFn = Apollo.MutationFunction<AddEmailMutation, AddEmailMutationVariables>;

/**
 * __useAddEmailMutation__
 *
 * To run a mutation, you first call `useAddEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailMutation, { data, loading, error }] = useAddEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddEmailMutation(baseOptions?: Apollo.MutationHookOptions<AddEmailMutation, AddEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmailMutation, AddEmailMutationVariables>(AddEmailDocument, options);
      }
export type AddEmailMutationHookResult = ReturnType<typeof useAddEmailMutation>;
export type AddEmailMutationResult = Apollo.MutationResult<AddEmailMutation>;
export type AddEmailMutationOptions = Apollo.BaseMutationOptions<AddEmailMutation, AddEmailMutationVariables>;
export const DeleteEmailDocument = gql`
    mutation DeleteEmail($id: Int) {
  deleteEmail(id: $id)
}
    `;
export type DeleteEmailMutationFn = Apollo.MutationFunction<DeleteEmailMutation, DeleteEmailMutationVariables>;

/**
 * __useDeleteEmailMutation__
 *
 * To run a mutation, you first call `useDeleteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmailMutation, { data, loading, error }] = useDeleteEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmailMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmailMutation, DeleteEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmailMutation, DeleteEmailMutationVariables>(DeleteEmailDocument, options);
      }
export type DeleteEmailMutationHookResult = ReturnType<typeof useDeleteEmailMutation>;
export type DeleteEmailMutationResult = Apollo.MutationResult<DeleteEmailMutation>;
export type DeleteEmailMutationOptions = Apollo.BaseMutationOptions<DeleteEmailMutation, DeleteEmailMutationVariables>;
export const ProfilePasswordEditDocument = gql`
    mutation profilePasswordEdit($password: String) {
  profileEdit(password: $password) {
    token
  }
}
    `;
export type ProfilePasswordEditMutationFn = Apollo.MutationFunction<ProfilePasswordEditMutation, ProfilePasswordEditMutationVariables>;

/**
 * __useProfilePasswordEditMutation__
 *
 * To run a mutation, you first call `useProfilePasswordEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfilePasswordEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profilePasswordEditMutation, { data, loading, error }] = useProfilePasswordEditMutation({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useProfilePasswordEditMutation(baseOptions?: Apollo.MutationHookOptions<ProfilePasswordEditMutation, ProfilePasswordEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfilePasswordEditMutation, ProfilePasswordEditMutationVariables>(ProfilePasswordEditDocument, options);
      }
export type ProfilePasswordEditMutationHookResult = ReturnType<typeof useProfilePasswordEditMutation>;
export type ProfilePasswordEditMutationResult = Apollo.MutationResult<ProfilePasswordEditMutation>;
export type ProfilePasswordEditMutationOptions = Apollo.BaseMutationOptions<ProfilePasswordEditMutation, ProfilePasswordEditMutationVariables>;
export const MailConfirmDocument = gql`
    mutation mailConfirm($token: String!) {
  mailConfirm(token: $token) {
    token
    account {
      id
    }
  }
}
    `;
export type MailConfirmMutationFn = Apollo.MutationFunction<MailConfirmMutation, MailConfirmMutationVariables>;

/**
 * __useMailConfirmMutation__
 *
 * To run a mutation, you first call `useMailConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMailConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mailConfirmMutation, { data, loading, error }] = useMailConfirmMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useMailConfirmMutation(baseOptions?: Apollo.MutationHookOptions<MailConfirmMutation, MailConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MailConfirmMutation, MailConfirmMutationVariables>(MailConfirmDocument, options);
      }
export type MailConfirmMutationHookResult = ReturnType<typeof useMailConfirmMutation>;
export type MailConfirmMutationResult = Apollo.MutationResult<MailConfirmMutation>;
export type MailConfirmMutationOptions = Apollo.BaseMutationOptions<MailConfirmMutation, MailConfirmMutationVariables>;
export const AddAccountDocument = gql`
    mutation AddAccount($email: String!, $canViewGroup: Boolean, $canEditGroup: Boolean, $canDeleteGroup: Boolean) {
  shareAccount(
    email: $email
    canViewGroup: $canViewGroup
    canEditGroup: $canEditGroup
    canDeleteGroup: $canDeleteGroup
  )
}
    `;
export type AddAccountMutationFn = Apollo.MutationFunction<AddAccountMutation, AddAccountMutationVariables>;

/**
 * __useAddAccountMutation__
 *
 * To run a mutation, you first call `useAddAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountMutation, { data, loading, error }] = useAddAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *      canViewGroup: // value for 'canViewGroup'
 *      canEditGroup: // value for 'canEditGroup'
 *      canDeleteGroup: // value for 'canDeleteGroup'
 *   },
 * });
 */
export function useAddAccountMutation(baseOptions?: Apollo.MutationHookOptions<AddAccountMutation, AddAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAccountMutation, AddAccountMutationVariables>(AddAccountDocument, options);
      }
export type AddAccountMutationHookResult = ReturnType<typeof useAddAccountMutation>;
export type AddAccountMutationResult = Apollo.MutationResult<AddAccountMutation>;
export type AddAccountMutationOptions = Apollo.BaseMutationOptions<AddAccountMutation, AddAccountMutationVariables>;
export const RemoveAccountDocument = gql`
    mutation RemoveAccount($sharing_id: Int!) {
  removeAccountSharing(sharing_id: $sharing_id)
}
    `;
export type RemoveAccountMutationFn = Apollo.MutationFunction<RemoveAccountMutation, RemoveAccountMutationVariables>;

/**
 * __useRemoveAccountMutation__
 *
 * To run a mutation, you first call `useRemoveAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAccountMutation, { data, loading, error }] = useRemoveAccountMutation({
 *   variables: {
 *      sharing_id: // value for 'sharing_id'
 *   },
 * });
 */
export function useRemoveAccountMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAccountMutation, RemoveAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAccountMutation, RemoveAccountMutationVariables>(RemoveAccountDocument, options);
      }
export type RemoveAccountMutationHookResult = ReturnType<typeof useRemoveAccountMutation>;
export type RemoveAccountMutationResult = Apollo.MutationResult<RemoveAccountMutation>;
export type RemoveAccountMutationOptions = Apollo.BaseMutationOptions<RemoveAccountMutation, RemoveAccountMutationVariables>;
export const ProfileLocaleEditDocument = gql`
    mutation profileLocaleEdit($locale: String, $recognizeLocale: String) {
  profileEdit(locale: $locale, recognizeLocale: $recognizeLocale) {
    locale
    recognizeLocale
  }
}
    `;
export type ProfileLocaleEditMutationFn = Apollo.MutationFunction<ProfileLocaleEditMutation, ProfileLocaleEditMutationVariables>;

/**
 * __useProfileLocaleEditMutation__
 *
 * To run a mutation, you first call `useProfileLocaleEditMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProfileLocaleEditMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [profileLocaleEditMutation, { data, loading, error }] = useProfileLocaleEditMutation({
 *   variables: {
 *      locale: // value for 'locale'
 *      recognizeLocale: // value for 'recognizeLocale'
 *   },
 * });
 */
export function useProfileLocaleEditMutation(baseOptions?: Apollo.MutationHookOptions<ProfileLocaleEditMutation, ProfileLocaleEditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProfileLocaleEditMutation, ProfileLocaleEditMutationVariables>(ProfileLocaleEditDocument, options);
      }
export type ProfileLocaleEditMutationHookResult = ReturnType<typeof useProfileLocaleEditMutation>;
export type ProfileLocaleEditMutationResult = Apollo.MutationResult<ProfileLocaleEditMutation>;
export type ProfileLocaleEditMutationOptions = Apollo.BaseMutationOptions<ProfileLocaleEditMutation, ProfileLocaleEditMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $newPassword: String!) {
  resetPassword(newPassword: $newPassword, token: $token) {
    token
    account {
      id
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  resetPasswordRequest(email: $email)
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const DeviceInfoDocument = gql`
    mutation deviceInfo($uuid: String, $os: String, $model: String, $osVersion: String, $platform: String, $diskFree: Float, $name: String, $pushToken: String, $timezone: String) {
  addDevice(
    uuid: $uuid
    os: $os
    model: $model
    osVersion: $osVersion
    platform: $platform
    diskFree: $diskFree
    name: $name
    pushToken: $pushToken
    timezone: $timezone
  ) {
    id
    uuid
    os
    model
    osVersion
    platform
    diskFree
    name
    pushToken
  }
}
    `;
export type DeviceInfoMutationFn = Apollo.MutationFunction<DeviceInfoMutation, DeviceInfoMutationVariables>;

/**
 * __useDeviceInfoMutation__
 *
 * To run a mutation, you first call `useDeviceInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceInfoMutation, { data, loading, error }] = useDeviceInfoMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      os: // value for 'os'
 *      model: // value for 'model'
 *      osVersion: // value for 'osVersion'
 *      platform: // value for 'platform'
 *      diskFree: // value for 'diskFree'
 *      name: // value for 'name'
 *      pushToken: // value for 'pushToken'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useDeviceInfoMutation(baseOptions?: Apollo.MutationHookOptions<DeviceInfoMutation, DeviceInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeviceInfoMutation, DeviceInfoMutationVariables>(DeviceInfoDocument, options);
      }
export type DeviceInfoMutationHookResult = ReturnType<typeof useDeviceInfoMutation>;
export type DeviceInfoMutationResult = Apollo.MutationResult<DeviceInfoMutation>;
export type DeviceInfoMutationOptions = Apollo.BaseMutationOptions<DeviceInfoMutation, DeviceInfoMutationVariables>;
export const RemoveUserDocument = gql`
    mutation RemoveUser {
  removeUser
}
    `;
export type RemoveUserMutationFn = Apollo.MutationFunction<RemoveUserMutation, RemoveUserMutationVariables>;

/**
 * __useRemoveUserMutation__
 *
 * To run a mutation, you first call `useRemoveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserMutation, { data, loading, error }] = useRemoveUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveUserMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserMutation, RemoveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserMutation, RemoveUserMutationVariables>(RemoveUserDocument, options);
      }
export type RemoveUserMutationHookResult = ReturnType<typeof useRemoveUserMutation>;
export type RemoveUserMutationResult = Apollo.MutationResult<RemoveUserMutation>;
export type RemoveUserMutationOptions = Apollo.BaseMutationOptions<RemoveUserMutation, RemoveUserMutationVariables>;
export const LoginByEmailDocument = gql`
    mutation loginByEmail($email: String!, $password: String!) {
  loginByEmail(email: $email, password: $password) {
    token
    account {
      id
    }
  }
}
    `;
export type LoginByEmailMutationFn = Apollo.MutationFunction<LoginByEmailMutation, LoginByEmailMutationVariables>;

/**
 * __useLoginByEmailMutation__
 *
 * To run a mutation, you first call `useLoginByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByEmailMutation, { data, loading, error }] = useLoginByEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginByEmailMutation(baseOptions?: Apollo.MutationHookOptions<LoginByEmailMutation, LoginByEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginByEmailMutation, LoginByEmailMutationVariables>(LoginByEmailDocument, options);
      }
export type LoginByEmailMutationHookResult = ReturnType<typeof useLoginByEmailMutation>;
export type LoginByEmailMutationResult = Apollo.MutationResult<LoginByEmailMutation>;
export type LoginByEmailMutationOptions = Apollo.BaseMutationOptions<LoginByEmailMutation, LoginByEmailMutationVariables>;
export const CreateOrUpdateReceiptDocument = gql`
    mutation createOrUpdateReceipt($photo: ReceiptPhoto, $name: String, $category_id: Int!, $amount: Float, $id: Int, $receiptDate: Int, $remindedAt: Int, $type: String, $fileExtension: String) {
  createOrUpdateReceipt(
    receiptPhoto: $photo
    name: $name
    category_id: $category_id
    amount: $amount
    id: $id
    receiptDate: $receiptDate
    remindedAt: $remindedAt
    type: $type
    fileExtension: $fileExtension
  ) {
    id
    imagePath
    thumbPath
    amount
    name
    receiptDate
    remindedAt
    updatedAt
    createdBy
    type
    uploadFileLink
    uploadFileThumbLink
    deleted
    category {
      id
    }
  }
}
    `;
export type CreateOrUpdateReceiptMutationFn = Apollo.MutationFunction<CreateOrUpdateReceiptMutation, CreateOrUpdateReceiptMutationVariables>;

/**
 * __useCreateOrUpdateReceiptMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateReceiptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateReceiptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateReceiptMutation, { data, loading, error }] = useCreateOrUpdateReceiptMutation({
 *   variables: {
 *      photo: // value for 'photo'
 *      name: // value for 'name'
 *      category_id: // value for 'category_id'
 *      amount: // value for 'amount'
 *      id: // value for 'id'
 *      receiptDate: // value for 'receiptDate'
 *      remindedAt: // value for 'remindedAt'
 *      type: // value for 'type'
 *      fileExtension: // value for 'fileExtension'
 *   },
 * });
 */
export function useCreateOrUpdateReceiptMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateReceiptMutation, CreateOrUpdateReceiptMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateReceiptMutation, CreateOrUpdateReceiptMutationVariables>(CreateOrUpdateReceiptDocument, options);
      }
export type CreateOrUpdateReceiptMutationHookResult = ReturnType<typeof useCreateOrUpdateReceiptMutation>;
export type CreateOrUpdateReceiptMutationResult = Apollo.MutationResult<CreateOrUpdateReceiptMutation>;
export type CreateOrUpdateReceiptMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateReceiptMutation, CreateOrUpdateReceiptMutationVariables>;
export const SetReadDocumentDocument = gql`
    mutation setReadDocument($id: Int, $fileUploaded: Boolean) {
  createOrUpdateReceipt(id: $id, fileUploaded: $fileUploaded) {
    id
    imagePath
    thumbPath
    amount
    name
    receiptDate
    updatedAt
    createdBy
    type
    category {
      id
    }
  }
}
    `;
export type SetReadDocumentMutationFn = Apollo.MutationFunction<SetReadDocumentMutation, SetReadDocumentMutationVariables>;

/**
 * __useSetReadDocumentMutation__
 *
 * To run a mutation, you first call `useSetReadDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetReadDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setReadDocumentMutation, { data, loading, error }] = useSetReadDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fileUploaded: // value for 'fileUploaded'
 *   },
 * });
 */
export function useSetReadDocumentMutation(baseOptions?: Apollo.MutationHookOptions<SetReadDocumentMutation, SetReadDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetReadDocumentMutation, SetReadDocumentMutationVariables>(SetReadDocumentDocument, options);
      }
export type SetReadDocumentMutationHookResult = ReturnType<typeof useSetReadDocumentMutation>;
export type SetReadDocumentMutationResult = Apollo.MutationResult<SetReadDocumentMutation>;
export type SetReadDocumentMutationOptions = Apollo.BaseMutationOptions<SetReadDocumentMutation, SetReadDocumentMutationVariables>;
export const DeleteReceiptsDocument = gql`
    mutation DeleteReceipts($ids: [Int]) {
  deleteReceipts(ids: $ids)
}
    `;
export type DeleteReceiptsMutationFn = Apollo.MutationFunction<DeleteReceiptsMutation, DeleteReceiptsMutationVariables>;

/**
 * __useDeleteReceiptsMutation__
 *
 * To run a mutation, you first call `useDeleteReceiptsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReceiptsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReceiptsMutation, { data, loading, error }] = useDeleteReceiptsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteReceiptsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReceiptsMutation, DeleteReceiptsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReceiptsMutation, DeleteReceiptsMutationVariables>(DeleteReceiptsDocument, options);
      }
export type DeleteReceiptsMutationHookResult = ReturnType<typeof useDeleteReceiptsMutation>;
export type DeleteReceiptsMutationResult = Apollo.MutationResult<DeleteReceiptsMutation>;
export type DeleteReceiptsMutationOptions = Apollo.BaseMutationOptions<DeleteReceiptsMutation, DeleteReceiptsMutationVariables>;
export const CreateOrUpdateCategoryDocument = gql`
    mutation createOrUpdateCategory($name: String!, $parent_id: Int, $id: Int, $type: String) {
  createOrUpdateCategory(name: $name, parent_id: $parent_id, id: $id, type: $type) {
    id
    name
    type
    createdBy
    updatedAt
    parent {
      id
    }
  }
}
    `;
export type CreateOrUpdateCategoryMutationFn = Apollo.MutationFunction<CreateOrUpdateCategoryMutation, CreateOrUpdateCategoryMutationVariables>;

/**
 * __useCreateOrUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateOrUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrUpdateCategoryMutation, { data, loading, error }] = useCreateOrUpdateCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      parent_id: // value for 'parent_id'
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateOrUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrUpdateCategoryMutation, CreateOrUpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrUpdateCategoryMutation, CreateOrUpdateCategoryMutationVariables>(CreateOrUpdateCategoryDocument, options);
      }
export type CreateOrUpdateCategoryMutationHookResult = ReturnType<typeof useCreateOrUpdateCategoryMutation>;
export type CreateOrUpdateCategoryMutationResult = Apollo.MutationResult<CreateOrUpdateCategoryMutation>;
export type CreateOrUpdateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateOrUpdateCategoryMutation, CreateOrUpdateCategoryMutationVariables>;
export const DeleteCategoriesDocument = gql`
    mutation DeleteCategories($ids: [Int]) {
  deleteCategories(ids: $ids)
}
    `;
export type DeleteCategoriesMutationFn = Apollo.MutationFunction<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>;

/**
 * __useDeleteCategoriesMutation__
 *
 * To run a mutation, you first call `useDeleteCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoriesMutation, { data, loading, error }] = useDeleteCategoriesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>(DeleteCategoriesDocument, options);
      }
export type DeleteCategoriesMutationHookResult = ReturnType<typeof useDeleteCategoriesMutation>;
export type DeleteCategoriesMutationResult = Apollo.MutationResult<DeleteCategoriesMutation>;
export type DeleteCategoriesMutationOptions = Apollo.BaseMutationOptions<DeleteCategoriesMutation, DeleteCategoriesMutationVariables>;
export const InviteFriendDocument = gql`
    mutation inviteFriend($email: String!) {
  inviteFriend(email: $email)
}
    `;
export type InviteFriendMutationFn = Apollo.MutationFunction<InviteFriendMutation, InviteFriendMutationVariables>;

/**
 * __useInviteFriendMutation__
 *
 * To run a mutation, you first call `useInviteFriendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteFriendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteFriendMutation, { data, loading, error }] = useInviteFriendMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInviteFriendMutation(baseOptions?: Apollo.MutationHookOptions<InviteFriendMutation, InviteFriendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteFriendMutation, InviteFriendMutationVariables>(InviteFriendDocument, options);
      }
export type InviteFriendMutationHookResult = ReturnType<typeof useInviteFriendMutation>;
export type InviteFriendMutationResult = Apollo.MutationResult<InviteFriendMutation>;
export type InviteFriendMutationOptions = Apollo.BaseMutationOptions<InviteFriendMutation, InviteFriendMutationVariables>;
export const LoginBySmsDocument = gql`
    mutation loginBySMS($phone: String!, $code: String) {
  loginBySMS(phone: $phone, code: $code) {
    token
    account {
      id
    }
  }
}
    `;
export type LoginBySmsMutationFn = Apollo.MutationFunction<LoginBySmsMutation, LoginBySmsMutationVariables>;

/**
 * __useLoginBySmsMutation__
 *
 * To run a mutation, you first call `useLoginBySmsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginBySmsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginBySmsMutation, { data, loading, error }] = useLoginBySmsMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useLoginBySmsMutation(baseOptions?: Apollo.MutationHookOptions<LoginBySmsMutation, LoginBySmsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginBySmsMutation, LoginBySmsMutationVariables>(LoginBySmsDocument, options);
      }
export type LoginBySmsMutationHookResult = ReturnType<typeof useLoginBySmsMutation>;
export type LoginBySmsMutationResult = Apollo.MutationResult<LoginBySmsMutation>;
export type LoginBySmsMutationOptions = Apollo.BaseMutationOptions<LoginBySmsMutation, LoginBySmsMutationVariables>;
export const RegisterByMailDocument = gql`
    mutation registerByMail($email: String!, $password: String!) {
  registerByMail(email: $email, password: $password) {
    token
    account {
      id
    }
  }
}
    `;
export type RegisterByMailMutationFn = Apollo.MutationFunction<RegisterByMailMutation, RegisterByMailMutationVariables>;

/**
 * __useRegisterByMailMutation__
 *
 * To run a mutation, you first call `useRegisterByMailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterByMailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerByMailMutation, { data, loading, error }] = useRegisterByMailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useRegisterByMailMutation(baseOptions?: Apollo.MutationHookOptions<RegisterByMailMutation, RegisterByMailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterByMailMutation, RegisterByMailMutationVariables>(RegisterByMailDocument, options);
      }
export type RegisterByMailMutationHookResult = ReturnType<typeof useRegisterByMailMutation>;
export type RegisterByMailMutationResult = Apollo.MutationResult<RegisterByMailMutation>;
export type RegisterByMailMutationOptions = Apollo.BaseMutationOptions<RegisterByMailMutation, RegisterByMailMutationVariables>;
export const GoogleLoginDocument = gql`
    mutation googleLogin($access_token: String!) {
  googleLogin(access_token: $access_token) {
    token
    account {
      id
    }
  }
}
    `;
export type GoogleLoginMutationFn = Apollo.MutationFunction<GoogleLoginMutation, GoogleLoginMutationVariables>;

/**
 * __useGoogleLoginMutation__
 *
 * To run a mutation, you first call `useGoogleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleLoginMutation, { data, loading, error }] = useGoogleLoginMutation({
 *   variables: {
 *      access_token: // value for 'access_token'
 *   },
 * });
 */
export function useGoogleLoginMutation(baseOptions?: Apollo.MutationHookOptions<GoogleLoginMutation, GoogleLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleLoginMutation, GoogleLoginMutationVariables>(GoogleLoginDocument, options);
      }
export type GoogleLoginMutationHookResult = ReturnType<typeof useGoogleLoginMutation>;
export type GoogleLoginMutationResult = Apollo.MutationResult<GoogleLoginMutation>;
export type GoogleLoginMutationOptions = Apollo.BaseMutationOptions<GoogleLoginMutation, GoogleLoginMutationVariables>;
export const FacebookLoginDocument = gql`
    mutation facebookLogin($access_token: String!) {
  facebookLogin(access_token: $access_token) {
    token
    account {
      id
    }
  }
}
    `;
export type FacebookLoginMutationFn = Apollo.MutationFunction<FacebookLoginMutation, FacebookLoginMutationVariables>;

/**
 * __useFacebookLoginMutation__
 *
 * To run a mutation, you first call `useFacebookLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFacebookLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [facebookLoginMutation, { data, loading, error }] = useFacebookLoginMutation({
 *   variables: {
 *      access_token: // value for 'access_token'
 *   },
 * });
 */
export function useFacebookLoginMutation(baseOptions?: Apollo.MutationHookOptions<FacebookLoginMutation, FacebookLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FacebookLoginMutation, FacebookLoginMutationVariables>(FacebookLoginDocument, options);
      }
export type FacebookLoginMutationHookResult = ReturnType<typeof useFacebookLoginMutation>;
export type FacebookLoginMutationResult = Apollo.MutationResult<FacebookLoginMutation>;
export type FacebookLoginMutationOptions = Apollo.BaseMutationOptions<FacebookLoginMutation, FacebookLoginMutationVariables>;
export const AppleLoginDocument = gql`
    mutation appleLogin($access_token: String!) {
  appleLogin(access_token: $access_token) {
    token
    account {
      id
    }
  }
}
    `;
export type AppleLoginMutationFn = Apollo.MutationFunction<AppleLoginMutation, AppleLoginMutationVariables>;

/**
 * __useAppleLoginMutation__
 *
 * To run a mutation, you first call `useAppleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appleLoginMutation, { data, loading, error }] = useAppleLoginMutation({
 *   variables: {
 *      access_token: // value for 'access_token'
 *   },
 * });
 */
export function useAppleLoginMutation(baseOptions?: Apollo.MutationHookOptions<AppleLoginMutation, AppleLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AppleLoginMutation, AppleLoginMutationVariables>(AppleLoginDocument, options);
      }
export type AppleLoginMutationHookResult = ReturnType<typeof useAppleLoginMutation>;
export type AppleLoginMutationResult = Apollo.MutationResult<AppleLoginMutation>;
export type AppleLoginMutationOptions = Apollo.BaseMutationOptions<AppleLoginMutation, AppleLoginMutationVariables>;