import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppIcon } from '@/components/app-icon';
import { hasError } from '@/services/form-service';
import c from 'classnames';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useClickAway } from 'react-use';
import { AppFormFieldClearButton } from '../app-form-field-clear-button';
import { AppFormFieldError } from '../app-form-field-error';
import './app-form-text-field.css';

export const DEFAULT_ICON_SIZE = { width: 22, height: 22 };
export const DEFAULT_CLEAR_ICON_SIZE = { width: 12, height: 12 };

export type AppFormFieldIconConfig = {
  name: string;
  size?: {
    width: number;
    height: number;
  };
};

export type AppFormTextFieldProps = {
  name: string;
  type: 'text' | 'email' | 'password';
  disabled?: boolean;
  placeholder: string;
  icon?: AppFormFieldIconConfig;
  inputClassName?: string;
  autoFocus?: boolean;
  clearable?: boolean;
};

export const AppFormTextField = ({
  name,
  type,
  disabled,
  placeholder,
  icon,
  inputClassName = '',
  autoFocus,
  clearable = true,
}: AppFormTextFieldProps) => {
  const context = useFormContext();
  const fieldId = `form-text-field${name}`;

  const {
    register,
    setValue,
    formState: { isDirty, errors },
  } = context;
  const { showError, error } = hasError({ errors: errors, name, isDirty });

  const onClearClick = () => {
    setValue(name, '');
  };

  const extraStyle =
    !clearable && !icon ? {} : { paddingRight: icon && clearable ? 70 : 40 };

  const onClickOutside = () => {
    const input = document.getElementById(fieldId);
    input?.blur();
  };

  const ref = useRef(null);
  useClickAway(ref, onClickOutside);

  return (
    <div className='w-full mb-[5px] box-border relative' ref={ref}>
      <input
        id={fieldId}
        {...register(name)}
        {...{
          autoFocus,
          type,
          disabled,
          // onChange,
          // onFocus,
          // onBlur,
          // onKeyPress,
          placeholder,
          style: extraStyle,

          className: c(
            'h-[40px] text-left block w-full box-border font-normal text-[14px] leading-[22px] p-[10px_15px] border border-[var(--secondary)] caret-[var(--textMain60)] text-[var(--textMain60)] rounded-[37px] placeholder:text-[14px] placeholder:leading-[22px] placeholder:opacity-60 disabled:opacity-70',
            inputClassName,
            // 'bg-transparent',
            'app-form__text-field',
          ),
        }}
      />

      <div className='absolute h-[40px] top-0 right-[15px] flex items-center gap-[5px] text-[var(--textMain60)]'>
        {clearable && (
          <AppFormFieldClearButton onClick={onClearClick} fieldName={name} />
        )}
        {icon && (
          <AppIcon
            name={icon.name}
            size={icon.size || DEFAULT_ICON_SIZE}
            color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
          />
        )}
      </div>

      <AppFormFieldError {...{ name, showError, error }} />
    </div>
  );
};
