import { AppHasAccess } from '@/components/app-has-access';
import { AppTappable } from '@/components/app-tappable';
import { AppType, Maybe } from '@/core.types';
import { currencyFormatter } from '@/services/helper-service';
import { CategoryTileName } from './category-tile-name';

type CategoryGridTileDescriptionProps = {
  name: string;
  isEmpty: boolean;
  amount: number;
  currency: string;
  onClick(): void;
  createdBy: string;
  date: Maybe<string>;
};

export const CategoryGridTileDescription = ({
  name,
  isEmpty,
  amount,
  currency,
  onClick,
  createdBy,
  date,
}: CategoryGridTileDescriptionProps) => {
  return (
    <AppTappable onTap={onClick}>
      <div>
        <CategoryTileName {...{ isEmpty, name }} />
        <AppHasAccess appType={AppType.ireceipt}>
          <div className='text-[var(--textMain)] font-bold text-[12px] leading-[14px] tablet:text-[15px] tablet:leading-[16px]'>
            {currencyFormatter(currency, amount)}
          </div>
        </AppHasAccess>
        <div className='flex justify-between items-end'>
          <div className='text-[6px] leading-[7px] uppercase text-[var(--textInfo)] pt-[4px] tablet:text-[9px] tablet:leading-[11px]'>
            <span className='font-black'>BY</span> {createdBy}
          </div>
          {date && (
            <div className='text-[6px] leading-[7px] text-[var(--textMain60)] tablet:text-[9px] tablet:leading-[11px]'>
              {date}
            </div>
          )}
        </div>
      </div>
    </AppTappable>
  );
};
