import {
  APP_COLOR_THEME_TYPE,
  APP_CSS_THEME_VARIABLES,
} from '@/color-theme.constants';
import { useRootContext } from '@/context/root-context';
import { Maybe } from '@/core.types';
import { isMobileApp } from '@/services/helper-service';
import { Datepicker, locale } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';
import { DateType } from '@mobiscroll/react/dist/src/core/util/datetime';
import c from 'classnames';
import { ChangeEvent, useMemo } from 'react';
import { AppIcon } from '../app-icon';
import './mbs-overwrite.css';

const DEFAULT_ICON_SIZE = { width: 22, height: 22 };

type AppDatePickerProps = {
  value: DateType | null;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder: string;
  maxDate?: any;
  iconName: Maybe<string>;
  timeSelection?: boolean;
  iconSize?: typeof DEFAULT_ICON_SIZE;
};

const DATE_MASK = 'DD.MM.YYYY';

export const AppDatePicker = ({
  value,
  onChange,
  iconName,
  placeholder,
  maxDate = null,
  timeSelection = false,
  iconSize,
}: AppDatePickerProps) => {
  const { locale: appLocale, appThemeType } = useRootContext();

  const controls = useMemo(() => {
    const controlsList: string[] = [];
    if (timeSelection) {
      controlsList.push('time');
    }
    controlsList.push(isMobileApp() ? 'date' : 'calendar');

    return controlsList;
  }, [timeSelection]);

  return (
    <div className='date-picker-input__wrapper relative'>
      <Datepicker
        cssClass={c('w-full', {
          'app-mobile-date-picker': isMobileApp,
          'app-mobile-date-picker-light':
            appThemeType === APP_COLOR_THEME_TYPE.LIGHT,
        })}
        locale={locale[appLocale]}
        theme='ios'
        // @ts-ignore
        controls={controls}
        onChange={onChange}
        placeholder={placeholder}
        dateFormat={DATE_MASK}
        inputComponent='input'
        value={value}
        max={maxDate}
      />
      <div className='pointer-events-none absolute top-1/2 right-[15px] transform -translate-y-1/2 flex items-center gap-[5px] text-[var(--textMain60)]'>
        {iconName && (
          <AppIcon
            color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
            className='w-[30px] flex items-center justify-center pointer-events-none'
            name={iconName}
            size={iconSize || DEFAULT_ICON_SIZE}
          />
        )}
      </div>
    </div>
  );
};
