import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { Maybe } from '@/core.types';
import c from 'classnames';
import { SyntheticEvent, useLayoutEffect, useRef } from 'react';
import { AppIcon } from '../app-icon';
import { InputClearableButton } from './input-clearable-button';

export const DEFAULT_ICON_SIZE = { width: 22, height: 22 };
export const DEFAULT_CLEAR_ICON_SIZE = { width: 12, height: 12 };

type DefaultInputProps = {
  value: string | number;
  placeholder: string;
  iconName?: Maybe<string>;
  iconSize?: typeof DEFAULT_ICON_SIZE;
  type?: string;
  inputMode?: 'text' | 'decimal' | 'numeric';
  onChange(event: SyntheticEvent<HTMLInputElement>): void;
  inputClassName?: string;
  autofocus?: boolean;
  clearable?: boolean;
  onKeyPress?(event: SyntheticEvent<HTMLInputElement>): void;
  errorMessage?: string;
  hasError?: boolean;
  wrapperClassName?: string;
};

export const DefaultInput = ({
  value,
  placeholder,
  iconName = null,
  iconSize = DEFAULT_ICON_SIZE,
  type,
  inputMode = 'text',
  onChange,
  inputClassName = '',
  autofocus,
  clearable = true,
  onKeyPress,
  errorMessage,
  hasError = false,
  wrapperClassName = '',
}: DefaultInputProps) => {
  const inputRef = useRef<Maybe<HTMLInputElement>>(null);

  useLayoutEffect(() => {
    if (autofocus && inputRef?.current) {
      // now we can focus on the target input
      inputRef?.current?.focus();
    }
  }, [autofocus]);

  const extraStyle =
    !clearable && !iconName
      ? {}
      : { paddingRight: iconName && clearable ? 70 : 40 };

  return (
    <>
      <div
        className={c(
          'w-full m-auto relative box-border flex justify-between border-none rounded-[37px] bg-[var(--mainBackground)]',
          wrapperClassName,
        )}
      >
        <input
          ref={inputRef}
          onKeyPress={onKeyPress}
          className={c(
            'bg-transparent',
            'h-[40px] text-left block w-full box-border font-normal text-[14px] leading-[22px] p-[10px_15px] border border-[var(--secondary)] caret-[var(--textMain60)] text-[var(--textMain60)] rounded-[37px] placeholder:text-[14px] placeholder:leading-[22px] placeholder:opacity-60 disabled:opacity-70',
            {
              'p-[10px_75px_10px_15px]': !!iconName,
            },
            inputClassName,
          )}
          {...{
            disabled: iconName === 'calendar',
            inputMode,
            value,
            onChange,
            type,
            placeholder,
            readOnly: !onChange,
          }}
          style={extraStyle}
        />

        <div className='absolute top-1/2 right-[15px] transform -translate-y-1/2 flex items-center gap-[5px] text-[var(--textMain60)]'>
          <InputClearableButton
            {...{
              clearable,
              value,
              onChange,
              isSecondIcon: !!iconName,
            }}
          />
          {iconName && (
            <AppIcon
              className='default-input__icon'
              name={iconName}
              size={iconSize}
              color={`var(${APP_CSS_THEME_VARIABLES.textMain60})`}
            />
          )}
        </div>
      </div>
      {hasError && (
        <div className='w-full m-auto relative box-border flex justify-start max-w-[343px]'>
          <span className='ml-[5px] mt-[5px] text-[14px] text-[var(--mistakeStroke)]'>
            {errorMessage}
          </span>
        </div>
      )}
    </>
  );
};
