import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { useDetectMobileResolution } from '@/hooks/use-detect-mobile-resolution';
import { SettingsIcon } from '@/scenes/Settings/settings-icon';
import c from 'classnames';
import { ReactNode } from 'react';

type PlanBenefitProps = {
  title: ReactNode;
  description: string;
};

export const PlanBenefit = ({ title, description }: PlanBenefitProps) => {
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;
  const isMobile = useDetectMobileResolution();

  return (
    <div className='flex not:first:mt-[15px]'>
      <SettingsIcon
        className={c('h-fit mt-[2px]', { 'h-fit mt-[2px]': isMobile })}
        name='check'
        color={colorEmphasized}
        sizeType='custom'
      />

      <p className='mb-0 ml-[10px] flex flex-col'>
        <span className='font-bold text-[18px] leading-[21px] text-[var(--emphasized)]'>
          {title}
        </span>
        <span className='mt-[5px] font-normal text-[13px] leading-[15px] text-[var(--textMain)] opacity-60'>
          {description}
        </span>
      </p>
    </div>
  );
};
