import { useIntl } from 'react-intl';
import {
  PlanInformation,
  SubscriptionGroup,
  SubscriptionPeriod,
} from './app-plan.types';
import { PlanBenefit } from './plan-benefit';
import { PlanTile } from './plan-tile';

type PlanInfoProps = {
  planInfo: PlanInformation | null;
  planBenefits: {
    title: string;
    description: string;
  }[];
  subscriptionGroup: SubscriptionGroup;
  onTabTileClick(id: string): Promise<void>;
};

export const PlanInfo = ({
  planBenefits,
  planInfo,
  onTabTileClick,
  subscriptionGroup,
}: PlanInfoProps) => {
  const { formatMessage: t } = useIntl();

  const { maxAccounts, text } = {
    [SubscriptionGroup.plus]: {
      maxAccounts: 1,
      text: t({ id: 'menu.profile.plan.argument.2.personal.description' }),
      type: SubscriptionGroup.plus,
    },
    [SubscriptionGroup.premium]: {
      maxAccounts: 3,
      text: t({ id: 'menu.profile.plan.argument.2.family.description' }),
      type: SubscriptionGroup.premium,
    },
    [SubscriptionGroup.business]: {
      maxAccounts: 25,
      text: t({ id: 'menu.profile.plan.argument.2.business.description' }),
      type: SubscriptionGroup.business,
    },
  }[subscriptionGroup];

  if (!planInfo) {
    return null;
  }

  const {
    monthlyPrice,
    yearlyPrice,
    discount,
    currency,
    monthlyId,
    yearlyId,
    firstYearPrice,
  } = planInfo;

  return (
    <>
      <div className='list-none mt-[25px] mb-0 pl-0 flex justify-center gap-[20px]'>
        {subscriptionGroup !== SubscriptionGroup.premium && (
          <PlanTile
            price={monthlyPrice}
            {...{
              onTabTileClick,
              id: monthlyId,
              currency,
              period: SubscriptionPeriod.monthly,
            }}
          />
        )}

        <PlanTile
          price={yearlyPrice}
          discountPrice={firstYearPrice}
          isYearlyPlan
          {...{
            customDiscountLabel:
              subscriptionGroup === SubscriptionGroup.premium
                ? 'subscription.plan.first-yeah'
                : null,
            currency,
            onTabTileClick,
            discount,
            id: yearlyId,
            period: SubscriptionPeriod.yearly,
          }}
        />
      </div>

      <div className='list-none mt-[50px] mb-0 pl-0'>
        {planBenefits.map((benefit, index) => (
          <PlanBenefit
            key={benefit.title}
            title={benefit.title}
            description={benefit.description}
          />
        ))}
        {maxAccounts > 1 && (
          <PlanBenefit
            title={
              <>
                {t({ id: 'menu.profile.plan.argument.2.title' })}
                {maxAccounts}
                {t({ id: 'menu.profile.plan.argument.2.title-2' })}
              </>
            }
            description={text}
          />
        )}
      </div>
    </>
  );
};
