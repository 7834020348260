import { PAGE_ROUTES } from '@/app.constants';
import { applyColorTheme } from '@/color-theme.constants';
import { useRootContext } from '@/context/root-context';
import { CategoryId } from '@/core.types';
import { useGetUser } from '@/gql/api-user';
import { useDetectOnlineApplication } from '@/hooks/use-detect-online-application';
import { SwipeBack } from 'capacitor-plugin-ios-swipe-back';
import { useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import {
  fileDownloadQueueTable,
  requestTable,
} from '../services/indexed-db-service';
import {
  imageQueryQueueItemsCountAtom,
  queryQueueItemsCountAtom,
} from '../store';
import { useOpenWith } from './use-open-with';

export const useRootInit = (isAuthorized: boolean) => {
  const setQueryQueueItemsCount = useSetRecoilState(queryQueueItemsCountAtom);
  const setImageQueryQueueItemsCount = useSetRecoilState(
    imageQueryQueueItemsCountAtom,
  );
  const { userLoading, fetchUser } = useGetUser();
  const { pathname } = useLocation();
  const { setActiveSliceId, rootID, activeSliceId } = useRootContext();
  const { appThemeType, tempIdMap } = useRootContext();

  useEffect(() => {
    if (!window.cordova) {
      document.body.style.minHeight = '-webkit-fill-available';
    }

    // TODO update counters
    requestTable
      .count()
      .then((count: number) => setQueryQueueItemsCount(count));

    fileDownloadQueueTable
      .count()
      .then((count: number) => setImageQueryQueueItemsCount(count));
  }, [setImageQueryQueueItemsCount, setQueryQueueItemsCount]);

  useEffect(() => {
    if (isAuthorized) {
      fetchUser();
    }
  }, [fetchUser, isAuthorized]);

  const setThemeOnInit = () => {
    if (appThemeType) {
      applyColorTheme(appThemeType);
    }
  };

  useEffect(() => {
    const onChangePathName = async (newCategoryId?: CategoryId) => {
      if (!activeSliceId) return;

      const finalNewNewID = newCategoryId || rootID;
      if (finalNewNewID && finalNewNewID !== activeSliceId) {
        const finalId = tempIdMap[finalNewNewID] || finalNewNewID;
        setActiveSliceId(finalId);
      }
    };

    const handleHistoryPathName = ({ pathname }: { pathname: string }) => {
      if (
        pathname === PAGE_ROUTES.category ||
        PAGE_ROUTES.settings === pathname
      ) {
        SwipeBack.disable({ error: 0 });
      }
      if (
        !pathname.includes(PAGE_ROUTES.previewReceipt) &&
        !pathname.includes(PAGE_ROUTES.updateReceipt)
      ) {
        window.preloadImage = null;
      }
      if (
        !pathname.includes(PAGE_ROUTES.category) ||
        pathname.includes(PAGE_ROUTES.createReceipt) ||
        pathname.includes(PAGE_ROUTES.updateReceipt) ||
        pathname.includes(PAGE_ROUTES.previewReceipt) ||
        pathname.includes(PAGE_ROUTES.cropStep)
      ) {
        return;
      }

      const pathList = pathname
        .split('/')
        .filter((item) => !['/', 'category', ''].includes(item));

      const categoryIdFromURL = pathList[pathList.length - 1];
      const newId = Number.isNaN(Number(categoryIdFromURL))
        ? categoryIdFromURL
        : Number(categoryIdFromURL);
      onChangePathName(newId ?? rootID);
    };

    handleHistoryPathName({ pathname });
  }, [pathname, rootID, activeSliceId, tempIdMap, setActiveSliceId]);

  useOpenWith();
  useDetectOnlineApplication();

  useLayoutEffect(() => {
    setThemeOnInit();
  }, [setThemeOnInit]);

  return {
    userLoading,
  };
};
