import { AppForm } from '@/components/app-form';
import { AppFormPhoneField } from '@/components/app-form/app-form-phone-field';
import { AppFormSubmitButton } from '@/components/app-form/app-form-submit-button';
import { AppLoader } from '@/components/app-loader';
import {
  FORM_VALIDATION_RESOLVER,
  setFormServerErrors,
} from '@/scenes/common-auth-logic';
import { useIntl } from 'react-intl';
import 'react-phone-input-2/lib/style.css';
import { useHistory } from 'react-router';

import { PAGE_ROUTES } from '@/app.constants';
import { AppFormOtpCodeField } from '@/components/app-form/app-form-otp-code-field';
import {
  AuthPageImage,
  AuthPageLink,
  AuthPageTitle,
  AuthPageWrapper,
} from '@/components/auth-base';
import { useLoginBySmsMutation } from '@/graphql/codegen/graphql';
import { useBooleanState } from '@/hooks/use-boolean-state';
import { useRequestDeviceNotifications } from '@/hooks/use-request-device-notifications';
import { setAccountIdToken } from '@/services/auth-service';
import { useIsAuthorizedAtomState } from '@/store/root-store';
import { useState } from 'react';
import { useCountdownTimer } from '../hooks/use-countdown-timer';

export const OtpLogin = () => {
  const history = useHistory();
  const { formatMessage: t } = useIntl();
  const [phone, setPhone] = useState('');
  const [loginBySMS, { loading: loginBySMSLoading }] = useLoginBySmsMutation();
  const [codeSectionShown, showCodeSection, hideCodeSection] =
    useBooleanState(false);
  const requestDeviceNotifications = useRequestDeviceNotifications();
  const { setIsAuthorised } = useIsAuthorizedAtomState();

  const onLogoClick = () => {
    history.push(PAGE_ROUTES.auth);
  };

  const { countdown, start: startCountdownTimer } = useCountdownTimer({
    timer: 60,
    interval: 1,
    speed: 1000,
    onExpire: () => {
      setPhone('');
      hideCodeSection();
    },
  });

  const normalisePhone = (phoneValue: string) => phoneValue.replace(/ /g, '');

  const onCodeSubmit = async (
    { otpCode }: { otpCode: string },
    _: any,
    { setValue, setError },
  ) => {
    try {
      const {
        data: {
          loginBySMS: {
            token,
            account: { id },
          },
        },
      } = (await loginBySMS({
        variables: {
          code: otpCode,
          phone: normalisePhone(phone),
        },
      })) as any;

      // !Note- order is important here
      setAccountIdToken(id);
      await setIsAuthorised(token);
      requestDeviceNotifications();
      history.push(PAGE_ROUTES.category);
    } catch (errorResponse: any) {
      setFormServerErrors(errorResponse, setError);
    } finally {
      setValue('otpCode', '');
    }
  };

  const onPhoneSubmit = async (
    { phone }: { phone: string },
    _: any,
    { setValue, setError },
  ) => {
    try {
      await loginBySMS({
        variables: {
          phone: normalisePhone(phone),
        },
      });

      showCodeSection();
      startCountdownTimer();
      setPhone(phone);
    } catch (errorResponse: any) {
      setFormServerErrors(errorResponse, setError);
    } finally {
      setValue('phone', '');
    }
  };

  return (
    <AuthPageWrapper>
      <AuthPageImage onClick={onLogoClick} />
      {codeSectionShown ? (
        <>
          <AuthPageTitle>{t({ id: 'verify.h1' })}</AuthPageTitle>
          <p>{t({ id: 'verify.p' })}</p>
          <AuthPageTitle>{phone}</AuthPageTitle>

          <AppForm
            onSubmit={onCodeSubmit}
            className='w-full max-w-[300px]'
            formConfig={{ resolver: FORM_VALIDATION_RESOLVER }}
          >
            <AppFormOtpCodeField name='otpCode' />
            <AuthPageTitle>
              {t({ id: 'verify.receive' })}
              <span>
                {' '}
                {t({ id: 'verify.reSend' })} {countdown}{' '}
              </span>
            </AuthPageTitle>

            <AppFormSubmitButton>{t({ id: 'btn.verify' })}</AppFormSubmitButton>
          </AppForm>
        </>
      ) : (
        <>
          <AuthPageTitle>{t({ id: 'otp.h1' })}</AuthPageTitle>
          <AuthPageTitle>{t({ id: 'otp.p' })}</AuthPageTitle>
          <AppForm
            onSubmit={onPhoneSubmit}
            className='w-full max-w-[300px]'
            formConfig={{ resolver: FORM_VALIDATION_RESOLVER }}
          >
            <AppFormPhoneField name='phone' />
            <AppFormSubmitButton>
              {t({ id: 'btn.continue' })}
            </AppFormSubmitButton>
          </AppForm>
        </>
      )}
      <AuthPageLink onClick={history.goBack}>{t({ id: 'back' })} </AuthPageLink>
      {loginBySMSLoading && <AppLoader />}
    </AuthPageWrapper>
  );
};
