import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { DefaultInput } from '@/components';
import { AppModal } from '@/components/app-modal';
import { useRootContext } from '@/context/root-context';
import { ReceiptType } from '@/core.types';
import { useCreateCategory, useUpdateCategoryList } from '@/hooks';
import { categoryToEditAtom } from '@/store';
import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilCallback, useRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

type CreateCategoryModalProps = {
  open: boolean;
  onClose(): void;
};

export const CreateCategoryModal = ({
  open,
  onClose,
}: CreateCategoryModalProps) => {
  const { activeSliceSelector } = useContext(CategoryTreeContext);
  const { receiptType } = useRootContext();
  const activeSlice = activeSliceSelector();
  const createCategory = useCreateCategory();
  const updateCategoryList = useUpdateCategoryList();
  const [categoryToEdit, setCategoryToEdit] =
    useRecoilState(categoryToEditAtom);
  const [folderName, setFolderName] = useState(categoryToEdit?.name ?? '');
  const { formatMessage: t } = useIntl();
  const isEditMode = categoryToEdit !== null;

  const onCloseHandler = () => {
    setFolderName('');
    setCategoryToEdit(null);
    onClose();
  };

  const onSaveClick = useRecoilCallback(({ snapshot }) => () => {
    if (!activeSlice) {
      return;
    }

    !isEditMode
      ? createCategory({
          id: uuidv4(),
          name: folderName,
          parent_id: activeSlice.id,
          type: receiptType ?? ReceiptType.document,
        })
      : updateCategoryList([{ ...categoryToEdit, name: folderName }]);
    onCloseHandler();
  });

  const onFolderNameChange = ({ currentTarget: { value } }) => {
    setFolderName(value);
  };

  const onKeyPress = ({ charCode }: any) => {
    if (charCode === 13 && folderName !== '') {
      onSaveClick();
    }
  };

  if (!activeSlice) {
    return null;
  }

  return (
    <AppModal
      content={
        <DefaultInput
          onKeyPress={onKeyPress}
          autofocus
          iconSize={{ width: 22, height: 22 }}
          iconName='folder-open'
          placeholder={t({ id: 'name' })}
          value={folderName}
          onChange={onFolderNameChange}
        />
      }
      cancelButtonClassName='w-1/2'
      saveDisabled={!folderName}
      onSaveClick={onSaveClick}
      title={t({ id: !isEditMode ? 'new.folder' : 'save' })}
      {...{ open, onClose: onCloseHandler }}
    />
  );
};
