export const StyleIcon = ({
  size = { width: 20, height: 20 },
  color = 'white',
}) => {
  const { width, height } = size;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0)'>
        <path
          d='M13.805 14.6604C13.9055 14.3831 14.1994 14.0953 14.4793 14.0021C15.4267 13.6847 16.3173 13.1527 17.0715 12.3985C19.6865 9.78354 19.6866 5.54332 17.0718 2.92849C14.4567 0.313373 10.2168 0.313134 7.60144 2.92849C6.8472 3.68273 6.31562 4.57368 5.99843 5.52113C5.90462 5.80045 5.61745 6.09498 5.33961 6.19505C4.45948 6.51281 3.6338 7.02149 2.92942 7.72587C0.352895 10.3024 0.353134 14.4942 2.92966 17.0707C5.50591 19.647 9.69774 19.6472 12.2743 17.0707C12.9786 16.3663 13.4871 15.5404 13.805 14.6604ZM5.17543 7.28614C5.44251 7.16039 5.64571 7.31989 5.64356 7.61485C5.6307 9.34476 6.28258 11.0781 7.6024 12.3979C8.92212 13.7176 10.6552 14.3692 12.3851 14.3564C12.6802 14.3544 12.8398 14.5576 12.7139 14.8246C12.4454 15.3961 12.0785 15.9314 11.6072 16.4027C9.39905 18.6109 5.80579 18.6114 3.59756 16.4032C1.38914 14.1948 1.38962 10.6015 3.5978 8.39329C4.06914 7.92195 4.60421 7.55489 5.17543 7.28614Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect width={width} height={height} fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
