import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { AppIcon } from '@/components';
import { AppButton } from '@/components/app-button';
import { AppModal } from '@/components/app-modal';
import { CategoryId, LocalCategory } from '@/core.types';
import { useBooleanState } from '@/hooks';
import c from 'classnames';
import { useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import { CreateReceiptModalTreeBreadcrumbsItem } from './create-receipt-modal-tree-breadcrumbs-item';
import { CreateReceiptModalTreeFolder } from './create-receipt-modal-tree-folder';
import { CreateReceiptModalTreeFolderInput } from './create-receipt-modal-tree-folder-input';

type CreateReceiptModalTreeProps = {
  treeModalOpened: boolean;
  closeTreeModal(): void;
};

export const CreateReceiptModalTree = ({
  treeModalOpened,
  closeTreeModal,
}: CreateReceiptModalTreeProps) => {
  const { formatMessage: t } = useIntl();
  const treeFoldersRef = useRef<null | HTMLDivElement>(null);
  const { folderPathSelector, activeSliceCategoryListSelectorFamily } =
    useContext(CategoryTreeContext);
  const foldersPath = folderPathSelector();
  const [
    createFolderModeEnabled,
    enableCreateFolderMode,
    disableCreateFolderMode,
  ] = useBooleanState(false);

  const foldersList = activeSliceCategoryListSelectorFamily();

  const scrollDownListContainer = () => {
    if (treeFoldersRef.current) {
      treeFoldersRef.current.scrollTo({
        top: treeFoldersRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const onCloseClick = () => {
    disableCreateFolderMode();
    closeTreeModal();
  };

  return (
    <AppModal
      className='h-[410px] overflow-hidden relative box-border'
      onSaveClick={onCloseClick}
      open={treeModalOpened}
      onClose={onCloseClick}
      content={
        <>
          <div className='w-full flex items-center justify-start mb-4 min-h-[22px]'>
            {foldersPath.map((id: CategoryId) => (
              <CreateReceiptModalTreeBreadcrumbsItem key={id} id={id} />
            ))}
          </div>
          <div ref={treeFoldersRef} className='overflow-y-auto h-[215px]'>
            {foldersList?.map((category: LocalCategory, index) => {
              return (
                <CreateReceiptModalTreeFolder {...{ category }} key={index} />
              );
            })}
            {createFolderModeEnabled && (
              <CreateReceiptModalTreeFolderInput
                {...{
                  closeInput: disableCreateFolderMode,
                  scrollDownListContainer,
                }}
              />
            )}
          </div>
          <AppButton
            className={c(
              'flex items-center justify-center text-[14px] p-2.5 mt-2 border-none bg-secondary rounded-[10px] text-white min-h-[22px] w-[92%] box-content',
            )}
            onPress={enableCreateFolderMode}
          >
            <AppIcon
              name='folder-open'
              size={{ width: 22, height: 16 }}
              className='pr-[18px]'
            />
            {t({ id: 'create.new.folder' })}
          </AppButton>
        </>
      }
      title={t({ id: 'saveTo' })}
    />
  );
};
