import { AppFooter } from '@/components/app-footer';
import type { ReactNode } from 'react';

export const SettingsWrap = ({ children }: { children: ReactNode }) => {
  return (
    <div className='w-full h-[calc(100vh-var(--sat)-var(--sab))] flex flex-col'>
      {children}
      <AppFooter />
    </div>
  );
};
