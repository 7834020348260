// @ts-nocheck
import { toast } from 'react-toastify';
import { NAVIGATOR_LANG_MAP } from '@/app.constants';
import { IMAGE_PREFIX } from '@/core.types';
import { SwipeBack } from 'capacitor-plugin-ios-swipe-back';
import CurrencyList from '../currencies.json';

export const createObjectURL = (file) => {
  const blob = new Blob([file], { type: file.type });
  if (window.webkitURL) {
    return window.webkitURL.createObjectURL(blob);
  } else if (window.URL && window.URL.createObjectURL) {
    return window.URL.createObjectURL(blob);
  } else {
    return null;
  }
};

// User Agent detection
export const userAgent = (() => {
  const ua = window.navigator.userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE ' + (tem[1] || '');
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge?)\/(\d+)/);
    if (tem != null)
      return tem
        .slice(1)
        .join(' ')
        .replace('OPR', 'Opera')
        .replace('Edg ', 'Edge ');
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);

  return M[0];
})();

// Cordova path resolver
export const pathForCordova = (() =>
  userAgent === 'Chrome' ? './android_asset/www/' : `${window.origin}/`)();

// Resolve SVG path
export const resolvePathSvg = (iconPath) => {
  if (!iconPath) {
    return iconPath;
  }
  return window.cordova ? iconPath.replace('./', pathForCordova) : iconPath;
};

// Parse boolean environment variable
export const parseBoolEnv = (env) => env === 'true';

// Get temporary path
export const getTempPath = (path) => {
  if (!path) {
    return null;
  }
  return parseBoolEnv(import.meta.env.VITE_EMULATE_VISIONKIT)
    ? path
    : window.Ionic.WebView.convertFileSrc(
        window.cordova.file.tempDirectory + path,
      );
};

// Resolve file path
export const resolveFilePath = (path) => {
  if (!path) return null;

  if (
    path.includes('ireceipt-file-bucket') &&
    !path.includes('https://storage.googleapis.com')
  ) {
    return `https://storage.googleapis.com/${path}`;
  }

  if (
    path.includes('http') ||
    path.includes('/var/mobile') ||
    path.includes('blob:app') ||
    path.includes('capacitor') ||
    path.includes('https://storage.googleapis.com')
  ) {
    return path;
  }

  if (path.includes(IMAGE_PREFIX.local_stub) && !path.includes('http')) {
    return `https://localhost:3000/${path}`;
  }

  if (path.includes(IMAGE_PREFIX.rl_scan)) {
    return window.Ionic.WebView.convertFileSrc(
      window.cordova.file.documentsDirectory + path,
    );
  }

  const prefixPath = path.startsWith('/') ? path : `/${path}`;
  return `${import.meta.env.VITE_WEBSITE_URL}${prefixPath}`;
};

// Validate email
export const isValidEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

// Extract validation error from GraphQL response
export const validationError = (error) => {
  if (error && error.graphQLErrors[0]) {
    const errorObj = error.graphQLErrors[0];
    if (errorObj && errorObj.field) {
      return { [errorObj.field]: errorObj.message };
    } else {
      return errorObj.message;
    }
  }
  return '';
};

// Convert Unix time to string date format
export const unixTimeToString = (date) =>
  new Date(date * 1000).toLocaleDateString().replace(/\//gi, '.');

// Convert canvas to Blob
export const getBlob = (canvas) =>
  new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve({ url: createObjectURL(file), file });
    }, 'image/jpeg');
  });

// Get image width and height based on max size
export const getImageWidthAndHeight = (image, max_size) => {
  let width = image.width;
  let height = image.height;

  if (max_size && width > height) {
    if (width > max_size) {
      height *= max_size / width;
      width = max_size;
    }
  } else {
    if (max_size && height > max_size) {
      width *= max_size / height;
      height = max_size;
    }
  }

  return { width, height };
};

// Convert HTMLImageElement to Canvas
export const HTMLImageElementToCanvas = (image, max_size) => {
  const canvas = document.createElement('canvas');

  const { width, height } = getImageWidthAndHeight(image, max_size);

  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  return canvas;
};

// Convert image URL to Canvas and return Data URL
export const urlToCanvas = async (url) => {
  const image = await createImage(url);

  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;

  const ctx = canvas.getContext('2d');
  ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

  return canvas.toDataURL('image/png');
};

// Load image from URL
export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = url;
  });

// Get the number of days in a specific month
export const daysInMonth = (month, year) => new Date(year, month, 0).getDate();

// Get the current month's period (start and end dates)
export const getMonthPeriod = () => {
  const date = new Date();
  const dateFrom = new Date(date.getFullYear(), date.getMonth(), 1);

  const dateTo = new Date(
    date.getFullYear(),
    date.getMonth(),
    daysInMonth(date.getMonth() + 1, date.getFullYear()),
  );

  return {
    dateFrom,
    dateTo,
  };
};

// Get the period for the previous month
export const getPrevMonthPeriod = (prevMonthCounter) => {
  const date = new Date();
  const dateFrom = new Date(
    date.getFullYear(),
    date.getMonth() - prevMonthCounter,
    1,
  );

  const dateTo = new Date(
    date.getFullYear(),
    date.getMonth() - 1,
    daysInMonth(date.getMonth(), date.getFullYear()),
  );

  return {
    dateFrom,
    dateTo,
  };
};

export const isFileUrlPdf = (url) => String(url).includes('pdf');

export const detectDesktopApplication = () =>
  !(
    window.cordova ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  );

export const copyToClipboard = async ({ link, message }) => {
  await navigator.clipboard.writeText(link);
  toast(message);
};

export const getArraySum = (array) => array.reduce((a, b) => a + b, 0);

export const detectAndroid = () =>
  navigator.userAgent.match(/Android/i) !== 'Android' && window.cordova;

export const getBase64FromBlob = (blob: Blob | File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      resolve(this.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const getBase64FromUrl = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();

  return getBase64FromBlob(blob);
};

export const getBlobFromUrl = async (url) => {
  const response = await fetch(url);
  return response.blob();
};

export function getBase64FromImageOrPdfFiles(url: string) {
  return new Promise((resolve) => {
    const image = new Image();
    image.src = url;
    image.crossOrigin = 'anonymous';
    image.onload = async () => {
      const canvas = HTMLImageElementToCanvas(image);

      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
      const base64String = canvas.toDataURL();

      resolve(base64String);
    };
  });
}

export const delay = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

// Helper function to stop event propagation
const stopPropagation = (event: SyntheticEvent) => {
  event.stopPropagation();
};

// Modified addClickHandler function
export const addClickHandler = (onClick: (event: SyntheticEvent) => void) => {
  const handler = (event: SyntheticEvent) => {
    stopPropagation(event);
    onClick(event);
  };

  return window.cordova ? { onTouchEnd: handler } : { onClick: handler };
};

export const lockScreenOrientation = (orientation = 'portrait') =>
  window.cordova && window.screen?.orientation?.lock(orientation);

export const detectLocalImagePath = (imagePath?: string) =>
  !!imagePath &&
  (imagePath.includes(IMAGE_PREFIX.local_stub) ||
    imagePath.includes(IMAGE_PREFIX.rl_scan));

export const getLocalImage = (src: string) => {
  return new Promise<{ width: number; height: number }>((resolve) => {
    const image = new Image();
    image.src = src;
    image.onload = function () {
      resolve(image);
    };
  });
};

export const preloadLocalImage = (src: string) => {
  return new Promise<{ width: number; height: number }>((resolve) => {
    const img = document.createElement('img');
    img.style.cssText = 'position:absolute;opacity:0.01;top:0';
    img.src = src;
    document.body.appendChild(img);
    img.onload = () => {
      const resolution = img.height * img.width;
      const timeout = resolution / 70000;
      setTimeout(() => {
        resolve({ width: img.width, height: img.height });
        img.remove();
      }, timeout);
    };
  });
};

export const getUnixTimestampFromDate = (date: Date) =>
  Math.floor(date.getTime() / 1000);

export const getDateFromUnixTimestamp = (stamp: number) =>
  new Date(stamp * 1000);

export const unlockBackSwipe = async () => {
  if (window?.cordova) {
    await SwipeBack?.enable({ error: 0 });
  }
};

export const currencyFormatter = (
  currency: string | null = null,
  amount: number,
) => {
  const currencyItem = CurrencyList.find(
    ({ abbreviation }) => abbreviation === currency,
  );

  return `${currencyItem?.symbol} ${amount.toFixed(2)}`;
};

export const isMobileApp = () => Capacitor.getPlatform() !== 'web';

const stringHash = (str) => {
  let hash = 5381; // Initial value for DJB2 algorithm
  for (let i = 0; i < str.length; i++) {
    hash = (hash * 33) ^ str.charCodeAt(i);
  }
  return hash >>> 0; // Convert to a 32-bit unsigned integer
};

// Helper function to generate a hash from an object
export const generateHash = (obj) => {
  // Convert the object to a JSON string
  const jsonString = JSON.stringify(obj, Object.keys(obj).sort()); // Sort keys for consistent hashing
  // Hash the string
  return stringHash(jsonString).toString(16); // Return as hex string
};

export const getUnixTimeDates = ({
  dateFrom,
  dateTo,
}: {
  dateFrom: Maybe<Date>;
  dateTo: Maybe<Date>;
}) => {
  const { dateFrom: defaultDateFrom, dateTo: defaultDateTo } = getMonthPeriod();
  dateFrom = dateFrom || defaultDateFrom;
  dateTo = dateTo || defaultDateTo;
  const ONE_DAY = 1000 * 60 * 60 * 24 - 1;
  return {
    dateFrom: getUnixTimestampFromDate(dateFrom),
    dateTo: Math.floor((dateTo.getTime() + ONE_DAY) / 1000),
  };
};
