import { useIntl } from 'react-intl';
import { AppBackButton } from '../components/app-back-button';
import { AppForwardButton } from '../components/app-forward-button';
import { useCropStepContext } from './crop-step-context';

export const CropStepFooter = () => {
  const { onBackClick, onNextClick } = useCropStepContext();
  const { formatMessage: t } = useIntl();
  return (
    <div className='w-full relative z-20 h-[72px] pl-4 pr-4 box-border flex justify-between items-center bg-primary tablet:h-24'>
      <AppBackButton {...{ onBackClick, label: t({ id: 'back' }) }} />
      <AppForwardButton {...{ onNextClick, label: t({ id: 'next' }) }} />
    </div>
  );
};
