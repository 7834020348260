export default {
  uk: {
    'auth.p': 'Ласкаво просимо до сховища ваших документів',
    'menu.profile.plan.description':
      'Зберігайте та діліться своїми документами, створюйте папки та діліться папками у форматі PDF або посиланням на PDF',
    'menu.profile.plan.argument.1.description':
      'зберігайте необмежену кількість документів та маєте до них доступ з будь-якого пристрою',
    'menu.profile.plan.argument.2.personal.description':
      'створіть свій особистий обліковий запис та контролюйте свої документи',
    'menu.profile.plan.subtitle.1': 'Безкоштовна пробна версія до ',
    'menu.profile.plan.subtitle.2': ' документів',
    'menu.recognition': 'Розпізнавання документів',
  },

  ru: {
    'auth.p': 'Добро пожаловать в хранилище ваших документов',
    'menu.profile.plan.description':
      'Сохраняйте и делитесь своими документами, создавайте папки и делитесь папками в формате PDF или ссылкой на PDF',
    'menu.profile.plan.argument.1.description':
      'сохраняйте неограниченное количество документов и получайте к ним доступ с любого устройства',
    'menu.profile.plan.argument.2.personal.description':
      'создайте свою личную учетную запись и контролируйте свои документы',
    'menu.profile.plan.subtitle.1': 'Бесплатное пробное использование до ',
    'menu.profile.plan.subtitle.2': ' документов',
    'menu.recognition': 'Распознавание документов',
  },
  en: {
    'auth.p': 'Welcome to your documents',
    'menu.profile.plan.description':
      'Save & share your documents, create folders & share folders as a PDF or as a link to PDF',
    'menu.profile.plan.argument.1.description':
      'save unlimited number of documents and have access to them from any device',
    'menu.profile.plan.argument.2.personal.description':
      'create your personal account and have control over your documents',
    'menu.profile.plan.subtitle.1': 'Free trial up to ',
    'menu.profile.plan.subtitle.2': ' documents',
    'menu.recognition': 'Document recognition',
  },
  no: {
    'auth.p': 'Velkommen til dine dokumenter',
    'menu.profile.plan.description':
      'Lagre & del dine dokumenter, opprett mapper & del mapper som en PDF eller som en lenke til PDF',
    'menu.profile.plan.argument.1.description':
      'lagre et ubegrenset antall dokumenter og ha tilgang til dem fra enhver enhet',
    'menu.profile.plan.argument.2.personal.description':
      'opprett din personlige konto og ha kontroll over dokumentene dine',
    'menu.profile.plan.subtitle.1': 'Gratis prøveversjon opp til ',
    'menu.profile.plan.subtitle.2': ' dokumenter',
    'menu.recognition': 'Dokumentgjenkjenning',
  },
  pt: {
    'auth.p': 'Bem-vindo aos seus documentos',
    'menu.profile.plan.description':
      'Salve & compartilhe seus documentos, crie pastas & compartilhe pastas como PDF ou como link para PDF',
    'menu.profile.plan.argument.1.description':
      'salve um número ilimitado de documentos e tenha acesso a eles de qualquer dispositivo',
    'menu.profile.plan.argument.2.personal.description':
      'crie sua conta pessoal e tenha controle sobre seus documentos',
    'menu.profile.plan.subtitle.1': 'Teste gratuito de até ',
    'menu.profile.plan.subtitle.2': ' documentos',
    'menu.recognition': 'Reconhecimento de documentos',
  },
  pl: {
    'auth.p': 'Witaj w swoich dokumentach',
    'menu.profile.plan.description':
      'Zapisz i udostępnij swoje dokumenty, twórz foldery i udostępniaj foldery jako PDF lub jako link do PDF',
    'menu.profile.plan.argument.1.description':
      'zapisz nieograniczoną liczbę dokumentów i miej do nich dostęp z dowolnego urządzenia',
    'menu.profile.plan.argument.2.personal.description':
      'utwórz osobiste konto i kontroluj swoje dokumenty',
    'menu.profile.plan.subtitle.1': 'Darmowy okres próbny do ',
    'menu.profile.plan.subtitle.2': ' dokumentów',
    'menu.recognition': 'Rozpoznawanie dokumentów',
  },
  fi: {
    'auth.p': 'Tervetuloa tutustumaan asiakirjoihin',
    'menu.profile.plan.description':
      'Tallenna & jaa dokumenttisi, luo kansioita & jaa kansioita PDF:nä tai linkkinä PDF:ään',
    'menu.profile.plan.argument.1.description':
      'tallenna rajaton määrä asiakirjoja ja pääse niihin käsiksi mistä tahansa laitteesta',
    'menu.profile.plan.argument.2.personal.description':
      'luo henkilökohtainen tilisi ja hallitse dokumenttejasi',
    'menu.profile.plan.subtitle.1': 'Ilmainen kokeilu jopa ',
    'menu.profile.plan.subtitle.2': ' dokumenttiin',
    'menu.recognition': 'Asiakirjojen tunnistaminen',
  },
  se: {
    'auth.p': 'Välkommen till dina dokument',
    'menu.profile.plan.description':
      'Spara & dela dina dokument, skapa mappar & dela mappar som en PDF eller som en länk till PDF',
    'menu.profile.plan.argument.1.description':
      'spara obegränsat antal dokument och ha tillgång till dem från vilken enhet som helst',
    'menu.profile.plan.argument.2.personal.description':
      'skapa ditt personliga konto och kontrollera dina dokument',
    'menu.profile.plan.subtitle.1': 'Gratis provversion upp till ',
    'menu.profile.plan.subtitle.2': ' dokument',
    'menu.recognition': 'Dokumentigenkänning',
  },
  it: {
    'auth.p': 'Benvenuto tra i tuoi documenti',
    'menu.profile.plan.description':
      'Salva & condividi i tuoi documenti, crea cartelle & condividi cartelle come PDF o come link al PDF',
    'menu.profile.plan.argument.1.description':
      'salva un numero illimitato di documenti e accedici da qualsiasi dispositivo',
    'menu.profile.plan.argument.2.personal.description':
      'crea il tuo account personale e controlla i tuoi documenti',
    'menu.profile.plan.subtitle.1': 'Prova gratuita fino a ',
    'menu.profile.plan.subtitle.2': ' documenti',
    'menu.recognition': 'Riconoscimento dei documenti',
  },
  fr: {
    'auth.p': 'Bienvenue dans vos documents',
    'menu.profile.plan.description':
      'Enregistrez & partagez vos documents, créez des dossiers & ' +
      'partagez des dossiers en PDF ou en tant que lien vers un PDF',
    'menu.profile.plan.argument.1.description':
      "enregistrez un nombre illimité de documents et accédez-y depuis n'importe quel appareil",
    'menu.profile.plan.argument.2.personal.description':
      'créez votre compte personnel et contrôlez vos documents',
    'menu.profile.plan.subtitle.1': "Essai gratuit jusqu'à ",
    'menu.profile.plan.subtitle.2': ' documents',
    'menu.recognition': 'Reconnaissance de documents',
  },
  es: {
    'auth.p': 'Bienvenida a sus documentos',
    'menu.profile.plan.description':
      'Guardar & compartir tus documentos, crear carpetas & compartir carpetas como un PDF o como un enlace a PDF',
    'menu.profile.plan.argument.1.description':
      'guarda un número ilimitado de documentos y accede a ellos desde cualquier dispositivo',
    'menu.profile.plan.argument.2.personal.description':
      'crea tu cuenta personal y controla tus documentos',
    'menu.profile.plan.subtitle.1': 'Prueba gratuita de hasta ',
    'menu.profile.plan.subtitle.2': ' documentos',
    'menu.recognition': 'Reconocimiento de documentos',
  },
  de: {
    'auth.p': 'Willkommen bei Ihren Dokumenten',
    'menu.profile.plan.description':
      'Speichern & teilen Sie Ihre Dokumente, erstellen Sie Ordner & teilen Sie Ordner als PDF oder als Link zu einem PDF',
    'menu.profile.plan.argument.1.description':
      'speichern Sie eine unbegrenzte Anzahl von Dokumenten und greifen Sie von jedem Gerät darauf zu',
    'menu.profile.plan.argument.2.personal.description':
      'erstellen Sie Ihr persönliches Konto und kontrollieren Sie Ihre Dokumente',
    'menu.profile.plan.subtitle.1': 'Kostenlose Probe bis zu ',
    'menu.profile.plan.subtitle.2': ' Dokumente',
    'menu.recognition': 'Dokumentenerkennung',
  },
  da: {
    'auth.p': 'Velkommen til dine dokumenter',
    'menu.profile.plan.description':
      'Gem & del dine dokumenter, opret mapper & del mapper som en PDF eller som et link til PDF',
    'menu.profile.plan.argument.1.description':
      'gem et ubegrænset antal dokumenter og få adgang til dem fra enhver enhed',
    'menu.profile.plan.argument.2.personal.description':
      'opret din personlige konto og kontroller dine dokumenter',
    'menu.profile.plan.subtitle.1': 'Gratis prøveversion op til ',
    'menu.profile.plan.subtitle.2': ' dokumenter',
    'menu.recognition': 'Dokumentgenkendelse',
  },
};
