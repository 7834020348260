// @ts-nocheck
import identity from 'lodash/identity';

export const FILTER_TYPE = {
  original: 'original',
  gray: 'gray',
  bw: 'bw',
  megaFilter: 'megaFilter',
};

export const INITIAL_FILTERS = {
  rotateDegree: 0,
  contrast: 1,
  brightness: 0,
  filterType: FILTER_TYPE.original,
};

export class ImageFilters {
  static FILTER_TYPE_METHOD_MAP = {
    [FILTER_TYPE.original]: identity,
    [FILTER_TYPE.gray]: ImageFilters.applyGrayFilter,
    [FILTER_TYPE.bw]: ImageFilters.applyBWFilter,
    [FILTER_TYPE.megaFilter]: ImageFilters.applyMegaFilter,
  };

  static changeFilterIntensity = ({ brightness, contrast }) => {
    return (matrix) => {
      const newMatrix = new window.cv.Mat();
      window.cv.convertScaleAbs(matrix, newMatrix, contrast, brightness);
      return newMatrix;
    };
  };

  static applyMegaFilter(matrix) {
    const newMatrix = new window.cv.Mat();
    window.cv.cvtColor(matrix, newMatrix, window.cv.COLOR_BGR2GRAY);
    window.cv.adaptiveThreshold(
      newMatrix,
      newMatrix,
      255,
      window.cv.ADAPTIVE_THRESH_GAUSSIAN_C,
      window.cv.THRESH_BINARY,
      21,
      15,
    );
    return newMatrix;
  }

  static rotate(rotateDegree) {
    return (matrix) => {
      if (rotateDegree === 0) {
        return matrix;
      }

      const rotateCount = rotateDegree / 90;
      const newMatrix = new window.cv.Mat();
      for (let i = 0; i < rotateCount; i++) {
        window.cv.rotate(matrix, newMatrix, 0);
        matrix = newMatrix;
      }
      return newMatrix;
    };
  }

  static applyBWFilter(matrix) {
    const newMatrix = new window.cv.Mat();
    const low = new window.cv.Mat(
      matrix.rows,
      matrix.cols,
      matrix.type(),
      [0, 0, 0, 0],
    );
    const high = new window.cv.Mat(
      matrix.rows,
      matrix.cols,
      matrix.type(),
      [150, 150, 150, 255],
    );

    window.cv.inRange(matrix, low, high, newMatrix);
    window.cv.bitwise_not(newMatrix, newMatrix);
    return newMatrix;
  }

  static applyGrayFilter(matrix) {
    const newMatrix = new window.cv.Mat();
    window.cv.cvtColor(matrix, newMatrix, window.cv.COLOR_RGBA2GRAY, 0);
    return newMatrix;
  }
}
