import { useSaveDeviceInfo } from '@/gql/api-app';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';

export const useRequestDeviceNotifications = () => {
  const { saveDeviceInfo } = useSaveDeviceInfo();

  const getDeviceInfo = async () => {
    const {
      operatingSystem: os,
      model,
      osVersion,
      platform,
      realDiskFree: diskFree,
      name,
    } = await Device.getInfo();
    const { uuid } = await Device.getId();

    return { uuid, os, osVersion, model, platform, diskFree, name };
  };

  return () => {
    if (!window.cordova) {
      return;
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        getDeviceInfo().then((info) => {
          saveDeviceInfo({ variables: { ...info, timezone } });
        });
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', async (token: Token) => {
      const deviceInfo = await getDeviceInfo();
      saveDeviceInfo({
        variables: { ...deviceInfo, pushToken: token.value, timezone },
      });
    });
  };
};
