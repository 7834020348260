import { useRootContext } from '@/context/root-context';
import { useGetUser } from '@/gql/api-user';
import {
  useAddEmailMutation,
  useDeleteEmailMutation,
} from '@/graphql/codegen/graphql';

export const useChangeEmail = () => {
  const { fetchUser } = useGetUser();
  const { currentUser } = useRootContext();

  const [_mutationAddEmail, { loading: processingAddEmail }] =
    useAddEmailMutation();
  const [_mutationDeleteEmail, { loading: processingDeleteEmail }] =
    useDeleteEmailMutation();

  const addEmail = async (email: string) => {
    try {
      await _mutationAddEmail({
        variables: { email },
      });
      fetchUser();
    } catch (error) {
      console.warn(error);
    }
  };

  const deleteEmail = async (id: number) => {
    try {
      await _mutationDeleteEmail({
        variables: { id },
      });
      fetchUser();
    } catch (error) {
      console.warn(error);
    }
  };

  return {
    loading: processingDeleteEmail || processingAddEmail,
    dataEmails: currentUser,
    deleteEmail,
    addEmail,
  };
};
