import c from 'classnames';
import noop from 'lodash/noop';
import { addClickHandler } from '@/services/helper-service';
import { SyntheticEvent } from 'react';

type AppPseudoRadioProps = {
  onClick?(e: SyntheticEvent): void;
  isItemActive: boolean;
  className?: string;
};

export const AppPseudoRadio = ({
  isItemActive,
  className = '',
  onClick = noop,
}: AppPseudoRadioProps) => {
  return (
    <div
      {...addClickHandler(onClick ?? noop)}
      className={c(
        'w-[24px] h-[24px] rounded-full bg-[var(--radioBackground)] border border-[var(--emphasized)] opacity-70 box-border shadow-[0_0_10px_var(--textMain20)] relative transition-[0.2s_linear]',
        {
          "opacity-100 border border-[var(--emphasized)] box-border before:content-[''] before:block before:absolute before:w-[9px] before:h-[9px] before:bg-[var(--emphasized)] before:top-1/2 before:left-1/2 before:transform before:-translate-x-1/2 before:-translate-y-1/2 before:rounded-full":
            isItemActive,
        },
        className,
      )}
    />
  );
};
