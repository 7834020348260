import { AppIcon } from '@/components';
import { AppButton } from '@/components/app-button';
import { useRootContext } from '@/context/root-context';
import type { LocalCategory } from '@/core.types';
import { useBooleanState } from '@/hooks';
import { addClickHandler } from '@/services/helper-service';
import { CreateReceiptModalTreeFolderInput } from './create-receipt-modal-tree-folder-input';

type CreateReceiptModalTreeFolderProps = {
  category: LocalCategory;
};

export const MOBILE_FOLDER_ICON_SIZE = { width: 27, height: 20 };
const MOBILE_PENCIL_ICON_SIZE = { width: 10, height: 10 };

export const CreateReceiptModalTreeFolder = ({
  category,
}: CreateReceiptModalTreeFolderProps) => {
  const { receiptType } = useRootContext();
  const { setActiveSliceId } = useRootContext();
  const [editModeEnabled, enableEditMode, closeEditMode] =
    useBooleanState(false);

  const treeFolderIconSize = MOBILE_FOLDER_ICON_SIZE;
  const pencilIconSize = MOBILE_PENCIL_ICON_SIZE;

  const onFolderIconClick = () => {
    setActiveSliceId(category.id);
  };

  if (category.type !== receiptType) {
    return null;
  }

  return (
    <div className='flex items-center justify-between max-h-[350px] overflow-y-auto border-b border-[#e9e9e9] mb-2 w-[97%] pb-2'>
      {!editModeEnabled && (
        <div
          className='flex items-center flex-grow'
          {...addClickHandler(onFolderIconClick)}
        >
          <AppIcon
            name='green-folder-non-empty'
            size={treeFolderIconSize}
            id={category.id}
          />
          <div className='text-[16px] pl-5 text-textMain cursor-pointer'>
            {category.name}
          </div>
        </div>
      )}
      {editModeEnabled && (
        <CreateReceiptModalTreeFolderInput
          {...{ category, closeInput: closeEditMode }}
        />
      )}
      {!editModeEnabled && (
        <AppButton
          onPress={enableEditMode}
          className='tree-folder__edit-button'
        >
          <AppIcon name='edit-pencil' size={pencilIconSize} color='#777' />
        </AppButton>
      )}
    </div>
  );
};
