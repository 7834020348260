import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { CategoryId } from '@/core.types';
import { selectedCategoryIDsAtom } from '@/store';
import c from 'classnames';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { useCategoryDndLogic } from './use-category-dnd-logic';
import { useRootContext } from '@/context/root-context';

type CategoryDropzoneItemProps = {
  id: CategoryId;
  label: string;
};

export const CategoryDropzones = () => {
  const { activeSliceId, rootID } = useRootContext();
  const { getCategoryById } = useContext(CategoryTreeContext);
  const category = getCategoryById(activeSliceId);
  const { formatMessage: t } = useIntl();

  const dropzoneItems = [
    {
      id: rootID,
      label: t({ id: 'move.home' }),
    },
    {
      id: category.parentId,
      label: t({ id: 'move.parent' }),
    },
  ];
  return (
    <div className='flex items-center justify-between gap-[10px] mb-[30px] pt-[15px] laptop:pt-[30px]'>
      {dropzoneItems.map((dropzone, index) => (
        <CategoryDropzoneItem {...dropzone} key={index} />
      ))}
    </div>
  );
};

export const CategoryDropzoneItem = ({
  id,
  label,
}: CategoryDropzoneItemProps) => {
  const selectedCategoryIdsList = useRecoilValue(selectedCategoryIDsAtom);
  const { drop, isOver } = useCategoryDndLogic(id);

  return (
    <div
      className={c(
        'border-[2px] border-dashed border-grey min-h-[36px] rounded-[36px] flex-grow text-center text-grey leading-[18px] cursor-pointer text-[14px] flex justify-center items-center text-[var(--textInfo)]',
        {
          'category-list__dropzone-item--active': isOver,
          'opacity-[0.45]': isOver && !selectedCategoryIdsList.includes(id),
        },
      )}
      ref={drop}
    >
      {label}
    </div>
  );
};
