import { AppSubscriptionId } from '@/core.types';

export enum SubscriptionGroup {
  plus = 'plus',
  premium = 'premium',
  business = 'business',
}

export type PlanTileDTO = {
  type: SubscriptionGroup;
};

export type PlanInformation = {
  monthlyPrice: number;
  yearlyPrice: number;
  currency: string;
  discount?: number;
  monthlyId: string;
  yearlyId: string;
  firstYearPrice?: number | string;
  yearSubscriptionMonthlyPrice?: number;
};

export enum SubscriptionPeriod {
  monthly = 'monthly',
  yearly = 'yearly',
}

export type SubscriptionPlansList = Record<SubscriptionGroup, PlanInformation>;

export const mockedPlansInfo = {
  [SubscriptionGroup.plus]: {
    currency: 'USD',
    monthlyPrice: 100,
    yearlyPrice: 200,
    yearSubscriptionMonthlyPrice: 33,
    discount: 50,
    monthlyId: 'monthlyId',
    yearlyId: 'yearlyId',
    period: SubscriptionPeriod.monthly,
  },
  [SubscriptionGroup.premium]: {
    currency: 'USD',
    monthlyPrice: 80,
    yearlyPrice: 190,
    yearSubscriptionMonthlyPrice: 56,
    discount: 70,
    firstYearPrice: 40,
    monthlyId: 'monthlyId',
    yearlyId: 'yearlyId',
    period: SubscriptionPeriod.monthly,
  },
  [SubscriptionGroup.business]: {
    currency: 'USD',
    monthlyPrice: 100,
    yearlyPrice: 200,
    yearSubscriptionMonthlyPrice: 56,
    discount: 70,
    monthlyId: 'monthlyId',
    yearlyId: 'yearlyId',
    period: SubscriptionPeriod.monthly,
  } as PlanInformation,
};

const pt = import.meta.env.VITE_PROJECT_TYPE;

// !Note Order is important
export const activePlanSubscriptionGroups: Record<SubscriptionGroup, string[]> =
  {
    [SubscriptionGroup.plus]: [
      pt + AppSubscriptionId.plusMonthly,
      pt + AppSubscriptionId.plusYear,
    ],
    [SubscriptionGroup.premium]: [
      pt + AppSubscriptionId.premiumMonthly,
      pt + AppSubscriptionId.premiumYear,
    ],
    [SubscriptionGroup.business]: [
      pt + AppSubscriptionId.businessMonthly,
      pt + AppSubscriptionId.businessYear,
    ],
  };
