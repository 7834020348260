import { gql } from 'graphql-request';

export const userQuery = gql`
  query userInfoQuery {
    user {
      email
      emails {
        id
        email
      }
      id
      currency
      firstName
      lastName
      gender
      birthday
      phone
      phones {
        id
        phone
      }
      secondsLeft
      facebookId
      facebookPictureUrl
      facebookName
      googleId
      googlePictureUrl
      customPictureUrl
      googleName
      locale
      recognizeLocale
      secondaryRecognizeLocale
      currentAccount {
        id
        hasUserFilter
        ownerCurrency
        ownerPictureUrl
      }
      account {
        id
        ownerFullName
        ownerCurrency
        ownerPictureUrl
        accountType
        hasUserFilter
        ownAccountShares {
          id
          accountFrom {
            id
            ownerFullName
            ownerCurrency
            ownerPictureUrl
            ownerEmail
          }
          accountTo {
            id
            ownerFullName
            ownerPictureUrl
            ownerCurrency
            ownerEmail
          }
          canViewGroup
          canEditGroup
          canDeleteGroup
        }
        availableAccountShares {
          id
          accountFrom {
            id
            ownerFullName
            ownerCurrency
            ownerPictureUrl
            ownerEmail
          }
          accountTo {
            id
          }
          canViewGroup
          canEditGroup
          canDeleteGroup
        }
      }
    }
  }
`;
