import c from 'classnames';
import { ReactNode } from 'react';

type CategoryItemNameProps = {
  isEmpty: boolean;
  name: ReactNode;
};

export const CategoryTileName = ({ isEmpty, name }: CategoryItemNameProps) => {
  return (
    <div
      className={c(
        'text-[var(--textMain60)] font-medium text-[14px] leading-[16px] overflow-hidden text-ellipsis whitespace-nowrap pb-[2px] pt-[5px] tablet:text-[16px] tablet:pt-[8px] tablet:pb-[4px]',
        {
          'text-[var(--mistakeText)]': isEmpty,
        },
      )}
    >
      {name}
    </div>
  );
};
