import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppButton } from '@/components/app-button';
import { AppIcon } from '@/components/app-icon';
import { useRootContext } from '@/context/root-context';
import { Maybe } from '@/core.types';
import debounce from 'lodash/debounce';
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

export const SearchInput = () => {
  const inputRef = useRef<Maybe<HTMLInputElement>>(null);
  const { searchFilter, setSearchFilter } = useRootContext();
  const [internalSearch, setInternalSearch] = useState(searchFilter);
  const setSearchDebounce = debounce((value) => setSearchFilter(value), 300);
  const onSearchChange = ({ currentTarget: { value } }) => {
    setSearchDebounce(value);
    setInternalSearch(value);
  };
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;

  useEffect(() => {
    if (searchFilter === '') {
      setInternalSearch(searchFilter);
    }
  }, [searchFilter]);

  const handleClearClick = () =>
    onSearchChange({
      currentTarget: { value: '' },
    } as SyntheticEvent<HTMLInputElement>);

  useClickAway(inputRef, () => inputRef?.current?.blur());

  return (
    <div className='app-search-block relative'>
      <AppIcon
        name='search-icon'
        className='absolute top-1/2 left-[8px] transform -translate-y-1/2 laptop:left-[14px]'
        color={colorEmphasized}
      />
      <div className='flex justify-between bg-[var(--mainBackground)] rounded-[10px] appearance-none text-[var(--textMain60)] pl-[35px] laptop:pl-[48px]'>
        <input
          ref={inputRef}
          placeholder='Search'
          className='outline-none w-full h-[36px] rounded-[10px] text-[17px] bg-[var(--mainBackground)] text-[var(--textMain)] laptop:h-[48px] laptop:text-[20px] laptop:leading-[23px] placeholder:text-[var(--textMain60)] placeholder:text-[16px] tablet:placeholder:text-[16px]'
          onChange={onSearchChange}
          value={internalSearch}
        />
        {searchFilter && (
          <AppButton onPress={handleClearClick} className='flex w-[40px]'>
            <AppIcon
              className='absolute top-1/2 transform -translate-y-1/2 right-0 h-full px-[15px] laptop:px-[20px]'
              name='close'
              size={{ width: '12px', height: '12px' }}
              color='black'
            />
          </AppButton>
        )}
      </div>
    </div>
  );
};
