import { AppButton } from '@/components/app-button';
import { AppConfirmModal } from '@/components/app-confirm-modal';
import { ProfileInfo } from '@/components/profile-info';
import { useBooleanState } from '@/hooks/use-boolean-state';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useQuerySharedAccounts } from './Manage/use-query-shared-accounts';
import { PermissionItem } from './permission-item';

export const ManageAccount = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { sharedAccounts, updateAccount, removeAccount } =
    useQuerySharedAccounts();
  const { formatMessage: t } = useIntl();
  const account = sharedAccounts.find(
    (sharedAccount) => sharedAccount.id === +Number(id),
  );
  const [canViewGroup, _, __, toggleViewGroup] = useBooleanState(
    !!account?.canViewGroup,
  );
  const [canEditGroup, _1, __1, toggleEditGroup] = useBooleanState(
    !!account?.canEditGroup,
  );
  const [canDeleteGroup, _2, __2, toggleDeleteGroup] = useBooleanState(
    !!account?.canDeleteGroup,
  );
  const [
    deleteAccountModalOpened,
    openDeleteAccountModal,
    closeDeleteAccountModal,
  ] = useBooleanState(false);

  const onSaveClick = async () => {
    if (account) {
      await updateAccount({
        email: account?.ownerEmail,
        canViewGroup,
        canEditGroup,
        canDeleteGroup,
      });
      history.goBack();
    }
  };

  const onRemoveAccount = async () => {
    if (account) {
      await removeAccount(account.id);
      history.goBack();
    }
    closeDeleteAccountModal();
  };

  const accountButtonClasses =
    'w-[150px] rounded-[10px] h-10 font-roboto font-medium text-sm leading-4 text-white text-center bg-[var(--emphasized)]';

  return (
    <div className='p-4 tablet:max-w-[667px] tablet:mx-auto laptop:w-[45%] laptop:p-0 laptop:mx-auto'>
      <ProfileInfo
        userIcon={account?.ownerPictureUrl ?? ''}
        ownerFullName={account?.ownerFullName}
        userCurrency={account?.ownerCurrency}
      />
      <div className='mt-3 bg-[var(--textMain10)] h-[2px]' />
      <ul className='list-none mb-0 pl-0'>
        <PermissionItem
          permissionLabel={t({ id: 'menu.profile.permission.viewing' })}
          permissionInfo={t({ id: 'menu.profile.permission.admin.view.info' })}
          onClick={toggleViewGroup}
          isActive={canViewGroup}
        />
        <PermissionItem
          permissionLabel={t({ id: 'menu.profile.permission.editing' })}
          permissionInfo={t({ id: 'menu.profile.permission.admin.edit.info' })}
          onClick={toggleEditGroup}
          isActive={canEditGroup}
        />
        <PermissionItem
          permissionLabel={t({ id: 'menu.profile.permission.deletion' })}
          permissionInfo={t({
            id: 'menu.profile.permission.admin.delete.info',
          })}
          onClick={toggleDeleteGroup}
          isActive={canDeleteGroup}
        />
      </ul>

      <div className='mt-15 flex justify-center items-center gap-2.5'>
        <AppButton className={accountButtonClasses} onPress={onSaveClick}>
          {t({ id: 'save' })}
        </AppButton>

        <AppButton
          className={`${accountButtonClasses} bg-[var(--mistakeStroke)]`}
          onPress={openDeleteAccountModal}
        >
          {t({ id: 'menu.profile.delete.profile' })}
        </AppButton>
      </div>

      <AppConfirmModal
        key='confirm-modal'
        open={deleteAccountModalOpened}
        onConfirmClick={onRemoveAccount}
        title={t({ id: 'menu.profile.delete.profile' })}
        onClose={closeDeleteAccountModal}
      />
    </div>
  );
};
