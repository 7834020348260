import { AppButton } from '@/components/app-button';
import c from 'classnames';
import { useIntl } from 'react-intl';
import { RECEIPT_STYLE } from '../crop-step.constants';
import { useForceUpdate } from '@/hooks/use-force-update';
import { useEffect } from 'react';
import { delay } from 'lodash';

type CropStepStyleFilterProps = {
  onClick(): void;
  type: RECEIPT_STYLE;
  styleFilter: string;
};

export const CropStepStyleFilter = ({
  onClick,
  type,
  styleFilter,
}: CropStepStyleFilterProps) => {
  const { formatMessage: t } = useIntl();
  const forceUpdate = useForceUpdate();

  const RECEIPT_STYLE_TEXT_MAP = {
    [RECEIPT_STYLE.original]: t({ id: 'filter.original' }),
    [RECEIPT_STYLE.gray]: t({ id: 'filter.grey' }),
    [RECEIPT_STYLE.bw]: t({ id: 'filter.bw' }),
  };

  const title = RECEIPT_STYLE_TEXT_MAP[type];
  const selectedStyle = styleFilter === type;

  useEffect(() => {
    (async () => {
      setTimeout(() => {
        forceUpdate();
      }, 1000);
    })();
  }, [styleFilter]);

  if (!window.newPoly.miniImages[type]) {
    return null;
  }

  return (
    <AppButton
      className={c(
        'flex items-center flex-col cursor-pointer relative z-[999]',
        {
          "before:block before:content-[''] before:w-3.5 before:h-3.5 before:bg-cover before:bg-center before:absolute before:top-1/5 before:left-1/2 before:transform before:-translate-x-1/2 before:filter before:drop-shadow-[0_0_1px_#000]":
            selectedStyle,
        },
      )}
      onPress={onClick}
      disabled={selectedStyle}
    >
      <img
        src={window.newPoly.miniImages[type] || ''}
        alt=''
        className='mb-1 w-[77px] h-[48px] object-contain object-center'
      />
      <span className='text-white mt-1 text-xs leading-[11px]'>{title}</span>
    </AppButton>
  );
};
