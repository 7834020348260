import { AppModal } from '@/components/app-modal';
import { DefaultInput } from '@/components/default-input';
import { isValidEmail } from '@/services/helper-service';
import { useState } from 'react';
import { useIntl } from 'react-intl';

type AddEmailModalProps = {
  open: boolean;
  closeAccountModal(): void;
  addAccount(email: string): Promise<void>;
};

export const AddEmailModal = ({
  open,
  closeAccountModal,
  addAccount,
}: AddEmailModalProps) => {
  const [email, setEmail] = useState('');
  const { formatMessage: t } = useIntl();

  const onSaveClick = async () => {
    if (isValidEmail(email)) {
      await addAccount(email);
      closeAccountModal();
    }
  };

  const onChangeEmail = ({ target }: any) => setEmail(target.value);

  return (
    <AppModal
      open={open}
      saveDisabled={!isValidEmail(email)}
      onSaveClick={onSaveClick}
      content={
        <div className='add-mail__input-wrapper'>
          <DefaultInput
            {...{
              value: email,
              placeholder: 'example@gmail.com',
              onChange: onChangeEmail,
              iconName: 'mail',
            }}
          />
        </div>
      }
      onClose={closeAccountModal}
      title={t({ id: 'menu.profile.new.account' })}
    />
  );
};
