import { useRootContext } from '@/context/root-context';
import { LocalReceipt } from '@/core.types';
import { useUpdateReceiptList } from '@/hooks';
import { useFormState } from '@/hooks/use-form-state';
import { getUnixTimestampFromDate } from '@/services/helper-service';
import { receiptToEditAtom } from '@/store/category-store';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { UpdateReceiptDTO } from './use-create-receipt-logic';

export const useUpdateReceiptLogic = () => {
  const history = useHistory();
  const [receiptToEdit, setReceiptToEdit] = useRecoilState(receiptToEditAtom);
  const updateReceiptList = useUpdateReceiptList();
  // TODO move recoil value -> useRecoilCallback
  const { activeSliceId, setActiveSliceId } = useRootContext();

  const { formState, setField } = useFormState<UpdateReceiptDTO>({
    defaultValues: {
      name: receiptToEdit?.name ?? '',
      amount: receiptToEdit?.amount ?? 0,
      remindedAt: receiptToEdit?.remindedAt ?? null,
      receiptDate:
        receiptToEdit?.receiptDate ?? getUnixTimestampFromDate(new Date()),
    },
  });

  const receiptUpdatedFields = {
    ...formState,
    amount: Number(formState.amount),
    parentId: activeSliceId,
  };

  const isReceiptUpdated = useMemo(() => {
    if (!receiptToEdit) {
      return true;
    }
    return !isEqual(
      pick(receiptToEdit, ['amount', 'name', 'receiptDate', 'parentId']),
      receiptUpdatedFields,
    );
  }, [receiptUpdatedFields, receiptToEdit]);

  const isPDFFile = receiptToEdit?.imagePath?.includes('pdf');

  const onSaveCloseClick = async () => {
    if (!activeSliceId || !receiptToEdit) {
      return;
    }

    try {
      if (isReceiptUpdated) {
        await updateReceiptList([
          {
            ...receiptToEdit,

            ...receiptUpdatedFields,
            parentId: activeSliceId,
          } as LocalReceipt,
        ]);
      }
    } finally {
      setReceiptToEdit(null);
      window.newPoly.reset();
    }
  };

  useEffect(() => {
    if (!receiptToEdit || !activeSliceId) {
      history.goBack();
    }
  }, [activeSliceId, history, receiptToEdit]);

  useEffect(() => {
    if (receiptToEdit?.parentId) {
      setActiveSliceId(receiptToEdit.parentId);
    }
  }, [receiptToEdit?.parentId, setActiveSliceId]);

  return {
    isPDFFile,
    imagePath: receiptToEdit?.imagePath,
    onSaveCloseClick,
    formState,
    setField,
    activeSliceId,
    loading: false,
  };
};
