import { useEffect, useState } from 'react';

export const useGetIOSKeyboardHeight = () => {
  const [keyboardHeight, setKeyboardHeight] = useState(0);

  useEffect(() => {
    window.addEventListener('keyboardWillShow', ({ keyboardHeight }: any) => {
      setKeyboardHeight(keyboardHeight);
    });

    window.addEventListener('keyboardWillHide', () => {
      setKeyboardHeight(0);
    });
  }, []);

  return keyboardHeight;
};
