import { useGetIOSKeyboardHeight } from '@/hooks/use-get-ios-keyboard-height';
import noop from 'lodash/noop';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { AppLogo } from './app-logo';

export const AuthPageWrapper = ({ children }: { children: ReactNode }) => {
  const keyboardHeight = useGetIOSKeyboardHeight();
  return (
    <div
      className='flex flex-col items-center justify-start h-[415px] w-[300px] m-auto'
      style={{
        paddingBottom: keyboardHeight,
      }}
    >
      {children}
    </div>
  );
};

export const AuthPageImage = ({ onClick }: { onClick?: () => void }) => (
  <AppLogo className='w-[150px] h-[133px] cursor-pointer' onClick={onClick} />
);

export const AuthPageTitle = ({ children }: { children: ReactNode }) => (
  <p className='tracking-[1.2px] mt-[43px] mb-[10px] text-[16px] text-center text-[var(--authTextColor)]'>
    {children}
  </p>
);

export const AuthPageLink = ({
  to,
  children,
  onClick = noop,
}: {
  to?: string;
  onClick?: () => void;
  children: ReactNode;
}) => {
  const className =
    'text-[12px] block font-bold mt-[10px] no-underline text-[#4bcab6] text-center hover:text-[var(--icons)]';

  if (!to) {
    return (
      <span className={className} onClick={onClick}>
        {children}
      </span>
    );
  }

  return (
    <Link className={className} to={to}>
      {children}
    </Link>
  );
};
