import { APP_COLOR_THEME_TYPE, applyColorTheme } from '@/color-theme.constants';
import { AppType } from '@/core.types';
import { hasAppAccess } from '@/services/app-service';

import { useRootContext } from '@/context/root-context';
import { useIntl } from 'react-intl';
import { ThemePalette } from './theme-palette';

export const AppTheme = () => {
  const { appThemeType, setAppThemeType } = useRootContext();
  const { formatMessage: t } = useIntl();

  const onThemeChange = (theme: APP_COLOR_THEME_TYPE) => {
    setAppThemeType(theme);
    applyColorTheme(theme);
  };

  const defaultPalette = hasAppAccess(AppType.ireceipt)
    ? {
        label: 'IReceipt',
        themeType: APP_COLOR_THEME_TYPE.IRECEIPT,
      }
    : {
        label: 'IDocument',
        themeType: APP_COLOR_THEME_TYPE.IDOCUMENT,
      };

  const themePalette = [
    defaultPalette,
    {
      label: 'Dark',
      themeType: APP_COLOR_THEME_TYPE.DARK,
    },
    {
      label: 'Light',
      themeType: APP_COLOR_THEME_TYPE.LIGHT,
    },
  ];

  return (
    <div className='flex flex-col h-full laptop:w-[45%] laptop:h-[670px]'>
      <div className='font-roboto p-[30px_20px_0] flex flex-col max-w-[428px] mx-auto tablet:max-w-[667px] laptop:max-w-unset laptop:p-0 laptop:m-0'>
        <div className='font-bold text-[24px] leading-[28px] text-center text-[var(--textMain)]'>
          {t({ id: 'menu.theme.title' })}
        </div>

        <div className='w-[277px] m-[10px_auto_0] font-normal text-[18px] leading-[21px] text-center text-[var(--textMain)] opacity-[0.9] laptop:w-full'>
          {t({ id: 'menu.theme.subtitle' })}
        </div>
        {themePalette.map((props) => {
          return (
            <ThemePalette
              {...props}
              onThemeChange={onThemeChange}
              key={props.label}
              selectedAppColorType={appThemeType}
            />
          );
        })}
      </div>
    </div>
  );
};
