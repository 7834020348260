import { AppButton } from '@/components/app-button';
import { RECOGNITION_TYPES } from '@/core.types';
import { useIntl } from 'react-intl';
import { useRecognitionLogic } from './use-recognition-logic';
import { AppIonSwitch } from './app-ion-switch';
import { AppIonSelect } from './app-ion-select';
import { SAVE_BUTTON_CLASSNAME } from '../../settings-page';

export const Recognition = () => {
  const { formatMessage: t } = useIntl();
  const {
    recognitionSwitchOptions,
    recognitionType,
    onRecognitionTypeChange,
    recognitionLanguageSelectOptions,
    recognitionLanguages,
    onRecognitionLanguagesChange,
    onSaveButtonClick,
  } = useRecognitionLogic();

  const saveButtonDisabled =
    recognitionType !== RECOGNITION_TYPES.none && !recognitionLanguages?.length;

  return (
    <div className='font-roboto p-[25px_15px_20px] tablet:max-w-[667px] tablet:mx-auto laptop:w-[45%] laptop:p-0 laptop:m-0'>
      <AppIonSwitch
        label={`${t({ id: 'recognition' })}:`}
        value={recognitionType}
        options={recognitionSwitchOptions}
        onChange={onRecognitionTypeChange}
      />
      <AppIonSelect
        className='mt-[20px] mx-auto mb-0 tablet:mt-[20px] tablet:max-w-[298px]'
        label={`${t({ id: 'menu.lang' })}:`}
        interfaceOptions={{
          header: t({ id: 'menu.lang' }),
        }}
        cancelText={t({ id: 'cancel' })}
        confirmText={t({ id: 'save' })}
        onChange={onRecognitionLanguagesChange}
        disabled={recognitionType === RECOGNITION_TYPES.none}
        value={recognitionLanguages}
        options={recognitionLanguageSelectOptions}
        isMultiple
      />

      <AppButton
        className={SAVE_BUTTON_CLASSNAME}
        onPress={onSaveButtonClick}
        disabled={saveButtonDisabled}
      >
        {t({ id: 'save' })}
      </AppButton>
    </div>
  );
};
