import { useRootContext } from '@/context/root-context';
import { AppType, SortField } from '@/core.types';
import { hasAppAccess } from '@/services/app-service';
import { dragEnabledATOM } from '@/store/category-store';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import { CategorySortHeaderCell } from './category-sort-header-cell';

export const CategorySortHeader = () => {
  const dragEnabled = useRecoilValue(dragEnabledATOM);
  const { isRoot } = useRootContext();
  const { formatMessage: t } = useIntl();

  const sortCells = [
    {
      label: t({ id: 'sort.store' }),
      sortField: SortField.name,
    },
    {
      label: t({ id: 'sort.madeBy' }),
      sortField: SortField.madeBy,
    },
    {
      label: t({ id: 'sort.amount' }),
      sortField: SortField.amount,
      hidden: !hasAppAccess(AppType.ireceipt),
    },
    {
      label: t({ id: 'sort.date' }),
      sortField: SortField.receiptDate,
    },
  ].filter(({ hidden }) => !hidden);

  if (!isRoot && dragEnabled) {
    return null;
  }

  return (
    <div className='flex items-center relative mb-[20px] border-[2px] border-transparent pt-[15px] laptop:pt-[30px] tablet:mb-[30px]'>
      {sortCells.map((cell) => (
        <CategorySortHeaderCell {...cell} key={cell.label} />
      ))}
    </div>
  );
};
