import {
  CategoryId,
  LocalCategory,
  LocalReceipt,
  Maybe,
  ReceiptId,
  VisionKitGalleryImagePoint,
} from '@/core.types';
import { atom } from 'recoil';

export const pullToRefreshInProgressATOM = atom({
  key: 'pullToRefreshInProgressATOM',
  default: false,
});

export const createFolderModalOpenedATOM = atom({
  key: 'createFolderModalOpenedATOM',
  default: false,
});

export const editModeEnabledATOM = atom({
  key: 'editModeEnabledATOM',
  default: false,
});

export const madeByModalOpenedATOM = atom({
  key: 'madeByModalOpenedATOM',
  default: false,
});

export const dragEnabledATOM = atom({
  key: 'dragEnabledATOM',
  default: false,
});

export const selectedReceiptIDsAtom = atom<ReceiptId[]>({
  key: 'selectedReceiptIDsAtom',
  default: [],
});

export const selectedCategoryIDsAtom = atom<CategoryId[]>({
  key: 'selectedCategoryIDsAtom',
  default: [],
});

export const croppedImageFileAtom = atom<Maybe<File>>({
  key: 'croppedImageFileAtom',
  default: null,
});

export const newReceiptFileAtom = atom<Maybe<File>>({
  key: 'newReceiptFileAtom',
  default: null,
});

export const receiptToEditAtom = atom<Maybe<LocalReceipt>>({
  key: 'receiptToEditAtom',
  default: null,
});

export const categoryToEditAtom = atom<Maybe<LocalCategory>>({
  key: 'categoryToEditAtom',
  default: null,
});

export const lastReceiptUpdateAtom = atom<Maybe<number>>({
  key: 'lastReceiptUpdateAtom',
  default: null,
});

export const lastCategoryUpdateAtom = atom<Maybe<number>>({
  key: 'lastCategoryUpdateAtom',
  default: null,
});

export const categoryLoadingAtom = atom<boolean>({
  key: 'categoryLoadingAtom',
  default: false,
});

export const recognizedImagePointsAtom = atom<
  Maybe<VisionKitGalleryImagePoint[]>
>({
  key: 'recognizedImagePointsAtom',
  default: null,
});
