import { PAGE_ROUTES } from '@/app.constants';
import { newReceiptFileAtom } from '@/store/category-store';
import { RefObject } from 'react';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';

export const useSelectReceiptFile = (
  fileInputRef: RefObject<HTMLInputElement>,
) => {
  const history = useHistory();
  const setNewReceiptFile = useSetRecoilState(newReceiptFileAtom);

  const onChangeInput = async ({
    target: {
      files: [file],
    },
  }: any) => {
    await setNewReceiptFile(file);
    const isPDFReceipt = String(file?.type).includes('pdf');
    history.push(
      `${PAGE_ROUTES.category}${
        isPDFReceipt ? PAGE_ROUTES.createReceipt : PAGE_ROUTES.cropStep
      }`,
    );

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return onChangeInput;
};
