import {
  CompressedImage,
  Maybe,
  VisionKitGalleryImagePoint,
} from '@/core.types';

export function reduceFileSize({
  file,
  maxSideSize,
  quality,
  points,
}: {
  file: Blob;
  maxSideSize: number;
  quality: number;
  points?: any;
}): Promise<CompressedImage> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onerror = function () {
      URL.revokeObjectURL(this.src);
      resolve({
        imageBlob: file,
        points: points,
        imageSize: { width: 0, height: 0 },
      });
    };
    img.onload = function () {
      // @ts-ignore
      URL.revokeObjectURL(this.src);

      let scale = 1;
      if (img.width > img.height && img.width > maxSideSize) {
        scale = maxSideSize / img.width;
      } else if (img.width < img.height && img.height > maxSideSize) {
        scale = maxSideSize / img.height;
      }
      if (scale !== 1 && points) {
        points = points.map((point: VisionKitGalleryImagePoint) => {
          return {
            x: point.x * scale,
            y: point.y * scale,
          };
        });
      }
      const canvas = document.createElement('canvas');
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;
      const ctx = canvas.getContext('2d');
      // @ts-ignore
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      canvas.toBlob(
        // @ts-ignore
        function (blob: Blob) {
          resolve({
            imageBlob: blob,
            points: points,
            imageSize: { width: canvas.width, height: canvas.height },
          });
        },
        'image/jpeg',
        quality,
      );
    };
    img.src = URL.createObjectURL(file);
  });
}

export const generateThumbnail = (
  file: File,
  canvasSize = [250, 90],
): Promise<Maybe<Blob>> => {
  const reader = new FileReader();
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  return new Promise((resolve) => {
    reader.onload = function (event) {
      const img = new Image();

      if (!ctx) {
        return null;
      }

      // @ts-ignore
      img.src = event?.target?.result;

      img.onload = function () {
        const [w] = canvasSize;
        const canvasHeight = Math.round((img.height * w) / img.width);
        canvas.width = w;
        canvas.height = canvasHeight;
        ctx.drawImage(img, 0, 0, w, canvasHeight);

        return canvas.toBlob((blob: Maybe<Blob>) => {
          resolve(blob);
        }, 'image/jpeg');
      };
    };
    reader.readAsDataURL(file);
  });
};
