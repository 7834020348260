export const clearAccountIdToken = () => {
  localStorage.removeItem('accountId');
};

export const setAccountIdToken = (id: number | string) => {
  localStorage.setItem('accountId', String(id));
};

export const getAccountId = () => {
  return Number(localStorage.getItem('accountId'));
};

export const getUserToken = () => localStorage.getItem('token');

export const setUserToken = (token: string) => {
  localStorage.setItem('token', token);
};

export const isUserAuthorized = () => !!getUserToken();

export const clearTokens = () => {
  localStorage.removeItem('accountId');
  localStorage.removeItem('token');
};
