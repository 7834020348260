import { AppPdfView } from '@/components/app-pdf-view';
import { AppPreviewReceiptImage } from '@/components/app-preview-receipt-image/app-preview-receipt-image';
import { PreloadImage } from '@/hooks/use-preload-image';

type AppFilePreviewProps = {
  preloadImage: PreloadImage;
  imageContainerHeight: number;
  isPDFFile?: boolean;
  name: string;
};

export const AppFilePreview = ({
  preloadImage,
  imageContainerHeight,
  isPDFFile,
  name,
}: AppFilePreviewProps) => {
  return isPDFFile && !window.cordova ? (
    <AppPdfView src={preloadImage.src} />
  ) : (
    <AppPreviewReceiptImage
      preloadImage={preloadImage}
      imageContainerHeight={imageContainerHeight}
      isPDFFile={!!isPDFFile}
      name={name}
    />
  );
};
