import {
  useSelectedIDsAtomSetters,
  useSetBooleanAtomState,
} from '@/store/category-store/category-store.callbacks';
import { useCallback, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useRecoilValue } from 'recoil';
import {
  CategoryId,
  DNDDropType,
  LocalCategory,
  LocalReceipt,
} from '../core.types';
import { useUpdateCategoryList, useUpdateReceiptList } from '../hooks';
import {
  dragEnabledATOM,
  editModeEnabledATOM,
  selectedCategoryIDsAtom,
  selectedReceiptIDsAtom,
} from '../store';

import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { useRootContext } from '@/context/root-context';

export const useCategoryDndLogic = (category_id: CategoryId) => {
  const { getCategoryById, getReceiptById } = useContext(CategoryTreeContext);
  const { activeSliceId } = useRootContext();
  const selectedCategoryIdsList = useRecoilValue(selectedCategoryIDsAtom);
  const selectedReceiptIdsList = useRecoilValue(selectedReceiptIDsAtom);
  const [addSelectedCategory, _1, clearSelectedCategories] =
    useSelectedIDsAtomSetters(selectedCategoryIDsAtom);
  const [_2, _3, clearSelectedReceipts] = useSelectedIDsAtomSetters(
    selectedReceiptIDsAtom,
  );
  const dragEnabled = useRecoilValue(dragEnabledATOM);
  const [enabledDrag, disabledDrag] = useSetBooleanAtomState(dragEnabledATOM);
  const updateReceiptList = useUpdateReceiptList();
  const updateCategoryList = useUpdateCategoryList();
  const [_, disabledEditMode] = useSetBooleanAtomState(editModeEnabledATOM);

  const onDropEvent = useCallback(() => {
    if (!activeSliceId) {
      return;
    }

    const categoryListToUpdate = selectedCategoryIdsList.filter(
      (id) => id !== category_id,
    );

    // !Categories move
    const categoryList: LocalCategory[] = [];
    for (const categoryId of categoryListToUpdate) {
      const category = getCategoryById(categoryId);
      if (category) {
        categoryList.push({ ...category, parentId: category_id });
      }
    }

    updateCategoryList(categoryList);

    // !Receipts move
    const receiptList: LocalReceipt[] = [];
    for (const receiptId of selectedReceiptIdsList) {
      const receipt = getReceiptById(receiptId);
      if (receipt) {
        receiptList.push({ ...receipt, parentId: category_id });
      }
    }
    updateReceiptList(receiptList);
  }, [
    activeSliceId,
    category_id,
    selectedCategoryIdsList,
    selectedReceiptIdsList,
  ]);

  const [{ isOver }, drop] = useDrop({
    accept: [DNDDropType.category, DNDDropType.receipt],
    drop: onDropEvent,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const [{ isDraggable }, drag] = useDrag({
    item: { id: category_id, type: DNDDropType.category },
    begin: () => {
      enabledDrag();
      disabledEditMode();
      if (!selectedCategoryIdsList.includes(category_id)) {
        addSelectedCategory(category_id);
      }
    },
    end: () => {
      clearSelectedCategories();
      clearSelectedReceipts();
      disabledDrag();
    },
    collect: (monitor) => ({
      isDraggable: !!monitor.isDragging(),
    }),
  });

  return {
    dragEnabled,
    isDraggable,
    drag,
    drop,
    isOver,
  };
};
