export type LocalReceipt = {
  id: ReceiptId;
  receiptDate: number;
  type: ReceiptType;
  amount: number;
  imagePath: string;
  thumbPath: string;
  deleted: boolean;
  name: string;
  parentId: CategoryId;
  createdBy: string;
  createdById: number;
  updatedAt: Maybe<number>;
  remindedAt: Maybe<number>;
  // !Note - this field is only local
  accountId: number;
};

export type CategoryItem = {
  createdBy: string;
  id: CategoryId;
  name: string;
  deleted: boolean;
  type: ReceiptType;
  parentId: CategoryId;
  createdById: number;
  updatedAt: Maybe<number>;
};

export enum ReceiptType {
  invoice = 'invoice',
  receipt = 'receipt',
  document = 'document',
}

export type LocalCategory = Omit<CategoryItem, 'receipts' | 'children'> & {
  receipts: ReceiptId[];
  children: CategoryId[];
  accountId: number;
  amount?: number;
};

export type ReceiptText = {
  text: string;
  x: number;
  y: number;
};

export type VisionKitPDFResult = {
  pdfTempUrl: string;
};

export type VisionKitGalleryImagePoint = {
  x: number;
  y: number;
};

export type VisionKitGalleryImageText = {
  height: number;
  text: string;
  width: number;
  x: number;
  y: number;
};

export type VisionKitGalleryImageFile = {
  imagePath: string;
  text: string[];
  points: VisionKitGalleryImagePoint[] | any;
};

export type CompressedImage = {
  imageBlob: Blob;
  points: VisionKitGalleryImagePoint[];
  imageSize: ImageSize;
};

export type ImageSize = {
  width: number;
  height: number;
};

export type VisionKitScanResult =
  | ScannedReceipt[]
  | VisionKitGalleryImageFile
  | VisionKitPDFResult;

export type VisionKitBase64DTO = {
  base64: string;
  extension: string;
};

export type ScannedReceipt = {
  imagePath: string;
  thumbPath: string;
  text: ReceiptText[];
};

export type VisionKitBase64ResponseDTO = {
  filePath: string;
  thumbPath: string;
};

export type CreateCategoryPayload = {
  id: Maybe<CategoryId>;
  name: string;
  parent_id: CategoryId;
  type: ReceiptType;
};

export type UpdateCategoryPayload = {
  id: CategoryId;
  name: string;
  parent_id: CategoryId;
};

export type DeleteCategoryPayload = CategoryId[];

export type CreateReceiptPayload = Omit<
  LocalReceipt,
  'deleted' | 'parentId'
> & {
  category_id: CategoryId;
};

export type UpdateReceiptPayload = Omit<
  LocalReceipt,
  'deleted' | 'parentId'
> & {
  category_id: CategoryId;
};

export type DeleteReceiptPayload = ReceiptId[];

export enum REQUEST_TYPE {
  createReceipt = 'createReceipt',
  updateReceipt = 'updateReceipt',
  deleteReceipt = 'deleteReceipt',
  createCategory = 'createCategory',
  updateCategory = 'updateCategory',
  deleteCategory = 'deleteCategory',
}

export type CommonQueryPayload = {
  id: string;
  headers: {
    'X-CURRENT-ACCOUNT-ID': string;
  };
};

export type RequestCreateCategoryItem = {
  requestType: REQUEST_TYPE.createCategory;
  rollbackPayload: CategoryId;
  payload: CreateCategoryPayload;
} & CommonQueryPayload;

export type RequestUpdateCategoryItem = {
  requestType: REQUEST_TYPE.updateCategory;
  payload: UpdateCategoryPayload;
  rollbackPayload: LocalCategory;
} & CommonQueryPayload;

export type RequestDeleteCategoryItem = {
  requestType: REQUEST_TYPE.deleteCategory;
  rollbackPayload: LocalCategory[];
  payload: DeleteCategoryPayload;
} & CommonQueryPayload;

export type RequestCreateReceiptItem = {
  requestType: REQUEST_TYPE.createReceipt;
  rollbackPayload: ReceiptId;
  payload: CreateReceiptPayload;
} & CommonQueryPayload;

export type RequestUpdateReceiptItem = {
  requestType: REQUEST_TYPE.updateReceipt;
  rollbackPayload: LocalReceipt;
  payload: UpdateReceiptPayload;
} & CommonQueryPayload;

export type RequestDeleteReceiptItem = {
  requestType: REQUEST_TYPE.deleteReceipt;
  rollbackPayload: LocalReceipt[];
  payload: DeleteReceiptPayload;
} & CommonQueryPayload;

export type RequestItem =
  | RequestCreateCategoryItem
  | RequestUpdateCategoryItem
  | RequestDeleteCategoryItem
  | RequestCreateReceiptItem
  | RequestUpdateReceiptItem
  | RequestDeleteReceiptItem;

export type CategoryId = string | number | any;
export type ReceiptId = string | number | any;

export type Maybe<T> = T | null;

export enum CategoryView {
  list = 'list',
  tile = 'tile',
}

export type ScrollPositionMapType = {
  root: number;
  [slideId: string]: number;
};

export enum SortField {
  name = 'name',
  madeBy = 'madeBy',
  amount = 'amount',
  receiptDate = 'receiptDate',
}

export enum SortType {
  asc = 'asc',
  desc = 'desc',
}

export type CreatorType = {
  id: string | number;
  name: string;
};

export type SortFilter = {
  sortField: SortField;
  sortType: SortType;
};

export type CategoryFilters = {
  search: string;
  receiptType: ReceiptType;
  filterMadeBy: CreatorType;
} & SortFilter;

export type VisionKitDownloadedFile = {
  filePath: string;
  thumbPath: string;
};

export type LocalFileReceipt = {
  id: ReceiptId;
} & VisionKitDownloadedFile;

export type FileRequest = {
  id: ReceiptId;
  url: string;
  accountId: number;
};

export enum IMAGE_PREFIX {
  rl_scan = 'rl_scan',
  local_stub = 'local_stub',
}

export enum DNDDropType {
  receipt = 'receipt',
  category = 'category',
}

export enum ServerErrorType {
  TYPE_CODE_TOKEN_ISSUE = 1,
  TYPE_CODE_WRONG_ACCOUNT_ID_HEADER = 2,
  TYPE_CODE_UNEXPECTED_BY_FE = 3,
  TYPE_CODE_ONLINE_ERROR = 4,
  TYPE_CODE_OFFLINE_ERROR = 5,
}

export type ServerError = {
  message: string;
  type: ServerErrorType;
  code: number;
  field: string;
};

export type ServerErrorResponse = {
  clientErrors: any[];
  extraInfo: string | undefined;
  graphQLErrors?: ServerError[];
  errors?: ServerError[];
  message: string | number;
  networkError: Maybe<string>;
};

export enum AppType {
  ireceipt = 'ireceipt',
  idocument = 'idocument',
}

export enum RECOGNITION_TYPES {
  none = 'none',
  fast = 'fast',
  accurate = 'accurate',
}

export enum RECOGNITION_LANGUAGES {
  en = 'en-US',
  fr = 'fr-FR',
  it = 'it-IT',
  de = 'de-DE',
  es = 'es-ES',
  pt = 'pt-BR',
  uk = 'uk-UA',
  ru = 'ru-RU',
}

export type RecognitionConfig = {
  languages: string[];
  recognitionType: RECOGNITION_TYPES;
};

export const AppSubscriptionId = {
  premiumMonthly: 'MonthSubscriptionPremium.v2',
  premiumYear: 'YearSubscriptionPremium.v2',
  plusMonthly: 'MonthSubscriptionPersonal.v2',
  plusYear: 'YearSubscriptionPersonal.v2',
  businessMonthly: 'MonthSubscriptionBusiness.v2',
  businessYear: 'YearSubscriptionBusiness.v2',
};
