// @ts-nocheck
export const resizeByScale = (matrix, scale) => {
  if (scale === 1) {
    return matrix.clone();
  }
  const dst = new window.cv.Mat();
  const dsize = new window.cv.Size(
    Math.round(matrix.cols * scale),
    Math.round(matrix.rows * scale),
  );
  window.cv.resize(matrix, dst, dsize, 0, 0, window.cv.INTER_AREA);

  return dst;
};

export const resizeByWidth = (matrix, width) => {
  const actualWidth = matrix.cols;
  const scale = width / actualWidth;

  return resizeByScale(matrix, scale);
};

export const rescalePoint = (point, scale) => {
  const newPoint = {};
  newPoint.x = point.x / scale;
  newPoint.y = point.y / scale;
  return newPoint;
};

export const rescalePoints = (points, scale) => {
  const res = [];
  points.forEach((point) => {
    res.push(rescalePoint(point, scale));
  });

  return res;
};

export const cropAndTransform = (points, sourceMatrix) => {
  const cornerArray = points;
  //Order the corners
  //Sort by Y position (to get top-down)
  cornerArray
    .sort((item1, item2) => {
      return item1.y < item2.y ? -1 : item1.y > item2.y ? 1 : 0;
    })
    .slice(0, 5);

  //Determine left/right based on x position of top and bottom 2
  const tl =
    cornerArray[0].x < cornerArray[1].x ? cornerArray[0] : cornerArray[1];
  const tr =
    cornerArray[0].x > cornerArray[1].x ? cornerArray[0] : cornerArray[1];
  const bl =
    cornerArray[2].x < cornerArray[3].x ? cornerArray[2] : cornerArray[3];
  const br =
    cornerArray[2].x > cornerArray[3].x ? cornerArray[2] : cornerArray[3];

  //Calculate the max width/height
  const widthBottom = Math.hypot(br.x - bl.x, br.y - bl.y);
  const widthTop = Math.hypot(tr.x - tl.x, tr.y - tl.y);
  const theWidth = widthBottom > widthTop ? widthBottom : widthTop;
  const heightRight = Math.hypot(tr.x - br.x, tr.y - br.y);
  const heightLeft = Math.hypot(tl.x - bl.x, tr.y - bl.y);
  const theHeight = heightRight > heightLeft ? heightRight : heightLeft;

  const newMatrix = new window.cv.Mat();
  const finalDestCoords = window.cv.matFromArray(4, 1, window.cv.CV_32FC2, [
    0,
    0,
    theWidth - 1,
    0,
    theWidth - 1,
    theHeight - 1,
    0,
    theHeight - 1,
  ]);
  const srcCoords = window.cv.matFromArray(4, 1, window.cv.CV_32FC2, [
    tl.x,
    tl.y,
    tr.x,
    tr.y,
    br.x,
    br.y,
    bl.x,
    bl.y,
  ]);
  const matrixSize = new window.cv.Size(theWidth, theHeight);
  const M = window.cv.getPerspectiveTransform(srcCoords, finalDestCoords);
  window.cv.warpPerspective(
    sourceMatrix,
    newMatrix,
    M,
    matrixSize,
    window.cv.INTER_LINEAR,
    window.cv.BORDER_CONSTANT,
    new window.cv.Scalar(),
  );

  return newMatrix;
};

export const sortPoints = (points) => {
  points.sort((a, b) => (a.x > b.x ? 1 : -1));
  const left = [points[0], points[1]];
  const right = [points[2], points[3]];
  left.sort((a, b) => (a.y > b.y ? 1 : -1));
  right.sort((a, b) => (a.y > b.y ? 1 : -1));

  return {
    leftTop: left[0],
    rightTop: right[0],
    rightBottom: right[1],
    leftBottom: left[1],
  };
};
