import { AppFooter } from '@/components/app-footer';
import { AppLoader } from '@/components/app-loader';
import { useRootContext } from '@/context/root-context';
import {
  categoryLoadingAtom,
  dragEnabledATOM,
  pullToRefreshInProgressATOM,
} from '@/store/category-store';
import {
  scrollPositionsMapAtom,
  useSetScrollPosition,
} from '@/store/root-store';
import { IonContent, IonRefresher, IonRefresherContent } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useRefreshTree } from '../category-tree-hooks/use-refresh-tree';
import { CategoryContent } from './category-content';
import { CategoryDNDPreview } from './category-dnd-preview';
import { CategoryDndProvider } from './category-dnd-provider';
import { CategoryFilters } from './category-filters';
import { CategoryPageHeader } from './category-header';

export const CategoryPullToRefresh = () => {
  const { activeSliceId } = useRootContext();
  const [pullToRefreshDisabled, setPullToRefreshDisabled] = useState(false);
  const triggerRefetch = useRefreshTree();
  const categoryLoading = useRecoilValue(categoryLoadingAtom);
  const dragEnabled = useRecoilValue(dragEnabledATOM);
  const [pullToRefreshInProgress, setPullToRefreshProgress] = useRecoilState(
    pullToRefreshInProgressATOM,
  );
  const pullToRefreshProgress = useRecoilValue(pullToRefreshInProgressATOM);
  const setPositionHandler = useSetScrollPosition();
  const scrollPositionsMap = useRecoilValue(scrollPositionsMapAtom);

  const saveScrollPosition = (scrollTop) => {
    if (activeSliceId) {
      setPositionHandler(activeSliceId, scrollTop);
    }
  };

  const onPullDown = async (event: any) => {
    try {
      if (
        !pullToRefreshInProgress &&
        !dragEnabled &&
        !categoryLoading &&
        !pullToRefreshDisabled
      ) {
        setPullToRefreshProgress(true);
        await triggerRefetch();
        setPullToRefreshProgress(false);
      }
      event.detail.complete();
    } catch {
      if (pullToRefreshInProgress) {
        setPullToRefreshProgress(false);
      }
    }
  };

  const onScroll = (event: any) => {
    setPullToRefreshDisabled(event.detail.currentY > 0);
    saveScrollPosition(event.detail.scrollTop);
  };

  useEffect(() => {
    const scrollContent = document.getElementById('category-content-wrapper');
    if (scrollContent) {
      if (activeSliceId) {
        const scrollTop = scrollPositionsMap[activeSliceId] || 0;
        // @ts-ignore
        scrollContent.scrollToPoint(0, scrollTop);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSliceId]);

  return (
    <CategoryDndProvider>
      <div className='w-full h-[calc(100vh_-_var(--sat)_-_var(--sab))] flex flex-col'>
        <CategoryPageHeader />
        <CategoryFilters />
        <IonContent className='rounded-[15px] overflow-hidden'>
          {!dragEnabled && (
            <IonRefresher
              disabled={pullToRefreshDisabled}
              onIonRefresh={onPullDown}
              draggable={!dragEnabled}
              pullFactor={0.5}
              pullMin={100}
              pullMax={200}
              slot='fixed'
              className='category-refresher'
            >
              <IonRefresherContent />
            </IonRefresher>
          )}

          <IonContent
            style={{ '--background': 'var(--mainBackground)' }}
            scrollEvents
            onIonScroll={onScroll}
            scrollY={!dragEnabled}
            id='category-content-wrapper'
            className={`ion-content-scroll-host ${
              dragEnabled ? 'no-scroll' : ''
            }`}
          >
            <CategoryContent />
          </IonContent>
        </IonContent>

        <AppFooter />
        <CategoryDNDPreview />
        {categoryLoading && !pullToRefreshProgress && <AppLoader />}
      </div>
    </CategoryDndProvider>
  );
};
