import { resolveFilePath } from '@/services/helper-service';

type AppPdfViewProps = {
  src: string;
};

export const AppPdfView = ({ src }: AppPdfViewProps) => {
  if (!src) {
    return null;
  }

  return (
    <iframe
      style={{
        width: '100%',
        height: '100%',
        maxWidth: 490,
        margin: '0 auto',
        display: 'block',
        backgroundColor: 'white',
      }}
      title='custom-iframe'
      src={resolveFilePath(src)}
      className='app-preview-receipt__embed'
    />
  );
};
