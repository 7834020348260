import { CreatorType } from './core.types';

export const NAVIGATOR_LANG_MAP = {
  'en-US': 'en',
  'en-En': 'en',
  'en-EG': 'en',
  'en-AU': 'en',
  'en-GB': 'en',
  'en-CA': 'en',
  'en-NZ': 'en',
  'en-IE': 'en',
  'en-ZA': 'en',
  'en-JM': 'en',
  'en-BZ': 'en',
  'en-TT': 'en',
  no: 'no',
  'no-NO': 'no',
  ru: 'ru',
  'ru-RU': 'ru',
  'ru-MI': 'ru',
  uk: 'uk',
  'uk-UA': 'uk',
  pt: 'pt',
  'pt-PT': 'pt',
  'pt-BR': 'pt',
  pl: 'pl',
  'pl-PL': 'pl',
  'sv-FI': 'fi',
  fi: 'fi',
  'fi-FI': 'fi',
  'se-SE': 'se',
  it: 'it',
  'it-IT': 'it',
  'it-CH': 'it',
  fr: 'fr',
  'fr-FR': 'fr',
  'fr-BE': 'fr',
  'fr-CA': 'fr',
  'fr-CH': 'fr',
  'fr-LU': 'fr',
  es: 'es',
  'es-ES': 'es',
  'es-AR': 'es',
  'es-GT': 'es',
  'es-CR': 'es',
  'es-PA': 'es',
  'es-DO': 'es',
  'es-MX': 'es',
  'es-VE': 'es',
  'es-CO': 'es',
  'es-PE': 'es',
  'es-EC': 'es',
  'es-CL': 'es',
  'es-UY': 'es',
  'es-PY': 'es',
  'es-BO': 'es',
  'es-SV': 'es',
  'es-HN': 'es',
  'es-NI': 'es',
  'es-PR': 'es',
  de: 'de',
  'de-DE': 'de',
  'de-CH': 'de',
  'de-AT': 'de',
  'de-LU': 'de',
  'de-LI': 'de',
  da: 'da',
  'da-DA': 'da',
};

export const DATE_MASK = 'dd.MM.yyyy';
export const ACCEPT_FILE_TYPE =
  'image/jpeg,image/gif,image/png,application/pdf';

export const SENTRY_DSN =
  'https://be193832226e476bb6f30512c930ae2a@o1101558.ingest.sentry.io/6127548';

export const ALL_PROFILES: CreatorType = {
  id: 'all',
  name: 'All Profiles',
};

export const IAP_VALIDATOR_URL = `https://validator.fovea.cc/v1/validate?appName=io.ireceipt&apiKey=f5d20d20-a7fd-41d9-811d-98b7635be610`;

export const SETTINGS_LANGUAGE_MAP = [
  'en', // English
  'es', // Spanish
  'fr', // French
  'de', // German
  'it', // Italian
  'uk', // Ukrainian
  'pl', // Polish
  'ru', // Russian
  'pt', // Portuguese
  'se', // Swedish
  'da', // Danish
  'no', // Norwegian
  'fi', // Finnish
];

export const PAGE_ROUTES = {
  login: '/login',
  category: '/category',
  settings: '/settings',
  cropStep: '/crop-step',
  createReceipt: '/create-receipt',
  updateReceipt: '/update-receipt',
  previewReceipt: '/preview-receipt',
  profile: '/settings/profile',
  auth: '/auth',
  otpLogin: '/otp-login',
  forgotPassword: '/forgot-password',
  mailConfirm: '/mail-confirm',
};

export const STORAGE_KEYS = {
  locale: 'locale',
  dateFromFilter: 'dateFromFilter',
  dateToFilter: 'dateToFilter',
  rootID: 'root-id',
  activeSliceId: 'activeSliceId',
  categoryListView: 'categoryListView',
  appThemeType: 'appThemeType',
  receiptType: 'receiptType',
  recognitionConfig: 'recognitionConfig',
  currentUser: 'current-user',
};
