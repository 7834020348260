import type { FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

type AuthRouteProps = {
  hasAccess: boolean;
  path: string;
  component: FC;
  redirect?: string;
};

export const AuthRoute = ({
  path,
  component,
  hasAccess,
  redirect = '/auth',
}: AuthRouteProps) => {
  return hasAccess ? (
    <Route {...{ path, component }} />
  ) : (
    <Redirect to={redirect} />
  );
};
