import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { useRootContext } from '@/context/root-context';
import { AppType } from '@/core.types';
import { hasAppAccess } from '@/services/app-service';
import { delay } from '@/services/helper-service';
import {
  lastCategoryUpdateAtom,
  lastReceiptUpdateAtom,
} from '@/store/category-store';
import { getSnapshotValue } from '@/store/root-store';
import { useCallback, useContext } from 'react';
import { useRecoilCallback } from 'recoil';
import {
  CategoryItem,
  FileRequest,
  IMAGE_PREFIX,
  LocalCategory,
  LocalReceipt,
  Maybe,
} from '../core.types';
import {
  fileDownloadQueueTable,
  localReceiptFilesTable,
} from '../services/indexed-db-service';

export const isReceiptEmpty = ({ name, amount }: LocalReceipt) =>
  hasAppAccess(AppType.ireceipt) ? !name || !amount : !name;

type SyncRecoilCategoryTreeDTO = {
  categories: CategoryItem[];
  receipts: LocalReceipt[];
  accountId?: number;
};

const addImageRequestListToQueueStore = (list: FileRequest[]) => {
  fileDownloadQueueTable.bulkPut(list);
};

export const getLastUpdated = (list: Array<Maybe<number>>): Maybe<number> => {
  const maxLastUpdated = Math.max(...list.filter(Boolean).map(Number));
  return maxLastUpdated > 0 ? maxLastUpdated : null;
};

export const useSyncLastUpdatedValuesFromServer = () =>
  useRecoilCallback(
    ({ set, snapshot }) =>
      (receipts: LocalReceipt[], categories: LocalCategory[]) => {
        const currentReceiptLastUpdate = getSnapshotValue(
          snapshot,
          lastReceiptUpdateAtom,
        );
        const currentCategoryLastUpdate = getSnapshotValue(
          snapshot,
          lastCategoryUpdateAtom,
        );

        const lastReceiptUpdatedAtFromServer = getLastUpdated(
          receipts.map(({ updatedAt }) => updatedAt),
        );

        const lastCategoryUpdatedAtFromServer = getLastUpdated(
          categories.map(({ updatedAt }) => updatedAt),
        );

        const finalReceiptUpdatedAt = getLastUpdated([
          currentReceiptLastUpdate,
          lastReceiptUpdatedAtFromServer,
        ]);

        const finalCategoryUpdatedAt = getLastUpdated([
          currentCategoryLastUpdate,
          lastCategoryUpdatedAtFromServer,
        ]);

        set(lastReceiptUpdateAtom, finalReceiptUpdatedAt);
        set(lastCategoryUpdateAtom, finalCategoryUpdatedAt);
      },
  );

// !Note important method
export const useSetCategoryTreeInRecoil = () => {
  const { updateCategoryList, updateReceiptList } =
    useContext(CategoryTreeContext);
  const { activeSliceId, rootID, setRootID, setActiveSliceId } =
    useRootContext();

  const transaction = useCallback(
    async ({ categories, receipts, accountId }: SyncRecoilCategoryTreeDTO) => {
      const receiptItemsToSave = receipts.map((receipt) => ({
        ...receipt,
        accountId: accountId ?? receipt.accountId,
      }));

      const localCategories = categories.map((category) => {
        const childReceipts = receiptItemsToSave
          .filter(({ parentId }) => parentId === category.id)
          .map(({ id }) => id);

        const childCategories = categories
          .filter(({ parentId }) => parentId === category.id)
          .map(({ id }) => id);

        return {
          ...category,
          receipts: childReceipts,
          children: childCategories,
          // @ts-ignore
          accountId: accountId ?? category.accountId,
        } as LocalCategory;
      });

      updateCategoryList(localCategories);
      updateReceiptList(receiptItemsToSave);

      // TODO update in future from currentAccount.rootCategory
      const rootCategory = categories.find(({ parentId }) => parentId === null);

      const needToChangeRootId =
        rootCategory?.id && (!rootID || rootID !== rootCategory.id);

      if (needToChangeRootId) {
        setRootID(rootCategory.id);
      }

      const localCategoryIDs = categories.map(({ id }) => id);

      if (
        !activeSliceId ||
        (needToChangeRootId && !localCategoryIDs.includes(activeSliceId))
      ) {
        setActiveSliceId(rootID ?? rootCategory?.id ?? null);
      }
    },
    [],
  );

  return transaction;
};

export const useGetLocalReceiptList = () => {
  const { currentAccountId } = useRootContext();

  return async (receipts: LocalReceipt[]) => {
    const imageRequestList: FileRequest[] = [];

    const [currentImageRequestListIds, currentLocalFileReceipts] =
      await Promise.all([
        fileDownloadQueueTable.toCollection().primaryKeys(),
        localReceiptFilesTable
          .where('id')
          .anyOf(receipts.map((r) => r.id))
          .toArray(),
      ]);

    const receiptPromiseListToSet = window.cordova
      ? receipts.map(async (receipt) => {
          const localFile = currentLocalFileReceipts.find(
            (re: LocalReceipt) => String(re.id) === String(receipt.id),
          );
          if (localFile) {
            receipt = {
              ...receipt,
              imagePath: localFile.filePath,
              thumbPath: localFile.thumbPath,
            };
          } else {
            if (
              !currentImageRequestListIds.includes(String(receipt.id)) &&
              !receipt.imagePath.includes(IMAGE_PREFIX.rl_scan) &&
              !receipt.deleted
            ) {
              imageRequestList.push({
                id: receipt.id,
                url: receipt.imagePath,
                accountId: currentAccountId as number,
              });
            } else {
              receipt = {
                ...receipt,
                imagePath: '',
                thumbPath: '',
              };
            }
          }

          return receipt;
        })
      : receipts;
    const receiptListToSet = window.cordova
      ? await Promise.all(receiptPromiseListToSet)
      : receipts;

    if (window.cordova) {
      addImageRequestListToQueueStore(imageRequestList);
    }

    return receiptListToSet;
  };
};

export const useSyncServerCategoryTree = () => {
  const setCategoryTreeInRecoil = useSetCategoryTreeInRecoil();
  const initLocalReceiptList = useGetLocalReceiptList();

  return useCallback(
    async ({
      receipts,
      categories,
      accountId,
    }: Omit<SyncRecoilCategoryTreeDTO, 'currentUserSharedAccount'>) => {
      const receiptToSyncList = await initLocalReceiptList(receipts);

      setCategoryTreeInRecoil({
        categories,
        receipts: receiptToSyncList,
        accountId,
      });
    },
    [initLocalReceiptList, setCategoryTreeInRecoil],
  );
};

export const waitUntilCategoryTreeInitialized = async () => {
  while (true) {
    // TODO - add message bus
    if (!window.syncProgress) {
      break;
    }

    await delay(300);
  }
};
