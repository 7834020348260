import { getLastUpdated } from '@/category-tree-hooks/category-service';
import { shouldSkipUnderPermissions } from '@/category-tree-provider/category-tree-provider.helpers';
import {
  CategoryId,
  LocalCategory,
  LocalReceipt,
  Maybe,
  ReceiptId,
} from '@/core.types';
import Dexie, { Table } from 'dexie';
import { AvailableAccountShare } from '@/gql/api-user/api-user.types';
import { delay } from './helper-service';

export enum IRECEIPT_COLLECTIONS {
  localReceipts = 'localReceipts',
  localCategories = 'localCategories',
  localReceiptFiles = 'localReceiptFiles',
  request = 'request',
  fileDownloadQueue = 'fileDownloadQueue',
}

const db: any = new Dexie('IRECIPT');
const dbVersion = 2;

db.version(dbVersion).stores({
  [IRECEIPT_COLLECTIONS.localReceipts]: `id, updatedAt, accountId`,
  [IRECEIPT_COLLECTIONS.localCategories]: `id, updatedAt, accountId`,
  [IRECEIPT_COLLECTIONS.localReceiptFiles]: `id, accountId`,
  [IRECEIPT_COLLECTIONS.request]: `id, payload.id`,
  [IRECEIPT_COLLECTIONS.fileDownloadQueue]: `id, accountId`,
});

db.open();

export const requestTable = db[IRECEIPT_COLLECTIONS.request] as Table;

export const fileDownloadQueueTable = db[
  IRECEIPT_COLLECTIONS.fileDownloadQueue
] as Table;

export const localReceiptsTable = db[
  IRECEIPT_COLLECTIONS.localReceipts
] as Table;

export const localCategoriesTable = db[
  IRECEIPT_COLLECTIONS.localCategories
] as Table;

export const localReceiptFilesTable = db[
  IRECEIPT_COLLECTIONS.localReceiptFiles
] as Table;

export const checkIfTableIsEmpty = async (table: Table) => {
  const count = await table.count();
  return !count;
};

export const replaceTempIdInLocalFilesTable = async ({
  table,
  tempId,
  id,
}: {
  table: Table;
  tempId: ReceiptId;
  id: ReceiptId;
}) => {
  await table.update(tempId, { id: id });
};

export const waitUntilTableWillBeEmpty = async (table: Table) => {
  while (true) {
    if (await checkIfTableIsEmpty(table)) {
      break;
    }
    await delay(1000);
  }
};

export const waitUntilDBWillBeNotEmpty = async (table: Table) => {
  while (true) {
    if (!(await checkIfTableIsEmpty(table))) {
      break;
    }
    await delay(1000);
  }
};

export const waitUntilAppWillBeOnline = async () => {
  while (true) {
    if (window.onlineDispatcher.isOnline) {
      break;
    }
    await delay(1000);
  }
};

export const getLocalCategoryTreeFromIndexedDB = async (
  currentAccountId: Maybe<number>,
  currentUserSharedAccount: Maybe<AvailableAccountShare>,
  userId: Maybe<number>,
  getLocalReceiptList: (receipts: LocalReceipt[]) => Promise<LocalReceipt[]>,
) => {
  const categoriesFromCache: LocalCategory[] = [];
  const receiptsFromCache: LocalReceipt[] = [];

  const categoryIdsToClearList: CategoryId[] = [];
  const receiptIdsToClearList: ReceiptId[] = [];

  if (!currentAccountId) {
    return {
      categoriesFromCache: [],
      receiptsFromCache: [],
      lastCategoryUpdatedAtFromCache: null,
      lastReceiptUpdatedAtFromCache: null,
    };
  }

  const allUpdatedAtCategoriesStamps: Array<Maybe<number>> = [];
  const allUpdatedAtReceiptsStamps: Array<Maybe<number>> = [];

  const iterateOverCategoriesPromise = localCategoriesTable
    .where('accountId')
    .equals(currentAccountId)
    .toArray()
    .then((localCategories: LocalCategory[]) => {
      localCategories.forEach((localCategory: LocalCategory) => {
        if (
          shouldSkipUnderPermissions(
            localCategory,
            currentUserSharedAccount,
            userId,
          )
        ) {
          categoryIdsToClearList.push(String(localCategory.id));
        } else {
          categoriesFromCache.push(localCategory);
          allUpdatedAtCategoriesStamps.push(localCategory.updatedAt);
        }
      });
    });

  const iterateOverReceiptsPromise = localReceiptsTable
    .where('accountId')
    .equals(currentAccountId)
    .toArray()
    .then((localReceipts: LocalReceipt[]) => {
      localReceipts.forEach((localReceipt: LocalReceipt) => {
        if (
          shouldSkipUnderPermissions(
            localReceipt,
            currentUserSharedAccount,
            userId,
          )
        ) {
          receiptIdsToClearList.push(String(localReceipt.id));
        } else {
          receiptsFromCache.push(localReceipt);
          allUpdatedAtReceiptsStamps.push(localReceipt.updatedAt);
        }
      });
    });

  await Promise.all([iterateOverCategoriesPromise, iterateOverReceiptsPromise]);
  await Promise.all([
    localCategoriesTable.where('id').anyOf(categoryIdsToClearList).delete(),
    localReceiptsTable.where('id').anyOf(receiptIdsToClearList).delete(),
  ]);

  const lastCategoryUpdatedAtFromCache = getLastUpdated(
    allUpdatedAtCategoriesStamps,
  );

  const lastReceiptUpdatedAtFromCache = getLastUpdated(
    allUpdatedAtReceiptsStamps,
  );

  const finalReceiptsFromCache = await getLocalReceiptList(receiptsFromCache);

  return {
    categoriesFromCache,
    receiptsFromCache: finalReceiptsFromCache,
    lastCategoryUpdatedAtFromCache,
    lastReceiptUpdatedAtFromCache,
  };
};
