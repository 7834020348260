import {
  FaApple,
  FaArrowLeft,
  FaArrowRight,
  FaCalendarAlt,
  FaClock,
  FaCog,
  FaCommentDots,
  FaCreditCard,
  FaEnvelope,
  FaFileAlt,
  FaFilePdf,
  FaGift,
  FaGlobe,
  FaHome,
  FaList,
  FaLock,
  FaPalette,
  FaPhone,
  FaPrint,
  FaShareAlt,
  FaSignOutAlt,
  FaStar,
  FaSyncAlt,
  FaTh,
  FaTrash,
  FaUserPlus,
  FaUsers,
} from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';
import { AppIconProps } from '../app-icon';
import { MdGroups } from 'react-icons/md';

export const ClockIcon = ({
  size = { width: 18, height: 18 },
  color = 'white',
}: AppIconProps) => {
  const { width } = size;
  return <FaClock size={width} color={color} />;
};

export const ThemeIcon = ({
  size = { width: 12, height: 22 },
  color = 'white',
  opacity = '1',
}: AppIconProps) => {
  const { width } = size;
  return <FaPalette size={width} color={color} style={{ opacity }} />;
};

export const TermsIcon = ({
  size = { width: 20, height: 20 },
  color = 'white',
  opacity = '1',
}: AppIconProps) => {
  const { width } = size;
  return <FaFileAlt size={width} color={color} style={{ opacity }} />;
};

export const LogoutIcon = ({
  size = { width: 20, height: 20 },
  color = 'white',
}) => {
  const { width } = size;
  return <FaSignOutAlt size={width} color={color} />;
};

export const GearIcon = ({
  size = { width: 14, height: 14 },
  color = 'white',
  opacity = '1',
}) => {
  const { width } = size;
  return <FaCog size={width} color={color} style={{ opacity }} />;
};

export const TileIcon = ({
  size = { width: 22, height: 22 },
  color = 'white',
}) => {
  const { width } = size;
  return <FaTh size={width} color={color} />;
};

export const PdfIcon = ({
  size = { width: 38, height: 38 },
  color = 'white',
}) => {
  const { width, height } = size;
  return (
    <FaFilePdf
      width={width}
      height={height}
      color={color}
      style={{ width, height }}
    />
  );
};

export const TrashIcon = ({
  size = { width: 22, height: 32 },
  color = 'white',
}) => {
  const { width } = size;
  return <FaTrash size={width} color={color} />;
};

export const LockIcon = ({
  size = { width: 20, height: 20 },
  color = 'white',
  opacity = '1',
}) => {
  const { width } = size;
  return <FaLock size={width} color={color} style={{ opacity }} />;
};

export const HomeIcon = ({
  size = { width: 36, height: 32 },
  color = 'white',
}) => {
  const { width } = size;
  return <FaHome size={width} color={color} />;
};

export const ShareIcon = ({
  size = { width: 30, height: 30 },
  color = 'white',
  opacity = '1',
}) => {
  const { width } = size;
  return <FaShareAlt size={width} color={color} style={{ opacity }} />;
};

export const LanguageIcon = ({
  size = { width: 20, height: 20 },
  color = 'white',
  opacity = '1',
}) => {
  const { width } = size;
  return <FaGlobe size={width} color={color} style={{ opacity }} />;
};

export const PhoneIcon = ({
  size = { width: 22, height: 22 },
  color = 'white',
  opacity = '1',
}: AppIconProps) => {
  const { width } = size;

  return <FaPhone size={width} color={color} style={{ opacity }} />;
};

export const QualityIcon = ({
  size = { width: 20, height: 20 },
  color = 'white',
  opacity = '1',
}) => {
  const { width } = size;
  return <FaStar size={width} color={color} style={{ opacity }} />;
};

export const MailIcon = ({
  size = { width: 20, height: 20 },
  color = 'white',
  opacity = '1',
}) => {
  const { width } = size;
  return <FaEnvelope size={width} color={color} style={{ opacity }} />;
};

export const UsersIcon = ({
  size = { width: 20, height: 20 },
  color = 'white',
  opacity = '1',
}) => {
  const { width } = size;
  return <FaUsers size={width} color={color} style={{ opacity }} />;
};

export const GoogleIcon = ({ size = { width: 25, height: 25 } }) => {
  const { width } = size;
  return <FcGoogle size={width} />;
};

export const GiftIcon = ({
  size = { width: 60, height: 64 },
  color = 'currentColor',
}) => {
  const { width } = size;
  return <FaGift size={width} color={color} />;
};

export const AppleIcon = ({
  size = { width: 25, height: 25 },
  color = 'black',
}: AppIconProps) => {
  const { width } = size;
  return <FaApple size={width} color={color} />;
};

export const PasswordIcon = ({
  size = { width: 20, height: 20 },
  color = 'white',
}: any) => {
  const { width } = size;
  return <FaLock size={width} color={color} />;
};

export const ListIcon = ({
  size = { width: 22, height: 22 },
  color = 'white',
}) => {
  const { width } = size;
  return <FaList size={width} color={color} />;
};

export const PrintIcon = ({
  size = { width: 30, height: 30 },
  color = 'white',
}) => {
  const { width } = size;
  return <FaPrint size={width} color={color} />;
};

export const RotateIcon = ({
  size = { width: 20, height: 20 },
  color = 'white',
}) => {
  const { width } = size;
  return <FaSyncAlt size={width} color={color} />;
};

export const InviteFriendIcon = ({
  size = { width: 21, height: 21 },
  color = 'white',
  opacity = '1',
}) => {
  const { width } = size;
  return <FaUserPlus size={width} color={color} style={{ opacity }} />;
};

export const ArrowRightIcon = ({
  size = { width: 12, height: 22 },
  color = 'white',
  opacity = '1',
}) => {
  const { width } = size;
  return <FaArrowRight size={width} color={color} style={{ opacity }} />;
};

export const ArrowLeftIcon = ({
  size = { width: 12, height: 22 },
  color = 'white',
}) => {
  const { width } = size;
  return <FaArrowLeft size={width} color={color} />;
};

export const PeopleGroupIcon = ({
  size = { width: 30, height: 30 },
  color = 'white',
}) => {
  const { width } = size;
  return <MdGroups size={width} color={color} />;
};

export const SmsIcon = ({
  size = { width: 20, height: 20 },
  color = 'white',
  opacity = '1',
}) => {
  const { width } = size;
  return <FaCommentDots size={width} color={color} style={{ opacity }} />;
};

export const CalendarIcon = ({
  size = { width: 30, height: 30 },
  color = 'white',
  opacity = '1',
}) => {
  const { width } = size;
  return <FaCalendarAlt size={width} color={color} style={{ opacity }} />;
};

export const SubscriptionIcon = ({
  height = 20,
  color = 'white',
  opacity = 1,
}) => {
  return <FaCreditCard size={height} color={color} style={{ opacity }} />;
};
