import { useSyncCategoryTree } from '@/category-tree-hooks/use-sync-category-tree';
import { useRootContext } from '@/context/root-context';
import { useInitPushNotifications } from '@/hooks/use-init-push-notifications';
import { useRegisterPurchaseList } from '@/hooks/use-register-purchase-list';
import { isAuthorizedAtom } from '@/store';
import { memo, ReactNode, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useImageRequestSync } from '../category-tree-hooks/use-image-request-sync';
import { useRequestSync } from '../category-tree-hooks/use-request-sync';

export const RootWrapper = memo(({ children }: { children: ReactNode }) => {
  const isAuthorized = useRecoilValue(isAuthorizedAtom);
  const startSync = useSyncCategoryTree();
  const { userId } = useRootContext();

  useEffect(() => {
    if (isAuthorized) {
      startSync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized]);

  useRequestSync();
  useImageRequestSync();
  useInitPushNotifications();
  useRegisterPurchaseList(userId as number);

  return <>{children}</>;
});
