import { AppBackButton } from '@/components/app-back-button';
import { AppForwardButton } from '@/components/app-forward-button';
import { FILTER_TYPE } from '@/components/CropImages/image-filters';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCropStepContext } from './crop-step-context';
import { CropStepImageActionControl } from './crop-step-image-action-control';
import { CONTROL_TYPE } from './crop-step.constants';

let rotateProgress = false;

export const CropStepImageActions = () => {
  const {
    toggleStyle,
    toggleIntensity,
    hideIntensity,
    hideStyle,
    enableProgress,
    disableProgress,
    progressEnabled,
    onBackClick,
    isSmallResolution,
    onNextClick,
  } = useCropStepContext();
  const { formatMessage: t } = useIntl();

  const [rotateDegree, setRotateDegree] = useState(
    window.newPoly.filters.rotateDegree,
  );

  const onRotateClick = async () => {
    try {
      if (!rotateProgress || !window.instIdCrop.handles.length) {
        rotateProgress = true;
        const newRotateDegree = rotateDegree === 360 ? 90 : rotateDegree + 90;
        setRotateDegree(newRotateDegree);
        await window.newPoly.applyFilters({ rotateDegree: newRotateDegree });
      }
    } finally {
      rotateProgress = false;
    }
  };

  const onCorrectionClick = async () => {
    try {
      enableProgress();
      await window.newPoly.applyFilters({ filterType: FILTER_TYPE.megaFilter });
      disableProgress();
    } finally {
      disableProgress();
    }
  };

  const onStyleClick = () => {
    try {
      hideIntensity();
      toggleStyle();
    } finally {
      disableProgress();
    }
  };

  const onIntensityClick = () => {
    try {
      hideStyle();
      toggleIntensity();
    } finally {
      disableProgress();
    }
  };

  const imageControlsList = [
    {
      onClick: onRotateClick,
      id: 'rotate-button',
      type: CONTROL_TYPE.rotate,
    },
    {
      onClick: onCorrectionClick,
      id: 'correction-button',
      type: CONTROL_TYPE.correction,
    },
    {
      onClick: onStyleClick,
      id: 'style-button',
      type: CONTROL_TYPE.style,
    },
    {
      onClick: onIntensityClick,
      id: 'intensity-button',
      type: CONTROL_TYPE.intensity,
    },
  ];

  return (
    <div className='px-[15px] bg-[var(--photoBackground)] shadow-[0_-4px_10px_rgba(0,0,0,0.2)] h-[70px] z-10 relative flex items-center justify-center'>
      <div className='w-full h-full flex items-center justify-center px-[15px] laptop:max-w-[1023px] laptop:mx-auto laptop:p-0 laptop:justify-between laptop:items-stretch'>
        {!isSmallResolution && (
          <AppBackButton label={t({ id: 'back' })} onBackClick={onBackClick} />
        )}
        <div className='flex items-center w-[460px] mx-auto'>
          <div className='w-full max-w-[500px] flex items-center justify-between mx-auto relative'>
            {imageControlsList.map((action) => (
              <CropStepImageActionControl
                {...action}
                key={action.id}
                disabled={progressEnabled}
              />
            ))}
          </div>
        </div>
        {!isSmallResolution && (
          <AppForwardButton
            label={t({ id: 'next' })}
            onNextClick={onNextClick}
          />
        )}
      </div>
    </div>
  );
};
