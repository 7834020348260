import { v4 as uuidv4 } from 'uuid';
import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';

export type GreenFolderIconProps = {
  size: {
    width: number | string;
    height: number | string;
  };
};

export const GreenFolderEmptyIcon = ({
  size = { width: 104, height: 70 },
}: GreenFolderIconProps) => {
  const { width, height } = size;
  const id = uuidv4();
  const colorFront = `var(${APP_CSS_THEME_VARIABLES.folderColorFront})`;
  const colorBack = `var(${APP_CSS_THEME_VARIABLES.folderColorBack})`;
  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 137 98'
    >
      <g filter={`url(#filter_folder-empty-${id})`}>
        <path
          d='M66.5 7.70613V6.2187C66.5 3.78802 64.398 2 61.5385 2H6.96154C4.10204 2 2 3.78802 2 6.20056V85.1614H131V12.0596C131 9.64705 128.678 7.68799 125.819 7.68799H66.5V7.70613Z'
          fill={colorBack}
        />

        <path
          d='M62.7375 20.1779H7.1815C4.322 20.1779 2 22.3586 2 25.0643V87.1338C2 89.8193 4.322 92 7.1815 92H125.797C128.656 92 130.979 89.8193 130.979 87.1338V26.1923C130.979 22.775 128.204 20.0072 124.786 20.0161L62.7375 20.1779Z'
          fill={colorFront}
        />
      </g>
      <defs>
        <filter
          id={`filter_folder-empty-${id}`}
          x='0'
          y='0'
          width='137'
          height='98'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_2825_830'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_2825_830'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
