import { AppPseudoRadio } from '@/components/app-pseudo-radio';
import { useRootContext } from '@/context/root-context';
import { getCurrency } from '@/currencySymbols';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';

type MadeByModalAccountItemProps = {
  id: number | string;
  ownerFullName: string;
  ownerPictureUrl: string;
  icon: string | ReactNode;
  ownerCurrency?: string;
};

export const MadeByModalAccountItem = ({
  ownerFullName,
  ownerCurrency = 'USD',
  ownerPictureUrl,
  id,
  icon,
}: MadeByModalAccountItemProps) => {
  const { formatMessage: t } = useIntl();
  const { filterMadeBy, setFilterMadeBy } = useRootContext();
  const isItemActive = filterMadeBy.id === id;

  const onItemClick = () => {
    setFilterMadeBy({ id, name: ownerFullName });
  };

  const iconClass =
    'flex items-center justify-center w-[55px] h-[55px] bg-[#252f6f] mr-[10px] rounded-full';
  let finalIcon;

  if (icon) {
    finalIcon =
      typeof icon === 'string' ? (
        <div className={iconClass}>
          <img src={icon} alt='' />
        </div>
      ) : (
        <div className={iconClass}>{icon}</div>
      );
  } else {
    finalIcon = (
      <img
        src={ownerPictureUrl}
        alt=''
        className='w-[55px] h-[55px] bg-[#252f6f] mr-[10px] rounded-full object-cover object-center'
      />
    );
  }

  return (
    <div
      className='flex items-center justify-between mb-[10px] cursor-pointer transition-[0.2s_linear] hover:filter hover:drop-shadow-[0_0_2px_var(--emphasized)]'
      onClick={onItemClick}
    >
      <div className='flex items-center'>
        {finalIcon}
        <div className='flex flex-col items-start'>
          <div className='font-bold text-[18px] text-[#fff]'>
            {ownerFullName}
          </div>
          {import.meta.env.VITE_PROJECT_TYPE === 'ireceipt' && (
            <div className='font-normal text-[14px] text-[#c5cbec]'>
              {t({ id: 'menu.profile.info.currency' })}{' '}
              <span className='text-white'>
                {getCurrency(ownerCurrency)} ({ownerCurrency})
              </span>
            </div>
          )}
        </div>
      </div>
      <AppPseudoRadio {...{ isItemActive }} />
    </div>
  );
};
