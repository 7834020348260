import { PAGE_ROUTES } from '@/app.constants';
import { preloadImage } from '@/hooks/use-preload-image';
import { getBlob, HTMLImageElementToCanvas } from '@/services/helper-service';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CompressedImage } from '../core.types';
import { useBooleanState, useDetectMobileResolution } from '../hooks';
import { useDisableBackSwipe } from '../hooks/use-disable-back-swipe';
import { reduceFileSize } from '../services/image-service';
import {
  croppedImageFileAtom,
  newReceiptFileAtom,
  recognizedImagePointsAtom,
} from '../store';
import { RECEIPT_STYLE } from './crop-step.constants';

const readFileAsync = (file: Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};

export const useCropStepLogic = () => {
  const intl = useIntl();
  const history = useHistory();
  const newReceiptFile = useRecoilValue(newReceiptFileAtom);
  const setCroppedImageFile = useSetRecoilState(croppedImageFileAtom);
  const [previewImageLoaded, setPreviewImageIsLoaded] = useBooleanState();
  const refContainer = useRef(null);
  const displayRef = useRef(null);
  const [styleFilter, setStyleFilter] = useState(RECEIPT_STYLE.original);
  const [progressEnabled, enableProgress, disableProgress] =
    useBooleanState(false);
  const [intensityEnabled, enableIntensity, hideIntensity, toggleIntensity] =
    useBooleanState(false);
  const [styleEnabled, enableStyle, hideStyle, toggleStyle] =
    useBooleanState(false);
  const isSmallResolution = useDetectMobileResolution();
  useDisableBackSwipe();
  const recognizedImagePoints = useRecoilValue(recognizedImagePointsAtom);

  useEffect(() => {
    if (!newReceiptFile) {
      history.goBack();
      return;
    }

    try {
      reduceFileSize({
        file: newReceiptFile,
        maxSideSize: 2500,
        quality: 0.9,
        points: recognizedImagePoints,
      }).then((compressedImage: CompressedImage) => {
        readFileAsync(compressedImage.imageBlob).then((imageSrc: string) => {
          window.newPoly.loadImg(imageSrc, () => {
            window.newPoly.initCrop(
              refContainer,
              compressedImage.points,
              compressedImage.imageSize,
            );
            setPreviewImageIsLoaded();
          });
        });
      });
    } catch {
      history.goBack();
    }
  }, [history, newReceiptFile, recognizedImagePoints, setPreviewImageIsLoaded]);

  const onNextClick = async () => {
    enableProgress();
    window.newPoly.crop(async (img: HTMLImageElement) => {
      // @ts-expect-error note
      const canvas = HTMLImageElementToCanvas(img);
      const { file } = (await getBlob(canvas)) as { file: File };

      await setCroppedImageFile(file);
      await preloadImage(img.src, false);
      disableProgress();
      history.push(`${PAGE_ROUTES.category}${PAGE_ROUTES.createReceipt}`);
    });
  };

  const onBackClick = () => {
    window.newPoly.reset();
    history.goBack();
  };

  const disableBackSwipe = (event) => {
    event.preventDefault();
  };

  return {
    refContainer,
    previewImageLoaded,
    isSmallResolution,

    onNextClick,
    onBackClick,

    styleFilter,
    setStyleFilter,

    intensityEnabled,
    enableIntensity,
    hideIntensity,
    toggleIntensity,

    styleEnabled,
    enableStyle,
    hideStyle,
    toggleStyle,

    progressEnabled,
    enableProgress,
    disableProgress,

    intl,
    disableBackSwipe,
    displayRef,
  };
};
