import { AppHasAccess } from '@/components/app-has-access';
import { AppType } from '@/core.types';
import { getCurrency } from '@/currencySymbols';
import { resolvePathSvg } from '@/services/helper-service';
import cx from 'classnames';
import { CSSProperties } from 'react';
import { useIntl } from 'react-intl';
import noImage from '../images/no-avatar-icon.svg';
import { PeopleGroupIcon } from './app-icon/new-icons';
{
  /* <FaUserCircle size={30} color="white" /> */
}

export const ProfileInfo = ({
  groupIcon = false,
  userIcon,
  ownerFullName,
  userCurrency = 'USD',
  containerStyles,
}: {
  groupIcon?: boolean;
  userIcon: string;
  ownerFullName?: string;
  userCurrency?: string;
  containerStyles?: CSSProperties;
}) => {
  const { formatMessage: t } = useIntl();
  const userIconCheck = !userIcon || userIcon.includes('null');

  const resolvedUserIcon = userIconCheck ? resolvePathSvg(noImage) : userIcon;

  const finalIcon = userIcon ? resolvedUserIcon : resolvePathSvg(noImage);

  return (
    <div
      style={containerStyles ?? {}}
      className='flex items-center w-[calc(100%-72px)]'
    >
      <div
        className={cx(
          'relative w-[65px] h-[65px] rounded-full overflow-hidden border border-transparent drop-shadow-md',
          {
            'bg-[#6ec6b4] flex justify-center shadow-[inset_2px_2px_4px_rgba(27,88,76,0.25)]':
              userIconCheck,
            'bg-[#252f6f] flex justify-center shadow-[inset_2px_2px_4px_rgba(27,88,76,0.25)]':
              groupIcon,
          },
        )}
      >
        {!userIcon && groupIcon ? (
          <PeopleGroupIcon />
        ) : (
          <img
            src={finalIcon}
            width='65'
            height='65'
            alt=''
            className={`w-full h-full object-cover ${
              (userIconCheck || groupIcon) && 'w-1/2 object-none h-auto'
            }`}
          />
        )}
      </div>
      <div
        className='ml-2.5 flex flex-col w-[calc(100%-75px)]'
        style={{
          marginBottom: '0 !important',
        }}
      >
        <span className='font-roboto font-bold text-base leading-5 text-textMain truncate whitespace-nowrap overflow-hidden'>
          {ownerFullName}
        </span>
        <AppHasAccess appType={AppType.ireceipt}>
          <span className='text-textMain text-sm leading-4 mt-[5px]'>
            <span className='opacity-40'>
              {t({ id: 'menu.profile.info.currency' })}
            </span>
            <span>{` ${getCurrency(userCurrency)} (${userCurrency})`}</span>
          </span>
        </AppHasAccess>
      </div>
    </div>
  );
};
