import { getRequestHTTPHeaders } from '@/ir-apollo-client';

export const getCategoryList = ({
  lastReceiptUpdatedAt,
  lastCategoryUpdatedAt,
}: {
  lastReceiptUpdatedAt: number;
  lastCategoryUpdatedAt: number;
}) => {
  const controller = new AbortController();
  const signal = controller.signal;
  window.addEventListener('serverOffline', () => {
    controller.abort();
  });
  return fetch(import.meta.env.VITE_SINGLE_QL_LINK, {
    method: 'POST',
    signal: signal,
    headers: {
      'Content-Type': 'application/json',
      ...getRequestHTTPHeaders().headers,
    },
    body: JSON.stringify({
      query: `
      query {
        user {
          id
          __typename
          account {
            id
            availableAccountShares {
              id
              accountFrom {
                id
                ownerFullName
                ownerCurrency
                ownerPictureUrl
                ownerEmail
              }
              accountTo {
                id
              }
              canViewGroup
              canEditGroup
              canDeleteGroup
            }
          }
          currentAccount {
            id
            __typename
            receipts(lastUpdated: ${lastReceiptUpdatedAt}) {
              id
              imagePath
              thumbPath
              updatedAt
              name
              type
              createdById
              createdBy
              receiptDate
              remindedAt
              parentId
              deleted
              amount
            }
            categories(lastUpdated: ${lastCategoryUpdatedAt}) {
              id
              parentId
              name
              deleted
              updatedAt
              createdBy
              createdById
              type
            }
          }
        }
      }
    `,
    }),
  }).then((res) => {
    return res.json();
  });
};
