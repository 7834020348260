const DEFAULT_COLOR = 'white';
const DEFAULT_OPACITY = '1';

export const calendarIcon = (
  color = DEFAULT_COLOR,
  opacity = DEFAULT_OPACITY,
) => {
  return `<svg
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g opacity=${opacity}>
          <path
            d='M10.1875 14.6667H8.8125C8.45655 14.6667 8.16797 14.9865 8.16797 15.3809C8.16797 15.7754 8.45655 16.0952 8.8125 16.0952H10.1875C10.5435 16.0952 10.832 15.7754 10.832 15.3809C10.832 14.9865 10.5435 14.6667 10.1875 14.6667Z'
            fill=${color}
          />
          <path
            d='M10.1875 17.7143H8.8125C8.45655 17.7143 8.16797 18.0341 8.16797 18.4286C8.16797 18.8231 8.45655 19.1429 8.8125 19.1429H10.1875C10.5435 19.1429 10.832 18.8231 10.832 18.4286C10.832 18.0341 10.5435 17.7143 10.1875 17.7143Z'
            fill=${color}
          />
          <path
            d='M10.1875 20.7619H8.8125C8.45655 20.7619 8.16797 21.0817 8.16797 21.4762C8.16797 21.8707 8.45655 22.1905 8.8125 22.1905H10.1875C10.5435 22.1905 10.832 21.8707 10.832 21.4762C10.832 21.0817 10.5435 20.7619 10.1875 20.7619Z'
            fill=${color}
          />
          <path
            d='M15.6875 14.6667H14.3125C13.9565 14.6667 13.668 14.9865 13.668 15.3809C13.668 15.7754 13.9565 16.0952 14.3125 16.0952H15.6875C16.0435 16.0952 16.332 15.7754 16.332 15.3809C16.332 14.9865 16.0435 14.6667 15.6875 14.6667Z'
            fill=${color}
          />
          <path
            d='M15.6875 17.7143H14.3125C13.9565 17.7143 13.668 18.0341 13.668 18.4286C13.668 18.8231 13.9565 19.1429 14.3125 19.1429H15.6875C16.0435 19.1429 16.332 18.8231 16.332 18.4286C16.332 18.0341 16.0435 17.7143 15.6875 17.7143Z'
            fill=${color}
          />
          <path
            d='M15.6875 20.7619H14.3125C13.9565 20.7619 13.668 21.0817 13.668 21.4762C13.668 21.8707 13.9565 22.1905 14.3125 22.1905H15.6875C16.0435 22.1905 16.332 21.8707 16.332 21.4762C16.332 21.0817 16.0435 20.7619 15.6875 20.7619Z'
            fill=${color}
          />
          <path
            d='M21.1875 14.6667H19.8125C19.4565 14.6667 19.168 14.9865 19.168 15.3809C19.168 15.7754 19.4565 16.0952 19.8125 16.0952H21.1875C21.5435 16.0952 21.832 15.7754 21.832 15.3809C21.832 14.9865 21.5435 14.6667 21.1875 14.6667Z'
            fill=${color}
          />
          <path
            d='M21.1875 17.7143H19.8125C19.4565 17.7143 19.168 18.0341 19.168 18.4286C19.168 18.8231 19.4565 19.1429 19.8125 19.1429H21.1875C21.5435 19.1429 21.832 18.8231 21.832 18.4286C21.832 18.0341 21.5435 17.7143 21.1875 17.7143Z'
            fill=${color}
          />
          <path
            d='M21.1875 20.7619H19.8125C19.4565 20.7619 19.168 21.0817 19.168 21.4762C19.168 21.8707 19.4565 22.1905 19.8125 22.1905H21.1875C21.5435 22.1905 21.832 21.8707 21.832 21.4762C21.832 21.0817 21.5435 20.7619 21.1875 20.7619Z'
            fill=${color}
          />
          <path
            d='M24.0664 5.90476H22.5195V4.71429C22.5195 4.31981 22.231 4 21.875 4C21.519 4 21.2305 4.31981 21.2305 4.71429V5.90476H15.6445V4.71429C15.6445 4.31981 15.356 4 15 4C14.644 4 14.3555 4.31981 14.3555 4.71429V5.90476H8.76953V4.71429C8.76953 4.31981 8.48095 4 8.125 4C7.76905 4 7.48047 4.31981 7.48047 4.71429V5.90476H5.93359C4.86741 5.90476 4 6.86605 4 8.04762V23.8571C4 25.0387 4.86741 26 5.93359 26H24.0664C25.1326 26 26 25.0387 26 23.8571C26 23.3961 26 8.44881 26 8.04762C26 6.86605 25.1326 5.90476 24.0664 5.90476ZM5.28906 8.04762C5.28906 7.65376 5.5782 7.33333 5.93359 7.33333H7.48047V8.52381C7.48047 8.91829 7.76905 9.2381 8.125 9.2381C8.48095 9.2381 8.76953 8.91829 8.76953 8.52381V7.33333H14.3555V8.52381C14.3555 8.91829 14.644 9.2381 15 9.2381C15.356 9.2381 15.6445 8.91829 15.6445 8.52381V7.33333H21.2305V8.52381C21.2305 8.91829 21.519 9.2381 21.875 9.2381C22.231 9.2381 22.5195 8.91829 22.5195 8.52381V7.33333H24.0664C24.4218 7.33333 24.7109 7.65376 24.7109 8.04762V10.8571H5.28906V8.04762ZM24.0664 24.5714H5.93359C5.5782 24.5714 5.28906 24.251 5.28906 23.8571V12.2857H24.7109V23.8571C24.7109 24.251 24.4218 24.5714 24.0664 24.5714Z'
            fill=${color}
          />
        </g>
      </svg>`;
};
