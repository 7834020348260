import c from 'classnames';
import noop from 'lodash/noop';
import { useState } from 'react';
import { ICON_NAME_MAP } from './app-icon.constants';

export type AppIconProps = {
  name: keyof typeof ICON_NAME_MAP;
  size?: {
    width: number | string;
    height: number | string;
  };
  hoverEnabled?: boolean;
  color?: string;
  opacity?: any;
  className?: string;
} & Record<string, any>;

export const AppIcon = ({
  className = '',
  name,
  onClick = noop,
  size,
  hoverEnabled = false,
  color,
  opacity,
  ...props
}: AppIconProps) => {
  const iconConfig = ICON_NAME_MAP[name] || {};
  const [iconColor, setIconColor] = useState(color ?? iconConfig?.color);

  const onIconMouseEnter = () => {
    if (hoverEnabled && iconConfig.hoverColor) {
      setIconColor(iconConfig.hoverColor);
    }
  };

  const onIconMouseLeave = () => {
    if (hoverEnabled) {
      setIconColor(color);
    }
  };

  if (!iconConfig.icon) {
    return null;
  }

  const Icon = iconConfig.icon;

  return (
    <span
      className={c('flex items-center justify-center', className)}
      onMouseEnter={onIconMouseEnter}
      onMouseLeave={onIconMouseLeave}
    >
      <Icon {...{ size, color: iconColor, opacity, ...props }} />
    </span>
  );
};
