import { PAGE_ROUTES } from '@/app.constants';
import { AppForm, AppFormTextField } from '@/components/app-form';
import { AppFormSubmitButton } from '@/components/app-form/app-form-submit-button';
import { AppLoader } from '@/components/app-loader';
import {
  AuthPageImage,
  AuthPageLink,
  AuthPageTitle,
  AuthPageWrapper,
} from '@/components/auth-base';
import { useRootContext } from '@/context/root-context';
import { usePageLayoutSizeStyle } from '@/create-receipt/use-page-layout-style';
import { useRegisterByMailMutation } from '@/graphql/codegen/graphql';
import { useGetSaveAreaPaddings } from '@/hooks/use-get-safe-area-paddings';
import { useRequestDeviceNotifications } from '@/hooks/use-request-device-notifications';
import {
  FORM_VALIDATION_RESOLVER,
  setFormServerErrors,
} from '@/scenes/common-auth-logic';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';

type SignUpValues = {
  email: string;
  password: string;
};

export const SignUp = () => {
  const { formatMessage: t } = useIntl();
  const [_registerByMail, { loading }] = useRegisterByMailMutation();
  const history = useHistory();
  const requestDeviceNotifications = useRequestDeviceNotifications();

  const { receiptType } = useRootContext();
  const { safeAreaTop, safeAreaBottom } = useGetSaveAreaPaddings();
  const { keyboardShown } = usePageLayoutSizeStyle({
    safeAreaTop,
    safeAreaBottom,
    receiptType,
  });

  const onSubmit = async (values: SignUpValues, _: any, { setError }: any) => {
    try {
      await _registerByMail({
        variables: values,
      });
      toast.info(t({ id: 'signUp.success' }), {
        autoClose: 3000,
        position: 'bottom-center',
      });

      requestDeviceNotifications();
      history.push(PAGE_ROUTES.login);
    } catch (errorResponse: any) {
      setFormServerErrors(errorResponse, setError);
    }
  };

  const onLogoClick = () => {
    history.push(PAGE_ROUTES.auth);
  };

  return (
    <AuthPageWrapper>
      {!keyboardShown && <AuthPageImage onClick={onLogoClick} />}

      <AuthPageTitle>{t({ id: 'signUp.h1' })}</AuthPageTitle>

      <AppForm
        onSubmit={onSubmit}
        className='w-full max-w-[300px]'
        formConfig={{
          resolver: FORM_VALIDATION_RESOLVER,
        }}
      >
        <AppFormTextField
          type='email'
          name='email'
          placeholder={t({ id: 'signUp.email' })}
          icon={{
            name: 'mail',
          }}
        />
        <AppFormTextField
          type='password'
          name='password'
          icon={{ name: 'password' }}
          placeholder={t({ id: 'signUp.pass' })}
        />
        <AppFormSubmitButton>{t({ id: 'btn.sign' })}</AppFormSubmitButton>

        <AuthPageLink to={PAGE_ROUTES.otpLogin}>
          {t({ id: 'login.otp' })}
        </AuthPageLink>

        <AuthPageLink to={PAGE_ROUTES.forgotPassword}>
          {t({ id: 'login.forgot' })}
        </AuthPageLink>
      </AppForm>
      <AuthPageLink to='/login'>
        {t({ id: 'signUp.footer' })} {t({ id: 'signUp.footer.in' })}
      </AuthPageLink>
      {loading && <AppLoader />}
    </AuthPageWrapper>
  );
};
