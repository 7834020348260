export const uppercaseFirstLettersInEachWordOfAString = (
  string: string,
): string => {
  const symbolRange = '\\w\\u0400-\\u04FF';

  const regex = new RegExp(
    '([^' + symbolRange + ']|^)([' + symbolRange + '])',
    'g',
  );

  return string.replace(regex, function ($0, $1, $2) {
    return $1 + $2.toUpperCase();
  });
};
