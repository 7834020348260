import { useRootContext } from '@/context/root-context';
import { useGetUser } from '@/gql/api-user';

import {
  useAddPhoneMutation,
  useDeletePhoneMutation,
} from '@/graphql/codegen/graphql';

export const usePhoneLogic = () => {
  const { currentUserPhones } = useRootContext();
  const { fetchUser } = useGetUser();

  const [addPhoneRequest, { loading: processingAddPhone }] =
    useAddPhoneMutation();
  const [deletePhoneRequest, { loading: processingDeletePhone }] =
    useDeletePhoneMutation();

  const addPhone = async (phone: string) => {
    await addPhoneRequest({
      variables: { phone },
    });
    fetchUser();
  };

  const deletePhone = async (id: number) => {
    await deletePhoneRequest({
      variables: { id },
    });
    fetchUser();
  };

  return {
    loading: processingDeletePhone || processingAddPhone,
    userPhones: currentUserPhones,
    deletePhone,
    addPhone,
  };
};
