import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppIcon } from '@/components';
import { AppButton } from '@/components/app-button';
import { useIntl } from 'react-intl';

export const AddButton = ({
  label,
  onClick,
  className = '',
  withInfo = false,
  maxAccounts = 5,
}: {
  label: string;
  onClick(): void;
  className?: string;
  withInfo?: boolean;
  maxAccounts?: number;
}) => {
  const { formatMessage: t } = useIntl();
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;

  return (
    <AppButton
      className={`flex items-center mt-[20px] ml-[-2px] cursor-pointer ${className}`}
      onPress={onClick}
    >
      <AppIcon
        className='filter drop-shadow-[0_0_4px_var(--textMain20)]'
        name='add-icon'
        color={colorEmphasized}
      />

      <div className='relative ml-[10px] text-[var(--textMain)] text-left'>
        <div>{label}</div>

        {withInfo && (
          <div className='pointer-events-none absolute bottom-[-10px] left-0 w-[200%] text-[9px] leading-[11px] tracking-[-0.3px] text-[var(--textMain)] opacity-30 text-left'>
            {t({ id: 'add.item.additional.info-1' })}
            {maxAccounts}
            {t({ id: 'add.item.additional.info-2' })}
          </div>
        )}
      </div>
    </AppButton>
  );
};
