import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import { AppConfirmModal } from '@/components/app-confirm-modal';
import { AppLoader } from '@/components/app-loader';
import { AppTappable } from '@/components/app-tappable';
import { useBooleanState } from '@/hooks/use-boolean-state';
import { useLogout } from '@/hooks/use-logout';
import { useIntl } from 'react-intl';
import { MenuItemClass } from './components/settings-menu';
import { SettingsIcon } from './settings-icon';

export const LogoutButton = () => {
  const [
    confirmLogoutModalOpened,
    openConfirmLogoutModal,
    closeConfirmLogoutModal,
  ] = useBooleanState(false);
  const { formatMessage: t } = useIntl();
  const [logoutInProgress, setLogoutProgress, unsetLogoutProgress] =
    useBooleanState(false);

  const logOut = useLogout();
  const colorEmphasized = `var(${APP_CSS_THEME_VARIABLES.emphasized})`;

  const onConfirmClick = async () => {
    if (!logoutInProgress) {
      setLogoutProgress();
      closeConfirmLogoutModal();
      logOut();
      unsetLogoutProgress();
    }
  };

  return (
    <>
      <AppTappable
        component='li'
        onTap={openConfirmLogoutModal}
        className={`${MenuItemClass} laptop:mb-[15px]`}
      >
        <div className='flex'>
          <SettingsIcon name='logout' color={colorEmphasized} />
          <span className='font-bold text-[18px] leading-[22px] tracking-[-0.1px] text-[var(--emphasized)] ml-[10px]'>
            {t({ id: 'menu.logout' })}
          </span>
        </div>
      </AppTappable>

      <AppConfirmModal
        open={confirmLogoutModalOpened}
        onConfirmClick={onConfirmClick}
        title={t({ id: 'menu.logout.modal.title' })}
        onClose={closeConfirmLogoutModal}
      />
      {logoutInProgress && <AppLoader />}
    </>
  );
};
