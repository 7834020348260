// @ts-nocheck
import Point from './Point';

/*
 * update Change this class to extend HTMLElement when it's stabilized and
 * available in most browsers. Right now it only works in Chrome.
 * https://html.spec.whatwg.org/multipage/custom-elements.html#custom-elements
 */

const inx = 0;

export default class Handle {
  constructor(parent, offsetX, offsetY, point, inx) {
    this.parent = parent;
    this.offsetX = offsetX;
    this.offsetY = offsetY;
    this.point = new Point(point.x, point.y);
    this.node = document.createElement('div');
    this.childNode = document.createElement('div');
    this.inx = inx;
  }

  init() {
    this.node.className = 'idwall-handle';
    this.node.dataset.inx = this.inx;
    this.node.classList.add();
    this.parent.appendChild(this.node);
    this.childNode.className = 'idwall-handle__circle';
    this.node.appendChild(this.childNode);
    this.updateStyle();
  }

  updatePoint(point) {
    this.point.x = point.x;
    this.point.y = point.y;
    this.updateStyle();
  }

  updateStyle() {
    this.node.style.left =
      this.point.x + this.offsetX - this.node.offsetWidth / 2 + 'px';
    this.node.style.top =
      this.point.y + this.offsetY - this.node.offsetHeight / 2 + 'px';
  }
}
