import { AppType } from '@/core.types';
import { useEffect } from 'react';
import { hasAppAccess } from '@/services/app-service';

export const useSetFavicon = () => {
  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      // @ts-ignore
      link.rel = 'icon';
      document.head.appendChild(link);
    }
    const prefix = window.location.origin + import.meta.env.VITE_PUBLIC_URL;
    const faviconUrl = hasAppAccess(AppType.ireceipt)
      ? prefix + '/favicon.ico'
      : prefix + '/favicon-idocument.ico';

    if (link) {
      // @ts-ignore
      link.href = faviconUrl;
    }
  }, []);
};
