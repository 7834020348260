import {
  AddFileIcon,
  EditIcon,
  CloseIcon,
  CreateFolderIcon,
  SelectModeIcon,
  FileIcon,
  TakePictureIcon,
  ImageGalleryIcon,
  CheckIcon,
  CorrectionIcon,
  FolderOpenIcon,
  EditPencilIcon,
  GreenFolderNonEmptyIcon,
  IntensityIcon,
  StyleIcon,
  GreenFolderEmptyIcon,
  FamilyAccountIcon,
  BusinessAccountIcon,
  PersonalAccountIcon,
  ChangeAccountIcon,
  ComingSoonIcon,
  NotifyIcon,
  CreditCardPaymentIcon,
  FacebookIcon,
  NoUserIcon,
  PlusIcon,
  SearchIcon,
  PaletteIcon,
  AddIcon,
} from './icons';
import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import {
  AppleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarIcon,
  ClockIcon,
  GearIcon,
  GiftIcon,
  GoogleIcon,
  HomeIcon,
  InviteFriendIcon,
  LanguageIcon,
  ListIcon,
  LockIcon,
  LogoutIcon,
  MailIcon,
  PasswordIcon,
  PdfIcon,
  PhoneIcon,
  PrintIcon,
  QualityIcon,
  RotateIcon,
  ShareIcon,
  SmsIcon,
  SubscriptionIcon,
  TermsIcon,
  ThemeIcon,
  TileIcon,
  TrashIcon,
  UsersIcon,
} from './new-icons';
import { ReceiptIcon } from './icons/receipt-icon';

type IconConfig = {
  icon?: any;
  hoverColor?: string;
  color?: string;
};

const colorEmphasized = () => `var(${APP_CSS_THEME_VARIABLES.icons})`;

export const ICON_NAME_MAP: Record<string, IconConfig> = {
  edit: {
    icon: EditIcon,
    color: colorEmphasized(),
  },
  close: {
    icon: CloseIcon,
    color: colorEmphasized(),
  },
  trash: {
    icon: TrashIcon,
    color: colorEmphasized(),
  },
  pdf: {
    icon: PdfIcon,
    color: colorEmphasized(),
  },
  list: {
    icon: ListIcon,
  },
  print: {
    icon: PrintIcon,
    color: colorEmphasized(),
  },
  share: {
    icon: ShareIcon,
    color: colorEmphasized(),
  },
  tile: {
    icon: TileIcon,
    color: colorEmphasized(),
  },
  'create-folder': {
    icon: CreateFolderIcon,
    color: colorEmphasized(),
  },
  'select-mode': {
    icon: SelectModeIcon,
    color: colorEmphasized(),
  },
  clock: {
    icon: ClockIcon,
  },
  file: {
    icon: FileIcon,
  },
  'take-picture': {
    icon: TakePictureIcon,
  },
  'image-gallery': {
    icon: ImageGalleryIcon,
  },
  'arrow-right': {
    icon: ArrowRightIcon,
    color: colorEmphasized(),
  },
  'arrow-left': {
    icon: ArrowLeftIcon,
  },
  check: {
    icon: CheckIcon,
  },
  correction: {
    icon: CorrectionIcon,
    color: colorEmphasized(),
  },
  'folder-open': {
    icon: FolderOpenIcon,
    color: colorEmphasized(),
  },
  'edit-pencil': {
    icon: EditPencilIcon,
  },
  'green-folder-non-empty': {
    icon: GreenFolderNonEmptyIcon,
  },
  intensity: {
    icon: IntensityIcon,
    color: colorEmphasized(),
  },
  style: {
    icon: StyleIcon,
    color: colorEmphasized(),
  },
  rotate: {
    icon: RotateIcon,
    color: colorEmphasized(),
  },
  'green-folder-empty': {
    icon: GreenFolderEmptyIcon,
  },
  receipt: {
    icon: ReceiptIcon,
  },
  'family-account': {
    icon: FamilyAccountIcon,
  },
  'business-account': {
    icon: BusinessAccountIcon,
  },
  'personal-account': {
    icon: PersonalAccountIcon,
  },
  gear: {
    icon: GearIcon,
  },
  'invite-friend': {
    icon: InviteFriendIcon,
  },
  subscription: {
    icon: SubscriptionIcon,
  },
  lock: {
    icon: LockIcon,
  },
  mail: {
    icon: MailIcon,
  },
  phone: {
    icon: PhoneIcon,
  },
  language: {
    icon: LanguageIcon,
  },
  theme: {
    icon: ThemeIcon,
  },
  quality: {
    icon: QualityIcon,
  },
  'change-account': {
    icon: ChangeAccountIcon,
  },
  logout: {
    icon: LogoutIcon,
  },
  gift: {
    icon: GiftIcon,
  },
  sms: {
    icon: SmsIcon,
  },
  'coming-soon': {
    icon: ComingSoonIcon,
  },
  notify: {
    icon: NotifyIcon,
  },
  calendar: {
    icon: CalendarIcon,
  },
  'users-icon': {
    icon: UsersIcon,
    color: colorEmphasized(),
  },
  payment: {
    icon: CreditCardPaymentIcon,
  },
  apple: {
    icon: AppleIcon,
  },
  facebook: {
    icon: FacebookIcon,
  },
  google: {
    icon: GoogleIcon,
  },
  home: {
    icon: HomeIcon,
    color: colorEmphasized(),
  },
  'no-user': {
    icon: NoUserIcon,
    color: colorEmphasized(),
  },
  'plus-icon': {
    icon: PlusIcon,
  },
  'search-icon': {
    icon: SearchIcon,
  },
  'add-file': {
    icon: AddFileIcon,
  },
  palette: {
    icon: PaletteIcon,
  },
  'add-icon': {
    icon: AddIcon,
  },
  password: {
    icon: PasswordIcon,
  },
  terms: {
    icon: TermsIcon,
  },
};
