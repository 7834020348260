import { Maybe } from '@/core.types';
import { useBooleanState } from '@/hooks';
import { subscriptionPlanListAtom } from '@/store/root-store';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import {
  PlanInformation,
  SubscriptionGroup,
  activePlanSubscriptionGroups,
  mockedPlansInfo,
} from './app-plan.types';

export const usePlanLogic = () => {
  const [loader, loaderSetProgress, loaderHide] = useBooleanState(false);
  const [activeSubscriptionGroupTab, setActiveSubscriptionGroupTab] = useState(
    SubscriptionGroup.plus,
  );
  const { formatMessage: t } = useIntl();

  const planList = useRecoilValue(subscriptionPlanListAtom);

  const activePlanInformation = ((): Maybe<PlanInformation> => {
    if (!planList || !window.cordova) {
      return null;
    }

    try {
      const subscriptionIdList =
        activePlanSubscriptionGroups[activeSubscriptionGroupTab];

      const [monthlySubscriptionId, yearlySubscriptionId] = subscriptionIdList;

      const monthlyProduct = planList[monthlySubscriptionId]?.product;
      const yearlyProduct = planList[yearlySubscriptionId]?.product;

      const monthlyPrice = monthlyProduct?.price ?? 0;
      const yearlyPrice = yearlyProduct?.price ?? 0;

      console.log('planList', planList);
      console.log('monthlyProduct', monthlyProduct);
      console.log('yearlyProduct', yearlyProduct);
      console.log('yearlyProduct?.discounts', yearlyProduct?.discounts);

      const firstYearPrice = yearlyProduct?.introPrice?.price || yearlyPrice;
      console.log({
        firstYearPrice,
        yearlyPrice,
        monthlyPrice,
      });

      const discount = firstYearPrice
        ? Math.round(((yearlyPrice - firstYearPrice) * 100) / yearlyPrice)
        : monthlyPrice
        ? Math.round(
            ((monthlyPrice * 12 - yearlyPrice) * 100) / (monthlyPrice * 12),
          )
        : 0;

      const currentPlan = {
        currency: monthlyProduct?.currencyCode,
        monthlyPrice: monthlyPrice ? Number(monthlyPrice.toFixed(2)) : '0.00',
        yearlyPrice: Number(yearlyPrice.toFixed(2)),
        monthlyId: monthlySubscriptionId,
        yearlyId: yearlySubscriptionId,
        firstYearPrice: firstYearPrice?.toFixed(2),
        discount,
        // yearSubscriptionMonthlyPrice: Math.round(yearlyPrice / 12),
      } as PlanInformation;

      return currentPlan;
    } catch (error) {
      console.log(JSON.stringify(error));
      return null;
    }
  })();

  const onTabClick = (subscriptionGroup: SubscriptionGroup) => {
    if (activeSubscriptionGroupTab !== subscriptionGroup) {
      console.log('onTabClick', subscriptionGroup);
      setActiveSubscriptionGroupTab(subscriptionGroup);
    }
  };

  const onTabTileClick = async (id: string) => {
    loaderSetProgress();
    try {
      const aPackage = planList[id];

      if (aPackage.product?.discounts?.length === 0) {
        await Purchases.purchasePackage({
          aPackage: aPackage,
        });
      } else {
        const discounts = aPackage?.product?.discounts ?? [];
        for (let i = 0; i < discounts.length; i++) {
          try {
            const paymentDiscount = await Purchases.getPromotionalOffer({
              product: aPackage.product,
              discount: discounts[i],
            });
            if (paymentDiscount) {
              await Purchases.purchaseDiscountedPackage({
                aPackage: aPackage,
                discount: paymentDiscount,
              });

              break; // Exit loop if purchase is successful
            }
          } catch (e: any) {
            if (e.code !== '18') {
              throw e; // Re-throw if error code is not 18
            }
            // Log the error and continue to the next discount
            console.log(
              `Discount ${i} failed with error code 18, trying next discount...`,
            );
          }
        }
        await Purchases.purchasePackage({
          aPackage: aPackage,
        });
      }

      loaderHide();
    } catch (e) {
      loaderHide();
      console.log(JSON.stringify(e));
    }
  };

  return {
    // todo add filtering of final products
    plansList: !!import.meta.env.VITE_EMULATE_SUBCRIPTIONS
      ? mockedPlansInfo[activeSubscriptionGroupTab]
      : activePlanInformation,
    onTabTileClick,
    planBenefits:
      activeSubscriptionGroupTab === SubscriptionGroup.plus
        ? [
            {
              title: t({ id: 'menu.plan.personal.storage-title' }),
              description: t({ id: 'menu.plan.personal.storage-description' }),
            },
          ]
        : [
            {
              title: t({ id: 'menu.plan.family.storage-title' }),
              description: t({ id: 'menu.plan.family.storage-description' }),
            },
          ],
    onTabClick,
    activePlanTab: activeSubscriptionGroupTab,
    loader,
  };
};
