import { hasError } from '@/services/form-service';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import ReactCodeInput from 'react-verification-code-input';
import { AppFormFieldError } from './app-form-field-error';

type AppFormOtpCodeFieldProps = {
  name: string;
};

export const AppFormOtpCodeField = ({ name }: AppFormOtpCodeFieldProps) => {
  const context = useFormContext();

  const {
    register,
    control,
    setValue,
    formState: { isDirty, errors },
  } = context;
  const { showError, error } = hasError({ errors: errors, name, isDirty });
  register(name);
  const value: string = useWatch({ name, control });

  const onChangeCodeField = (value: string) => {
    setValue(name, value);
  };

  useEffect(() => {
    if (value === '') {
      Array.from(
        document.querySelectorAll<HTMLInputElement>(
          '.app-form__control input[type=tel]',
        ),
      ).forEach((item) => {
        item.value = '';
      });
    }
  }, [value]);

  return (
    <div className='w-full mb-[5px] box-border relative'>
      <ReactCodeInput
        fields={4}
        className='app-form__control w-full !flex justify-center'
        onChange={onChangeCodeField}
        autoFocus
      />
      <AppFormFieldError {...{ name, showError, error }} />
    </div>
  );
};
