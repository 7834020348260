import { AppBackButton } from '@/components/app-back-button';
import { AppFilePreview } from '@/components/app-file-preview';
import { AppType, ReceiptType } from '@/core.types';
import { hasAppAccess } from '@/services/app-service';
import c from 'classnames';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { AppIcon } from '../components';
import { AppConfirmModal } from '../components/app-confirm-modal';
import { ShareModal } from '../components/share-modal';
import { usePreloadImage } from '../hooks/use-preload-image';
import { receiptToEditAtom } from '../store';
import { PreviewReceiptControls } from './preview-receipt-controls';
import { usePreviewReceiptLogic } from './use-preview-receipt-logic';

export const PreviewReceipt = () => {
  const { formatMessage: t } = useIntl();
  const history = useHistory();
  const {
    infoItems,
    previewBottomControls,
    shareModalOpened,
    closeShareModal,
    deleteModalOpened,
    closeDeleteModal,
    moveBillingDocument,
    moveBillingDocumentModalOpened,
    moveBillingDocumentModal,
    closeBillingDocumentModal,
    deleteCurrentReceipt,
    imageContainerHeight,
    isPDFFile,
    onShareEnd,
  } = usePreviewReceiptLogic();
  const receiptToEdit = useRecoilValue(receiptToEditAtom);

  const preloadImage = usePreloadImage(receiptToEdit?.imagePath, isPDFFile);

  if (!receiptToEdit || !preloadImage) {
    return null;
  }

  return (
    <>
      <div className="bg-[var(--photoBackground)] h-[100vh] w-full p-[var(--sat)_0_var(--sab)_0] grid grid-rows-[50px_auto_50px_75px] box-border relative laptop:grid-rows-[55px_auto_75px_100px] before:content-[''] before:block before:absolute before:w-full before:left-0 before:top-0 before:bottom-0 before:h-[var(--sat)] before:bg-[var(--primary)] after:content-[''] after:block after:absolute after:w-full after:left-0 after:right-0 after:bottom-0 after:h-[var(--sab)] after:bg-[var(--primary)]">
        <div className='flex items-stretch bg-[var(--primary)] pl-[15px]'>
          <AppBackButton
            onBackClick={history.goBack}
            label={t({ id: 'back' })}
          />
        </div>
        {/* !NOTE-we need here extra div for calculating button height */}
        <div className='flex flex-col'>
          <AppFilePreview
            {...{
              isPDFFile,
              imageContainerHeight,
              preloadImage,
              name: receiptToEdit.name,
            }}
          />
          {receiptToEdit.type !== ReceiptType.document && (
            <div
              onClick={moveBillingDocumentModal}
              className='cursor-pointer h-[40px] flex justify-center items-center bg-[var(--secondary)] text-white rounded-[10px] mt-[10px] mx-auto w-full max-w-[340px] laptop:mt-[20px]'
            >
              <AppIcon className='mr-[10px]' name='payment' />
              <span>
                {receiptToEdit?.type == ReceiptType.invoice
                  ? t({ id: 'move.to.receipts' })
                  : t({ id: 'move.to.invoices' })}
              </span>
            </div>
          )}
        </div>
        <div
          className={c(
            'flex flex-wrap justify-center items-center box-border gap-[5px]',
            {
              'flex-wrap': hasAppAccess(AppType.idocument),
            },
          )}
        >
          {infoItems.map((item) => (
            <div
              className='flex flex-col items-center text-white gap-[5px] w-[92px] laptop:w-[130px]'
              key={item.title}
            >
              <span className='font-medium text-[12px] leading-[14px] text-center text-[var(--actionText50)]'>
                {item.title}
              </span>
              <div className='w-full font-normal text-[13px] leading-[14px] text-center text-[var(--actionText)] overflow-hidden whitespace-nowrap text-ellipsis'>
                {item.value}
              </div>
            </div>
          ))}
        </div>
        <PreviewReceiptControls {...{ previewBottomControls }} />
      </div>
      <ShareModal
        {...{
          onShareEnd,
          shareModalOpened,
          closeShareModal,
          selectedReceiptIdsList: [receiptToEdit.id],
        }}
      />
      <AppConfirmModal
        open={deleteModalOpened}
        onConfirmClick={deleteCurrentReceipt}
        title={t({ id: 'alert' })}
        onClose={closeDeleteModal}
      />
      <AppConfirmModal
        open={moveBillingDocumentModalOpened}
        onConfirmClick={moveBillingDocument}
        title={
          receiptToEdit.type == ReceiptType.invoice
            ? t({ id: 'move.to.receipts.modal.title' })
            : t({ id: 'move.to.invoices.modal.title' })
        }
        onClose={closeBillingDocumentModal}
      />
    </>
  );
};
