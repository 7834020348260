import { PAGE_ROUTES } from '@/app.constants';
import { AppForm, AppFormTextField } from '@/components/app-form';
import { AppFormSubmitButton } from '@/components/app-form/app-form-submit-button';
import { AppLoader } from '@/components/app-loader';
import {
  AuthPageImage,
  AuthPageTitle,
  AuthPageWrapper,
} from '@/components/auth-base';
import { useResetPasswordMutation } from '@/graphql/codegen/graphql';
import {
  FORM_VALIDATION_RESOLVER,
  setFormServerErrors,
} from '@/scenes/common-auth-logic';
import { getUserToken, setAccountIdToken } from '@/services/auth-service';
import { useIsAuthorizedAtomState } from '@/store/root-store';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

export const ResetPassword = () => {
  const { formatMessage: t } = useIntl();
  const [resetPasswordMutation, { loading }] = useResetPasswordMutation();
  const { setIsAuthorised } = useIsAuthorizedAtomState();
  const history = useHistory();
  const { pathname } = useLocation();

  const onSubmit = async (
    { password }: { password: string },
    _: any,
    { setError }: { setError: any },
  ) => {
    try {
      const pathList = pathname.split('/');
      await setIsAuthorised(pathList[pathList.length - 1]);
      const { data } = await resetPasswordMutation({
        variables: {
          newPassword: password,
          token: getUserToken() ?? '',
        },
      });

      const token = data?.resetPassword.token;
      const id = data?.resetPassword.account?.id;

      if (token && id) {
        // !Note- order is important here - todo refactor
        setAccountIdToken(id);
        await setIsAuthorised(token);
        history.push(PAGE_ROUTES.category);
      }
    } catch (errorResponse: any) {
      setFormServerErrors(errorResponse, setError);
    }
  };

  const onLogoClick = () => {
    history.push(PAGE_ROUTES.auth);
  };

  return (
    <AuthPageWrapper>
      <AuthPageImage onClick={onLogoClick} />
      <AuthPageTitle>{t({ id: 'reset.h1' })}</AuthPageTitle>

      <AppForm
        onSubmit={onSubmit}
        className='auth__form'
        formConfig={{ resolver: FORM_VALIDATION_RESOLVER }}
      >
        <AppFormTextField
          type='password'
          name='password'
          icon={{ name: 'password' }}
          placeholder={t({ id: 'reset.pass' })}
        />
        <AppFormSubmitButton>{t({ id: 'btn.submit' })}</AppFormSubmitButton>
      </AppForm>
      {loading && <AppLoader />}
    </AuthPageWrapper>
  );
};
