import { useReceiptDNDLogic } from '@/category/use-receipt-dnd-logic';
import { AppTappable } from '@/components/app-tappable';
import { hasAppAccess } from '@/services/app-service';
import { editModeEnabledATOM } from '@/store/category-store';
import c from 'classnames';
import { useRecoilValue } from 'recoil';
import { AppIcon } from '@/components';
import { AppPseudoRadio } from '@/components/app-pseudo-radio';
import { AppType, LocalReceipt } from '@/core.types';
import { useDetectMobileResolution } from '@/hooks';
import { useReceiptItemLogic } from '../use-receipt-item-logic';
import { currencyFormatter } from '@/services/helper-service';
import { AppHasAccess } from '@/components/app-has-access';

const RECEIPT_MOBILE_ICON_SIZE = {
  width: 24,
  height: 29,
};

const RECEIPT_DESKTOP_ICON_SIZE = {
  width: 29,
  height: 35,
};

export const CategoryItemTableRowReceipt = (localReceipt: LocalReceipt) => {
  const editModeEnabled = useRecoilValue(editModeEnabledATOM);
  const {
    onItemClick,
    date,
    currency,
    isItemActive,
    itemName,
    isReceiptItemEmpty,
  } = useReceiptItemLogic(localReceipt);
  const { createdBy } = localReceipt;
  const { drag } = useReceiptDNDLogic(localReceipt.id);
  const isMobileResolution = useDetectMobileResolution();

  const createdByBlock = (
    <div className='text-[6px] leading-[7px] uppercase text-[var(--textInfo)] pt-[2px] tablet:text-[14px] tablet:leading-[16px] tablet:w-[25%] tablet:pt-0'>
      <span className='font-black'>BY </span>
      {createdBy}
    </div>
  );

  return (
    <div
      className='border-b border-[#e9e9e9] last:border-b-0 last:mb-0'
      ref={drag}
    >
      <AppTappable
        onTap={onItemClick}
        className='flex items-center justify-between h-[40px] cursor-pointer box-border tablet:justify-start drop-container'
      >
        <div className='w-1/2 flex justify-start items-center ml-[4px] tablet:w-1/4 tablet:box-content tablet:ml-[8px]'>
          {editModeEnabled && (
            <AppPseudoRadio
              {...{
                isItemActive,
                className:
                  'm-[0_4px] shadow-none bg-transparent min-w-[16px] before:w-[6px] before:h-[6px]',
              }}
            />
          )}
          <div className='min-w-[30px] flex justify-center tablet:min-w-[35px]'>
            <AppIcon
              size={
                isMobileResolution
                  ? RECEIPT_MOBILE_ICON_SIZE
                  : RECEIPT_DESKTOP_ICON_SIZE
              }
              name='receipt'
            />
          </div>
          <div
            className={c(
              'font-medium text-[14px] leading-[17px] text-[var(--textMain60)] pl-[8px] pr-[12px] overflow-hidden text-ellipsis whitespace-nowrap tablet:text-[16px] tablet:leading-[19px] tablet:pl-[12px]',
              {
                'text-[#ef2d2d]': isReceiptItemEmpty,
              },
            )}
          >
            {itemName}
            {isMobileResolution && createdByBlock}
          </div>
        </div>
        {!isMobileResolution && createdByBlock}

        <AppHasAccess appType={AppType.ireceipt}>
          <div className='font-bold text-[13px] leading-[15px] text-[var(--textMain)] w-[25%] tablet:text-[16px] tablet:leading-[14px]'>
            {currencyFormatter(currency, localReceipt?.amount)}
          </div>
        </AppHasAccess>

        <div
          className={c(
            'text-[13px] leading-[15px] text-[#979797] text-right w-[18%] pr-[4px] tablet:w-[25%] tablet:text-[16px] tablet:leading-[19px] tablet:text-left tablet:pr-0',
            {
              'text-[#ef2d2d]': isReceiptItemEmpty,
            },
          )}
        >
          {date}
        </div>
      </AppTappable>
    </div>
  );
};
