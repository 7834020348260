import { useFolderItemLogic } from '@/category/use-folder-item-logic';
import { AppHasAccess } from '@/components/app-has-access';
import { AppTappable } from '@/components/app-tappable';
import {
  editModeEnabledATOM,
  selectedCategoryIDsAtom,
} from '@/store/category-store';
import c from 'classnames';
import { useContext } from 'react';
import { useRecoilValue } from 'recoil';
import { AppIcon } from '@/components';
import { AppPseudoRadio } from '@/components/app-pseudo-radio';
import { AppType, LocalCategory } from '@/core.types';
import { useDetectMobileResolution } from '@/hooks';
import { useCategoryDndLogic } from '../use-category-dnd-logic';
import { CategoryTreeContext } from '@/category-tree-provider/category-tree-provider';
import { currencyFormatter } from '@/services/helper-service';

const MOBILE_FOLDER_ICON_SIZE = { width: 30, height: 25 };
const DESKTOP_FOLDER_ICON_SIZE = { width: 35, height: 28 };

export const CategoryItemTableRowFolder = (localCategory: LocalCategory) => {
  const editModeEnabled = useRecoilValue(editModeEnabledATOM);
  const { onItemClick, isItemActive, itemName, currency } =
    useFolderItemLogic(localCategory);
  const { isOver, isDraggable, drop, drag } = useCategoryDndLogic(
    localCategory.id,
  );
  const selectedCategoryIdsList = useRecoilValue(selectedCategoryIDsAtom);
  const { createdBy } = localCategory;
  const isMobileResolution = useDetectMobileResolution();
  const { isEmptyActiveSliceSelector } = useContext(CategoryTreeContext);
  const isEmpty = isEmptyActiveSliceSelector(localCategory);

  const createdByBlock = (
    <div className='text-[6px] leading-[7px] uppercase text-[var(--textInfo)] pt-[2px] tablet:text-[14px] tablet:leading-[16px] tablet:w-[25%] tablet:pt-0'>
      <span className='font-black'>BY </span>
      {createdBy}
    </div>
  );

  return (
    <div
      className='border-b border-[#e9e9e9] last:border-b-0 last:mb-0'
      ref={drag}
    >
      <AppTappable
        onTap={onItemClick}
        className='flex items-center justify-between h-[40px] cursor-pointer box-border tablet:justify-start drop-container'
      >
        <div
          className={c(
            'w-1/2 flex justify-start items-center ml-[4px] tablet:w-1/4 tablet:box-content tablet:ml-[8px]',
            'drop',
            {
              'opacity-[0.45]':
                !isDraggable &&
                isOver &&
                !selectedCategoryIdsList.includes(localCategory.id),
            },
          )}
          ref={drop}
        >
          {editModeEnabled && (
            <AppPseudoRadio
              {...{
                isItemActive,
                className:
                  'm-[0_4px] shadow-none bg-transparent min-w-[16px] before:w-[6px] before:h-[6px]',
              }}
            />
          )}
          <div className='min-w-[30px] flex justify-center tablet:min-w-[35px]'>
            <AppIcon
              name={isEmpty ? 'green-folder-empty' : 'green-folder-non-empty'}
              id={localCategory.id}
              size={
                isMobileResolution
                  ? MOBILE_FOLDER_ICON_SIZE
                  : DESKTOP_FOLDER_ICON_SIZE
              }
              className='justify-start'
            />
          </div>
          <div className='font-medium text-[14px] leading-[17px] text-[var(--textMain60)] pl-[8px] pr-[12px] overflow-hidden text-ellipsis whitespace-nowrap tablet:text-[16px] tablet:leading-[19px] tablet:pl-[12px]'>
            {itemName}
            {isMobileResolution && createdByBlock}
          </div>
        </div>
        {!isMobileResolution && createdByBlock}
        <AppHasAccess appType={AppType.ireceipt}>
          <div className='font-bold text-[13px] leading-[15px] text-[var(--textMain)] w-[25%] tablet:text-[16px] tablet:leading-[14px]'>
            {currencyFormatter(currency, localCategory?.amount ?? 0)}
          </div>
        </AppHasAccess>
        <div
          className={c(
            'text-[13px] leading-[15px] text-[#979797] text-right w-[18%] pr-[4px] tablet:w-[25%] tablet:text-[16px] tablet:leading-[19px] tablet:text-left tablet:pr-0',
            {
              'text-[#ef2d2d]': true,
            },
          )}
        />
      </AppTappable>
    </div>
  );
};
