import { uploadFile } from '@/gql/api-receipt';
import { useGetServerPdfLink } from '@/gql/use-get-server-pdf-link';
import {
  useGetPDFBlobFromSelectedList,
  useGetReceiptsFromSelectedTitle,
} from '@/hooks/use-get-pdf-blob-from-selected-list';
import { copyToClipboard, resolveFilePath } from '@/services/helper-service';
import { downloadBlob } from '@/services/pdf-service';
import { isApplicationOnlineAtom } from '@/store/root-store';
import { isPlatform } from '@ionic/react';
import noop from 'lodash/noop';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';
import type { ShareModalProps } from './share-modal';

export const useShareModalLogic = ({
  selectedCategoryIdsList = [],
  selectedReceiptIdsList = [],
  closeShareModal,
  onShareEnd = noop,
}: Omit<ShareModalProps, 'shareModalOpened'>) => {
  const isApplicationOnline = useRecoilValue(isApplicationOnlineAtom);
  const { getServerPDFLink, notifyPdfSaved } = useGetServerPdfLink();
  const { formatMessage: t } = useIntl();
  const isMobile = isPlatform('capacitor');
  const getPDFBlob = useGetPDFBlobFromSelectedList();
  const getTitle = useGetReceiptsFromSelectedTitle();
  const [loading, setLoading] = useState(false);

  const getSharePdfLink = async () => {
    let RETRY_COUNT = 3;

    while (RETRY_COUNT > 0) {
      try {
        setLoading(true);
        const title = await getTitle({
          selectedCategoryIdsList,
          selectedReceiptIdsList,
        });
        const {
          data: {
            // @ts-ignore
            createPdfLink: { id, path, uploadFileLink },
          },
        } = await getServerPDFLink(title);
        const pdfBlob = await getPDFBlob({
          selectedCategoryIdsList,
          selectedReceiptIdsList,
        });
        await uploadFile({
          url: uploadFileLink,
          file: pdfBlob,
        });

        await notifyPdfSaved(id);
        return resolveFilePath(path);
      } catch (e) {
        RETRY_COUNT--;
      } finally {
        setLoading(false);
      }
    }
  };

  const onDownloadClick = async () => {
    setLoading(true);
    const pdfBlob = await getPDFBlob({
      selectedCategoryIdsList,
      selectedReceiptIdsList,
    });
    const title = getTitle({
      selectedCategoryIdsList,
      selectedReceiptIdsList,
    });

    downloadBlob(pdfBlob, title + '.pdf');
    setLoading(false);
    closeShareModal();
    onShareEnd();
  };

  const onShareLinkOnDesktopClick = async () => {
    const pdfLink = await getSharePdfLink();
    copyToClipboard({
      link: pdfLink,
      message: t({ id: 'toast.share' }),
    });
    closeShareModal();
    onShareEnd();
  };

  const shareMultipleReceipts = async () => {
    if (!navigator.share) {
      return null;
    }
    const pdfLink = await getSharePdfLink();
    window.plugins.socialsharing.share(null, null, null, pdfLink);
    closeShareModal();
    onShareEnd();
  };

  const onSharePdfFileClick = async () => {
    setLoading(true);
    const filename = await getTitle({
      selectedCategoryIdsList,
      selectedReceiptIdsList,
    });
    const pdfBlob = await getPDFBlob({
      selectedCategoryIdsList,
      selectedReceiptIdsList,
    });
    const reader = new FileReader();
    reader.readAsDataURL(pdfBlob);
    reader.onloadend = function () {
      const base64data = reader.result;
      window.plugins.socialsharing.share(null, null, [
        'data:application/pdf;df:' + filename + '.pdf;base64,' + base64data,
      ]);
      setLoading(false);
      closeShareModal();
      onShareEnd();
    };
  };

  const ShareLinkOption = {
    onClick: !isMobile ? onShareLinkOnDesktopClick : shareMultipleReceipts,
    label: t({ id: 'modal.share.link' }),
    icon: 'share',
    hidden: !isApplicationOnline,
  };

  const DownloadLinkOption = {
    onClick: onDownloadClick,
    label: t({ id: 'modal.share.download' }),
    icon: 'pdf',
    hidden: false,
  };

  const SharePDFOption = {
    onClick: onSharePdfFileClick,
    label: t({ id: 'modal.share.pdf' }),
    icon: 'pdf',
    hidden: false,
  };
  const desktopButtons = [ShareLinkOption, DownloadLinkOption];
  const mobileButtons = [ShareLinkOption, SharePDFOption];
  const buttons = isMobile ? mobileButtons : desktopButtons;
  return {
    buttons: buttons.filter(({ hidden }) => !hidden),
    isMobile,
    loading: loading,
  };
};
