import { PAGE_ROUTES } from '@/app.constants';
import { useRootContext } from '@/context/root-context';
import { useDetectMobileResolution } from '@/hooks/use-detect-mobile-resolution';
import { useGetSaveAreaPaddings } from '@/hooks/use-get-safe-area-paddings';
import { hasAppAccess } from '@/services/app-service';
import { currencyFormatter, unixTimeToString } from '@/services/helper-service';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  useDeleteReceiptList,
  useUpdateReceiptList,
} from '../category-tree-hooks/use-receipts-sync-logic';
import { AppType, ReceiptType } from '../core.types';
import { useBooleanState, usePrint } from '../hooks';
import { useGetReceiptName } from '../hooks/use-get-receipt-name';
import { isApplicationOnlineAtom, receiptToEditAtom } from '../store';

export const usePreviewReceiptLogic = () => {
  const [shareModalOpened, openShareModal, closeShareModal] =
    useBooleanState(false);
  const [deleteModalOpened, openDeleteModal, closeDeleteModal] =
    useBooleanState(false);
  const [
    moveBillingDocumentModalOpened,
    moveBillingDocumentModal,
    closeBillingDocumentModal,
  ] = useBooleanState(false);
  const receiptToEdit = useRecoilValue(receiptToEditAtom);
  const { setActiveSliceId, rootID, currentUserCurrency } = useRootContext();
  const isApplicationOnline = useRecoilValue(isApplicationOnlineAtom);
  const updateReceiptList = useUpdateReceiptList();
  const deleteReceiptList = useDeleteReceiptList();
  const activeReceiptName = useGetReceiptName({
    name: receiptToEdit?.name,
    type: receiptToEdit?.type,
  });
  const isMobileResolution = useDetectMobileResolution();
  const print = usePrint();
  const { formatMessage: t } = useIntl();
  const history = useHistory();
  const { safeAreaTop, safeAreaBottom } = useGetSaveAreaPaddings();

  const moveBillingDocument = () => {
    if (!setActiveSliceId || !receiptToEdit) {
      return;
    }

    updateReceiptList([
      {
        ...receiptToEdit,
        parentId: rootID,
        type:
          receiptToEdit.type == ReceiptType.invoice
            ? ReceiptType.receipt
            : ReceiptType.invoice,
      },
    ]);
    history.goBack();
  };

  const onPrintClick = () => {
    if (!receiptToEdit) {
      return;
    }
    print({ receiptIds: [receiptToEdit.id] });
  };

  const onEditClick = () => {
    history.push(`${PAGE_ROUTES.category}${PAGE_ROUTES.updateReceipt}`);
  };
  const isPDFFile = receiptToEdit?.imagePath?.includes('pdf');

  const onShareEnd = () => {
    history.goBack();
  };

  const previewBottomControls = [
    {
      onClick: onEditClick,
      icon: 'edit',
      title: t({ id: 'preview.edit' }),
    },
    {
      onClick: openShareModal,
      icon: 'share',
      hidden: !isApplicationOnline,
      title: t({ id: 'preview.share' }),
    },
    {
      onClick: openDeleteModal,
      icon: 'trash',
      title: t({ id: 'preview.delete' }),
    },
    {
      onClick: onPrintClick,
      icon: 'print',
      title: t({ id: 'preview.print' }),
    },
  ].filter(({ hidden }) => !hidden);

  const infoItems = [
    {
      title: t({ id: 'preview.name' }),
      value: activeReceiptName,
    },
    {
      title: t({ id: 'preview.amount' }),
      value: currencyFormatter(currentUserCurrency, receiptToEdit?.amount ?? 0),
      hidden: hasAppAccess(AppType.idocument),
    },
    {
      title: t({ id: 'preview.date' }),
      value: unixTimeToString(receiptToEdit?.receiptDate),
    },
    {
      title: t({ id: 'preview.created' }),
      value: receiptToEdit?.createdBy,
    },
  ].filter(({ hidden }) => !hidden);

  const deleteCurrentReceipt = () => {
    if (!receiptToEdit) {
      return;
    }
    deleteReceiptList({
      ids: [receiptToEdit.id],
      parentId: setActiveSliceId,
    });
    history.goBack();
  };

  const imageContainerHeight = (() => {
    const isIreceipt = hasAppAccess(AppType.ireceipt);
    const moveBillingDocumentButtonHeight = isMobileResolution ? 50 : 60;
    const finalHeight =
      document.body.offsetHeight -
      (isMobileResolution ? 175 : 230) -
      (isIreceipt ? moveBillingDocumentButtonHeight : 0) -
      safeAreaBottom -
      safeAreaTop;

    return finalHeight;
  })();

  useEffect(() => {
    if (!receiptToEdit) {
      history.goBack();
    }
  }, [history, receiptToEdit]);

  return {
    onShareEnd,
    isPDFFile,
    imageContainerHeight,
    previewBottomControls,
    infoItems,
    shareModalOpened,
    closeShareModal,
    deleteModalOpened,
    closeDeleteModal,
    moveBillingDocument,
    moveBillingDocumentModalOpened,
    moveBillingDocumentModal,
    closeBillingDocumentModal,
    deleteCurrentReceipt,
  };
};
