import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import type { ReactNode } from 'react';
import { detectDesktopApplication } from '@/services/helper-service';

export const CategoryDndProvider = ({ children }: { children: ReactNode }) => {
  const isNativeApp = !!window.cordova;

  const getDropTargetElementsAtPoint = (
    x: number,
    y: number,
    dropTargets: any,
  ) => {
    return dropTargets.filter((target: any) => {
      const rect = target.getBoundingClientRect();
      return (
        x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
      );
    });
  };

  return (
    <DndProvider
      backend={TouchBackend}
      options={{
        delay: 150,
        enableMouseEvents: detectDesktopApplication(),
        getDropTargetElementsAtPoint:
          !isNativeApp && getDropTargetElementsAtPoint,
      }}
    >
      {children}
    </DndProvider>
  );
};
