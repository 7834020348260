import { AppButton } from '@/components/app-button';
import { AppIcon } from '../components';
import { useDetectMobileResolution } from '../hooks';

type PreviewReceiptControlsProps = {
  previewBottomControls: any[];
};

export type PreviewReceiptControlProps = {
  title: string;
  onClick(): void;
  icon: string;
};

const MOBILE_ICON_RESOLUTION = { width: 20, height: 20 };
const DESKTOP_ICON_RESOLUTION = { width: 30, height: 30 };

export const PreviewReceiptControls = ({
  previewBottomControls,
}: PreviewReceiptControlsProps) => {
  const renderPreviewImageIcon = (previewIcon: PreviewReceiptControlProps) => (
    <PreviewReceiptControl {...previewIcon} key={previewIcon.title} />
  );

  return (
    <div className='bg-[var(--primary)] grid items-stretch justify-evenly grid-cols-[repeat(4,80px)] box-border pt-[5px] pb-[5px] tablet:justify-center laptop:grid-cols-[repeat(4,130px)] laptop:pt-[10px] laptop:pb-[10px]'>
      {previewBottomControls.map(renderPreviewImageIcon)}
    </div>
  );
};

export const PreviewReceiptControl = ({
  title,
  onClick,
  icon,
}: PreviewReceiptControlProps) => {
  const isMobile = useDetectMobileResolution();
  const iconSize = isMobile ? MOBILE_ICON_RESOLUTION : DESKTOP_ICON_RESOLUTION;

  return (
    <AppButton
      className='box-content flex items-center flex-col gap-[10px] cursor-pointer justify-center'
      onPress={onClick}
    >
      <AppIcon name={icon} size={iconSize} />
      <span className='text-[10px] leading-[12px] w-full text-center text-ellipsis whitespace-nowrap overflow-hidden text-[var(--actionText)] laptop:mt-0 laptop:text-[14px] laptop:leading-[16px]'>
        {title}
      </span>
    </AppButton>
  );
};
