import { v4 as uuidv4 } from 'uuid';
import type { GreenFolderIconProps } from './green-folder-empty-icon';
import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';

export const GreenFolderNonEmptyIcon = ({
  size = { width: 137, height: 98 },
}: GreenFolderIconProps) => {
  const { width, height } = size;
  const id = uuidv4();
  const colorFront = `var(${APP_CSS_THEME_VARIABLES.folderColorFront})`;
  const colorBack = `var(${APP_CSS_THEME_VARIABLES.folderColorBack})`;

  return (
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 137 98'
    >
      <g filter={`url(#filter_folder-non-empty-${id})`}>
        <path
          d='M66.5 7.70613V6.2187C66.5 3.78802 64.398 2 61.5385 2H6.96154C4.10204 2 2 3.78802 2 6.20056V85.1614H131V12.0596C131 9.64705 128.678 7.68799 125.819 7.68799H66.5V7.70613Z'
          fill={colorBack}
        />
        <g filter={`url(#filter_folder-non-empty-second-${id})`}>
          <path
            d='M122 13.4897H10.6671C8.62053 13.4897 6.96143 15.1489 6.96143 17.1955V81.7431C6.96143 83.7897 8.62052 85.4488 10.6671 85.4488H122C124.047 85.4488 125.706 83.7897 125.706 81.7431V17.1955C125.706 15.1489 124.047 13.4897 122 13.4897Z'
            fill='white'
          />
          <path
            d='M10.6671 14.1074H122C123.706 14.1074 125.088 15.49 125.088 17.1955V81.7431C125.088 83.4486 123.706 84.8312 122 84.8312H10.6671C8.96162 84.8312 7.57904 83.4486 7.57904 81.7431V17.1955C7.57904 15.49 8.96163 14.1074 10.6671 14.1074Z'
            stroke='#C7C7C7'
            strokeWidth='1.23524'
          />
        </g>
        <path
          d='M62.7375 20.1779H7.1815C4.322 20.1779 2 22.3586 2 25.0643V87.1338C2 89.8193 4.322 92 7.1815 92H125.797C128.656 92 130.979 89.8193 130.979 87.1338V26.1923C130.979 22.775 128.204 20.0072 124.786 20.0161L62.7375 20.1779Z'
          fill={colorFront}
        />
      </g>
      <defs>
        <filter
          id={`filter_folder-non-empty-${id}`}
          x='0'
          y='0'
          width='137'
          height='98'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='2' dy='2' />
          <feGaussianBlur stdDeviation='2' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_2825_831'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_2825_831'
            result='shape'
          />
        </filter>
        <filter
          id={`filter_folder-non-empty-second-${id}`}
          x='2.02047'
          y='7.31356'
          width='128.627'
          height='81.8409'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='-1.23524' />
          <feGaussianBlur stdDeviation='2.47048' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0631944 0 0 0 0 0.0940691 0 0 0 0 0.291667 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_2825_831'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_2825_831'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
