import { APP_CSS_THEME_VARIABLES } from '@/color-theme.constants';
import noop from 'lodash';
import { AppIcon } from '../../components/app-icon';
import { AppPseudoRadio } from '../../components/app-pseudo-radio';
import { ProfileInfo } from '../../components/profile-info';
import { addClickHandler } from '@/services/helper-service';

export const ACCOUNT_ITEM_TYPES = {
  gear: 'gear',
  radio: 'radio',
};

export const AccountItem = ({
  onClick = noop,
  userIcon,
  ownerFullName,
  userCurrency,
  type = ACCOUNT_ITEM_TYPES.radio,
  isActive = false,
}: {
  userIcon: string;
  ownerFullName: string;
  userCurrency: string;
  onClick: any;
  type?: string;
  isActive?: boolean;
}) => {
  const colorTextMain20 = `var(${APP_CSS_THEME_VARIABLES.textMain20})`;

  return (
    <div
      className='cursor-pointer flex justify-between items-center w-full h-[82px]'
      {...addClickHandler(onClick)}
    >
      <ProfileInfo
        userIcon={userIcon}
        ownerFullName={ownerFullName}
        userCurrency={userCurrency}
      />
      {type === ACCOUNT_ITEM_TYPES.gear ? (
        <AppIcon
          name='gear'
          color={colorTextMain20}
          size={{ width: 22, height: 22 }}
        />
      ) : (
        <div className='account-item__radio'>
          <AppPseudoRadio isItemActive={isActive} />
        </div>
      )}
    </div>
  );
};
