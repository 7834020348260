import { RECOGNITION_LANGUAGES, RECOGNITION_TYPES } from '../core.types';

const RECOGNITION_KEY = 'recognition';

export const resolveRecognitionByLanguage = (language: string) => {
  const recognitionStore = localStorage.getItem(RECOGNITION_KEY);
  if (!recognitionStore) {
    const recognition = {
      languages: [RECOGNITION_LANGUAGES.en],
      recognitionType: RECOGNITION_TYPES.fast,
    };
    if (language !== 'en') {
      recognition.languages.push(RECOGNITION_LANGUAGES[language]);
    }
    localStorage.setItem(RECOGNITION_KEY, JSON.stringify(recognition));
  }
};
