import { PAGE_ROUTES } from '@/app.constants';
import { AppForm, AppFormTextField } from '@/components/app-form';
import { AppFormSubmitButton } from '@/components/app-form/app-form-submit-button';
import { AppLoader } from '@/components/app-loader';
import {
  AuthPageImage,
  AuthPageLink,
  AuthPageTitle,
  AuthPageWrapper,
} from '@/components/auth-base';
import { useLoginByEmail as useLoginByEmailM } from '@/gql/api-user';
import { useRequestDeviceNotifications } from '@/hooks/use-request-device-notifications';
import {
  FORM_VALIDATION_RESOLVER,
  setFormServerErrors,
} from '@/scenes/common-auth-logic';
import {
  clearAccountIdToken,
  setAccountIdToken,
} from '@/services/auth-service';
import { useIsAuthorizedAtomState } from '@/store/root-store';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';


type LoginDTO = {
  email: string;
  password: string;
};

export const LoginPage = () => {
  const { formatMessage: t } = useIntl();
  const { setIsAuthorised } = useIsAuthorizedAtomState();
  const requestDeviceNotifications = useRequestDeviceNotifications();
  const history = useHistory();

  const { loginByEmail: loginByEmailMutation, loading } = useLoginByEmailM();

  const onSubmit = async (values: LoginDTO, _, { setError }) => {
    try {
      clearAccountIdToken();
      const { data } = await loginByEmailMutation({
        variables: values,
      });
      const id = data?.loginByEmail?.account?.id;
      const token = data?.loginByEmail?.token;
      if (!id || !token) return;

      // !Note- order is important here - todo refactor
      setAccountIdToken(id);
      await setIsAuthorised(token);
      requestDeviceNotifications();
      history.push(PAGE_ROUTES.category);
    } catch (errorResponse: any) {
      setFormServerErrors(errorResponse, setError);
    }
  };

  const onLogoClick = () => {
    history.push(PAGE_ROUTES.auth);
  };

  return (
    <AuthPageWrapper>
      <AuthPageImage onClick={onLogoClick} />
      <AuthPageTitle>{t({ id: 'login.h1' })}</AuthPageTitle>
      <AppForm
        onSubmit={onSubmit}
        className='w-full max-w-[300px]'
        formConfig={{
          resolver: FORM_VALIDATION_RESOLVER,
          defaultValues: {
            email: '',
            password: '',
          },
        }}
      >
        <AppFormTextField
          type='email'
          name='email'
          placeholder={t({ id: 'login.email' })}
          inputClassName='bg-[var(--mainBackground)]'
          icon={{
            name: 'mail',
          }}
        />
        <AppFormTextField
          type='password'
          name='password'
          inputClassName='bg-[var(--mainBackground)]'
          icon={{ name: 'password' }}
          placeholder={t({ id: 'signUp.pass' })}
        />
        <AppFormSubmitButton>{t({ id: 'btn.login' })}</AppFormSubmitButton>
        <AuthPageLink to={PAGE_ROUTES.otpLogin}>
          {t({ id: 'login.otp' })}
        </AuthPageLink>

        <AuthPageLink to={PAGE_ROUTES.forgotPassword}>
          {t({ id: 'login.forgot' })}
        </AuthPageLink>
      </AppForm>

      <AuthPageLink to='/sign-up'>
        {t({ id: 'login.footer' })} {t({ id: 'login.footer.up' })}
      </AuthPageLink>
      {loading && <AppLoader />}
    </AuthPageWrapper>
  );
};
