import type { Option } from '@/components/app-select';
import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
  SegmentChangeEventDetail,
} from '@ionic/react';
import c from 'classnames';
import { AppIonLabel } from '../app-ion-label';

import './app-ion-switch.css';

type AppIonSwitchProps = {
  className?: string;
  options: Option[];
  value: string;
  onChange: (event: CustomEvent<SegmentChangeEventDetail>) => void;
  label?: string;
};

export const AppIonSwitch = ({
  className,
  options,
  value,
  onChange,
  label,
}: AppIonSwitchProps) => {
  return (
    <div className={c('app-ion-switch', className)}>
      {!!label && (
        <AppIonLabel className='app-ion-switch__label' label={label} />
      )}
      <IonSegment
        className='app-ion-switch__switch'
        mode='ios'
        value={value}
        onIonChange={onChange}
      >
        {options.map(({ value, label }: Option) => (
          <IonSegmentButton
            className='app-ion-switch__button'
            key={value}
            value={value}
          >
            <IonLabel>{label}</IonLabel>
          </IonSegmentButton>
        ))}
      </IonSegment>
    </div>
  );
};
